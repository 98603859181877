import { useRef, MutableRefObject, useEffect } from 'react';

export const useLiveRef = <T>(value: T): MutableRefObject<T> => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
