import { pipe } from 'fp-ts/function';
import { DateFromISOString } from 'io-ts-types';

import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, urlWithQueryParams } from 'utils/request';

import { TStoreId } from '../stores';
import {
  CustomerLabelArrayValidator,
  CustomerTierArrayValidator,
  TopCustomerArrayValidator,
  CustomerRetentionRateArrayValidator,
  CustomerRiskArrayValidator,
  CustomerChurnArrayValidator,
  TCustomerLabel,
  TCustomerTier,
  CustomerLostGroupArrayValidator,
  CustomerMapDataValidator,
  CustomerBehaviorMapDataValidator,
  CustomerMapExportLinkValidator,
} from './types';
import { TGetAnyCsvParams } from 'domain/core';
import { Filters } from 'hooks/useFiltersContext';

const API_URLS = {
  getCustomerLabels: () => `${API_BASE_URL}/customer/label`,
  getCustomerTiers: () => `${API_BASE_URL}/customer/tier`,
  getTopCustomers: () => `${API_BASE_URL}/customer/top`,
  getTopCustomersCSV: () => `${API_BASE_URL}/customer/top/xlsx`,
  getCustomersRetentionRate: () => `${API_BASE_URL}/customer/retention_rate`,
  getCustomersRisk: () => `${API_BASE_URL}/customer/risk`,
  getCustomersChurn: () => `${API_BASE_URL}/customer/churn`,
  getCustomersLostGroups: () => `${API_BASE_URL}/customer/lost_group`,
  getLostCustomersExport: () => `${API_BASE_URL}/customer/lost`,
  getCustomersCategoryMap: () => `${API_BASE_URL}/customer/category/map`,
  getCustomersBehaviorMap: () => `${API_BASE_URL}/customer/behavior/map`,
  getCustomersMapExport: () => `${API_BASE_URL}/customer/map/download/xlsx`,
  postCreateExport: () => `${API_BASE_URL}/customer/map/create/xlsx`,
};

export interface TGetCustomerLabelsParams {
  store_id?: TStoreId | Array<TStoreId>;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_labels_customer_label_get
 */
export const getCustomerLabels = ({ store_id }: TGetCustomerLabelsParams = {}) =>
  pipe(
    API_URLS.getCustomerLabels(),
    urlWithQueryParams({ store_id }),
    createValidatedCancellableFetch(CustomerLabelArrayValidator),
  );

export interface TGetCustomerTiersParams {
  store_id?: TStoreId | Array<TStoreId>;
  label?: string | string[];
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_tiers_customer_tier_get
 */
export const getCustomerTiers = ({ store_id, label }: TGetCustomerTiersParams = {}) =>
  pipe(
    API_URLS.getCustomerTiers(),
    urlWithQueryParams({ store_id, label }, { allowEmptyString: true }),
    createValidatedCancellableFetch(CustomerTierArrayValidator),
  );

export interface TGetTopCustomersParams {
  store_id?: TStoreId | Array<TStoreId>;
  label?: string;
  min_average_transaction?: number;
  max_average_transaction?: number;
  paging_bookmark?: string;
  paging_forward?: boolean;
  limit?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_top_customers_customer_top_get
 */
export const getTopCustomers = ({ limit = 15, ...queryParams }: TGetTopCustomersParams = {}) =>
  pipe(
    API_URLS.getTopCustomers(),
    urlWithQueryParams({ ...queryParams, limit }, { allowEmptyString: true }),
    createValidatedCancellableFetch(TopCustomerArrayValidator),
  );

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_top_customers_customer_top_csv_get
 */
export const getTopCustomersCSVLink = ({
  limit = 15,
  ...queryParams
}: TGetTopCustomersParams & TGetAnyCsvParams = {}) =>
  pipe(API_URLS.getTopCustomersCSV(), urlWithQueryParams({ ...queryParams, limit }, { allowEmptyString: true }));

export interface TGetCustomersRetentionRateParams {
  store_id?: TStoreId | Array<TStoreId>;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_retention_customer_retention_rate_get
 */
export const getCustomersRetentionRate = (queryParams: TGetCustomersRetentionRateParams) =>
  pipe(
    API_URLS.getCustomersRetentionRate(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(CustomerRetentionRateArrayValidator),
  );

export interface TGetCustomersRiskParams {
  store_id?: TStoreId | Array<TStoreId>;
  until: Date;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_risk_groups_customer_risk_get
 */
export const getCustomerGroupsRisk = ({ until, ...queryParams }: TGetCustomersRiskParams) =>
  pipe(
    API_URLS.getCustomersRisk(),
    urlWithQueryParams({ ...queryParams, until: DateFromISOString.encode(until) }),
    createValidatedCancellableFetch(CustomerRiskArrayValidator),
  );

export interface TGetCustomersChurnParams {
  store_id: TStoreId | Array<TStoreId>;
  first: Date;
  end: Date;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_churn_customer_churn_get
 */
export const getCustomersChurn = ({ first, end, ...queryParams }: TGetCustomersChurnParams) =>
  pipe(
    API_URLS.getCustomersChurn(),
    urlWithQueryParams({
      ...queryParams,
      first: DateFromISOString.encode(first),
      end: DateFromISOString.encode(end),
    }),
    createValidatedCancellableFetch(CustomerChurnArrayValidator),
  );

export interface TGetCustomersLostGroupsParams {
  store_id: TStoreId | Array<TStoreId>;
  first: Date;
  end: Date;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_lost_groups_customer_lost_group_get
 */
export const getCustomersLostGroups = ({ first, end, ...queryParams }: TGetCustomersLostGroupsParams) =>
  pipe(
    API_URLS.getCustomersLostGroups(),
    urlWithQueryParams({
      ...queryParams,
      first: DateFromISOString.encode(first),
      end: DateFromISOString.encode(end),
    }),
    createValidatedCancellableFetch(CustomerLostGroupArrayValidator),
  );

export interface TGetLostCustomersExportParams {
  store_id: TStoreId | Array<TStoreId>;
  first: Date;
  end: Date;
  tier: TCustomerTier['tier'];
  label: TCustomerLabel['label'];
}

export const getLostCustomersExportLink = ({
  first,
  end,
  ...queryParams
}: TGetLostCustomersExportParams & TGetAnyCsvParams) =>
  pipe(
    API_URLS.getLostCustomersExport(),
    urlWithQueryParams({
      ...queryParams,
      first: DateFromISOString.encode(first),
      end: DateFromISOString.encode(end),
    }),
  );

export interface TGetCustomersMapParams {
  // TODO: remove string after adding store selector
  store_id: TStoreId | string;
}

export const getCustomersCategoryMapData = ({ store_id }: TGetCustomersMapParams) =>
  pipe(
    API_URLS.getCustomersCategoryMap(),
    urlWithQueryParams({ store_id }),
    createValidatedCancellableFetch(CustomerMapDataValidator),
  );

export interface TGetCutomersMapDownloadExportParams {
  filename?: string;
}

export const getCustomersMapExportLink = ({ filename }: TGetCutomersMapDownloadExportParams) =>
  pipe(API_URLS.getCustomersMapExport(), urlWithQueryParams({ filename }));

export const getCustomerBehaviorMapData = ({ store_id }: TGetCustomersMapParams) =>
  pipe(
    API_URLS.getCustomersBehaviorMap(),
    urlWithQueryParams({ store_id }),
    createValidatedCancellableFetch(CustomerBehaviorMapDataValidator),
  );

export interface TPostCreateCustomersMapExportParams extends TGetCustomersMapParams {
  filters?: Filters;
  customer_ids?: Array<string>;
}

export const postCreateExportLink = ({ store_id, ...rest }: TPostCreateCustomersMapExportParams) =>
  pipe(
    API_URLS.postCreateExport(),
    urlWithQueryParams({ store_id }),
    createValidatedCancellableFetch(CustomerMapExportLinkValidator, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(rest),
    }),
  );
