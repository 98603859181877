import { pipe } from 'fp-ts/function';

import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, urlWithQueryParams } from 'utils/request';

import {
  ClusterMapGroupsValidator,
  ClusterMapStoreWeeklyPositionsValidator,
  ClusterMapStoreCharacteristicsValidator,
  MapLayersValidator,
} from './types';
import { TStoreId } from 'domain/stores';
import { dateToYMDorUndefined } from 'utils/date';

const API_URLS = {
  getClusterMapGroups: () => `${API_BASE_URL}/map/groups`,
  getClusterMapStorePosition: () => `${API_BASE_URL}/map/position`,
  getClusterMapCharacteristics: () => `${API_BASE_URL}/map/characteristics`,
  getMapLayers: () => `${API_BASE_URL}/map/layers`,
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_map_layers_map_layers_get
 */
export const getMapLayers = () => pipe(API_URLS.getMapLayers(), createValidatedCancellableFetch(MapLayersValidator));

/**
 *
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_groups_map_groups_get
 */
export const getClusterMapGroups = () =>
  pipe(API_URLS.getClusterMapGroups(), createValidatedCancellableFetch(ClusterMapGroupsValidator));

export interface GetClusterMapStorePositionsParams {
  date_from?: Date;
}

const getClusterMapStorePositionsDefaultParams: GetClusterMapStorePositionsParams = {};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_store_position_map_position_get
 */
export const getClusterMapStorePositions = ({ date_from, ...queryParams } = getClusterMapStorePositionsDefaultParams) =>
  pipe(
    API_URLS.getClusterMapStorePosition(),
    urlWithQueryParams({
      date_from: dateToYMDorUndefined(date_from),
      ...queryParams,
    }),
    createValidatedCancellableFetch(ClusterMapStoreWeeklyPositionsValidator),
  );

export interface GetMapCharacteristicsParams {
  storeIds?: TStoreId[];
  date_from?: Date;
  date_to?: Date;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_store_characteristics_map_characteristics_get
 */
export const getClusterMapCharacteristics = ({
  storeIds,
  date_from,
  date_to,
  ...queryParams
}: GetMapCharacteristicsParams = {}) =>
  pipe(
    API_URLS.getClusterMapCharacteristics(),
    urlWithQueryParams({
      store_id: storeIds,
      date_from: dateToYMDorUndefined(date_from),
      date_to: dateToYMDorUndefined(date_to),
      ...queryParams,
    }),
    createValidatedCancellableFetch(ClusterMapStoreCharacteristicsValidator),
  );
