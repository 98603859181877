import React from 'react';
import { useRadio, UseRadioProps, Box } from '@chakra-ui/react';

interface Props extends UseRadioProps {}

export const StoreRoleRadio: React.FC<Props> = ({ children, ...rest }) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  return (
    <Box as="label">
      <input {...getInputProps()} />
      <Box
        {...getCheckboxProps()}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={4}
        py={2}
      >
        {children}
      </Box>
    </Box>
  );
};
