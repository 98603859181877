import { API_BASE_URL } from 'constants/env';
import { pipe } from 'fp-ts/function';
import { createValidatedCancellableFetch, makeCancellableFetch, urlWithQueryParams } from 'utils/request';
import {
  SaveStoreGroupParams,
  StoreArrayValidator,
  StoreGroupArrayValidator,
  SimilarStoresValidator,
  TGetSimilarStoresParams,
} from './types';

const API_URLS = {
  getAllStores: () => `${API_BASE_URL}/store/`,
  getSimilarStores: () => `${API_BASE_URL}/similar_stores/`,
  deleteStoreGroup: (group_id: number) => `${API_BASE_URL}/store_group/${group_id}`,
  getStoresGroups: () => `${API_BASE_URL}/store_group/`,
  saveStoreGroup: () => `${API_BASE_URL}/store_group/`,
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_store__get
 */
export const getAllStores = () => createValidatedCancellableFetch(StoreArrayValidator)(API_URLS.getAllStores());

export const getSimilarStores = (queryParams: TGetSimilarStoresParams) =>
  pipe(
    API_URLS.getSimilarStores(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(SimilarStoresValidator),
  );

export const getStoresGroups = () =>
  createValidatedCancellableFetch(StoreGroupArrayValidator)(API_URLS.getStoresGroups());

export const deleteStoreGroup = (group_id: number) => {
  return makeCancellableFetch(API_URLS.deleteStoreGroup(group_id), {
    method: 'DELETE',
  });
};

export const saveStoreGroup = ({ name, store_ids, store_group_id }: SaveStoreGroupParams) => {
  const payload: SaveStoreGroupParams = {
    name,
    store_ids,
  };
  if (store_group_id) payload['store_group_id'] = store_group_id;
  return makeCancellableFetch(API_URLS.saveStoreGroup(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};
