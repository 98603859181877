import {
  endOfISOWeek,
  format,
  formatISO,
  isSameDay,
  parseISO,
  startOfDay,
  startOfToday,
  subDays,
  subWeeks,
} from 'date-fns';
import { DateFromISOString } from 'io-ts-types';

export const dateToYMD = (d: Date) => formatISO(d, { representation: 'date' });

export const toDate = (s: string) => new Date(s);

export const dateToHM = (d: Date) => format(d, 'HH:mm');

export const dateToYMDHM = (d: Date) => `${dateToYMD(d)} ${format(d, 'HH:mm')}`;

export const dateToYMDorUndefined = (d: unknown) => (d instanceof Date ? dateToYMD(d) : undefined);

export const dateToDDMMEEE = (d: Date, locale: Locale | undefined) => format(d, 'dd-MM EEE', { locale: locale });

export const dateToISOString = (d: unknown) => (d instanceof Date ? DateFromISOString.encode(d) : undefined);

export const getCurrentDayOfWeek = (d: Date, locale: Locale | undefined) => format(d, 'EEEE', { locale: locale });

export const getCurrentDate = () => startOfToday();

// FIXME(m.kania): this should be propagated with some api data
export const getEarliestDate = () => startOfDay(parseISO('2019-01-01'));

// NOTE(m.kania): if current date is the last day of ISO week then return it, otherwise use last week
export const lastCompleteISOWeek = (d: Date) => {
  const end = endOfISOWeek(d);

  return isSameDay(d, end) ? d : subWeeks(end, 1);
};

export const prepareDayStartValues = (lastDays: number, yesterday: Date, data: { time: string }[]): string[] => {
  const dates = Array.from({ length: lastDays })
    .map((_, index) => {
      const date = format(!index ? yesterday : subDays(yesterday, index), 'yyyy-MM-dd');
      return data.find((d) => d.time.includes(date))?.time ?? '';
    })
    .reverse();

  const last = data.at(-1);
  if (last) dates.push(last.time);

  return dates.filter((d) => d);
};
