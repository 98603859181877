import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

import { StoreIdValidator } from '../stores';

const LabelValidator = t.string;
const TierValidator = t.number;

export const CustomerLabelValidator = t.type({
  label: LabelValidator,
  count: t.number,
  percent: t.number,
});
export type TCustomerLabel = t.TypeOf<typeof CustomerLabelValidator>;

export const CustomerLabelArrayValidator = t.array(CustomerLabelValidator);
export type TCustomerLabelArray = t.TypeOf<typeof CustomerLabelArrayValidator>;

export const CustomerTierValidator = t.type({
  tier: TierValidator,
  count: t.number,
  percent: t.number,
  min_average_transaction: t.number,
  max_average_transaction: t.number,
});
export type TCustomerTier = t.TypeOf<typeof CustomerTierValidator>;

export const CustomerTierArrayValidator = t.array(CustomerTierValidator);
export type TCustomerTierArray = t.TypeOf<typeof CustomerTierArrayValidator>;

export const TopCustomerValidator = t.type({
  id: t.string,
  card_id: t.string,
  store_id: StoreIdValidator,
  label: t.string,
  average_transaction: t.number,
  transaction_count: t.number,
});
export type TTopCustomer = t.TypeOf<typeof TopCustomerValidator>;

export const TopCustomerArrayValidator = t.array(TopCustomerValidator);
export type TTopCustomerArray = t.TypeOf<typeof TopCustomerArrayValidator>;

export const CustomerRetentionRateValidator = t.type({
  month: DateFromISOString,
  retention: t.number,
});
export type TCustomerRetentionRate = t.TypeOf<typeof CustomerRetentionRateValidator>;

export const CustomerRetentionRateArrayValidator = t.array(CustomerRetentionRateValidator);
export type TCustomerRetentionRateArray = t.TypeOf<typeof CustomerRetentionRateArrayValidator>;

const TopProductFamiliesValidator = t.array(t.string);

export const CustomerRiskValidator = t.type({
  label: t.string,
  median_risk: t.number,
  median_daily_gross: t.number,
  group_size: t.number,
  group_daily_gross: t.number,
  top_product_families: TopProductFamiliesValidator,
});
export type TCustomerRisk = t.TypeOf<typeof CustomerRiskValidator>;

export const CustomerRiskArrayValidator = t.array(CustomerRiskValidator);
export type TCustomerRiskArray = t.TypeOf<typeof CustomerRiskArrayValidator>;

export const CustomerChurnValidator = t.type({
  date_from: DateFromISOString,
  date_to: DateFromISOString,
  lost: t.number,
  recaptured: t.number,
  new: t.number,
});
export type TCustomerChurn = t.TypeOf<typeof CustomerChurnValidator>;

export const CustomerChurnArrayValidator = t.array(CustomerChurnValidator);
export type TCustomerChurnArray = t.TypeOf<typeof CustomerChurnArrayValidator>;

export const CustomerLostGroupValidator = t.type({
  size: t.number,
  label: LabelValidator,
  tier: TierValidator,
  average_daily_gross: t.number,
  top_product_families: TopProductFamiliesValidator,
});
export type TCustomerLostGroup = t.TypeOf<typeof CustomerLostGroupValidator>;

export const CustomerLostGroupArrayValidator = t.array(CustomerLostGroupValidator);
export type TCustomerLostGroupArray = t.TypeOf<typeof CustomerLostGroupArrayValidator>;

export const CustomerMapValuesValidator = t.array(t.array(t.number));
export type TCustomerMapValues = t.TypeOf<typeof CustomerMapValuesValidator>;

export const LabelsValidator = t.array(t.string);
export type TCategories = t.TypeOf<typeof LabelsValidator>;

export const CustomerBaseMapsDataValidator = t.type({
  positions: t.array(t.tuple([t.number, t.number])),
  labels: LabelsValidator,
  values: CustomerMapValuesValidator,
  ids: t.array(t.string),
});

export const CustomerMapDataValidator = CustomerBaseMapsDataValidator;
export type TCustomerMapData = t.TypeOf<typeof CustomerMapDataValidator>;

export const CustomerBehaviorMapDataValidator = CustomerBaseMapsDataValidator;

export type TCustomerBehaviorMapData = t.TypeOf<typeof CustomerBehaviorMapDataValidator>;

export const CustomerMapExportLinkValidator = t.type({
  filename: t.string,
});

export type TCustomeMapExportLink = t.TypeOf<typeof CustomerMapExportLinkValidator>;
