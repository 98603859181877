import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { hasReadRole, hasAdminRole, TRoleDetails, ROLE_CODES, TRoleCode } from 'domain/roles';
import { TStoreId } from 'domain/stores';
import { TSectionCode } from 'domain/core';

export type TRoleOption = 'none' | 'read' | 'admin';

export type TRoleCategories = 'store_privileges' | 'section_privileges';

export interface StoreRolesFormData {
  name: string;
  section_privileges: Record<TSectionCode, TRoleOption>;
  store_privileges: Record<TStoreId, TRoleOption>;
}

const setRoleOption = (privilegeRole: number[]): TRoleOption => {
  if (hasAdminRole(privilegeRole)) {
    return 'admin';
  }

  if (hasReadRole(privilegeRole)) {
    return 'read';
  }

  return 'none';
};

export const roleDetailsToFormData = (roleDetails: TRoleDetails): StoreRolesFormData => ({
  name: roleDetails.name,
  store_privileges: pipe(
    roleDetails.store_privileges,
    R.map((storeRole) => setRoleOption(storeRole)),
  ),
  section_privileges: pipe(
    roleDetails.section_privileges,
    R.map((sectionRole) => setRoleOption(sectionRole)),
  ),
});

const setRoleCode = (privilegeRole: string): O.Option<TRoleCode[]> => {
  if (privilegeRole === 'admin') {
    return O.some([ROLE_CODES.READ, ROLE_CODES.ADMIN]);
  }

  if (privilegeRole === 'read') {
    return O.some([ROLE_CODES.READ]);
  }

  return O.none;
};

export const formDataToRoleDetails = (formData: StoreRolesFormData): TRoleDetails => ({
  name: formData.name,
  store_privileges: pipe(
    formData.store_privileges,
    R.filterMap((storeRole) => setRoleCode(storeRole)),
  ),
  section_privileges: pipe(
    formData.section_privileges,
    R.filterMap((sectionRole) => setRoleCode(sectionRole)),
  ),
});
