import { TStoreId, StoreIdValidator } from 'domain/stores';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';
import type { TGetProductListParams } from './service';
import { FamilyCodeValidator, ProductCodeValidator, TProductCode } from '../core';

export const ProductDetailsValidator = t.type({
  description: t.string,
  family_code: FamilyCodeValidator,
  is_regional: optionFromNullable(t.boolean),
  minimum_stock: optionFromNullable(t.number),
  weekend_product: optionFromNullable(t.boolean),
  always_available: optionFromNullable(t.boolean),
  supplier_id: optionFromNullable(t.number),
  supplier_name: optionFromNullable(t.string),
  first_appearance_date: optionFromNullable(t.string),
});

export const ProductKPIDailyValidator = t.intersection([
  ProductDetailsValidator,
  t.type({
    full_price_quantity: t.number,
    full_price_gross: t.number,
    discount_10_gross: t.number,
    discount_10_quantity: t.number,
    discount_20_gross: t.number,
    discount_20_quantity: t.number,
    discount_30_gross: t.number,
    discount_30_quantity: t.number,
    discount_40_gross: t.number,
    discount_40_quantity: t.number,
    discount_50_gross: t.number,
    discount_50_quantity: t.number,
  }),
]);

export const UpdateProductDetailsValidator = t.type({
  description: t.string,
  family_code: FamilyCodeValidator,
  is_regional: optionFromNullable(t.boolean),
  minimum_stock: optionFromNullable(t.number),
  weekend_product: optionFromNullable(t.boolean),
  always_available: optionFromNullable(t.boolean),
  supplier_id: optionFromNullable(t.number),
  first_appearance_date: optionFromNullable(t.string),
});

export const CreateProductDetailsValidator = t.type({
  description: t.string,
  family_code: FamilyCodeValidator,
  is_regional: optionFromNullable(t.boolean),
  minimum_stock: optionFromNullable(t.number),
  weekend_product: optionFromNullable(t.boolean),
  always_available: optionFromNullable(t.boolean),
  supplier_id: optionFromNullable(t.number),
  first_appearance_date: optionFromNullable(t.string),
});

export const ProductFamiliesStatsValidator = t.type({
  all_products: t.number,
  products_unknown_category: t.number,
  is_bio: t.number,
  is_regional: t.number,
  last_3_days: t.number,
  last_3_days_unknown_category: t.number,
  last_30_days: t.number,
  last_30_days_unknown_category: t.number,
});

export const ProductDetailsStatisticsValidator = t.type({
  product_code: ProductCodeValidator,
  store_id: StoreIdValidator,
  revenue_days_position: t.number,
  discounted_product_percentage: t.number,
  min_price: t.number,
  max_price: t.number,
});

export const ProductDetailsStatisticsValidatorArray = t.array(ProductDetailsStatisticsValidator);

export const ProductAnomalyDetailsStatisticsValidator = t.type({
  product_code: ProductCodeValidator,
  store_id: StoreIdValidator,
  anomaly_count: t.number,
  estimated_loss: t.number,
});

export const ProductAnomalyDetailsStatisticsValidatorArray = t.array(ProductAnomalyDetailsStatisticsValidator);

export const ProductValidator = t.intersection([
  t.type({
    product_code: ProductCodeValidator,
  }),
  ProductDetailsValidator,
]);

export const CreateProductValidator = t.intersection([
  t.type({
    product_code: ProductCodeValidator,
  }),
  CreateProductDetailsValidator,
]);
export const UpdateProductValidator = t.intersection([
  t.type({
    product_code: ProductCodeValidator,
  }),
  UpdateProductDetailsValidator,
]);

export type TProductDetails = t.TypeOf<typeof ProductDetailsValidator>;

export type TProduct = t.TypeOf<typeof ProductValidator>;
export const NewProductValidator = t.intersection([
  t.type({
    product_code: ProductCodeValidator,
    sales_today: t.number,
    sales_week: t.number,
  }),
  UpdateProductDetailsValidator,
]);
export const NewProductsValidator = t.array(NewProductValidator);
export const TaskNewProductValidator = t.type({
  product_code: ProductCodeValidator,
  sales_today: optionFromNullable(t.number),
  sales_week: optionFromNullable(t.number),
  description: t.string,
  store_id: StoreIdValidator,
});
export const TaskNewProductsValidator = t.array(TaskNewProductValidator);
export type TNewProduct = t.TypeOf<typeof NewProductValidator>;
export type TNewProducts = t.TypeOf<typeof NewProductsValidator>;
export type TCreateProduct = t.TypeOf<typeof CreateProductValidator>;
export type TUpdateProduct = t.TypeOf<typeof UpdateProductValidator>;

export interface TGetProductExtendedInfoCSVParams extends TGetProductListParams {
  lang?: string;
}

export const ProductsValidator = t.array(ProductValidator);
export type TProducts = t.TypeOf<typeof ProductsValidator>;
export type TCreateProducts = t.TypeOf<typeof NewProductsValidator>;

export const GoodToHaveValidator = t.type({
  product_code: ProductCodeValidator,
  description: t.string,
  quantity_sum: t.number,
  gross_sales: t.number,
});

export type TGoodToHave = t.TypeOf<typeof GoodToHaveValidator>;

export const GoodToHaveDataValidator = t.array(GoodToHaveValidator);

export type TGoodToHaveData = t.TypeOf<typeof GoodToHaveDataValidator>;

export enum ViewVariant {
  TIME = 'time',
  STORES = 'stores',
}

export type ColumnSortsWeeklyProductsManagement = 'ean' | 'name' | 'ifls' | 'ean13' | 'package' | 'shelf';

export interface TWeeklyProductManagementParams {
  year: number;
  week: number;
  store_ids: TStoreId[] | ['ALL'];
  sort_by?: string;
  direction?: string;
  limit?: number;
  offset?: number;
  shelf?: string;
}

export interface TNewWeekQueryParams {
  store_ids?: TStoreId[] | ['ALL'];
  group_ids?: number[];
  user_id?: number;
}

export interface TCreateNewWeekQueryParams extends TNewWeekQueryParams {
  strategy: string;
  returning?: boolean;
}

export const StoreProductWeeklyWeekCheckValidator = t.boolean;

const StoreManagementValue = t.union([t.literal('promo'), t.literal('standard'), t.literal('disabled')]);

export type TStoreManagementValue = t.TypeOf<typeof StoreManagementValue>;

const OStoreManagementValue = optionFromNullable(StoreManagementValue);

export type TOStoreManagementValue = t.TypeOf<typeof OStoreManagementValue>;

export const WeeklyProductManagementValidator = t.type({
  product_code: ProductCodeValidator,
  year: t.number,
  week: t.number,
  stores: t.record(t.string, OStoreManagementValue),
  stores_always_available: t.record(t.string, t.boolean),
  shelf: optionFromNullable(t.string),
  ifls: optionFromNullable(t.number),
  ean13: optionFromNullable(t.number),
  description: t.string,
  delivery_days: t.array(t.number),
  package_size: optionFromNullable(t.number),
});

export type TWeeklyProductManagement = t.TypeOf<typeof WeeklyProductManagementValidator>;

export const WeeklyProductManagementListValidator = t.array(WeeklyProductManagementValidator);

export type TWeeklyProductManagementList = t.TypeOf<typeof WeeklyProductManagementListValidator>;

export type localTStoreManagementValue = TStoreManagementValue | 'nodata';

export type WeeklyProductManagementFilterParams = {
  column_sort?: ColumnSortsWeeklyProductsManagement | 'shelf';
  order?: 'ASC' | 'DESC';
  shelf?: string;
  variant?: ViewVariant;
  week: number;
  group_id?: number;
  column_display: string;
  types: string[];
};

export type SaveWeeklyProductManagement = {
  product_code: TProduct['product_code'];
  year: number;
  week: number;
  stores: Record<TStoreId | 'ALL', TStoreManagementValue | null>;
};

export const ProductWeeklyCheckResponseValidator = t.record(
  t.string,
  t.record(StoreIdValidator, OStoreManagementValue),
);

export type TProductWeeklyCheckResponseValidator = t.TypeOf<typeof ProductWeeklyCheckResponseValidator>;

export type TStoresToUpdate = Record<TProductCode, Record<TStoreId, TOStoreManagementValue>>;

export type SaveWeeklyProductManagementList = SaveWeeklyProductManagement[];

export const ProductWeeklyAssortmentUploadValidator = t.tuple([
  t.record(t.string, t.record(StoreIdValidator, OStoreManagementValue)),
  t.record(t.string, t.record(StoreIdValidator, t.boolean)),
]);

export interface TCreateWeekQueryParams {
  strategy: string;
  returning: boolean;
  store_ids: TStoreId[] | ['ALL'];
}

export const ProductAssortmentStatisticsValidator = t.type({
  total_products: t.number,
  active_products: t.number,
  store_active_products: t.number,
  store_id: t.string,
  shelf: optionFromNullable(t.string),
});

export const ProductAssortmentStatisticsListValidator = t.array(ProductAssortmentStatisticsValidator);

export interface TAssortmentStatisticsParams {
  year: number;
  week: number;
  store_ids: TStoreId[];
}

export type GetValueFromStoresOptions = {
  year: number;
  week: number;
  product_code: TProductCode;
  storeId: string;
};
