import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';
import { StoreIdValidator } from '../stores';
import { FamilyCodeValidator, Plot, PlotValidator, ProductCodeValidator } from '../core';

/* NOTE(m.kania): list from swagger/openapi definition: http://localhost:8000/docs#/default/store_statistics_statistics__get
 */
export const STATISTIC_TYPE = {
  AVERAGE_CART: 'avg_cart',
  BIO_ITEMS_GROSS: 'bio_items_gross',
  DISCOUNT_RATIO: 'discount_ratio',
  DISCOUNTS_GROSS: 'discounts_gross',
  GROSS: 'gross',
  ACTIVE_PRODUCT_COUNT: 'active_product_count',
  ACTIVE_BIO_PRODUCT_COUNT: 'active_bio_product_count',
  ACTIVE_REGIONAL_PRODUCT_COUNT: 'active_regional_product_count',
  INACTIVE_PRODUCT_COUNT: 'inactive_product_count',
  NUMBER_OF_BIO_ITEMS: 'no_of_bio_items',
  NUMBER_OF_DISCOUNTS: 'no_of_discounts',
  NUMBER_OF_ITEMS: 'no_of_items',
  NUMBER_OF_RECEIPTS: 'no_of_receipts',
  NUMBER_OF_REGIONAL_ITEMS: 'no_of_regional_items',
  // NOTE(m.kania): product families based on is neoinstinct hierarchy, sections are client's
  PRODUCT_FAMILIES: 'product_families',
  PRODUCT_SECTIONS: 'product_sections',
  REGIONAL_ITEMS_GROSS: 'regional_items_gross',
  REGIONAL_RATIO: 'regional_ratio',
  VAT_TOTAL: 'vat_total',
  PROCESSED_RECEIPTS: 'processed_receipts',
  FAILED_RECEIPTS: 'failed_receipts',
  PROCESSED_RECEIPTS_LOWER_BOUND: 'processed_receipts_lower_bound',
  TOTAL: 'total_products',
} as const;

export type TStatisticType = (typeof STATISTIC_TYPE)[keyof typeof STATISTIC_TYPE];
export const STATISTIC_TYPE_VALUES = Object.values(STATISTIC_TYPE);

export interface StatisticsPlot extends Plot {
  id: TStatisticType;
}
export const StatisticPlotValidator = t.intersection([t.type({ id: t.string }), PlotValidator]);
export type TStatisticsPlot = t.TypeOf<typeof StatisticPlotValidator>;

export const StatisticsPlotsValidator = t.array(StatisticPlotValidator);
export type TStatisticsPlots = t.TypeOf<typeof StatisticsPlotsValidator>;

export const ProductStatisticValidator = t.type({
  product_code: ProductCodeValidator,
  description: optionFromNullable(t.string),
  family_code: FamilyCodeValidator,
  is_regional: optionFromNullable(t.boolean),
  section_name: optionFromNullable(t.string),
  family_name: optionFromNullable(t.string),
  quantity: t.number,
  // NOTE(m.kania): as an (safety) improvement - money could be modelled with custom/branded types
  sales_eur: t.number,
  total_discounts_eur: t.number,
});

export type TProductStatistic = t.TypeOf<typeof ProductStatisticValidator>;

export const ProductStatisticArrayValidator = t.type({ items: t.array(ProductStatisticValidator), total: t.number });

interface ErrorIdBrand {
  readonly errorId: unique symbol;
}
export const ErrorIdValidator = t.brand(
  t.string,
  (v: string): v is t.Branded<string, ErrorIdBrand> => v.length === 32,
  'errorId',
);

export type TErrorId = t.TypeOf<typeof ErrorIdValidator>;

export const ErrorValidator = t.type({
  id: ErrorIdValidator,
  store_id: StoreIdValidator,
  message: t.string,
  source: t.string,
  checkout_date: t.string,
});

export const ErrorListValidator = t.array(ErrorValidator);

export type TErrorListValidator = t.TypeOf<typeof ErrorListValidator>;

export const ErrorListArrayValidator = t.array(ErrorListValidator);

export const ReceiptSourceLinkValidator = t.type({
  details: t.array(t.string),
  message: t.string,
});

export type TReceiptSourceLinkId = t.TypeOf<typeof ReceiptSourceLinkValidator>;
