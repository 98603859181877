import { Link, useToast } from '@chakra-ui/react';
import { ParseKeys } from 'i18next';
import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

type ExportLinkProps = {
  href: string;
  fileName: string;
  translation?: ParseKeys<'whisperme'>;
};

/**
 * Component containg download link for list exports
 * @param href Export link.
 * @param fileName Name of export used when backend won't provide one.
 */
export const ExportLink: FC<ExportLinkProps> = ({ href, fileName, translation = 'GENERIC.EXPORT_LIST_LABEL' }) => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();

  return (
    <Link
      href={href}
      onClick={() =>
        toast({
          title: t('GENERIC.DOWNLOAD_TOAST_MESSAGE'),
          status: 'info',
          position: 'top',
        })
      }
      download={fileName}
      color="blue.500"
    >
      {t(translation)}
    </Link>
  );
};
