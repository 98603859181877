import React, { useState, useCallback } from 'react';
import { useReducedMotion } from 'framer-motion';
import { Box, Flex } from '@chakra-ui/react';
import { useFocusWithin } from '@react-aria/interactions';
import { useTranslation } from 'react-i18next';

import { CSS_VARIABLES } from 'constants/css';
import { LogoutIcon } from 'components/Icons';
import { useUserContext } from 'hooks';
import { DesktopSidebarItem } from './DesktopSidebarItem';
import { NavigationItem, useMainNavigationItems } from '../useMainNavigationItems';

const {
  SIDE_BAR: { BACKGROUND_ACTIVE, WIDTH_COLLAPSED, WIDTH },
  TOPBAR: { HEIGHT: TOPBAR_HEIGHT },
} = CSS_VARIABLES;

interface Props extends React.ComponentPropsWithoutRef<typeof Box> {
  children?: never;
}

export const DesktopSidebar: React.FC<Props> = (props) => {
  const { t } = useTranslation('whisperme');
  const { logout } = useUserContext();
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const shouldReduceMotion = useReducedMotion();
  const navItems = useMainNavigationItems();

  const expandSidebar = () => setSidebarExpanded(true);
  const collapseSidebar = () => setSidebarExpanded(false);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithin: expandSidebar,
    onBlurWithin: collapseSidebar,
  });

  const generateNav = useCallback((items: NavigationItem[]) => {
    return items.map(({ path, label, icon: Icon, active }, idx) => (
      <li key={idx} role="presentation">
        <DesktopSidebarItem to={path} icon={<Icon />} active={active}>
          {label}
        </DesktopSidebarItem>
      </li>
    ));
  }, []);

  // TODO(m.kania): check/improve a11y?
  return (
    <Box
      data-hidden-print
      sx={{
        flexShrink: 0,
        top: 0,
        maxHeight: '100vh',
        position: 'sticky',
        zIndex: 'sticky',
        width: `var(${WIDTH_COLLAPSED})`,
        [WIDTH_COLLAPSED]: '64px',

        // TODO(m.kania): not sure if we should use design/xd colors or stick to chakra theme?
        bg: `#43425d`,
        [BACKGROUND_ACTIVE]: '#3c3b53',
      }}
      onMouseOver={expandSidebar}
      onMouseLeave={collapseSidebar}
      {...props}
    >
      <Flex
        direction="column"
        aria-expanded={isSidebarExpanded ? 'true' : 'false'}
        sx={{
          width: `var(${WIDTH})`,
          position: 'absolute',
          top: 0,
          left: 0,
          [WIDTH]: `var(${WIDTH_COLLAPSED})`,
          bg: 'inherit',
          height: '100%',
          overflowX: 'hidden',
          paddingTop: `var(${TOPBAR_HEIGHT})`,

          '&[aria-expanded="true"]': {
            zIndex: 'sticky',
            [WIDTH]: '256px',
          },

          '& nav': {
            height: '100%',
            '& ul': {
              display: 'flex',
              flexDirection: 'column',
              height: '100%',

              '& > li:last-of-type': {},
            },
          },

          ...(shouldReduceMotion
            ? undefined
            : {
                transitionDuration: 'fast',
                transitionTimingFunction: 'ease-in-out',
                transitionProperty: 'width',
              }),
        }}
        {...focusWithinProps}
        // NOTE(m.kania): even though it's a div, firefox seems to focus it when onFocus handler is defined
        tabIndex={-1}
      >
        <nav>
          <ul>
            {generateNav(navItems[0])}
            <li style={{ flexGrow: 1 }} />
            {generateNav(navItems[1])}
            <li role="presentation">
              <DesktopSidebarItem to="#" icon={<LogoutIcon />} onClick={logout}>
                {t('GENERIC.NAVIGATION.LOGOUT_LABEL')}
              </DesktopSidebarItem>
            </li>
          </ul>
        </nav>
      </Flex>
    </Box>
  );
};
