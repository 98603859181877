import { API_BASE_URL } from 'constants/env';
import { pipe } from 'fp-ts/function';
import { createValidatedCancellableFetch } from 'utils/request';

import { ProductFamilyArrayValidator, SectionValidator } from './types';

const API_URLS = {
  getAllProductFamilies: () => `${API_BASE_URL}/product_family/`,
  getAllSections: () => `${API_BASE_URL}/product_family/sections`,
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_product_family__get
 */
export const getAllProductFamilies = () =>
  pipe(API_URLS.getAllProductFamilies(), createValidatedCancellableFetch(ProductFamilyArrayValidator));

export const getAllSections = () => pipe(API_URLS.getAllSections(), createValidatedCancellableFetch(SectionValidator));
