import { useQuery, UseQueryOptions } from 'react-query';

import { createClusterMapStoresCharacteristicsKey } from 'constants/queryCacheKeys';
import { getClusterMapCharacteristics, GetMapCharacteristicsParams, TClusterMapStoreCharacteristics } from 'domain/map';
import { ONE_DAY } from 'constants/time';

type DataType = TClusterMapStoreCharacteristics;
type ErrorType = Error;

export const useClusterMapStoreCharacteristics = <Data = DataType>(
  params: GetMapCharacteristicsParams,
  options?: UseQueryOptions<DataType, ErrorType, Data>,
) =>
  useQuery<DataType, ErrorType, Data>(
    createClusterMapStoresCharacteristicsKey(params),
    () => getClusterMapCharacteristics(params),
    {
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      ...options,
    },
  );
