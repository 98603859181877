import { pipe } from 'fp-ts/function';
import { API_BASE_URL } from 'constants/env';
import { CreteRecipeParams, RecipeValidator } from './types';
import { createValidatedCancellableFetch } from 'utils/request';

const API_URL = {
  postCreateReceipt: () => `${API_BASE_URL}/neoshopping/`,
};

export const createRecipe = (params: CreteRecipeParams) =>
  pipe(
    API_URL.postCreateReceipt(),
    createValidatedCancellableFetch(RecipeValidator, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  );
