import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Stack, useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Card, CardTitleSection } from 'components/Card';
import { changePassword } from 'domain/user';
import { useUserContext } from 'hooks';

interface FormData {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
}

export const ChangePasswordCard = () => {
  const { t } = useTranslation('whisperme');
  const formOptions = useMemo(() => {
    const formSchema = Yup.object().shape({
      old_password: Yup.string().required(t('VALIDATION.PASSWORD_REQUIRED')),
      new_password: Yup.string().required(t('VALIDATION.PASSWORD_REQUIRED')),
      new_password_confirm: Yup.string()
        .required(t('VALIDATION.PASSWORD_REQUIRED'))
        .oneOf([Yup.ref('new_password')], t('VALIDATION.PASSWORDS_DO_NOT_MATCH')),
    });

    return {
      resolver: yupResolver(formSchema),
    };
  }, [t]);

  const toast = useToast();
  const { logout } = useUserContext();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>(formOptions);
  const { mutateAsync } = useMutation(changePassword, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.PASSWORD_CHANGED_SUCCESS_MESSAGE'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // NOTE(m.kania): after changing password BE returns 200 for /auth/login, but 401 for everything else
      // so we need to login again in order for app to work
      logout();
    },
    onError: () => {
      toast({
        title: t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.PASSWORD_CHANGED_FAILURE_MESSAGE'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    },
  });

  return (
    <Card>
      <CardTitleSection>{t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.TITLE')}</CardTitleSection>
      <Stack
        py="2"
        as="form"
        direction="column"
        spacing="3"
        onSubmit={handleSubmit(({ old_password, new_password: password }) => mutateAsync({ old_password, password }))}
      >
        <FormControl id="old_password" isInvalid={Boolean(errors.old_password)}>
          <FormLabel>{t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.OLD_PASSWORD_LABEL')}</FormLabel>
          <Input type="password" {...register('old_password')} />
          {errors.old_password && <FormErrorMessage>{errors.old_password.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="new_password" isInvalid={Boolean(errors.new_password)}>
          <FormLabel>{t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.NEW_PASSWORD_LABEL')}</FormLabel>
          <Input type="password" {...register('new_password')} />
          {errors.new_password && <FormErrorMessage>{errors.new_password.message}</FormErrorMessage>}
        </FormControl>
        <FormControl id="new_password_confirm" isInvalid={Boolean(errors.new_password_confirm)}>
          <FormLabel>{t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_LABEL')}</FormLabel>
          <Input type="password" {...register('new_password_confirm')} />
          {errors.new_password_confirm && <FormErrorMessage>{errors.new_password_confirm.message}</FormErrorMessage>}
        </FormControl>
        <HStack justifyContent="center">
          <Button
            variant="outline"
            onClick={() => {
              reset();
            }}
            colorScheme="blue"
            size="sm"
          >
            {t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.CANCEL_BUTTON_LABEL')}
          </Button>
          <Button type="submit" colorScheme="blue" size="sm" isLoading={isSubmitting}>
            {t('SETTINGS.GENERAL_PAGE.CHANGE_PASSWORD.CONFIRM_BUTTON_LABEL')}
          </Button>
        </HStack>
      </Stack>
    </Card>
  );
};
