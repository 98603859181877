import { useContext, useMemo } from 'react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { UserContext } from 'components/Contexts';
import { userHasAccessToSection, userHasAccessToStore } from 'domain/user';
import { useProductFamiliesMaps } from './data/useProductFamiliesMaps';
import { useStoresData } from './data/useStores';
import { TStoreId } from 'domain/stores';
import { TProductFamily } from 'domain/product-family';
import { TSectionCode } from 'domain/core';

export const useUserContext = () => useContext(UserContext);

export const useUserStoreIds = () => {
  const allStores = useStoresData();
  const { user } = useUserContext();

  return useMemo(() => {
    const hasAccess = userHasAccessToStore(user);

    return pipe(
      allStores,
      A.reduce([] as TStoreId[], (acc, store) => {
        if (hasAccess(store.id)) {
          return [...acc, store.id];
        }

        return acc;
      }),
    );
  }, [user, allStores]);
};

export const useUserSections = () => {
  const { rootFamilies } = useProductFamiliesMaps();
  const { user } = useUserContext();

  return useMemo(() => {
    const hasAccess = userHasAccessToSection(user);

    return pipe(
      rootFamilies,
      A.reduce([], (acc: TProductFamily[], section: TProductFamily) => {
        if (hasAccess(String(section.id) as TSectionCode)) {
          return [...acc, section];
        }

        return acc;
      }),
    );
  }, [user, rootFamilies]);
};

export const useUserSectionIds = () => {
  const userSections = useUserSections();

  return useMemo(
    () =>
      pipe(
        userSections,
        A.map((section) => String(section.id) as TSectionCode),
      ),
    [userSections],
  );
};
