import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Button, useToast } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import * as PATHS from 'constants/paths';
import { ProductFamiliesList } from './components';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { UploadIcon } from 'components/Icons';
import { TProduct, uploadFileWithProducts } from 'domain/products';
import { hasAdminAccess } from 'domain/user';
import { useUserContext } from 'hooks';

const FILE_COLUMNS: Array<keyof TProduct> = ['product_code', 'description', 'family_code', 'is_regional'];

export const ProductFamiliesListPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useUserContext();
  const isAdmin = hasAdminAccess(user);
  const toast = useToast();
  const { mutateAsync, status } = useMutation(uploadFileWithProducts, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_SUCCESS_TEXT'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_FAIL_TEXT'),
        description: `${t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_UPLOAD_HINT')} (${FILE_COLUMNS.join(', ')})`,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    },
    onSettled: () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
  });

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            <input
              ref={inputRef}
              style={{ display: 'none' }}
              type="file"
              name="products-file"
              accept="text/csv, .csv"
              onChange={(ev) => {
                const maybeFile = ev.target.files?.[0];
                if (maybeFile) {
                  mutateAsync({ file: maybeFile });
                }
              }}
            />
            {isAdmin ? (
              <>
                <Button
                  leftIcon={<UploadIcon />}
                  colorScheme="blue"
                  size="sm"
                  isLoading={status === 'loading'}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  {t('SETTINGS.PRODUCT_FAMILIES.IMPORT_PRODUCTS_BUTTON_LABEL')}
                </Button>
                <Button
                  as={Link}
                  to={PATHS.SETTINGS.PRODUCT_FAMILIES.NEW.toPath()}
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  size="sm"
                >
                  {t('SETTINGS.PRODUCT_FAMILIES.ADD_PRODUCTS_BUTTON_LABEL')}
                </Button>
              </>
            ) : null}
          </CardTitleSectionSettings>
        }
      >
        {t('SETTINGS.PRODUCT_FAMILIES.LIST_TITLE')}
      </CardTitleSection>
      <ProductFamiliesList />
    </Card>
  );
};
