import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as PATHS from 'constants/paths';
import { ProductFamiliesListPage } from './ProductFamiliesListPage';
import { ProductFamiliesNewProductsPage } from './ProductFamiliesNewProductsPage';

export const ProductFamiliesPage: React.FC = () => {
  return (
    <Switch>
      <Route exact={true} path={PATHS.SETTINGS.PRODUCT_FAMILIES.NEW.PATH}>
        <ProductFamiliesNewProductsPage />
      </Route>
      <Route path={PATHS.SETTINGS.PRODUCT_FAMILIES.ROOT.PATH}>
        <ProductFamiliesListPage />
      </Route>
      <Redirect to={PATHS.SETTINGS.PRODUCT_FAMILIES.ROOT.toPath()} />
    </Switch>
  );
};
