import { createNeochatExampleQueriesKey } from 'constants/queryCacheKeys';
import { getExampleQueries } from 'domain/neochat';
import { useQuery } from 'react-query';

export const useExampleQueries = () => {
  const params = {
    n_queries: 10,
  };

  return useQuery(createNeochatExampleQueriesKey(params), () => getExampleQueries(params));
};
