import { generatePath } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import { StoreIdValidator, TStoreId } from 'domain/stores';

const ROOT_PATH = '/objectives';
export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

export const LEVEL_STORE_BASE_PATH = `${ROOT_PATH}/store/:storeId` as const;

interface TLevelParams {
  storeId: TStoreId;
}

const validateParams = (params?: Record<string, unknown> | null) => ({
  storeId: pipe(params?.storeId, StoreIdValidator.decode, O.fromEither),
});

export const LEVEL_SHORTAGES_PATH = `${LEVEL_STORE_BASE_PATH}/shortages` as const;
export const LEVEL_SHORTAGES = {
  PATH: LEVEL_SHORTAGES_PATH,
  toPath: ({ storeId }: TLevelParams) => generatePath(LEVEL_SHORTAGES_PATH, { storeId }),
  validateParams,
};

export const LEVEL_CUSTOMER_GROUPS_PATH = `${LEVEL_STORE_BASE_PATH}/groups` as const;
export const LEVEL_CUSTOMER_GROUPS = {
  PATH: LEVEL_CUSTOMER_GROUPS_PATH,
  toPath: ({ storeId }: TLevelParams) => generatePath(LEVEL_CUSTOMER_GROUPS_PATH, { storeId }),
  validateParams,
};

export const LEVEL_RECAPTURE_CUSTOMERS_PATH = `${LEVEL_STORE_BASE_PATH}/customers` as const;
export const LEVEL_RECAPTURE_CUSTOMERS = {
  PATH: LEVEL_RECAPTURE_CUSTOMERS_PATH,
  toPath: ({ storeId }: TLevelParams) => generatePath(LEVEL_RECAPTURE_CUSTOMERS_PATH, { storeId }),
  validateParams,
};

export const LEVEL_CART_PATH = `${LEVEL_STORE_BASE_PATH}/cart` as const;
export const LEVEL_CART = {
  PATH: LEVEL_CART_PATH,
  toPath: ({ storeId }: TLevelParams) => generatePath(LEVEL_CART_PATH, { storeId }),
  validateParams,
};

export const LEVEL_LOYALTY_PATH = `${LEVEL_STORE_BASE_PATH}/loyalty` as const;
export const LEVEL_LOYALTY = {
  PATH: LEVEL_LOYALTY_PATH,
  toPath: ({ storeId }: TLevelParams) => generatePath(LEVEL_LOYALTY_PATH, { storeId }),
  validateParams,
};

export const LEVEL_AREA_BASE_PATH = `${ROOT_PATH}/area` as const;

export const LEVEL_AREA_SHORTAGES_PATH = `${LEVEL_AREA_BASE_PATH}/shortages` as const;
export const LEVEL_AREA_SHORTAGES = {
  PATH: LEVEL_AREA_SHORTAGES_PATH,
  toPath: () => LEVEL_AREA_SHORTAGES_PATH,
};

export const LEVEL_AREA_CUSTOMER_GROUPS_PATH = `${LEVEL_AREA_BASE_PATH}/groups` as const;
export const LEVEL_AREA_CUSTOMER_GROUPS = {
  PATH: LEVEL_AREA_CUSTOMER_GROUPS_PATH,
  toPath: () => LEVEL_AREA_CUSTOMER_GROUPS_PATH,
};

export const LEVEL_AREA_CUSTOMERS_PATH = `${LEVEL_AREA_BASE_PATH}/customers` as const;
export const LEVEL_AREA_CUSTOMERS = {
  PATH: LEVEL_AREA_CUSTOMERS_PATH,
  toPath: () => LEVEL_AREA_CUSTOMERS_PATH,
};
