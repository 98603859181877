import React from 'react';
import { Tab, TabProps, TabList, TabPanel, TabPanels, Tabs, TabsProps, forwardRef } from '@chakra-ui/react';

// TODO(m.kania): check if lazyBehavior="keepMounted" would work nicely with heading/title changes
export const DashboardTabs = forwardRef<TabsProps, 'div'>(({ children, ...rest }, ref) => (
  <Tabs isLazy={true} variant="dashboard" {...rest} ref={ref}>
    {children}
  </Tabs>
));

export { TabList as DashboardTabList, TabPanels as DashboardTabPanels, TabPanel as DashboardTabPanel };

interface DashboardTabProps extends TabProps {
  icon?: React.ElementType;
}

export const DashboardTab = forwardRef<DashboardTabProps, 'div'>(({ children, icon, ...rest }, ref) => (
  <Tab {...rest} ref={ref}>
    {children}
  </Tab>
));
