import React from 'react';
import { Center, Divider, HStack } from '@chakra-ui/react';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useTranslation } from 'react-i18next';
import { AppName } from 'components/AppName';
import { MobileNavigation } from 'components/Navigation';
import { AppViewport, useUserContext } from 'hooks';
import { DashboardTopbarContainer } from './DashboardTopbarContainer';
import { DashboardProfileActions } from './DashboardProfileActions';
import { TopbarHeadingContainer } from './TopbarHeadingContainer';
import { MatchUserAccess } from 'components/Permissions';
import { AppUser } from 'domain/user';

interface Props extends React.ComponentPropsWithoutRef<typeof DashboardTopbarContainer> {
  variant?: AppViewport;
}

const topbarDivider = (
  <Center height="10" px="2">
    <Divider orientation="vertical" />
  </Center>
);

const DashboardTopbarComponent: React.FC<Props> = ({ children, variant = 'mobile', ...rest }) => {
  const { t } = useTranslation('whisperme');

  const { logout, user } = useUserContext();

  const userAccessLevelName = O.fold(
    () => '',
    (user: AppUser) => user.access && t(`GENERIC.NAVIGATION.SUBHEADER_ROLES.${user.access}`),
  );

  const subHeader = pipe(user, userAccessLevelName, (role) =>
    t('GENERIC.NAVIGATION.ROLE_SUBHEADER', {
      role,
    }),
  );

  // TODO(m.kania): lift search state here, so it's not lost when viewport is changing
  return (
    <DashboardTopbarContainer {...rest}>
      {variant === 'desktop' ? (
        <>
          <HStack>
            <AppName fontSize="6xl" />
            <TopbarHeadingContainer variant="desktop" subHeader={subHeader} />
          </HStack>
          <HStack justifyContent="flex-end">
            <MatchUserAccess
              anyAccess={(user) => (
                <>
                  {topbarDivider}
                  <DashboardProfileActions user={user} onLogout={logout} />
                </>
              )}
            />
          </HStack>
        </>
      ) : (
        <>
          <MobileNavigation />
          <TopbarHeadingContainer
            variant="mobile" //t(`GENERIC.NAVIGATION.SUBHEADER_ROLES.${userAccessLevelName}`)
            subHeader={subHeader}
          />
          <div />
        </>
      )}
    </DashboardTopbarContainer>
  );
};

export const DashboardTopbar = React.memo<Props>(DashboardTopbarComponent);
