import { useQuery, UseQueryOptions } from 'react-query';

import { createRolesListKey } from 'constants/queryCacheKeys';
import { getRolesList, TRoleDetails, TRoleId } from 'domain/roles';

type DataType = Unpromisify<ReturnType<typeof getRolesList>>;
type ErrorType = Error;

export const useRoleList = <Data = DataType>(options?: UseQueryOptions<DataType, ErrorType, Data>) =>
  useQuery<DataType, ErrorType, Data>(createRolesListKey(), getRolesList, options);

const EMPTY_MAP = new Map<TRoleId, TRoleDetails>();

export const useRoleDetailsMap = () => {
  const { data = EMPTY_MAP } = useRoleList({
    suspense: true,
    select: (data) => new Map<TRoleId, TRoleDetails>(data.items.map((r) => [r.id, r.role])),
  });

  return data;
};
