import React from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useUserContext } from 'hooks';
import { AppName } from 'components/AppName';
import { Card } from 'components/Card';
import { AppLanguageMenu } from 'components/AppLanguageMenu';
import { DESKTOP_BREAKPOINT } from 'constants/css';
import { ProvenciaLogo } from './ProvenciaLogo';

interface FormData {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { login } = useUserContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();

  return (
    <Flex width="100%" minHeight="100%" flexGrow={1}>
      <Center
        p="5"
        flexBasis="60%"
        display={{
          base: 'none',
          lg: 'flex',
        }}
      >
        <HStack spacing="5">
          <Box flexGrow={1}>
            <ProvenciaLogo />
          </Box>
          <Box height="200px">
            <Divider orientation="vertical" />
          </Box>
          <AppName as="h1" fontSize="96px" />
        </HStack>
      </Center>
      <Flex flexGrow={1} bg="gray.100" flexDirection="column">
        <Flex justifyContent="flex-end" p="1">
          <AppLanguageMenu />
        </Flex>
        <Center
          p={{
            base: '3',
            [DESKTOP_BREAKPOINT]: '10',
          }}
          flexGrow={1}
        >
          <Stack spacing="10" w="100%">
            <Card p="10" boxShadow="lg">
              <Stack as="form" spacing="5" onSubmit={handleSubmit(login)}>
                <Text as="span" textAlign="center" color="gray.600">
                  {t('LOGIN_PAGE.LOGIN_FORM_INFO_TEXT')}
                </Text>
                <FormControl id="username">
                  <FormLabel>{t('LOGIN_PAGE.USERNAME_LABEL')}</FormLabel>
                  <Input {...register('email', { required: t('VALIDATION.FIELD_REQUIRED') })} />
                  {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                </FormControl>
                <FormControl id="password">
                  <FormLabel>{t('LOGIN_PAGE.PASSWORD_LABEL')}</FormLabel>
                  <Input type="password" {...register('password', { required: t('VALIDATION.FIELD_REQUIRED') })} />
                  {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
                </FormControl>
                <Button type="submit" isLoading={isSubmitting} colorScheme="blue">
                  {t('LOGIN_PAGE.LOGIN_BUTTON_LABEL')}
                </Button>
              </Stack>
            </Card>
          </Stack>
        </Center>
      </Flex>
    </Flex>
  );
};
