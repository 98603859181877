import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import * as PATHS from 'constants/paths';
import { UserListPage } from './UserListPage';
import { UserDetailsPage } from './UserDetailsPage';
import { UserEditPage } from './UserEditPage';
import { UserNewPage } from './UserNewPage';

export const UsersPage: React.VFC = () => {
  const listRedirect = <Redirect to={PATHS.SETTINGS.USERS.ROOT.toPath()} />;

  return (
    <Switch>
      <Route path={PATHS.SETTINGS.USERS.NEW.PATH}>
        <UserNewPage />
      </Route>
      <Route path={PATHS.SETTINGS.USERS.EDIT.PATH}>
        {({ match }) =>
          pipe(
            match?.params,
            PATHS.SETTINGS.USERS.EDIT.validateParams,
            (matched) => matched.userId,
            O.match(
              () => listRedirect,
              (userId) => <UserEditPage userId={userId} />,
            ),
          )
        }
      </Route>
      <Route path={PATHS.SETTINGS.USERS.DETAILS.PATH}>
        {({ match }) =>
          pipe(
            match?.params,
            PATHS.SETTINGS.USERS.DETAILS.validateParams,
            (matched) => matched.userId,
            O.match(
              () => listRedirect,
              (userId) => <UserDetailsPage userId={userId} />,
            ),
          )
        }
      </Route>
      <Route path={PATHS.SETTINGS.USERS.ROOT.PATH} exact={true}>
        <UserListPage />
      </Route>
      {listRedirect}
    </Switch>
  );
};
