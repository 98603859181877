import React from 'react';

import { SingleStoreSelect, SingleStoreSelectProps } from './SingleStoreSelect';
import { useUserStoreIds } from 'hooks';

interface Props extends Omit<SingleStoreSelectProps, 'selectableStoreIds'> {}

export const SingleUserStoreSelect: React.FC<Props> = (props) => {
  const userStoreIds = useUserStoreIds();

  return <SingleStoreSelect selectableStoreIds={userStoreIds} {...props} />;
};
