import React, { useMemo } from 'react';
import { ScaleOrdinal } from 'd3-scale';
import {
  AreaStack,
  AnimatedAreaStack,
  AreaSeries,
  AnimatedAreaSeries,
  LineSeries,
  AnimatedLineSeries,
} from '@visx/xychart';
import { curveMonotoneX } from '@visx/curve';
import { format } from 'date-fns';

import { BaseXYChart, ParentRect } from 'components/Charts';
import { TObjectiveSeries } from './types';
import { useAppConfig } from 'hooks';
import { ESalesAnomalyDataResolution } from 'domain/objectives';
import { getDataResolutionFormat } from 'domain/core';

export const DEFAULT_MARGIN = { top: 20, left: 25, right: 25, bottom: 20 };

const objectiveSeriesAccessors = {
  xAccessor: (d: TObjectiveSeries['values'][0]) => d?.x ?? 0,
  yAccessor: (d: TObjectiveSeries['values'][0]) => d?.y ?? 0,
};

const domainAccessors = {
  xAccessor: (d: any) => d,
  yAccessor: () => 0,
};

export interface ObjectiveAreaChartProps<StackKey = TObjectiveSeries['key'], LineKey = TObjectiveSeries['key']> {
  children?: React.ReactNode;
  margin?: typeof DEFAULT_MARGIN;
  areaData: TObjectiveSeries<StackKey>[];
  areaColorScale: ScaleOrdinal<StackKey, string, never>;
  areaLineColorScale?: ScaleOrdinal<StackKey, string, never>;
  lineData: TObjectiveSeries<LineKey>[];
  lineDataColorScale: ScaleOrdinal<LineKey, string, never>;
  dataResolution: ESalesAnomalyDataResolution;
  definitions?: JSX.Element | null;
  startDate: Date;
  endDate: Date;
  thresholdValue?: number;
  areaVariant?: 'stack' | 'default';
}

interface InnerProps<StackKey, LineKey> extends ObjectiveAreaChartProps<StackKey, LineKey> {
  width: number;
  height: number;
}

const ObjectiveAreaChartComponent = <StackKey, LineKey>({
  children,
  width,
  height,
  margin = DEFAULT_MARGIN,
  areaData,
  areaColorScale,
  areaLineColorScale,
  areaVariant = 'default',
  lineData,
  lineDataColorScale,
  dataResolution,
  definitions,
  startDate,
  endDate,
  thresholdValue = 0,
  ...rest
}: InnerProps<StackKey, LineKey>) => {
  const { dateOptions, canAnimate, language } = useAppConfig();
  const AStack = canAnimate ? AnimatedAreaStack : AreaStack;
  const ASeries = canAnimate ? AnimatedAreaSeries : AreaSeries;
  const Line = canAnimate ? AnimatedLineSeries : LineSeries;

  const maxYValue = useMemo(() => {
    let maxY = Math.max(thresholdValue, 0);

    [areaData, lineData].forEach((data) => {
      data.forEach((d) => {
        maxY = Math.max(maxY, ...d.values.map((v) => v.y));
      });
    });

    return maxY;
  }, [lineData, areaData, thresholdValue]);

  const chartProps = useMemo(() => {
    const formatYAxisValue = new Intl.NumberFormat(language, { notation: 'compact' });

    return {
      xScale: {
        type: 'time',
        domain: [startDate, endDate] as any[],
        padding: 0,
        nice: false,
        round: true,
      } as const,
      yScale: { type: 'linear', domain: [0, maxYValue] as any[], nice: true, round: true } as const,
      xAxisProps: {
        tickFormat: (v: Date) => format(v, getDataResolutionFormat(dataResolution), dateOptions),
      },
      yAxisProps: {
        tickFormat: (v: number) => formatYAxisValue.format(v),
      },
    };
  }, [language, startDate, endDate, maxYValue, dataResolution, dateOptions]);

  let areaCharts = (
    <>
      {areaData.map((data) => (
        <ASeries
          renderLine={true}
          lineProps={{
            stroke: (areaLineColorScale ?? areaColorScale)(data.key),
          }}
          curve={curveMonotoneX}
          key={`area-${data.key}}`}
          dataKey={`area-${data.key}`}
          data={data.values}
          fill={areaColorScale(data.key)}
          {...objectiveSeriesAccessors}
        />
      ))}
    </>
  );

  if (areaVariant === 'stack') {
    areaCharts = (
      <AStack curve={curveMonotoneX} renderLine={true}>
        {areaCharts}
      </AStack>
    );
  }

  return (
    <BaseXYChart width={width} height={height} {...chartProps} margin={margin} {...rest}>
      {definitions}
      <LineSeries<any, any, any>
        dataKey="domain"
        enableEvents={false}
        data={chartProps.xScale.domain}
        {...domainAccessors}
        fill="transparent"
        stroke="transparent"
      />
      {areaCharts}
      {lineData.map((data) => (
        <Line
          key={`line-${data.key}`}
          dataKey={`line-${data.key}`}
          data={data.values}
          curve={curveMonotoneX}
          stroke={lineDataColorScale(data.key)}
          {...objectiveSeriesAccessors}
        />
      ))}
      {children}
    </BaseXYChart>
  );
};

type TObjectiveAreaChartComponent = <StackKey, LineKey>(
  p: ObjectiveAreaChartProps<StackKey, LineKey>,
) => React.ReactElement<typeof p> | null;

export const ObjectiveAreaChart: TObjectiveAreaChartComponent = React.memo(
  React.forwardRef((props, ref) => (
    <ParentRect ref={ref}>{(dimensions) => <ObjectiveAreaChartComponent {...dimensions} {...props} />}</ParentRect>
  )),
);
