import { createNavigationMatch, Path } from '../../utils/router';
import * as PATHS from '../../constants/paths';

export enum NeoEmailNavigationMatch {
  CREATE,
  HISTORY,
}

export const neoEmailMatchMapping: Array<[NeoEmailNavigationMatch, Path]> = [
  [NeoEmailNavigationMatch.CREATE, PATHS.LLM.NEOEMAIL.ROOT.PATH],
  [NeoEmailNavigationMatch.HISTORY, PATHS.LLM.NEOEMAIL.HISTORY.PATH],
];

export const findNeoEmailNavigationMatch = createNavigationMatch({
  matchMapping: neoEmailMatchMapping,
  defaultValue: NeoEmailNavigationMatch.CREATE,
});
