import React, { useMemo } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Portal, Button, MenuButtonProps, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { I18nAppLanguage } from 'locales';
import { useAppConfig } from 'hooks';

import { AppLanguageFlag } from './AppLanguageFlag';

interface Props extends MenuButtonProps {}

export interface LanguageItem {
  code: I18nAppLanguage;
  label: string;
}

export const AppLanguageMenu: React.VFC<Props> = React.forwardRef<HTMLButtonElement>((props, ref) => {
  const { t } = useTranslation('whisperme');
  const { language, setLanguage } = useAppConfig();

  const items = useMemo(
    (): LanguageItem[] => [
      {
        code: 'en-GB',
        label: t('COMPONENTS.LANGUAGE_MENU.ENGLISH'),
      },
      {
        code: 'fr',
        label: t('COMPONENTS.LANGUAGE_MENU.FRENCH'),
      },
    ],
    [t],
  );

  return (
    <Menu isLazy={true}>
      <MenuButton as={Button} size="sm" variant="ghost" {...props} ref={ref}>
        <HStack as="span" spacing="2">
          <Text as="span">{t('COMPONENTS.LANGUAGE_MENU.BUTTON_LABEL')}</Text>
          <AppLanguageFlag language={language} />
        </HStack>
      </MenuButton>
      <Portal>
        <MenuList>
          {items.map(({ code, label }) => (
            <MenuItem key={code} onClick={() => setLanguage(code)}>
              <HStack as="span" spacing="2">
                <AppLanguageFlag language={code} />
                <Text as="span">{label}</Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
});
