import { generatePath } from 'react-router';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import { RoleIdValidator, TRoleId } from 'domain/roles';
import { SETTINGS_PATH } from './settings';

const ROOT_PATH = `${SETTINGS_PATH}/roles` as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const NEW_PATH = `${ROOT_PATH}/new` as const;
export const NEW = {
  PATH: NEW_PATH,
  toPath: () => NEW_PATH,
};

interface RoleDetailsPathParams {
  roleId: TRoleId;
}

const DETAILS_PATH = `${ROOT_PATH}/:roleId` as const;
export const DETAILS = {
  PATH: DETAILS_PATH,
  toPath: ({ roleId }: RoleDetailsPathParams) => generatePath(DETAILS_PATH, { roleId }),
  validateParams: (params?: Record<string, unknown> | null) => ({
    roleId: pipe(
      params?.roleId,
      String,
      (code) => Number.parseInt(+code as any, 10),
      RoleIdValidator.decode,
      O.fromEither,
    ),
  }),
};

interface RoleEditPathParams {
  roleId: TRoleId;
}

const EDIT_PATH = `${DETAILS_PATH}/edit` as const;
export const EDIT = {
  PATH: EDIT_PATH,
  toPath: ({ roleId }: RoleEditPathParams) => generatePath(EDIT_PATH, { roleId }),
  validateParams: DETAILS.validateParams,
};
