import React from 'react';
import { chakra, ChakraProps } from '@chakra-ui/react';

interface Props extends ChakraProps {
  isVisible: boolean;
  color: Exclude<ChakraProps['color'], undefined>;
}

export const LegendOval: React.FC<Props> = ({ isVisible, ...props }) => (
  <chakra.svg
    viewBox="0 0 24 24"
    sx={{
      width: '1em',
      height: '1em',

      flexShrink: 0,
      fontSize: '20px',

      ...(isVisible
        ? {
            fill: 'currentColor',
          }
        : {
            fill: 'rgba(0,0,0,0)',
            stroke: 'currentColor',
            strokeWidth: '2px',
          }),
    }}
    {...props}
  >
    <rect x={3} y={6} width={18} height={12} rx={6} ry={6} />
  </chakra.svg>
);
