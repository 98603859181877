import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';

import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, makeCancellableFetch } from 'utils/request';

import { RoleListValidator, TRoleId, TRoleDetails, RoleIdValidator, RoleDetailsValidator } from './types';

const API_URLS = {
  getRolesList: () => `${API_BASE_URL}/role/`,
  createRole: () => `${API_BASE_URL}/role/`,
  getRoleById: (id: TRoleId) => `${API_BASE_URL}/role/${id}`,
  updateRoleById: (id: TRoleId) => `${API_BASE_URL}/role/${id}`,
  deleteRole: (id: TRoleId) => `${API_BASE_URL}/role/${id}`,
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/list_roles_role__get
 */
export const getRolesList = () => pipe(API_URLS.getRolesList(), createValidatedCancellableFetch(RoleListValidator));

export interface CreateRoleParams {
  roleDetails: TRoleDetails;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/create_role_role__post
 */
export const createRole = ({ roleDetails }: CreateRoleParams) =>
  pipe(API_URLS.createRole(), (url) =>
    // NOTE(m.kania): BE responds with just { id } instead of whole object...
    createValidatedCancellableFetch(t.type({ id: RoleIdValidator }))(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(roleDetails),
    }),
  );

export interface GetRoleByIdParams {
  roleId: TRoleId;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_role_role__id___get
 */
export const getRoleById = ({ roleId }: GetRoleByIdParams) =>
  pipe(API_URLS.getRoleById(roleId), createValidatedCancellableFetch(RoleDetailsValidator));

export interface UpdateRoleByIdParams {
  roleId: TRoleId;
  roleDetails: TRoleDetails;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/set_role_role__id___put
 */
export const updateRoleById = ({ roleId, roleDetails }: UpdateRoleByIdParams) =>
  pipe(
    API_URLS.updateRoleById(roleId),
    // NOTE(m.kania): BE responds with just id as a plain text..., so no point of validating with io-ts
    (url) =>
      makeCancellableFetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(roleDetails),
      }),
  );

export interface DeleteRoleParams {
  roleId: TRoleId | null;
}

export const deleteRole = ({ roleId }: DeleteRoleParams) =>
  pipe(API_URLS.deleteRole(roleId as TRoleId), (url) =>
    makeCancellableFetch(url, {
      method: 'DELETE',
    }),
  );
