import { generatePath } from 'react-router';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';
import { StoreIdValidator, TStoreId } from 'domain/stores';
import { ANALYTICS_PATH } from './analytics';
import { urlWithQueryParams } from 'utils/request';
import { TProductCode } from 'domain/core';

const ROOT_PATH = `${ANALYTICS_PATH}/products` as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

export interface ProductListPathArgs {
  path?: string;
  storeId?: TStoreId;
  family?: string;
  order?: string;
  date_from?: any;
}

const PRODUCTS_LIST_PATH = `${ROOT_PATH}/list/`;
const DETAILS_PATH = `${ROOT_PATH}/details/:productCode` as const;

export const DETAILS = {
  PATH: DETAILS_PATH,
  toPath: (productCode: TProductCode) => generatePath(DETAILS_PATH, { productCode }),
};

export const getValidatedList = (path: string) => ({
  PATH: path,
  toPath: (params?: ProductListPathArgs) =>
    pipe(
      path,
      urlWithQueryParams({
        path: ROOT_PATH,
        storeId: params?.storeId,
        family: params?.family,
        order: params?.order,
        date_from: params?.date_from,
      }),
    ),
  validateParams: (params?: Record<string, unknown> | null) => ({
    path: pipe(params?.path, () => optionFromNullable(t.string)),
    storeId: pipe(params?.storeId, StoreIdValidator.decode, O.fromEither),
    family: pipe(params?.family, () => optionFromNullable(t.string)),
    order: pipe(params?.order, () => optionFromNullable(t.string)),
    date_from: pipe(params?.date_from, () => optionFromNullable(t.string)),
  }),
});

export const LIST = getValidatedList(PRODUCTS_LIST_PATH);
