import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Center,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
  Text,
  Flex,
  Link,
  Grid,
  Tfoot,
} from '@chakra-ui/react';
import { identity, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { AnimatePresence } from 'framer-motion';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { CardChartSkeleton } from 'components/Skeletons';
import { useAppConfig, useAnomalousTotals, useChartDateRangeLabel, useUserSections, useTopAnomalousQuery } from 'hooks';
import { getTopAnomalousCSVLink, TGetTopAnomalousParams } from 'domain/objectives';
import { TStoreId } from 'domain/stores';
import { ChartLoadingOverlay } from 'components/Charts';
import { useProductFamiliesMaps, useSectionParams } from 'hooks/data';
import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { InventoryShortageMostAffectedProductCalendar } from './InventoryShortageMostAffectedProductCalendar';
import { ExportLink } from 'components/ExportLink';
import { DateRangeContextType } from './ObjectivesInventoryShortagePage';
import { TMostAffectedProductsMachineContext } from './mostAffectedProductsMachine';
import { TProductCode } from 'domain/core';
import { TTopAnomalousArray } from 'domain/objectives';
import * as A from 'fp-ts/Array';

const emptyData: never[] = [];
interface Props {
  children?: string | JSX.Element | JSX.Element[];
  storeId: TStoreId;
  dateRange: DateRangeContextType;
  stockState: TMostAffectedProductsMachineContext;
  probabilityThreshold: number;
  deselectProduct: () => void;
  selectProduct: (productCode: TProductCode) => void;
}

export const InventoryShortageMostAffectedProductsCard: React.FC<Props> = ({
  storeId,
  dateRange,
  stockState,
  selectProduct,
  probabilityThreshold,
  deselectProduct,
}) => {
  const { t } = useTranslation('whisperme');
  const { language } = useAppConfig();
  const { pfMap } = useProductFamiliesMaps();
  const userRootFamilies = useUserSections();

  const { shortageDateParams } = dateRange;
  const { productsFamily, productsSection, selectedProductCode } = stockState;

  const {
    viewport,
    formatters: { currencyFormatter, numberFormatter },
  } = useAppConfig();

  const params: TGetTopAnomalousParams = {
    store_id: storeId,
    probability_threshold: probabilityThreshold,
    first: shortageDateParams.startDate,
    end: shortageDateParams.endDate ?? undefined,
    family_id: productsFamily,
    root_family_id: productsSection,
  };

  const dateRangeLabel = useChartDateRangeLabel(shortageDateParams.startDate, shortageDateParams.endDate);
  const filteredParams = useSectionParams(
    params,
    pipe(
      userRootFamilies,
      A.map(({ id }) => id),
    ),
    productsSection,
  );

  const { data = emptyData, status, isFetching } = useTopAnomalousQuery(filteredParams);

  const filteredData = useMemo(() => {
    if (typeof selectedProductCode === 'undefined') {
      return data;
    }

    return data.filter((d) => d.product_code === selectedProductCode);
  }, [data, selectedProductCode]);

  const { totalIncidents, totalLoss } = useAnomalousTotals(filteredData as TTopAnomalousArray);

  // NOTE(m.kania): initial fetch
  if (data.length === 0 && status === 'loading') {
    return <CardChartSkeleton chartMinWidth="200px" chartMinHeight="300px" />;
  }

  const table = (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',
        tableLayout: viewport !== 'mobile' ? 'fixed' : undefined,
        '& [data-cell-wrap]': {
          whiteSpace: 'pre-line',
        },

        tbody: {
          tr: {
            _hover: {
              bg: 'gray.50',
            },
          },
        },
      }}
    >
      <Thead>
        <Tr>
          <Th data-cell-wrap>{t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.PRODUCT_NAME_LABEL')}</Th>
          <Th>{t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.PRODUCT_EAN_LABEL')}</Th>
          <Th data-cell-wrap>{t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.SECTION_LABEL')}</Th>
          <Th data-cell-wrap>{t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.FAMILY_LABEL')}</Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.NUMBER_OF_INCIDENTS_LABEL')}
          </Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.POSSIBLE_LOSS_LABEL')}
          </Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {filteredData.map((d) => {
          const maybeSection = pipe(
            d.root_family_id,
            O.chain((id: any) => pipe(pfMap.get(id), O.fromNullable)),
          );
          const maybeFamily = pipe(
            d.family_id,
            O.chain((id: any) => pipe(pfMap.get(id), O.fromNullable)),
          );

          return (
            <Tr key={d.product_code}>
              <Td data-cell-wrap>
                {pipe(
                  d.description,
                  O.fold(() => '', identity),
                )}
              </Td>
              <Td
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {d.product_code}
              </Td>
              <Td data-cell-wrap>
                {pipe(
                  maybeSection,
                  O.fold(
                    () => '',
                    (s) => s.name ?? s.id,
                  ),
                )}
              </Td>
              <Td data-cell-wrap>
                {pipe(
                  maybeFamily,
                  O.fold(
                    () => '',
                    (s) => s.name ?? s.id,
                  ),
                )}
              </Td>
              <Td isNumeric={true}>{numberFormatter.format(d.incident_count)}</Td>
              {/* NOTE(m.kania): loss is returned in cents */}
              <Td isNumeric={true}>{currencyFormatter.format(d.loss / 100)}</Td>
              <Td>
                <Flex justifyContent="center">
                  {typeof selectedProductCode !== 'undefined' ? (
                    <Link href="#" color="blue.500" onClick={deselectProduct}>
                      {t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.SEE_LIST_LABEL')}
                    </Link>
                  ) : (
                    <Link
                      href="#"
                      color="blue.500"
                      onClick={() => {
                        selectProduct(d.product_code);
                      }}
                    >
                      {t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.CHECK_DETAILS_LABEL')}
                    </Link>
                  )}
                </Flex>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
      {status === 'success' && data.length === 0 && (
        <Center>
          <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
        </Center>
      )}
      <Tfoot sx={{ bg: 'gray.100' }}>
        <Tr>
          <Th colSpan={4} sx={{ textAlign: 'right' }}>
            {t('OBJECTIVES.SHORTAGE_LEVEL.TABLE.TOTAL')}
          </Th>
          <Th isNumeric={true}>{totalIncidents}</Th>
          <Th isNumeric={true}>{currencyFormatter.format(totalLoss / 100)}</Th>
          <Th />
        </Tr>
      </Tfoot>
    </Table>
  );

  return (
    <Card>
      <Stack spacing="4">
        <Grid
          gap={GRID_GAP_DEFAULT_VALUE}
          alignItems="center"
          gridTemplateColumns={{
            base: '100%',
            [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
          }}
        ></Grid>

        <CardTitleSection
          as="h2"
          settings={
            <CardTitleSectionSettings>
              <ExportLink
                href={getTopAnomalousCSVLink({ ...filteredParams, lang: language })}
                fileName="top-anomalous.xlsx"
              />
            </CardTitleSectionSettings>
          }
        >
          {t('OBJECTIVES.SHORTAGE_LEVEL.MOST_AFFECTED_PRODUCTS_TITLE')} {dateRangeLabel}
        </CardTitleSection>
        <Stack position="relative">
          {viewport === 'mobile' ? <TableContainer>{table}</TableContainer> : table}
          {typeof selectedProductCode !== 'undefined' && (
            <Flex pt="2" width="100%">
              <InventoryShortageMostAffectedProductCalendar
                storeId={storeId}
                productCode={selectedProductCode}
                startDate={shortageDateParams.startDate}
                endDate={shortageDateParams.endDate ?? shortageDateParams.startDate}
              />
            </Flex>
          )}
          <AnimatePresence>{isFetching && <ChartLoadingOverlay />}</AnimatePresence>
        </Stack>
      </Stack>
    </Card>
  );
};
