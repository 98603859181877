import { MatchUserAccess } from 'components/Permissions';
import { useUserSectionIds, useUserStoreIds } from 'hooks';
import React from 'react';
import { DailyTaskCashierAdvice } from './DailyTaskCashierAdvice';
import { Flex } from '@chakra-ui/react';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { DailyTaskCustomersPrediction } from './DailyTaskCustomersPrediction';
import { DailyTaskIncomePrediction } from './DailyTaskIncomePrediction';
import { dateToYMD, getCurrentDate } from 'utils/date';
import { ShortageCard } from './ShortageCard';

export const DailyTasksPage: React.FC = () => {
  const [userID] = useUserStoreIds();
  const [sectionId] = useUserSectionIds();
  const date = dateToYMD(getCurrentDate());

  return (
    <Flex direction="column" gap={GRID_GAP_DEFAULT_VALUE}>
      <MatchUserAccess
        sectionManager={
          <>
            <ShortageCard storeId={userID} />
            <DailyTaskCustomersPrediction storeId={userID} sectionId={sectionId} date={date} />
            <DailyTaskIncomePrediction storeId={userID} sectionId={sectionId} date={date} />
          </>
        }
        supermarketManager={<DailyTaskCashierAdvice storeId={userID} />}
      />
    </Flex>
  );
};
