import React, { useCallback, useMemo } from 'react';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import throttle from 'lodash.throttle';

import { useLiveRef } from './useLiveRef';

interface UseChartTooltipReturn<TooltipDataType, RefType = HTMLDivElement>
  extends Pick<UseTooltipParams<TooltipDataType>, 'showTooltip' | 'hideTooltip'> {
  containerRef: React.Ref<RefType>;
  getCoordinates: (ev: React.PointerEvent<any>) => {
    tooltipLeft: number;
    tooltipTop: number;
  };
  tooltipProps: Pick<UseTooltipInPortal, 'TooltipInPortal'> &
    Pick<UseTooltipParams<TooltipDataType>, 'tooltipData' | 'tooltipOpen' | 'tooltipTop' | 'tooltipLeft'>;
}

export const useChartTooltip = <TooltipDataType>(): UseChartTooltipReturn<TooltipDataType> => {
  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    debounce: 10,
  });
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipTop, tooltipLeft } = useTooltip<TooltipDataType>();
  const { throttledShowTooltip, throttledHideTooltip } = useMemo(() => {
    const throttledShowTooltip = throttle(showTooltip, 10, { leading: true, trailing: true });

    return {
      throttledShowTooltip,
      throttledHideTooltip: () => {
        throttledShowTooltip.cancel();
        hideTooltip();
      },
    };
  }, [hideTooltip, showTooltip]);
  const helperRef = useLiveRef(containerBounds);

  const getCoordinates = useCallback(
    (ev: React.PointerEvent<any>) => ({
      tooltipLeft: ('clientX' in ev ? ev.clientX : 0) - helperRef.current.left,
      tooltipTop: ('clientY' in ev ? ev.clientY : 0) - helperRef.current.top,
    }),
    [helperRef],
  );

  return {
    containerRef,
    showTooltip: throttledShowTooltip,
    hideTooltip: throttledHideTooltip,
    tooltipProps: {
      tooltipData,
      tooltipOpen,
      tooltipTop,
      tooltipLeft,
      TooltipInPortal,
    },
    getCoordinates,
  };
};
