import React, { useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Flex,
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tfoot,
  Tr,
} from '@chakra-ui/react';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import { contramap } from 'fp-ts/Ord';
import { pipe } from 'fp-ts/function';
import { addDays, format, fromUnixTime, getUnixTime } from 'date-fns';
import { createSectionsAnomaliesKey } from 'constants/queryCacheKeys';
import {
  TGetTopAnomalousParams,
  getSectionAnomalies,
  TGetSectionAnomaliesParams,
  TSectionsAnomaly,
} from 'domain/objectives';
import { TStoreId } from 'domain/stores';
import { useAppConfig, useChartDateRangeLabel, useTopAnomalousQuery, useUserSections } from 'hooks';
import { useMachine } from '@xstate/react';
import { mostAffectedProductsMachine } from './mostAffectedProductsMachine';
import { DateRangeContextType } from './ObjectivesInventoryShortagePage';
import { Card, CardTitleSection } from 'components/Card';
import { enGB, fr } from 'date-fns/locale';
import { i18nInstance } from 'locales';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { CELL_DATA_VARIANT, CellDataVariant } from './types';
import { dateToYMD } from 'utils/date';
import { TMostAffectedProductsMachineContext } from './mostAffectedProductsMachine';
import { LEGEND_THRESHOLD_LEVELS } from './utils';
import { AnimatePresence } from 'framer-motion';
import { ChartLoadingOverlay } from 'components/Charts';
import { useSectionParams } from 'hooks/data';

interface Props {
  children?: never;
  storeId: TStoreId;
  dateRange: DateRangeContextType;
  stockState: TMostAffectedProductsMachineContext;
  probabilityThreshold: number;
  withTable?: boolean;
}

type TFilteredValues = { loss: number; incident_count: number; datesData: Map<string, TSectionsAnomaly> };
type TFilteredShortageBySection = Record<string, TFilteredValues>;

const getProbabilityVariant = (probability?: number): CellDataVariant => {
  if (!probability) return CELL_DATA_VARIANT.OK;

  if (probability > 1) {
    return CELL_DATA_VARIANT.ERROR;
  }

  if (probability > 0) {
    return CELL_DATA_VARIANT.POSSIBLE;
  }

  return CELL_DATA_VARIANT.OK;
};

const updateSectionData = (record: TSectionsAnomaly, sectionData?: TFilteredValues): TFilteredValues | undefined => {
  if (sectionData) {
    sectionData.datesData.set(dateToYMD(record.date), record);
  }

  return sectionData;
};

export const InventoryShortageBySectionsCalendar: React.FC<Props> = ({
  storeId,
  dateRange,
  stockState,
  withTable,
  probabilityThreshold,
}) => {
  const { t } = useTranslation('whisperme');
  const { shortageDateParams } = dateRange;
  const { endDate, startDate } = shortageDateParams;
  const [state] = useMachine(mostAffectedProductsMachine);
  const {
    context: { productsFamily, productsSection },
  } = state;
  const { productsSection: selectedSection } = stockState;
  const userRootFamilies = useUserSections();

  const {
    formatters: { currencyFormatter },
  } = useAppConfig();

  const substructTwoWeeksFromDate = (startDate: Date, endDate: Date | null) => {
    const isoDate = endDate ? endDate : startDate;
    const TWO_WEEKS_IN_UNIX = 60 * 60 * 24 * 14;
    return fromUnixTime(getUnixTime(isoDate) - TWO_WEEKS_IN_UNIX);
  };

  const substructedDate = substructTwoWeeksFromDate(startDate, endDate);

  const params: TGetTopAnomalousParams = {
    store_id: storeId,
    probability_threshold: probabilityThreshold,
    first: startDate,
    end: endDate || undefined,
    family_id: productsFamily,
    root_family_id: productsSection,
  };

  const filteredParams = useSectionParams(
    params,
    pipe(
      userRootFamilies,
      A.map(({ id }) => id),
    ),
    productsSection,
  );

  const dateRangeLabel = useChartDateRangeLabel(startDate, endDate);

  const { data: anomalData, status: anomalStatus } = useTopAnomalousQuery(filteredParams);

  const paramsSectionsCalendar: TGetSectionAnomaliesParams = {
    store_id: [storeId],
    probability_threshold: probabilityThreshold,
    first: substructedDate,
    end: endDate || undefined,
  };

  const {
    data: calendarData,
    status: calendarStatus,
    isFetching,
  } = useQuery(createSectionsAnomaliesKey(paramsSectionsCalendar), () => getSectionAnomalies(paramsSectionsCalendar), {
    keepPreviousData: true,
  });

  const filteredShortageBySection = useMemo<TFilteredShortageBySection>(() => {
    if (
      anomalStatus !== 'success' ||
      anomalData === undefined ||
      calendarStatus !== 'success' ||
      calendarData === undefined
    )
      return {} as TFilteredShortageBySection;

    const sectionsAnomalyData = pipe(
      anomalData,
      A.reduce({} as TFilteredShortageBySection, (acc, product) => {
        if (O.isSome(product.root_family_id)) {
          const sectionId = String(product.root_family_id.value);

          acc[sectionId] = acc[sectionId]
            ? {
                ...acc[sectionId],
                loss: acc[sectionId].loss + product.loss,
                incident_count: acc[sectionId].incident_count + product.incident_count,
              }
            : { loss: product.loss, incident_count: product.incident_count, datesData: new Map() };
        }

        return acc;
      }),
    );

    pipe(
      calendarData,
      A.map((record) => {
        if (O.isSome(record.root_family_id)) {
          const key = String(record.root_family_id.value);
          const values = updateSectionData(record, sectionsAnomalyData[key]);

          if (values) sectionsAnomalyData[key] = values;
        }

        return sectionsAnomalyData;
      }),
    );

    return sectionsAnomalyData;
  }, [anomalData, anomalStatus, calendarData, calendarStatus]);

  const DIMENSIONS = {
    minWidth: '200px',
    minHeight: '150px',
  };

  const TABLE_CALENDAR_STYLES = {
    width: '100%',

    'th, td': {
      fontSize: 'xs',
      padding: '2px',
      px: '1',
      py: '0.5',
      flex: '1',
    },

    td: {
      '&[data-cell-variant="ok"]': {
        bg: 'blue.100',
      },

      '&[data-cell-variant="error"]': {
        bg: 'blue.500',
      },
      '&[data-cell-variant="possible"]': {
        bg: 'blue.300',
      },
      '&[data-cell-variant="empty"]': {
        bg: 'gray.300',
      },
    },
  };

  if (!calendarData && calendarStatus === 'loading') {
    return <Skeleton width="100%" height="100px" />;
  }

  const table = (
    <>
      <Table variant="products" size="sm" table-layout="fixed" sx={TABLE_CALENDAR_STYLES}>
        <Thead>
          <Tr>
            <Th colSpan={3} rowSpan={2} style={{ padding: '0.5em' }}>
              {t('OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.SECTION_LABEL')}
            </Th>
            <Th colSpan={2} rowSpan={2} whiteSpace="pre-wrap" style={{ padding: '0.5em', paddingRight: '2em' }}>
              {t('OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.NUMBER_OF_INCIDENTS_LABEL')}
            </Th>
            <Th colSpan={2} rowSpan={2} whiteSpace="pre-wrap" style={{ padding: '0.5em' }}>
              {t('OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.POSSIBLE_LOSS_LABEL')}
            </Th>
            {withTable ? (
              Array.from({ length: 14 }).map((_v, idx) => (
                <Th key={idx} colSpan={1} textAlign="center" whiteSpace="pre-wrap">
                  {format(addDays(substructedDate, idx), 'EEE dd.MM', {
                    locale: i18nInstance.language === 'en-GB' ? enGB : fr,
                  })}
                </Th>
              ))
            ) : (
              <></>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {pipe(
            Object.entries(filteredShortageBySection),
            A.sortBy([
              pipe(
                N.Ord,
                contramap((data: [string, TFilteredValues]) => data[1].loss),
              ),
            ]),
            A.reverse,
            A.map(([id, { loss, incident_count, datesData }]) => {
              const selectedStyle = Number(id) === selectedSection ? 'gray.100' : '';
              return (
                <Tr key={id}>
                  <Td colSpan={3} whiteSpace="nowrap" bg={selectedStyle}>
                    {userRootFamilies.find((family) => Number(family.id) === Number(id))?.name ?? ''}
                  </Td>
                  <Td colSpan={2} whiteSpace="nowrap" bg={selectedStyle}>
                    {incident_count}
                  </Td>
                  <Td colSpan={2} whiteSpace="nowrap" bg={selectedStyle}>
                    {currencyFormatter.format(loss / 100)}
                  </Td>
                  {withTable ? (
                    Array.from({ length: 14 }).map((_v, idx) => {
                      const convertDate = (currIdx: number) => pipe(addDays(substructedDate, currIdx), dateToYMD);

                      const mapKey = convertDate(idx);
                      const mapValue = datesData.get(mapKey);
                      const probability = getProbabilityVariant(mapValue ? mapValue.probability : undefined);

                      return (
                        <Td
                          colSpan={1}
                          textAlign="center"
                          data-cell-variant={probability}
                          flex="1"
                          borderWidth={3}
                          borderColor="white"
                          borderTopWidth={0}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Tr>
              );
            }),
          )}
        </Tbody>
      </Table>
    </>
  );

  return (
    <Card width="100%">
      <CardTitleSection as="h2">
        {t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_IN_SECTIONS_TITLE')} {dateRangeLabel}
      </CardTitleSection>
      <Flex pt="3" width="100%">
        <Stack
          flexDirection="column"
          flex="1 1 auto"
          minWidth={DIMENSIONS.minWidth}
          minHeight={DIMENSIONS.minHeight}
          position="relative"
          overflowY="auto"
        >
          <Grid
            width="100%"
            gap={GRID_GAP_DEFAULT_VALUE}
            gridTemplateColumns={{ base: '100%', xl: 'repeat(3, minmax(250px, 1fr))' }}
          >
            <GridItem colSpan={3}>
              <TableContainer>{table}</TableContainer>
            </GridItem>
            {withTable ? (
              <GridItem colSpan={3}>
                <TableContainer>
                  <Table variant="products" size="sm" table-layout="fixed" sx={TABLE_CALENDAR_STYLES}>
                    <Tfoot>
                      <Tr style={{ textAlign: 'center' }}>
                        {pipe(
                          Object.entries(LEGEND_THRESHOLD_LEVELS),
                          A.map(([id, { variant, description }]) => (
                            <Fragment key={id}>
                              <Td
                                whiteSpace="pre-wrap"
                                colSpan={3}
                                textAlign="right"
                                borderColor="white"
                                style={{ fontSize: 'x-small' }}
                              >
                                {t(description as any)}
                              </Td>
                              <Td
                                colSpan={2}
                                textAlign="center"
                                minWidth="25px"
                                data-cell-variant={variant}
                                flex="1"
                                borderWidth={3}
                                borderColor="white"
                                borderTopWidth={0}
                              />
                            </Fragment>
                          )),
                        )}
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </GridItem>
            ) : (
              <></>
            )}
          </Grid>
          {/* NOTE(dmalysa): Magic number 97 is just done by testing probably other changes are needed to full solve overflow apearing issue */}
          <AnimatePresence>{isFetching && <ChartLoadingOverlay h="97%" />}</AnimatePresence>
        </Stack>
      </Flex>
    </Card>
  );
};
