import {
  HStack,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { QuestionMarkIcon, TicketIcon, CheckIcon } from 'components/Icons';
import { TOStoreManagementValue, localTStoreManagementValue } from 'domain/products';
import { useTranslation } from 'react-i18next';
import { toUndefined } from 'fp-ts/Option';
import React, { FC, forwardRef, LegacyRef, useEffect, useMemo, useState } from 'react';
import { EditStoreValue } from '../hooks/useEditStore';
import { useUserContext } from 'hooks';
import { hasSectionManagerAccess } from 'domain/user';
import { DisabledStateIcon } from './DisabledStateIcon';

type MarkIconButtonProps = {
  variant: TOStoreManagementValue;
  onClick?: (value: EditStoreValue) => void;
  editable?: boolean;
  allowDisabledState?: boolean;
};

type CustomIconButtonProps = {
  variant: localTStoreManagementValue;
  onClick?: () => void;
  disabled?: boolean;
};

const COLOR_MAP: Record<localTStoreManagementValue, IconButtonProps['color']> = {
  nodata: 'gray.700',
  promo: 'yellow.700',
  standard: 'green.700',
  disabled: 'red.700',
};

const ICON_MAP: Record<localTStoreManagementValue, React.ReactElement | null> = {
  nodata: <QuestionMarkIcon />,
  promo: <TicketIcon />,
  standard: <CheckIcon />,
  disabled: <DisabledStateIcon />,
};

const ARIA_MAP: Record<localTStoreManagementValue, string> = {
  nodata: 'UNKNOWN_STATUS_LABEL',
  promo: 'PROMO_STATUS_LABEL',
  standard: 'STANDARD_STATUS_LABEL',
  disabled: 'DISABLED_STATUS_LABEL',
};

export const CustomIconButton = React.memo(
  forwardRef(({ variant, onClick, disabled }: CustomIconButtonProps, ref: LegacyRef<HTMLButtonElement> | undefined) => {
    const { t } = useTranslation('whisperme');

    return (
      <Tooltip label={t(`SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.${ARIA_MAP[variant]}`)}>
        <IconButton
          ref={ref}
          aria-label={t(`SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.${ARIA_MAP[variant]}`)}
          variant="ghost"
          size="lg"
          onClick={() => onClick?.()}
          disabled={disabled}
          borderRadius="0"
          color={COLOR_MAP[variant]}
        >
          {ICON_MAP[variant]}
        </IconButton>
      </Tooltip>
    );
  }),
);

export const MarkIconOnly: FC<Pick<MarkIconButtonProps, 'variant'>> = ({ variant }) => {
  return <CustomIconButton variant={toUndefined(variant) ?? 'nodata'} />;
};

export const MarkIconButton: FC<MarkIconButtonProps> = ({ variant, editable, onClick, allowDisabledState = true }) => {
  const { t } = useTranslation('whisperme');
  const { user } = useUserContext();
  const [realVariant, setRealVariant] = useState<localTStoreManagementValue>(toUndefined(variant) ?? 'nodata');
  const sectionMangerScope = hasSectionManagerAccess(user);

  useEffect(() => {
    setRealVariant(toUndefined(variant) ?? 'nodata');
  }, [variant]);

  const statuses = useMemo(() => {
    if (sectionMangerScope) return ['promo', 'standard', 'disabled'];
    return ['nodata', 'promo', 'standard', 'disabled'];
  }, [sectionMangerScope]);

  return (
    <HStack flexDirection="column">
      {editable ? (
        <Popover>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <CustomIconButton variant={realVariant} />
              </PopoverTrigger>
              <Portal>
                <PopoverContent w="100%">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    {t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.CHANGE_TITLE')}
                  </PopoverHeader>
                  <PopoverBody>
                    <HStack spacing={4}>
                      {(statuses as localTStoreManagementValue[]).map((v) => (
                        <CustomIconButton
                          key={v}
                          variant={v}
                          onClick={() => {
                            setRealVariant(v);
                            onClose();
                            setTimeout(() => onClick?.(v === 'nodata' ? null : v), 0);
                          }}
                          disabled={v === realVariant || (v === 'disabled' && allowDisabledState)}
                        />
                      ))}
                    </HStack>
                    {allowDisabledState ? (
                      <Text color="red.500" maxW="250px">
                        {t(
                          'SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.ALWAYS_AVAILABLE_STATUS_LIMITATIONS',
                        )}
                      </Text>
                    ) : null}
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      ) : (
        <CustomIconButton variant={realVariant} disabled />
      )}
    </HStack>
  );
};
