import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Skeleton, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import * as PATHS from 'constants/paths';
import { countRolePriviledges, getRolesList, TRoleId } from 'domain/roles';
import { createRolesListKey } from 'constants/queryCacheKeys';
import { RoleDeleteConfirmationModal } from './RoleDeleteConfirmationModal';

export interface TRoleProps {
  roleId: TRoleId | null;
}

const nullRole = { roleId: null };

export const RolesListPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { data = { items: [] }, isLoading } = useQuery(createRolesListKey(), getRolesList);
  const [roleToDelete, setRoleToDelete] = useState<TRoleProps>(nullRole);
  const onModalClose = useCallback(() => {
    setRoleToDelete(nullRole);
  }, []);

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            <Button
              as={Link}
              to={PATHS.SETTINGS.ROLES.NEW.toPath()}
              leftIcon={<AddIcon />}
              size="sm"
              colorScheme="blue"
            >
              {t('SETTINGS.ROLES.NEW_ROLE_ACTION_LABEL')}
            </Button>
          </CardTitleSectionSettings>
        }
      >
        {t('SETTINGS.ROLES.TITLE')}
      </CardTitleSection>
      <Table variant="kpi">
        <Thead>
          <Tr>
            <Th>{t('SETTINGS.ROLES.ROLE_NAME_LABEL')}</Th>
            <Th>{t('SETTINGS.ROLES.STORE_ROLES_LABEL')}</Th>
            <Th>{t('SETTINGS.ROLES.ROLE_USERS_LABEL')}</Th>
            <Th>{t('SETTINGS.ROLES.ACTIONS_LABEL')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && data.items.length === 0
            ? Array.from({ length: 3 }).map((_v, idx) => (
                <Tr key={idx}>
                  <Td>
                    <Skeleton minHeight="1em" />
                  </Td>
                  <Td>
                    <Skeleton minHeight="1em" />
                  </Td>
                  <Td>
                    <Skeleton minHeight="1em" />
                  </Td>
                  <Td>
                    <Skeleton minHeight="1em" />
                  </Td>
                </Tr>
              ))
            : data.items.map((item) => {
                const { readCount, adminCount, itemStoreIdsCount, itemSectionIdsCount } = countRolePriviledges(item);
                const usersCount = item.user_ids.length;

                return (
                  <Tr key={item.id}>
                    <Td>{item.role.name}</Td>
                    <Td>
                      {t('SETTINGS.ROLES.STORE_ROLES_ENTRY_LABEL', {
                        readCount,
                        adminCount,
                        itemStoreIdsCount,
                        itemSectionIdsCount,
                      })}
                    </Td>
                    <Td>
                      {t('SETTINGS.ROLES.ROLE_USERS_ENTRY_LABEL', {
                        usersCount,
                      })}
                    </Td>
                    <Td>
                      {/* NOTE(m.kania): tables should have only static content (no buttons etc) for better wcag/a11y */}
                      <Stack direction="row" spacing={3}>
                        <Button
                          as={Link}
                          to={PATHS.SETTINGS.ROLES.DETAILS.toPath({ roleId: item.id })}
                          size="xs"
                          variant="outline"
                        >
                          {t('SETTINGS.ROLES.VIEW_ACTION_LABEL')}
                        </Button>
                        <Button
                          size="xs"
                          textColor="red.600"
                          variant="outline"
                          onClick={() => setRoleToDelete({ roleId: item.id })}
                        >
                          {t('SETTINGS.ROLES.DELETE_ROLE_LABEL')}
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
        </Tbody>
      </Table>
      <RoleDeleteConfirmationModal role={roleToDelete} onClose={onModalClose} />
    </Card>
  );
};
