import { matchPath } from 'react-router-dom';

export type Path = string | string[];

export const pathnameMatchesPath = (path: Path) => (pathname: string) => matchPath(pathname, path) !== null;

interface CreateNavigationMatchArgs<NavigationMatch> {
  matchMapping: Array<[NavigationMatch, Path]>;
  defaultValue: NavigationMatch;
}

export const createNavigationMatch = <MatchType>({
  matchMapping,
  defaultValue,
}: CreateNavigationMatchArgs<MatchType>): ((pathname: string) => MatchType) => {
  const matchMap = new Map<MatchType, ReturnType<typeof pathnameMatchesPath>>(
    matchMapping.map(([nm, path]) => [nm, pathnameMatchesPath(path)]),
  );

  return (pathname: string) =>
    Array.from(matchMap.entries()).reduce((acc, [nextMatch, checkNextMatch]) => {
      if (checkNextMatch(pathname)) {
        return nextMatch;
      }

      return acc;
    }, defaultValue as MatchType);
};
