export enum NavigationMatch {
  NONE,
  MORNING_TASKS,
  DAILY_TASKS,
  FREE_TASKS,
  ASSORTMENT_MANAGEMENT,
  MAP,
  INSIGHTS,
  ANALYTICS,
  NEOEMAIL,
  NEOCHAT,
  SETTINGS,
  SHOPPING,
}
