import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import { Card } from 'components/Card';
import { SingleUserStoreSelect } from 'components/Select';
import { TFamilyCode } from 'domain/core';
import { TStoreId } from 'domain/stores';
import React, { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { useProductFamiliesMaps, useStoresData } from 'hooks/data';
import { DateRangePicker } from 'components/DateRangePicker';
import { ProbablitySliderThreshold } from 'components/ShortagesFilters/ProbablitySliderThreshold';
import { SelectFamilies } from 'components/ShortagesFilters/SelectFamilies';
import { TMostAffectedProductsMachineContext } from 'pages/AnalyticsPage/ObjectivesPage/ObjectivesInventoryShortagePage/mostAffectedProductsMachine';
import { DateRangeContextType } from 'pages/AnalyticsPage/ObjectivesPage/ObjectivesInventoryShortagePage';
import { useTranslation } from 'react-i18next';
import { useUserSections, useUserStoreIds } from 'hooks';

type FilterProps = {
  storeId?: TStoreId;
  onSelectStore?: (newStoreId: TStoreId) => void;
  numberOfStoresAffected?: number;
  setNumberOfStoresAffected?: (stringValue: string, value: number) => void;
  dateRange: DateRangeContextType;
  stockState: TMostAffectedProductsMachineContext;
  probabilityThreshold: number;
  setProbabilityThreshold: (threshold: number) => void;
  setFamily: (family: TFamilyCode) => void;
  setSection: (section: TFamilyCode) => void;
  clearSection: () => void;
  clearFamily: () => void;
  variant: 'inventory' | 'area';
};

export const ShortagesFilters: React.VFC<FilterProps> = ({
  storeId,
  onSelectStore,
  dateRange,
  stockState,
  setProbabilityThreshold,
  numberOfStoresAffected,
  setNumberOfStoresAffected,
  probabilityThreshold,
  clearFamily,
  clearSection,
  setSection,
  setFamily,
  variant = 'inventory',
}) => {
  const { t } = useTranslation('whisperme');
  const { shortageCalendarProps } = dateRange;
  const stores = useStoresData();
  const { pfMap, allSubfamilies } = useProductFamiliesMaps();
  const { productsFamily, productsSection } = stockState;

  const userSections = useUserSections();

  const userStoreIds = useUserStoreIds();

  const userFamilies = useMemo(() => {
    const userSectionIds = pipe(
      userSections,
      A.map((s) => s.id),
    );

    return pipe(
      allSubfamilies,
      A.filter((s) =>
        pipe(
          s.parent_id,
          O.fold(
            () => false,
            (id) => userSectionIds.includes(id),
          ),
        ),
      ),
    );
  }, [userSections, allSubfamilies]);

  const filteredFamilies = useMemo(
    () =>
      typeof productsSection === 'undefined'
        ? userFamilies
        : pipe(
            userFamilies,
            A.filter((s) =>
              pipe(
                s.parent_id,
                O.fold(
                  () => false,
                  (id) => id === productsSection,
                ),
              ),
            ),
          ),
    [userFamilies, productsSection],
  );

  return (
    <Card>
      <Flex direction="row" flexWrap="wrap" my="3" gap="0.5rem">
        <ProbablitySliderThreshold
          setProbabilityThreshold={setProbabilityThreshold}
          probabilityThreshold={probabilityThreshold}
        />
        {userStoreIds.length > 1 && variant === 'inventory' && storeId && onSelectStore ? (
          <SingleUserStoreSelect value={storeId} maxW="200px" onChange={onSelectStore} />
        ) : null}
        {variant === 'area' && Number.isInteger(numberOfStoresAffected) && setNumberOfStoresAffected ? (
          <Flex direction="row" gap="0.5rem" alignItems="center">
            <Text>{t('OBJECTIVES.AREA_SHORTAGE_LEVEL.AFFECTED_STORES_LEVEL')}</Text>
            <NumberInput
              size="sm"
              maxW={20}
              max={stores.length}
              onChange={setNumberOfStoresAffected}
              value={numberOfStoresAffected}
              min={2}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>
        ) : null}
        {userSections.length > 1 ? (
          <SelectFamilies
            value={productsSection}
            onChange={(maybeCode) => {
              if (maybeCode) {
                const newSection = pfMap.get(maybeCode);

                if (newSection) {
                  // NOTE(m.kania): if family code doesn't 'fit' inside new section, then deselect it
                  if (typeof productsFamily !== 'undefined' && !newSection.children_ids.includes(productsFamily)) {
                    clearFamily();
                    setSection(maybeCode);
                  } else {
                    setSection(maybeCode);
                  }
                }
              } else {
                clearSection();
              }
            }}
            options={userSections}
          />
        ) : null}
        <SelectFamilies
          value={productsFamily}
          onChange={(maybeCode) => {
            if (typeof maybeCode !== 'undefined') {
              setFamily(maybeCode);
            } else {
              clearFamily();
            }
          }}
          options={filteredFamilies}
          allPlaceholder={`${t('OBJECTIVES.COMMON.SELECTORS.SELECT_FAMILY_PLACEHOLDER')}`}
        />
        <DateRangePicker {...shortageCalendarProps} />
      </Flex>
    </Card>
  );
};
