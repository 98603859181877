import { SelectProps } from '@chakra-ui/react';
import { SelectableGroupMap, TStoreId } from 'domain/stores';
import { SelectableItem } from 'components/Select';

export interface StoreGroupSelectBaseProps extends Omit<SelectProps, 'onChange' | 'value'> {
  selectableStoreIds: TStoreId[];
  selectableGroupMap: SelectableGroupMap;
}

export interface StoreGroupSelectProps extends StoreGroupSelectBaseProps {
  onChange?: (newValue: string) => void;
  value: string | number | null | undefined;
}

export interface NewWeekModalProps {
  isOpen: boolean;
  onClose: () => void;
  actionHandler: (actionType: string) => void;
  year: number;
  week: number;
}

export const selectableTypes = [
  { label: 'Enabled', value: 'standard' } as SelectableItem,
  { label: 'Promo', value: 'promo' } as SelectableItem,
  { label: 'Disabled', value: 'disabled' } as SelectableItem,
  { label: 'No data', value: 'nodata' } as SelectableItem,
];
