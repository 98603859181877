import { Td, Tr } from '@chakra-ui/react';
import { TRecipeProduct } from 'domain/neoshopping';
import React from 'react';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

type ProductRowProps = {
  product: TRecipeProduct;
};

export const ProductRow: React.VFC<ProductRowProps> = React.memo(({ product }) => {
  return (
    <Tr _hover={{ bg: 'gray.50' }}>
      <Td>{product.llm_product}</Td>
      <Td>
        {pipe(
          product.product_code,
          O.match(
            () => 'None',
            (code) => String(code),
          ),
        )}
      </Td>
      <Td>{product.description}</Td>
    </Tr>
  );
});
