import { Center, Stack } from '@chakra-ui/react';
import { curveStep } from '@visx/curve';
import { AreaSeries, Grid, Tooltip } from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import { Card } from 'components/Card';
import { BaseXYChart, ChartLegend, ParentRect } from 'components/Charts';
import { add, format, subDays } from 'date-fns';
import { pipe } from 'fp-ts/function';
import { useAppConfig, useUserStoreIds } from 'hooks';
import { useShortageProductPoints } from 'hooks/data';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToDDMMEEE, dateToYMD, dateToYMDHM, prepareDayStartValues, toDate } from 'utils/date';
import { theme } from './consts/chartTheme';
import { TooltipRow } from './TooltipRow';
import { TickRendererProps } from '@visx/axis';

type StockShortageProductPointsEntry = {
  store_id: string;
  time: string;
  always_available: boolean;
  shortage_count: number;
  new_ifls: string[];
  reoccurring_ifls: string[];
  lost_ifls: string[];
};

interface StockPointsShortageChartProps {
  yesterday: Date;
  lastDays: number;
  dataVisibility: boolean[];
  handleDataVisibilityChange: (index: number) => void;
}

function determinePaddingOuter(lastDays: number) {
  if (lastDays <= 1) return 0;
  if (lastDays === 3) return -0.25;
  if (lastDays === 7 || lastDays === 14) return -0.4;
}

const addDateToHHMM = (v: string) => {
  return format(toDate(v), 'dd-MM HH:mm');
};

export const StockPointsShortageChart: VFC<StockPointsShortageChartProps> = ({
  yesterday,
  lastDays,
  dataVisibility,
  handleDataVisibilityChange,
}) => {
  const { t } = useTranslation('whisperme');
  const userStores = useUserStoreIds();
  const {
    dateOptions: { locale },
  } = useAppConfig();
  const { data, isFetching } = useShortageProductPoints({
    store_id: userStores[0],
    start_date: dateToYMD(subDays(yesterday, lastDays - 1)),
    end_date: dateToYMD(yesterday),
    always_available: dataVisibility[0] === dataVisibility[1] ? undefined : dataVisibility[1],
  });
  const alwaysAvailableProducts = data?.filter((item) => item.always_available);
  const regularProducts = data?.filter((item) => !item.always_available);
  const xAccessor = (d: StockShortageProductPointsEntry) => d?.time;
  const yAccessor = (d: StockShortageProductPointsEntry) => String(d?.shortage_count);

  if (isFetching || !data) {
    return (
      <Center w="100%" minH="2vh">
        {t('COMPONENTS.CHARTS.LOADING_LABEL')}
      </Center>
    );
  }
  if (!isFetching && !data.length) {
    return (
      <Center w="100%" minH="2vh">
        {t('COMPONENTS.CHARTS.NO_DATA_LABEL')}
      </Center>
    );
  }
  const xTickValues =
    data && lastDays > 1
      ? pipe(prepareDayStartValues(lastDays, yesterday, data))
      : [
          add(yesterday, { hours: 8, minutes: 30 }),
          add(yesterday, { hours: 12 }),
          add(yesterday, { hours: 16 }),
          add(yesterday, { hours: 20 }),
        ].map((item) => format(item, "yyyy-MM-dd'T'HH:mm:ss"));
  const chartProps = {
    theme,
    xAxisProps: {
      tickFormat: (v: string) => (lastDays === 1 ? addDateToHHMM(v) : dateToDDMMEEE(toDate(v), locale)),
      tickValues: xTickValues,
      tickLabelProps: { fill: 'gray.800' },
      tickComponent: ({ formattedValue, x, y, ...props }: TickRendererProps) => (
        <svg x="0" y="0.125em">
          <text x={x} y={y} {...props}>
            {formattedValue?.split(' ').map((item, index) => (
              <tspan x={x} y={y * index + 15}>
                {item}
              </tspan>
            ))}
          </text>
        </svg>
      ),
    },
    yAxisProps: {
      tickLabelProps: { fill: 'gray.800' },
    },
  };
  const maxYAxis = Math.max(
    0,
    ...(dataVisibility[0] ? (regularProducts?.map((d) => d.shortage_count) ?? [0]) : [0]),
    ...(dataVisibility[1] ? (alwaysAvailableProducts?.map((d) => d.shortage_count) ?? [0]) : [0]),
  );
  const domainTimeMapping = data.map(({ time }) => time);
  domainTimeMapping.push(
    pipe(
      domainTimeMapping.at(-1) ?? '',
      toDate,
      (date) => add(date, { minutes: 30 }),
      (date) => format(date, "yyyy-MM-dd'T'HH:mm"),
    ),
  );
  const paddingOuter = determinePaddingOuter(lastDays);
  const paddingInner = lastDays > 1 ? 0.5 : 1;

  const gridProps = {
    rows: false,
    columns: true,
    numTicks: xTickValues.length,
    stroke: '#000',
    // NOTE: this is a hack that was required to make Grid work for our custom tick values
    tickValues: xTickValues,
  } as unknown as React.ComponentPropsWithoutRef<typeof Grid>;

  return (
    <Card w="100%" h="460px" mb={3}>
      <ParentRect minHeight="350px" pb={3}>
        {({ width, height }) => (
          <>
            <ChartLegend
              direction="row"
              onLegendItemClick={(v) => {
                handleDataVisibilityChange(v);
              }}
              isMetricVisible={(v) => {
                return dataVisibility[v];
              }}
              items={[
                {
                  name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.REGULAR_PRODUCTS_LABEL'),
                  color: '#6aa8e2',
                },
                {
                  name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.ALWAYS_AVAILABLE_PRODUCTS_LABEL'),
                  color: '#ff6565',
                },
              ]}
            />
            <BaseXYChart
              width={width}
              height={height}
              yScale={{ type: 'linear', domain: [0, maxYAxis] }}
              xScale={{ type: 'band', domain: domainTimeMapping, paddingInner, paddingOuter }}
              showGrid={false}
              {...chartProps}
            >
              <Grid {...gridProps} />
              {dataVisibility[0] && (
                <AreaSeries
                  dataKey="regular_products"
                  renderLine={true}
                  data={regularProducts ?? []}
                  xAccessor={xAccessor}
                  yAccessor={yAccessor}
                  opacity={0.9}
                  fillOpacity={1}
                  color="#6aa8e2"
                  fill="#6aa8e2"
                  curve={curveStep}
                />
              )}
              {dataVisibility[1] && (
                <AreaSeries
                  dataKey="always_available"
                  renderLine={true}
                  data={alwaysAvailableProducts ?? []}
                  xAccessor={xAccessor}
                  yAccessor={yAccessor}
                  opacity={0.9}
                  fillOpacity={1}
                  color="#ff6565"
                  fill="#ff6565"
                  curve={curveStep}
                />
              )}
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showDatumGlyph
                renderTooltip={({ tooltipData }: RenderTooltipParams<StockShortageProductPointsEntry>) => {
                  const datumData = tooltipData?.nearestDatum?.datum;

                  return datumData ? (
                    <Stack spacing="4" w={250}>
                      <Stack fontWeight={'normal'} lineHeight={1.2}>
                        <TooltipRow
                          label={t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.DATE')}
                          value={dateToYMDHM(toDate(datumData.time))}
                        />
                        <TooltipRow
                          label={t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.DATA')}
                          value={
                            datumData.always_available
                              ? t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.ALWAYS_AVAILABLE_PRODUCTS_LABEL')
                              : t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.REGULAR_PRODUCTS_LABEL')
                          }
                        />
                        <TooltipRow
                          label={t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.NUMBER_OF_PRODUCTS')}
                          value={String(datumData.shortage_count)}
                        />
                        {datumData.new_ifls.length > 0 && (
                          <TooltipRow
                            label={t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.NEW_IFLS')}
                            value={datumData.new_ifls.sort().join(', ')}
                          />
                        )}
                        {datumData.reoccurring_ifls.length > 0 && (
                          <TooltipRow
                            label={t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.REOCCURRING_IFLS')}
                            value={datumData.reoccurring_ifls.sort().join(', ')}
                          />
                        )}
                        {datumData.lost_ifls.length > 0 && (
                          <TooltipRow
                            label={t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.LOST_IFLS')}
                            value={datumData.lost_ifls.sort().join(', ')}
                          />
                        )}
                      </Stack>
                    </Stack>
                  ) : null;
                }}
              />
            </BaseXYChart>
          </>
        )}
      </ParentRect>
    </Card>
  );
};
