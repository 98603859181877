import { DESKTOP_BREAKPOINT } from 'constants/css';

export const contentPadding = {
  py: {
    base: '2',
    [DESKTOP_BREAKPOINT]: '3',
  },
  px: {
    base: '2',
    [DESKTOP_BREAKPOINT]: '5',
  },
};

const marginPrefix = (val: string) => '-' + val;

export const tabsMargin = {
  my: {
    base: marginPrefix(contentPadding.py.base),
    [DESKTOP_BREAKPOINT]: marginPrefix(contentPadding.py[DESKTOP_BREAKPOINT]),
  },
  mx: {
    base: marginPrefix(contentPadding.px.base),
    [DESKTOP_BREAKPOINT]: marginPrefix(contentPadding.px[DESKTOP_BREAKPOINT]),
  },
};
