import { useQuery } from 'react-query';
import { createSalesPredictionKey } from 'constants/queryCacheKeys';
import { getSalesPrediction, SalesPredictionParams } from 'domain/predictions';
import { dateToYMD } from 'utils/date';
import { flow, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { getOrElse } from 'fp-ts/Option';

export const usePredictionStoreData = ({
  stores,
  deliveryType,
  sections,
  order,
  name,
  column_sort,
  ean,
}: {
  stores?: SalesPredictionParams['store_ids'];
  sections?: SalesPredictionParams['section_ids'];
  deliveryType?: SalesPredictionParams['delivery_type'];
  column_sort?: SalesPredictionParams['column_sort'];
  order?: SalesPredictionParams['order'];
  ean?: SalesPredictionParams['ean'];
  name?: SalesPredictionParams['name'];
}) => {
  const params: SalesPredictionParams = {
    store_ids: stores,
    section_ids: sections,
    delivery_type: deliveryType,
    date: dateToYMD(new Date()),
    size: 20,
    order,
    name,
    column_sort,
    ean,
    assortment_filter: true,
  };

  return useQuery(createSalesPredictionKey(params), () => getSalesPrediction(params), {
    select: flow(
      A.map((elem) => {
        const {
          package_size,
          sales_qty: { prediction, quantity, delivered, real_stock, wasted_stolen },
        } = elem;
        let maybeQty = quantity ?? Math.abs(prediction - (real_stock - wasted_stolen + delivered));
        const packageSizeValue = pipe(
          package_size,
          getOrElse(() => 1),
        );
        const mod = maybeQty % packageSizeValue;
        if (mod !== 0) {
          maybeQty += packageSizeValue - mod;
        }
        return {
          ...elem,
          sales_qty: {
            ...elem.sales_qty,
            quantity: Math.max(0, maybeQty),
          },
        };
      }),
    ),
  });
};
