import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import * as PATHS from 'constants/paths';
import { Card, CardTitleSection } from 'components/Card';
import { TRoleId, updateRoleById } from 'domain/roles';
import { useRoleDetails } from 'hooks/data';
import { StoreRolesForm } from './components';
import { createRoleDetailsKey, createRolesListKey } from 'constants/queryCacheKeys';

interface Props {
  roleId: TRoleId;
}

export const RoleEditPage: React.VFC<Props> = ({ roleId }) => {
  const { data, isLoading, isFetching } = useRoleDetails({ roleId }, { staleTime: 10 * 1000 });
  const { mutateAsync } = useMutation(updateRoleById);
  const queryClient = useQueryClient();
  const { push } = useHistory();

  const goBackLink = PATHS.SETTINGS.ROLES.DETAILS.toPath({ roleId });

  if (!data || isLoading || isFetching) {
    return (
      <Card>
        <CardTitleSection backButtonLink={goBackLink} width="30%">
          <Skeleton height="1em" width="100%" />
        </CardTitleSection>
        <Skeleton width="100%" minHeight="400px" />
      </Card>
    );
  }

  return (
    <Card>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {data.name}
      </CardTitleSection>
      <StoreRolesForm
        initialValue={data}
        cancelButtonLink={goBackLink}
        onSubmit={(roleDetails) =>
          mutateAsync(
            { roleId, roleDetails },
            {
              onSuccess: () => {
                queryClient.setQueryData(createRoleDetailsKey({ roleId }), roleDetails);
                queryClient.invalidateQueries(createRolesListKey());
                push(goBackLink);
              },
            },
          )
        }
      />
    </Card>
  );
};
