import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { AppUser, login, logout, getAuthenticatedUserDetails, TLoginPayload } from 'domain/user';
import { createUserKey } from 'constants/queryCacheKeys';
import { ONE_DAY } from 'constants/time';
import { UserContext } from './UserContext';
import { UnauthorizedHttpError } from 'utils/request';
import { toAppUser } from './utils';

const emptyData: O.Option<never> = O.none;

const getInitialUserDetails = async () => {
  let result: O.Option<AppUser> = O.none;

  try {
    const userDetails = await getAuthenticatedUserDetails();
    result = pipe(userDetails, toAppUser, O.some);
  } catch (err) {
    console.error(err);
  }

  return result;
};

// NOTE(m.kania): keep track whether login/cookie was checked after intial load, so that react-query doesn't
// try to fetch it once again after logging out
const state = {
  authChecked: false,
};

const UserContextProviderComponent: React.FC = ({ children }) => {
  const { data = emptyData } = useQuery(createUserKey(), getInitialUserDetails, {
    suspense: true,
    useErrorBoundary: true,
    retry: 0,
    cacheTime: ONE_DAY,
    staleTime: ONE_DAY,
    enabled: !state.authChecked,
    onSettled: () => {
      state.authChecked = true;
    },
  });
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const { mutateAsync: loginAsync } = useMutation(login, {
    onError: (err) => {
      const message =
        err instanceof UnauthorizedHttpError
          ? t('MESSAGES.INVALID_CREDENTIALS_ERROR_MESSAGE')
          : t('COMPONENTS.ERROR_MESSAGES.GENERIC_TEXT');

      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const { mutateAsync: logoutAsync } = useMutation(logout, {
    onSuccess: () => {
      queryClient.cancelQueries();
      queryClient.clear();
      setUser(O.none);
    },
  });
  const queryClient = useQueryClient();
  const [user, setUser] = useState<O.Option<AppUser>>(data);

  const contextValue = useMemo(() => {
    return {
      user,
      setUser,
      login: async (payload: TLoginPayload) => {
        let result: O.Option<AppUser> = O.none;

        try {
          await loginAsync(payload);
          const userDetails = await getAuthenticatedUserDetails();

          result = pipe(userDetails, toAppUser, O.some);
        } catch (err) {
          console.error(err);
        }

        setUser(result);
      },
      logout: async () => {
        await logoutAsync();
      },
    };
  }, [loginAsync, logoutAsync, user]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const UserContextProvider: React.FC = React.memo(UserContextProviderComponent);
