import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {}

export const ProductFamiliesTableHeaderGroup: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Box
      role="rowgroup"
      sx={{
        display: 'table-header-group',
        bg: 'gray.100',

        '& [role="columnheader"]': {
          pb: '1',
        },

        '& [role="row"]:last-of-type [role="columnheader"]': {
          pt: '1',
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
