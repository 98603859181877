import { HStack, IconButton, Select, Text, useToast } from '@chakra-ui/react';
import { MultiSelectComponent, SelectableItem } from 'components/Select';
import { StatsIconOff, StatsIconOn } from 'components/Icons';
import { getWeek } from 'date-fns';
import {
  getStoreProductWeeklyWeekCheck,
  saveStoreProductWeeklyWeekCheck,
  TCreateNewWeekQueryParams,
  TNewWeekQueryParams,
  TStoresToUpdate,
} from 'domain/products';
import React, { useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { NewWeekModal } from './NewWeekModal';
import { selectableTypes } from './types';
import { useUserContext, useUserStoreIds } from 'hooks';
import { hasMonitorAccess, hasSectionManagerAccess } from 'domain/user';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

interface FiltersProps {
  week: number;
  year: number;
  shelves: string[];
  shelf?: string;
  setShelf: (arg0: string) => void;
  setWeek: (arg0: number) => void;
  handleUpdateStore: (response: TStoresToUpdate) => void;
  types: string[];
  setTypes: (newTypes: string[]) => void;
  isSummaryStatisticsVisible: boolean;
  toggleSummaryStatistics: (isVisible: boolean) => void;
}

const filterIfTrue: <T>(check: boolean, filter: (value: T) => boolean) => (values: T[]) => T[] =
  (check, filter) => (values) => {
    if (check) {
      return values.filter(filter);
    }

    return values;
  };

export const Filters: React.FC<FiltersProps> = ({
  week,
  year,
  shelf,
  shelves,
  setShelf,
  setWeek,
  handleUpdateStore,
  types,
  setTypes,
  isSummaryStatisticsVisible,
  toggleSummaryStatistics,
}) => {
  const { t } = useTranslation('whisperme');
  const userStoreIds = useUserStoreIds();
  const [isOpen, onToggle] = useToggle(false);
  const toast = useToast();
  const currentWeek = getWeek(new Date());
  const { user } = useUserContext();
  const sectionManagerScope = hasSectionManagerAccess(user);

  const newWeekParams = useMemo<TNewWeekQueryParams>(() => {
    const params: TNewWeekQueryParams = {
      store_ids: hasMonitorAccess(user) ? ['ALL'] : userStoreIds,
    };
    return params;
  }, [user, userStoreIds]);

  const filteredSelectableTypes = useMemo(
    () =>
      pipe(
        selectableTypes,
        filterIfTrue(sectionManagerScope, ({ value }: SelectableItem) => value !== 'nodata'),
        A.map((type: SelectableItem) => {
          return {
            value: type.value,
            label: t(`SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.${type.value.toUpperCase()}_LABEL`),
          };
        }),
      ),
    [sectionManagerScope, t],
  );

  useLayoutEffect(() => {
    getStoreProductWeeklyWeekCheck(new Date().getFullYear(), week, newWeekParams)
      .then((resp) => {
        if (!resp) onToggle(true);
      })
      .catch(() => {
        toast({
          status: 'error',
          title: t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.WEEK_CHECK_ERROR'),
        });
      });
  }, [newWeekParams, week, t, toast, onToggle]);

  const saveNewWeekStatus = (status: string) => {
    const params: TCreateNewWeekQueryParams = {
      strategy: status,
      returning: true,
      ...newWeekParams,
    };
    saveStoreProductWeeklyWeekCheck(new Date().getFullYear(), week, params)
      .then((response) => {
        if (response) {
          handleUpdateStore(response);
        }
        toast({
          status: 'info',
          title: t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.WEEK_CHECK_SAVE_SUCCESS', { strategyName: status }),
        });
      })
      .catch(() => {
        toast({
          status: 'error',
          title: t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.WEEK_CHECK_SAVE_ERROR', { strategyName: status }),
        });
      })
      .finally(() => {
        onToggle(false);
      });
  };

  return (
    <HStack alignItems="end">
      <HStack>
        <IconButton
          aria-label={t('SETTINGS.ASSORTMENT_ANALYSIS.TOGGLE_STATISTICS')}
          size="sm"
          variant="ghost"
          onClick={() => toggleSummaryStatistics(!isSummaryStatisticsVisible)}
        >
          {isSummaryStatisticsVisible ? <StatsIconOff /> : <StatsIconOn />}
        </IconButton>
      </HStack>
      <HStack>
        <Text as="span">{t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.WEEK_LABEL')}</Text>
        <Select
          size="sm"
          value={week}
          onChange={(ev) => {
            const newWeek = parseInt(ev.target.value, 10);
            setWeek(newWeek);
          }}
        >
          {[-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <option value={currentWeek + i} key={`week-${currentWeek + i}`} selected={currentWeek === week}>
              {currentWeek + i}
            </option>
          ))}
        </Select>
      </HStack>

      {hasMonitorAccess(user) ? (
        <NewWeekModal
          isOpen={isOpen}
          onClose={() => onToggle(false)}
          actionHandler={saveNewWeekStatus}
          year={year}
          week={week}
        />
      ) : null}

      <HStack>
        <Text as="span">{t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.SHELF_LABEL')}</Text>
        <Select
          size="sm"
          value={shelf}
          onChange={(ev) => {
            setShelf(ev.target.value);
          }}
        >
          <option value="">{t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.ALL_LABEL')}</option>
          {shelves.map((entry) => (
            <option value={entry} key={`shelf-${entry}`}>
              {entry}
            </option>
          ))}
        </Select>
      </HStack>

      <HStack>
        <Text as="span">{t('ANALYTICS.DISCOUNTS_PAGE.FILTERS.TYPES_LABEL')}</Text>
        <MultiSelectComponent<string> onChange={setTypes} values={types} items={filteredSelectableTypes} />
      </HStack>
    </HStack>
  );
};
