import * as Ord from 'fp-ts/Ord';
import * as S from 'fp-ts/string';
import * as N from 'fp-ts/number';
import * as D from 'fp-ts/Date';
import { pipe } from 'fp-ts/function';

type FieldType = string | number | symbol;

export const sortFieldByNumberOrd = <FieldName extends FieldType, InputValues extends Record<FieldName, number>>(
  fieldName: FieldName,
): Ord.Ord<InputValues> =>
  pipe(
    N.Ord,
    Ord.contramap(({ [fieldName]: field }) => field),
  );

export const sortFieldByStringOrd = <FieldName extends FieldType, InputValues extends Record<FieldName, string>>(
  fieldName: FieldName,
): Ord.Ord<InputValues> =>
  pipe(
    S.Ord,
    Ord.contramap(({ [fieldName]: field }) => field),
  );

export const sortFieldByDateOrd = <FieldName extends FieldType, InputValues extends Record<FieldName, Date>>(
  fieldName: FieldName,
): Ord.Ord<InputValues> =>
  pipe(
    D.Ord,
    Ord.contramap(({ [fieldName]: field }) => field),
  );

export const byName = sortFieldByStringOrd('name');
