import { Box, Grid, Text } from '@chakra-ui/react';
import { CheckIcon, QuestionMarkIcon, TicketIcon } from 'components/Icons';
import { hasMonitorAccess } from 'domain/user';
import { useUserContext, useUserStoreIds } from 'hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { localTStoreManagementValue } from 'domain/products';
import { WeeklyProductListManagement } from '../hooks';
import { DisabledStateIcon } from './DisabledStateIcon';
import { ICONS_DEFAULT_ATTRIBUTES } from '../consts/css';

interface SummaryStatisticsProps {
  w1: WeeklyProductListManagement;
  w2: WeeklyProductListManagement;
  w3: WeeklyProductListManagement;
}

interface CounterProps {
  standard: number;
  promo: number;
  disabled: number;
  nodata: number;
}

const INITIAL_COUNTER: CounterProps = {
  standard: 0,
  promo: 0,
  disabled: 0,
  nodata: 0,
};

export const SummaryStatistics: React.FC<SummaryStatisticsProps> = ({ w1, w2, w3 }) => {
  const { t } = useTranslation('whisperme');
  const userStoreIds = useUserStoreIds();
  const { user } = useUserContext();
  const monitorScope = hasMonitorAccess(user);
  const counters = useMemo(() => {
    const userStore = monitorScope ? 'ALL' : userStoreIds[0];
    const counter = [{ ...INITIAL_COUNTER }, { ...INITIAL_COUNTER }, { ...INITIAL_COUNTER }];

    [w1, w2, w3].forEach((week, index) => {
      week?.data?.forEach((item) => {
        const status = pipe(
          item.stores[userStore],
          O.getOrElse(() => 'nodata'),
        );
        counter[index][status as localTStoreManagementValue]++;
        return item;
      });
    });

    return counter;
  }, [monitorScope, userStoreIds, w1, w2, w3]);

  const totalStatuses = (item: CounterProps) =>
    monitorScope
      ? item['standard'] + item['promo'] + item['disabled'] + item['nodata']
      : item['standard'] + item['promo'] + item['disabled'];

  return (
    <>
      <Grid
        templateColumns="repeat(3, 1fr)"
        mb={2}
        pt={1}
        sx={{
          paddingLeft: {
            base: `696px`,
            '2xl': `calc(min(0.3fr, 1fr) + 4fr + 250px)`,
          },
        }}
      >
        {counters.map((item, index) => {
          return (
            <Box textAlign="center" bg="gray.100" key={`counter-${index}`}>
              <Box fontSize={'lg'} pt={1}>
                <Text display="inline-block">
                  <CheckIcon color="green.700" {...ICONS_DEFAULT_ATTRIBUTES} />
                  <Text as="span" fontSize="md">
                    {item['standard']}
                  </Text>
                </Text>
                <Text display="inline-block" ml={3}>
                  <TicketIcon color="yellow.700" {...ICONS_DEFAULT_ATTRIBUTES} />
                  <Text as="span" fontSize="md">
                    {item['promo']}
                  </Text>
                </Text>
                <Text display="inline-block" ml={3}>
                  <DisabledStateIcon />

                  <Text as="span" fontSize="md">
                    {item['disabled']}
                  </Text>
                </Text>
                {monitorScope ? (
                  <Text display="inline-block" ml={3}>
                    <QuestionMarkIcon color="gray.700" {...ICONS_DEFAULT_ATTRIBUTES} />
                    <Text as="span" fontSize="md">
                      {item['nodata']}
                    </Text>
                  </Text>
                ) : null}

                {monitorScope ? (
                  <Text fontSize="md">
                    <Text as="strong">{t(`SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.TOTAL_LABEL`)}:</Text>{' '}
                    {totalStatuses(item)}
                  </Text>
                ) : null}
              </Box>
            </Box>
          );
        })}
      </Grid>
    </>
  );
};
