import { TStoreManagementValue } from 'domain/products';
import { Reducer } from 'react';
import { createReducerContext } from 'react-use';

export type EditStoreValue = TStoreManagementValue | null;

export type EditStore = {
  [year: number]: {
    [week: number]: {
      [productId: string]: {
        stores: Record<string, EditStoreValue>;
        stores_always_available: Record<string, boolean>;
      };
    };
  };
};

const INITIAL_STATE: EditStore = {};

const SET_EDIT_STORE = 'SET_EDIT_STORE';

const setEditStore = (
  productCode: string,
  storeId: string,
  week: number,
  year: number,
  value: EditStoreValue | boolean,
) => ({
  type: SET_EDIT_STORE,
  payload: {
    productCode,
    storeId,
    year,
    week,
    value,
  },
});

const matchEditStore = (action: unknown): action is ReturnType<typeof setEditStore> => {
  return (action as { type: string }).type === SET_EDIT_STORE;
};

const RESET_EDIT_STORE = 'RESET_EDIT_STORE';

const resetEditStore = () => ({
  type: RESET_EDIT_STORE,
});

const matchResetEditStore = (action: unknown): action is ReturnType<typeof resetEditStore> => {
  return (action as { type: string }).type === RESET_EDIT_STORE;
};

const reducer: Reducer<EditStore, unknown> = (state = INITIAL_STATE, action: unknown) => {
  if (matchEditStore(action)) {
    const { productCode, storeId, week, year, value } = action.payload;
    const yearData = state[year] || {};
    const weekData = yearData[week] || {};
    const productDataStores = weekData[productCode]?.stores || {};
    const productDataStoresAlwaysAvailable = weekData[productCode]?.stores_always_available || {};

    const storesToUpdate = {
      ...productDataStores,
      ...(typeof value !== 'boolean' && productDataStores[storeId] !== value ? { [storeId]: value } : {}),
    };

    const storesAlwaysAvailableToUpdate = {
      ...productDataStoresAlwaysAvailable,
      ...(typeof value === 'boolean' && productDataStoresAlwaysAvailable[storeId] !== value
        ? { [storeId]: value }
        : {}),
    };
    return {
      ...state,
      [year]: {
        ...yearData,
        [week]: {
          ...weekData,
          [productCode]: {
            stores: storesToUpdate,
            stores_always_available: storesAlwaysAvailableToUpdate,
          },
        },
      },
    };
  }

  if (matchResetEditStore(action)) {
    return INITIAL_STATE;
  }

  return state;
};

const [useEditStoreReducer, EditStoreContext] = createReducerContext(reducer, INITIAL_STATE);

type Handlers = {
  handleSetEditStore: (
    productCode: string,
    storeId: string,
    week: number,
    year: number,
  ) => (value: EditStoreValue | boolean) => void;
  handleResetEditStore: () => void;
};

export const useEditStore = (): [EditStore, Handlers] => {
  const [state, dispatch] = useEditStoreReducer();

  return [
    state,
    {
      handleSetEditStore:
        (productCode: string, storeId: string, week: number, year: number) => (value: EditStoreValue | boolean) => {
          dispatch(setEditStore(productCode, storeId, week, year, value));
        },
      handleResetEditStore: () => {
        dispatch(resetEditStore());
      },
    },
  ];
};

export { EditStoreContext };
