import { useMemo } from 'react';
import { createWeeklyProductManagement } from 'constants/queryCacheKeys';
import {
  TWeeklyProductManagementParams,
  getWeeklyProductManagement,
  TWeeklyProductManagementList,
  TWeeklyProductManagement,
} from 'domain/products';
import { useQuery } from 'react-query';

const prepareNewWeekParams = (params: TWeeklyProductManagementParams, back = 1) => {
  const newWeek = params.week - back;

  if (newWeek <= 0) {
    return { ...params, week: 52 - newWeek, year: params.year - (back % 52) };
  }

  return { ...params, week: newWeek };
};

const prepareDataMap = (
  data?: TWeeklyProductManagementList,
): Map<TWeeklyProductManagement['product_code'], TWeeklyProductManagement> | undefined => {
  return data ? new Map(data.map((param) => [param.product_code, param])) : undefined;
};

export type WeeklyProductListManagement = {
  isLoading: boolean;
  week: number;
  year: number;
  data?: TWeeklyProductManagementList;
  dataMap?: ReturnType<typeof prepareDataMap>;
};

export const useWeeklyProductManagement = (
  params: TWeeklyProductManagementParams,
): [w1: WeeklyProductListManagement, w2: WeeklyProductListManagement, w3: WeeklyProductListManagement] => {
  const { data: dataW3, isLoading: isLoadingW3 } = useQuery(createWeeklyProductManagement(params), () =>
    getWeeklyProductManagement(params),
  );
  const dataMapW3 = useMemo(() => prepareDataMap(dataW3), [dataW3]);

  const paramsW2 = prepareNewWeekParams(params, 1);
  const { data: dataW2, isLoading: isLoadingW2 } = useQuery(createWeeklyProductManagement(paramsW2), () =>
    getWeeklyProductManagement(paramsW2),
  );

  const dataMapW2 = useMemo(() => prepareDataMap(dataW2), [dataW2]);

  const paramsW1 = prepareNewWeekParams(params, 2);
  const { data: dataW1, isLoading: isLoadingW1 } = useQuery(createWeeklyProductManagement(paramsW1), () =>
    getWeeklyProductManagement(paramsW1),
  );

  const dataMapW1 = useMemo(() => prepareDataMap(dataW1), [dataW1]);

  return [
    { data: dataW1, dataMap: dataMapW1, isLoading: isLoadingW1, week: paramsW1.week, year: paramsW1.year },
    { data: dataW2, dataMap: dataMapW2, isLoading: isLoadingW2, week: paramsW2.week, year: paramsW2.year },
    { data: dataW3, dataMap: dataMapW3, isLoading: isLoadingW3, week: params.week, year: params.year },
  ];
};
