import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { enGB } from 'constants/date-fns';
import {
  DATA_RESOLUTION,
  DATA_RESOLUTION_ORDER,
  FamilyCodeValidator,
  Series,
  TDataResolution,
  TDateOptions,
  TDayNumber,
} from './types';

export const sumPlotSeries = (series: Series) =>
  pipe(
    series,
    A.reduce(0, (acc, value) => acc + (value ?? 0)),
  );

export const isDataResolution = (v: any): v is TDataResolution => DATA_RESOLUTION_ORDER.includes(v);

interface FindMinimumDataResolutionArgs {
  outputResolution: TDataResolution;
  plotDataResolution: TDataResolution;
}

export const findMinimumDataResolution = ({ outputResolution, plotDataResolution }: FindMinimumDataResolutionArgs) => {
  // NOTE(m.kania): if requested resolution is 'lower' than data granularity, set resolution to plot resolution
  if (
    plotDataResolution !== outputResolution &&
    DATA_RESOLUTION_ORDER.indexOf(outputResolution) < DATA_RESOLUTION_ORDER.indexOf(plotDataResolution)
  ) {
    return plotDataResolution;
  }

  return outputResolution;
};

export const getDataResolutionFormat = (dataResolution: TDataResolution) => {
  switch (dataResolution) {
    case DATA_RESOLUTION.MONTH:
      return 'MMMM';
    case DATA_RESOLUTION.QUARTER:
      return 'QQQ';
    case DATA_RESOLUTION.YEAR:
      return 'yyyy';
    default:
      return 'MMM dd';
  }
};

export const DEFAULT_WEEK_STARTS_ON: TDayNumber = 1;
export const DEFAULT_DATE_OPTIONS: TDateOptions = {
  locale: enGB,
  weekStartsOn: DEFAULT_WEEK_STARTS_ON,
};

export const parseFamilyCodeValue = (value?: string) =>
  pipe(
    Number.parseInt(value ?? '', 10),
    FamilyCodeValidator.decode,
    O.fromEither,
    O.fold(() => undefined, identity),
  );
