import * as t from 'io-ts';
import { ProductCodeValidator } from 'domain/core';
import { optionFromNullable } from 'io-ts-types';

export type CreteRecipeParams = {
  human_input: string;
};

const RecipeProductValidator = t.type({
  llm_product: t.string,
  description: t.string,
  product_code: optionFromNullable(ProductCodeValidator),
});

export type TRecipeProduct = t.TypeOf<typeof RecipeProductValidator>;

export const RecipeValidator = t.type({
  dish: t.string,
  recipe: t.string,
  products: t.array(RecipeProductValidator),
});

export type TRecipe = t.TypeOf<typeof RecipeValidator>;
