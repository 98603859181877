import * as R from 'fp-ts/Record';
import { Ord } from 'fp-ts/string';

import { pipe } from 'fp-ts/function';

import { ROLE_CODES, TRoleCode, TRoleCodes, TRoleDetails, TRole } from './types';

export const isReadRole = (v: any): v is TRoleCode => v === ROLE_CODES.READ;
export const isAdminRole = (v: any): v is TRoleCode => v === ROLE_CODES.ADMIN;

export const hasRoleCode = (role: TRoleCode) => (roleCodes: TRoleCodes) => roleCodes.includes(role);
export const hasAdminRole = hasRoleCode(ROLE_CODES.ADMIN);
export const hasReadRole = hasRoleCode(ROLE_CODES.READ);

export const countRoleCode =
  (roleCode: TRoleCode) =>
  (storePrivileges: TRoleDetails['store_privileges'], sectionPrivileges: TRoleDetails['section_privileges']): number =>
    pipe(
      { ...storePrivileges, ...sectionPrivileges },
      R.reduce(Ord)(0, (acc, current: number[]) => (hasRoleCode(roleCode)(current) ? acc + 1 : acc)),
    );

export const countRolePriviledges = (role: TRole) => {
  const {
    role: { store_privileges, section_privileges },
  } = role;

  const itemStoreIdsCount = R.keys(store_privileges).length;
  const itemSectionIdsCount = R.keys(section_privileges).length;

  const readCount = countRoleCode(ROLE_CODES.READ)(store_privileges, section_privileges);
  const adminCount = countRoleCode(ROLE_CODES.ADMIN)(store_privileges, section_privileges);

  return {
    itemStoreIdsCount,
    itemSectionIdsCount,
    readCount,
    adminCount,
  };
};
