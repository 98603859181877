import { useBreakpointValue } from '@chakra-ui/react';

export const APP_VIEWPORT = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
} as const;
export type AppViewport = (typeof APP_VIEWPORT)[keyof typeof APP_VIEWPORT];

export const useViewport = () =>
  useBreakpointValue({ base: APP_VIEWPORT.MOBILE, md: APP_VIEWPORT.TABLET, xl: APP_VIEWPORT.DESKTOP }) as AppViewport;
