import * as PATHS from 'constants/paths';
import * as O from 'fp-ts/Option';
import { createNavigationMatch, Path } from 'utils/router';

export enum SettingsNavigationMatch {
  GENERAL,
  PRODUCT_FAMILIES,
  PROCESING,
  USERS,
  ROLES,
}

export const settingsMatchMapping: Array<[SettingsNavigationMatch, Path]> = [
  [SettingsNavigationMatch.GENERAL, PATHS.SETTINGS.GENERAL.ROOT.PATH],
  [SettingsNavigationMatch.PRODUCT_FAMILIES, PATHS.SETTINGS.PRODUCT_FAMILIES.ROOT.PATH],
  [SettingsNavigationMatch.PROCESING, PATHS.SETTINGS.PROCESING.ROOT.PATH],
  [SettingsNavigationMatch.USERS, PATHS.SETTINGS.USERS.ROOT.PATH],
  [SettingsNavigationMatch.ROLES, PATHS.SETTINGS.ROLES.ROOT.PATH],
];

export const findSettingsNavigationMatch = createNavigationMatch({
  matchMapping: settingsMatchMapping,
  defaultValue: SettingsNavigationMatch.GENERAL,
});

//TODO(malysad): move this to more common utils
export const getOrEmptyString = O.getOrElse(() => '');
export const getOrZero = O.getOrElse(() => 0);
