import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { TObjectiveSeries } from '../types';

export const getDataTimeDomain = <Data extends { values: TObjectiveSeries['values'] }>(data: Data[]) =>
  pipe(
    data,
    A.lookup(0),
    O.chain(({ values }) =>
      pipe(
        values,
        A.lookup(0),
        O.chain((firstValue) =>
          pipe(
            values,
            A.last,
            O.map((lastValue) => [firstValue.x, lastValue.x] as [Date, Date]),
          ),
        ),
      ),
    ),
    O.toUndefined,
  );

export const PROBABILITY_THRESHOLD = 0.1;

export const AREA_SHORTAGE_LAST_WEEKS = 12;
