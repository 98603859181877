import React from 'react';
import FontFaceObserver from 'fontfaceobserver';

const bebas = new FontFaceObserver('Bebas Neue');

const cache: Array<Promise<unknown> | null> = [];
const bebasPromise = bebas
  .load()
  .catch(() => null)
  .then(() => {
    cache[0] = null;
  });

cache.push(bebasPromise);

// NOTE(m.kania): wait for assets(fonts) to load so there are no jumps/flashes of unstyled text/content
export const WaitForAssets: React.FC = () => {
  const assetPromise = cache.find((el) => el !== null);
  if (assetPromise) {
    throw assetPromise;
  }

  return null;
};
