import { createNeoEmailFindProducts } from 'constants/queryCacheKeys';
import { TFamilyCode } from 'domain/core';
import { findNeoEmailProducts, FindProductsParams } from 'domain/neoemail';
import { TStoreId } from 'domain/stores';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'react-use';

/**
 * Hook for running query in NeoEmail cration form
 * @param query - search query
 * @param store_id - store id
 * @param section_id - section id
 */
export const useNeoEmailProductSearch = (query: string, store_id: TStoreId, section_id?: TFamilyCode) => {
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const params: FindProductsParams = {
    limit: 30,
    store_id,
    section_id,
  };

  useDebounce(() => setCurrentQuery(query), 300, [query]);

  if (currentQuery && Number.isInteger(parseInt(currentQuery, 10))) {
    params.product_code_prefix = query;
  } else if (currentQuery) {
    params.product_description_query = query;
  }

  return useQuery(
    createNeoEmailFindProducts(params),
    () =>
      params.product_code_prefix || params.product_description_query
        ? findNeoEmailProducts(params)
        : Promise.resolve([]),
    {
      enabled: !!currentQuery,
    },
  );
};
