import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useReducedMotion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { I18nAppLanguage, defaultLanguage } from 'locales';
// NOTE(m.kania): direct hook imports to avoid circular deps
import { useViewport } from 'hooks/useViewport';
import { useCharacteristicFormatter } from 'hooks/useCharacteristicFormatter';
import { AppConfigContext } from './AppConfigContext';
import { enGB, fr } from 'constants/date-fns';

// NOTE(m.kania): this could come from API/user settings (if returned by BE)
const currencyCode = 'EUR' as const;

interface Props {}

const AppConfigProviderComponent: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation('whisperme');
  const [language, setLanguage] = useState<I18nAppLanguage>(
    () => (i18n.language as I18nAppLanguage) || defaultLanguage,
  );
  useLayoutEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const viewport = useViewport();
  const intlFormatters = useMemo(() => {
    const currencyFormatterOptions: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    const percentageFormatterOptions: Intl.NumberFormatOptions = {
      style: 'percent',
      minimumFractionDigits: 2,
    };
    const numberFormatterOptions: Intl.NumberFormatOptions = {
      style: 'decimal',
    };

    return {
      currencyFormatter: new Intl.NumberFormat(language, currencyFormatterOptions),
      currencyExactFormatter: new Intl.NumberFormat(language, {
        style: 'currency',
        currency: currencyCode,
      }),
      currencyFormatterOptions,

      percentageFormatter: new Intl.NumberFormat(language, percentageFormatterOptions),
      percentageRoundingFormatter: new Intl.NumberFormat(language, {
        ...percentageFormatterOptions,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      percentageFormatterOptions,

      numberFormatter: new Intl.NumberFormat(language, numberFormatterOptions),
      numberRoundingFormatter: new Intl.NumberFormat(language, {
        ...numberFormatterOptions,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      numberFormatterOptions,

      createFormatter: (options?: Intl.NumberFormatOptions) => new Intl.NumberFormat(language, options),
    };
  }, [language]);
  const characteristicFormatter = useCharacteristicFormatter(intlFormatters);
  const formatters = useMemo(
    () => ({
      ...intlFormatters,
      characteristicFormatter,
    }),
    [characteristicFormatter, intlFormatters],
  );

  const shouldReduceMotion = useReducedMotion();
  const canAnimate = shouldReduceMotion === null ? true : !shouldReduceMotion;

  return (
    <AppConfigContext.Provider
      value={{
        language,
        setLanguage,
        currencyCode,
        dateOptions: {
          locale: language === 'fr' ? fr : enGB,
          weekStartsOn: 1,
        },

        // visuals
        viewport,
        canAnimate,

        // formatting
        formatters,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

// NOTE(m.kania): reassigning so that react devtools shows 'AppConfigProviderComponent' in component tree
export const AppConfigProvider = React.memo<Props>(AppConfigProviderComponent);
