import React, { VFC } from 'react';
import { Text, UnorderedList, ListItem, PopoverHeader, PopoverBody, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useExampleQueries } from 'hooks/data';

type ChatExampleQueriesProps = {
  handleItemClick: (clickedItem: string) => void;
};

export const ChatExampleQueries: VFC<ChatExampleQueriesProps> = ({ handleItemClick }) => {
  const { t } = useTranslation('whisperme');
  const { data: exampleQueries, isFetching: isFetchingExampleQueries } = useExampleQueries();

  if (isFetchingExampleQueries || !exampleQueries) return <Text as="p">{t('COMPONENTS.CHARTS.LOADING_LABEL')}</Text>;

  return (
    <>
      <PopoverHeader fontWeight={'bold'}>{t('NEOCHAT.EXAMPLE_QUERIES_COMMANDS_LABEL')}</PopoverHeader>
      <PopoverBody>
        <UnorderedList>
          {exampleQueries?.example_queries?.map((item) => {
            return (
              <ListItem>
                <Link onClick={() => handleItemClick(item)} color="blue.500">
                  {item}
                </Link>
              </ListItem>
            );
          })}
        </UnorderedList>
      </PopoverBody>
    </>
  );
};
