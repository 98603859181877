import { Reducer } from 'react';
import { createReducerContext } from 'react-use';

export type Filters = Record<string, [from: number, to: number]>;

type FiltersState = {
  search: string;
  categoryFilters: Filters;
  behaviorFilters: Filters;
  defaultBehaviorFilters: Filters;
  defaultCategoryFilters: Filters;
};

export const filtersInitialState: FiltersState = {
  search: '',
  defaultCategoryFilters: {},
  categoryFilters: {},
  behaviorFilters: {},
  defaultBehaviorFilters: {
    frac_holiday_checkouts: [0, 1],
    frac_bio: [0, 1],
    frac_regional: [0, 1],
    frac_traditional: [0, 1],
    frac_fresh: [0, 1],
  },
};

const SET_CATEGORY_FILTERS = 'SET_CATEGORY_FILTERS';

const SET_BEHAVIOR_FILTERS = 'SET_BEHAVIOR_FILTERS';

const SET_DEFAULT_BEHAVIOR_FILTERS = 'SET_DEFAULT_BEHAVIOR_FILTERS';

const SET_DEFAULT_CATEGORY_FILTERS = 'SET_DEFAULT_CATEGORY_FILTERS';

const SET_SEARCH = 'SET_SEARCH';

const ADD_TO_SEARCH = 'ADD_TO_SEARCH';

const RESET_FILTERS = 'RESET_FILTERS';

export const setCategoryFilters = (
  payload: FiltersState['categoryFilters'],
): { type: typeof SET_CATEGORY_FILTERS; payload: FiltersState['categoryFilters'] } => ({
  type: SET_CATEGORY_FILTERS,
  payload,
});

export const setBehaviorFilters = (
  payload: FiltersState['behaviorFilters'],
): { type: typeof SET_BEHAVIOR_FILTERS; payload: FiltersState['behaviorFilters'] } => ({
  type: SET_BEHAVIOR_FILTERS,
  payload,
});

export const setDefaultBehaviorFilters = (
  payload: FiltersState['defaultBehaviorFilters'],
): { type: typeof SET_DEFAULT_BEHAVIOR_FILTERS; payload: FiltersState['defaultBehaviorFilters'] } => ({
  type: SET_DEFAULT_BEHAVIOR_FILTERS,
  payload,
});

export const setDefaultCategoryFilters = (
  payload: FiltersState['categoryFilters'],
): { type: typeof SET_DEFAULT_CATEGORY_FILTERS; payload: FiltersState['categoryFilters'] } => ({
  type: SET_DEFAULT_CATEGORY_FILTERS,
  payload,
});

export const setSearch = (paylaod: string): { type: typeof SET_SEARCH; paylaod: string } => ({
  type: SET_SEARCH,
  paylaod,
});

export const addToSearch = (payload: string): { type: typeof ADD_TO_SEARCH; payload: string } => ({
  type: ADD_TO_SEARCH,
  payload,
});

export const resetFilters = (): { type: typeof RESET_FILTERS } => ({ type: RESET_FILTERS });

type Actions =
  | ReturnType<typeof setCategoryFilters>
  | ReturnType<typeof resetFilters>
  | ReturnType<typeof setBehaviorFilters>
  | ReturnType<typeof setDefaultBehaviorFilters>
  | ReturnType<typeof setSearch>
  | ReturnType<typeof addToSearch>
  | ReturnType<typeof setDefaultCategoryFilters>;

export const filtersReducer: Reducer<FiltersState, Actions> = (state = filtersInitialState, action) => {
  if (action.type === SET_CATEGORY_FILTERS) {
    return {
      ...state,
      categoryFilters: {
        ...state.categoryFilters,
        ...action.payload,
      },
    };
  }

  if (action.type === SET_BEHAVIOR_FILTERS) {
    return {
      ...state,
      behaviorFilters: {
        ...state.behaviorFilters,
        ...action.payload,
      },
    };
  }

  if (action.type === SET_DEFAULT_BEHAVIOR_FILTERS) {
    return {
      ...state,
      behaviorFilters: {
        ...state.defaultBehaviorFilters,
        ...action.payload,
      },
      defaultBehaviorFilters: {
        ...state.defaultBehaviorFilters,
        ...action.payload,
      },
    };
  }

  if (action.type === SET_DEFAULT_CATEGORY_FILTERS) {
    return {
      ...state,
      categoryFilters: {
        ...action.payload,
      },
      defaultCategoryFilters: {
        ...action.payload,
      },
    };
  }

  if (action.type === SET_SEARCH) {
    return {
      ...state,
      search: action.paylaod,
    };
  }

  if (action.type === ADD_TO_SEARCH) {
    if (state.search.includes(action.payload)) return state;

    return {
      ...state,
      search: `${action.payload} ${state.search}`.trimEnd(),
    };
  }

  if (action.type === RESET_FILTERS) {
    return {
      ...state,
      behaviorFilters: {
        ...state.defaultBehaviorFilters,
      },
      categoryFilters: {
        ...state.defaultCategoryFilters,
      },
      search: '',
    };
  }

  return state;
};

const [useFilterReducer, FilterReducerProvider] = createReducerContext(filtersReducer, filtersInitialState);

export { useFilterReducer, FilterReducerProvider };
