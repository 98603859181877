import React from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';

import { defaultFallbackRender } from './DefaultFallbackRender';
import { Card, CardTitleSection } from 'components/Card';

interface Props {
  title?: string;
  fallbackRender?: (props: FallbackProps) => JSX.Element | null;
}

export const CardErrorBoundary: React.FC<Props> = ({ children, fallbackRender, title }) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ReactErrorBoundary
        onReset={reset}
        fallbackRender={(props) => (
          <Card>
            {title ? <CardTitleSection>{title}</CardTitleSection> : null}
            {(fallbackRender ?? defaultFallbackRender)(props)}
          </Card>
        )}
      >
        {children}
      </ReactErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);
