import { pipe } from 'fp-ts/function';
import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, makeCancellableFetch, urlWithQueryParams } from 'utils/request';
import {
  FindProductsParams,
  GetCustomersSimilarityParams,
  NeoEmailCustomerSimilarity,
  NeoEmailProductList,
} from './types';
import { DownloadCampaigne, GetHistoryParams, NeoEmailCampaigneHistoryValidator, TPostCreateCampaigne } from './types';

const API_URLS = {
  getCustomerSimilarity: () => `${API_BASE_URL}/neoemail/customers`,
  getCampaigneHistory: () => `${API_BASE_URL}/neoemail/`,
  downloadCampaigne: (reference_id: DownloadCampaigne['reference_id']) =>
    `${API_BASE_URL}/neoemail/download/${reference_id}`,
  findProducts: () => `${API_BASE_URL}/neoemail/product`,
};

export const getCampaigneHistory = (params: GetHistoryParams) =>
  pipe(
    API_URLS.getCampaigneHistory(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(NeoEmailCampaigneHistoryValidator),
  );

export const postCreateCampaigne = (params: TPostCreateCampaigne) =>
  pipe(API_URLS.getCampaigneHistory(), (url) => {
    return makeCancellableFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
  });

export const downloadCampaigneHistory = (params: DownloadCampaigne) => API_URLS.downloadCampaigne(params.reference_id);

export const getCustomerSimilarity = (params: GetCustomersSimilarityParams) =>
  pipe(
    API_URLS.getCustomerSimilarity(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(NeoEmailCustomerSimilarity),
  );

export const findNeoEmailProducts = (params: FindProductsParams) =>
  pipe(API_URLS.findProducts(), urlWithQueryParams(params), createValidatedCancellableFetch(NeoEmailProductList));
