import React, { useCallback } from 'react';
import { useMeasure } from 'react-use';
import { Box } from '@chakra-ui/react';

type BoxProps = React.ComponentPropsWithoutRef<typeof Box>;
type ChildrenProps = ReturnType<typeof useMeasure>[1];

interface Props extends BoxProps {
  children: (rect: ChildrenProps) => React.ReactNode;
  rectVariant?: 'default' | 'square';
}

export const ParentRect: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ children, rectVariant, ...rest }, ref) => {
    const [measureRef, measureRect] = useMeasure<HTMLDivElement>();
    // TODO(m.kania): find some existing helper/util
    const mergedRef = useCallback(
      (el: HTMLDivElement | null) => {
        [ref, measureRef].forEach((r) => {
          if (typeof r === 'function' && el !== null) {
            r(el);
          } else if (r && 'current' in r) {
            r.current = el;
          }
        });
      },
      [ref, measureRef],
    );

    const whMin = Math.min(measureRect.width, measureRect.height);
    const rect = rectVariant === 'square' ? { ...measureRect, width: whMin, height: whMin } : measureRect;

    return (
      <Box width="100%" height="100%" flexGrow={1} {...rest} ref={mergedRef}>
        {rect.width > 0 && rect.height > 0 ? children(rect) : null}
      </Box>
    );
  },
);
