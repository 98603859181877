import { useQuery, UseQueryOptions } from 'react-query';
import {
  createProductDetailsKey,
  createProductFullDetailsKey,
  createMonthProductStatisticsKey,
  createWeekProductStatisticsKey,
  createSalesAnomalyProductKey,
} from 'constants/queryCacheKeys';
import {
  getProductDetails,
  getProductFullDetails,
  getMonthProductAnalyticsDetails,
  getSalesAnomalyProductStats,
  getWeekProductAnalyticsDetails,
  TGetProductDetailsParams,
  TProductDetails,
  TProductAnomaliesRequestQueryParams,
  TProductStatisticsRequestQueryParams,
} from 'domain/products';

type DataType = TProductDetails;
type ErrorType = Error;

export const useProductDetails = (
  params: TGetProductDetailsParams,
  options?: UseQueryOptions<DataType, ErrorType, any>,
) => useQuery<DataType, ErrorType>(createProductDetailsKey(params), () => getProductDetails(params), options);

export const useProductFullDetails = (
  params: TGetProductDetailsParams,
  options?: UseQueryOptions<DataType, ErrorType, any>,
) => useQuery<DataType, ErrorType>(createProductFullDetailsKey(params), () => getProductFullDetails(params), options);

export const useWeekProductStatistics = (params: TProductStatisticsRequestQueryParams) =>
  useQuery(createWeekProductStatisticsKey(params), () => getWeekProductAnalyticsDetails(params));

export const useMonthProductStatistics = (params: TProductStatisticsRequestQueryParams) =>
  useQuery(createMonthProductStatisticsKey(params), () => getMonthProductAnalyticsDetails(params));

export const useProductAnomalies = (params: TProductAnomaliesRequestQueryParams) =>
  useQuery(createSalesAnomalyProductKey(params), () => getSalesAnomalyProductStats(params));
