import { Stack } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { CardErrorBoundary } from 'components/ErrorMessages';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatCard } from './components/ChatCard';

export const NeoChatPage: VFC = () => {
  const { t } = useTranslation('whisperme');

  return (
    <CardErrorBoundary>
      <Card>
        <Stack h="100%" maxHeight={'100vh'}>
          <CardTitleSection as="h2" fontWeight="bold">
            {t('NEOCHAT.TITLE')}
          </CardTitleSection>
          <ChatCard showExampleQueries={true} />
        </Stack>
      </Card>
    </CardErrorBoundary>
  );
};
