import React, { useState, VFC } from 'react';
import {
  Button,
  Box,
  HStack,
  FormControl,
  IconButton,
  Spinner,
  Stack,
  Text,
  Textarea,
  Switch,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { DisplayFormat, RequestedModel } from 'domain/neochat';
import { QuestionMarkIcon } from 'components/Icons';
import { ChatExampleQueries } from './ChatExampleQueries';

interface ChatFooterProps {
  handleSendMessage?: (inputPrompt: string) => void | undefined;
  isWorking: boolean;
  displayFormat: DisplayFormat;
  setDisplayFormat: (value: DisplayFormat) => void;
  requestedModel: RequestedModel;
  setRequestedModel: (value: RequestedModel) => void;
  compact?: boolean;
  showExampleQueries?: boolean;
}

export const ChatFooter: VFC<ChatFooterProps> = ({
  handleSendMessage,
  isWorking,
  displayFormat,
  setDisplayFormat,
  requestedModel,
  setRequestedModel,
  compact = false,
  showExampleQueries,
}) => {
  const { t } = useTranslation('whisperme');
  const [inputMessage, setInputMessage] = useState('');
  const [isOpen, onToggle] = useToggle(false);

  const handleSend = () => {
    const trimmedMessage = inputMessage.trim();
    if (!!trimmedMessage && handleSendMessage) {
      handleSendMessage(inputMessage.trim());
      setInputMessage('');
    }
  };

  return (
    <Box w="100%" mt="5">
      <FormControl size="md" p={1}>
        <Flex>
          <Box w="100%">
            <Textarea
              fontSize={compact ? '0.8rem' : '1rem'}
              id="queryPrompt"
              value={inputMessage}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter' && !ev.shiftKey && !!inputMessage.trim()) {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleSend();
                }
              }}
              onChange={(ev) => {
                setInputMessage(ev.target.value);
              }}
              minH={{ base: '50px', md: '100px' }}
            />
          </Box>
          {showExampleQueries ? (
            <Box ml={2} w="1fr">
              <Popover isOpen={isOpen} closeOnBlur={true} onClose={() => onToggle(false)} isLazy={true}>
                <PopoverTrigger>
                  <IconButton
                    icon={<QuestionMarkIcon />}
                    fontSize="sm"
                    size="sm"
                    onClick={() => onToggle(!isOpen)}
                    variant="outline"
                    colorScheme="blue"
                    aria-label={t('NEOCHAT.EXAMPLE_QUERIES_COMMANDS_LABEL')}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <ChatExampleQueries handleItemClick={setInputMessage} />
                </PopoverContent>
              </Popover>
            </Box>
          ) : null}
        </Flex>
      </FormControl>
      <Stack direction={compact ? 'column' : 'row'} h="42px">
        <HStack m={2} p={1}>
          <Button
            onClick={() => handleSend()}
            mt={isWorking ? 0 : 3}
            py={2}
            disabled={isWorking}
            size={compact ? 'sm' : 'md'}
            px={6}
            fontSize={compact ? '0.8rem' : '1rem'}
            w="100px"
          >
            {t('NEOCHAT.SEND_BUTTON_LABEL')}
          </Button>
          <Box m={2} pt={3} px={2} w="32px">
            {isWorking ? <Spinner size={'md'} thickness="2px" p={1} /> : null}
          </Box>
        </HStack>
        <HStack m={2} width="0.25fr" p={3}>
          <Text as="span">{t('NEOCHAT.RESPONSE_FORMAT_SWITCH_LABEL_SIMPLE')}</Text>
          <Switch
            size="sm"
            id="toggleResponseFormat"
            value={displayFormat}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              ev.preventDefault();
              setDisplayFormat(
                ev.target.value === DisplayFormat.SIMPLE ? DisplayFormat.ADVANCED : DisplayFormat.SIMPLE,
              );
            }}
          />
          <Text as="span">{t('NEOCHAT.RESPONSE_FORMAT_SWITCH_LABEL_ADVANCED')}</Text>
        </HStack>
        <HStack m={2} width="0.25fr" p={3}>
          <Text as="span">{t('NEOCHAT.REQUESTED_MODEL_SWITCH_LABEL_LOCAL')}</Text>
          <Switch
            size="sm"
            id="toggleRequestedModel"
            value={requestedModel}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              ev.preventDefault();
              setRequestedModel(
                ev.target.value === RequestedModel.LOCAL_LLM ? RequestedModel.OPENAI : RequestedModel.LOCAL_LLM,
              );
            }}
          />
          <Text as="span">{t('NEOCHAT.REQUESTED_MODEL_SWITCH_LABEL_OPENAI')}</Text>
        </HStack>
      </Stack>
    </Box>
  );
};
