import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { createUserDeleteKey } from 'constants/queryCacheKeys';
import { deleteUser, TUser, TUserId, TUserList } from 'domain/user';
import { useUserDetails } from 'hooks/data';
import { PageSpinner } from 'components/Spinners';

interface Props {
  userId: TUserId;
  onClose: () => void;
}
const UserDeleteConfirmationModalComponent: React.VFC<Props> = ({ userId, onClose }) => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data } = useUserDetails({ userId });
  const userName = data?.display_name;

  const { mutateAsync, status } = useMutation(deleteUser, {
    retry: false,
    onMutate: () => {
      const usersSnapshot = queryClient.getQueryData<TUserList>(['user', 'list']);
      if (usersSnapshot && !!userId) {
        const filteredUsers = usersSnapshot.items.filter((item: { id: number; user: TUser }) => item.id !== userId);
        queryClient.cancelQueries(createUserDeleteKey({ userId }));
        queryClient.setQueryData(['user', 'list'], {
          ...usersSnapshot,
          items: [...filteredUsers],
        });
      }
      onClose();
      return { usersSnapshot };
    },
    onError: (_err, _var, context) => {
      if (context?.usersSnapshot) {
        queryClient.setQueryData(['user', 'list'], context.usersSnapshot);
      }
      toast({
        title: 'Error: Failed to delete user',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onSuccess: (_err, _var, context) => {
      if (context?.usersSnapshot) {
        const deletedUser = context.usersSnapshot.items.filter(
          (item: { id: number; user: TUser }) => item.id === userId,
        );
        toast({
          title: `User ${deletedUser[0].user.display_name} (user id: ${userId}) deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });
  return (
    <Modal isOpen={userId !== null} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {userName ? (
          <>
            <ModalHeader>{t('SETTINGS.USERS.USER_DELETE_MODAL.TITLE', { value: userName })}</ModalHeader>
            <ModalBody>
              <Text as="span"> {t('SETTINGS.USERS.USER_DELETE_MODAL.HINT_TEXT')}</Text>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button variant="ghost" onClick={onClose}>
                  {t('SETTINGS.USERS.USER_DELETE_MODAL.CANCEL_BUTTON_LABEL')}
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={status === 'loading'}
                  onClick={() => {
                    if (userId !== null) {
                      mutateAsync({ userId: userId });
                    }
                  }}
                >
                  {t('SETTINGS.USERS.USER_DELETE_MODAL.DELETE_BUTTON_LABEL')}
                </Button>
              </HStack>
            </ModalFooter>
          </>
        ) : (
          <PageSpinner />
        )}
      </ModalContent>
    </Modal>
  );
};
export const UserDeleteConfirmationModal = React.memo(UserDeleteConfirmationModalComponent);
