import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Fill } from 'nano-slots';
import { useTranslation } from 'react-i18next';

import { TITLE } from 'constants/slots';

interface Props {
  children?: string | string[];
}

export const TopbarHeading: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('whisperme');
  let title = children;
  if (Array.isArray(children)) {
    title = children.join(t('GENERIC.TITLE_PARTS_CONNECTOR'));
  }

  return (
    <>
      <Fill name={TITLE}>{title}</Fill>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </>
  );
};
