import { MutableRefObject, useMemo } from 'react';

type RefValue = HTMLElement | SVGSVGElement | null;
type CallbackRef = (node: RefValue) => void;
type AnyRef = CallbackRef | MutableRefObject<RefValue>;

const setRefValue = (ref: AnyRef, value: RefValue) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

export const useForkRef = (refA: AnyRef, refB: AnyRef): CallbackRef | null => {
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }

    return (refValue: RefValue) => {
      setRefValue(refA, refValue);
      setRefValue(refB, refValue);
    };
  }, [refA, refB]);
};
