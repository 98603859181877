import * as PATHS from 'constants/paths';
import * as O from 'fp-ts/Option';
import { GetValueFromStoresOptions } from 'domain/products';
import { createNavigationMatch, Path } from 'utils/router';
import { WeeklyProductListManagement } from './WeeklyProductList/hooks';
import { EditStore } from './WeeklyProductList/hooks/useEditStore';

export enum AssortmentManagementPageTabs {
  WEEKLY_PRODUCT_LIST,
  SUPERMARKET_SELECTED_ASSORTMENT,
}

export const AssortmentManagementMapping: Array<[number, Path]> = [
  [AssortmentManagementPageTabs.WEEKLY_PRODUCT_LIST, PATHS.ASSORTMENT_MANAGEMENT.ROOT.PATH],
  [
    AssortmentManagementPageTabs.SUPERMARKET_SELECTED_ASSORTMENT,
    PATHS.ASSORTMENT_MANAGEMENT.SUPERMARKET_SELECTED_ASSORTMENT.PATH,
  ],
];

export const findAssortmentManagementMatch = (assortmentManagementMapping = AssortmentManagementMapping) =>
  createNavigationMatch({
    matchMapping: assortmentManagementMapping,
    defaultValue: AssortmentManagementPageTabs.WEEKLY_PRODUCT_LIST,
  });

export const getValueFromStores = (
  editStore: EditStore,
  weekData: WeeklyProductListManagement,
  { year, week, product_code, storeId }: GetValueFromStoresOptions,
) => {
  const editStoreValue = editStore[year]?.[week]?.[product_code]?.stores?.[storeId];

  if (editStoreValue) {
    return O.some(editStoreValue);
  }

  return weekData.dataMap?.get(product_code)?.stores[storeId] ?? O.none;
};

export const getValueFromStoresAvailable = (
  editStore: EditStore,
  weekData: WeeklyProductListManagement,
  { year, week, product_code, storeId }: GetValueFromStoresOptions,
): boolean => {
  const editStoreValue = editStore[year]?.[week]?.[product_code]?.stores_always_available?.[storeId];

  if (editStoreValue !== undefined) {
    return editStoreValue;
  }

  return weekData.dataMap?.get(product_code)?.stores_always_available[storeId] ?? false;
};
