import React from 'react';
import { HStack, useRadioGroup } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { StoreRoleRadio } from './StoreRoleRadio';
import { TRoleOption } from './utils';

interface Props {
  name: string;
  value?: TRoleOption;
  onChange?: (v: TRoleOption) => void;
}

export const StoreRoleRadioGroup: React.VFC<Props> = ({ name, value = 'none', onChange }) => {
  const { t } = useTranslation('whisperme');
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  });

  return (
    <HStack {...getRootProps()}>
      <StoreRoleRadio {...getRadioProps({ value: 'none' })}>
        {t('SETTINGS.ROLES.ROLE_FORM.NO_ROLE_LABEL')}
      </StoreRoleRadio>
      <StoreRoleRadio {...getRadioProps({ value: 'read' })}>{t('SETTINGS.ROLES.READ_ROLE_LABEL')}</StoreRoleRadio>
      <StoreRoleRadio {...getRadioProps({ value: 'admin' })}>{t('SETTINGS.ROLES.ADMIN_ROLE_LABEL')}</StoreRoleRadio>
    </HStack>
  );
};
