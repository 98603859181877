import whispermeEn from './en-GB/whisperme.translations.json';
import whispermeFr from './fr/whisperme.translations.json';
import whispermeApiEn from './en-GB/whisperme-api.translations.json';
import whispermeApiFr from './fr/whisperme-api.translations.json';

// NOTE(m.kania): if this grows, we can switch to dynamic imports
export const resources = {
  'en-GB': {
    whisperme: whispermeEn,
    'whisperme-api': whispermeApiEn,
  },
  fr: {
    whisperme: whispermeFr,
    'whisperme-api': whispermeApiFr,
  },
} as const;

export type ResourcesConfig = typeof resources;
export type I18nAppLanguage = keyof ResourcesConfig;
export type I18nNamespace = keyof ResourcesConfig[I18nAppLanguage];

export const defaultNS: I18nNamespace = 'whisperme';
export const languages = Object.keys(resources) as I18nAppLanguage[];

export const isValidLanguageKey = (v: any): v is I18nAppLanguage => languages.includes(v);
