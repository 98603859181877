import { ProductCodeValidator } from 'domain/core';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

export const INSIGHT_TAG_TYPE = {
  TOP_PRODUCTS: 'insight_top_products',
  FAMILY_BEST: 'insight_family_best',
  FAMILY_GROWING: 'insight_family_growing',
  FAMILY_DROPPING: 'insight_family_dropping',
  TOP_PRODUCTS_SHORTAGE: 'insight_top_products_shortage',
} as const;
export const SUPPORTED_INSIGHT_TAG_TYPES = Object.values(INSIGHT_TAG_TYPE);
export type TInsightTagType = TInsightDetails['tag'];

const InsightTopProductsDetailsValidator = t.type({
  tag: t.literal(INSIGHT_TAG_TYPE.TOP_PRODUCTS),
  params: t.type({
    products: t.array(
      t.type({
        description: optionFromNullable(t.string),
        product_code: ProductCodeValidator,
        quantity: t.number,
        eur: t.number,
      }),
    ),
  }),
});

const InsightFamilyBestDetailsValidator = t.type({
  tag: t.literal(INSIGHT_TAG_TYPE.FAMILY_BEST),
  params: t.type({
    best_last_week: optionFromNullable(t.string),
    best_this_week: optionFromNullable(t.string),
  }),
});

const InsightFamilyGrowingDetailsValidator = t.type({
  tag: t.literal(INSIGHT_TAG_TYPE.FAMILY_GROWING),
  params: t.type({
    family_name: t.string,
    pct_change: t.number,
    change_eur: t.number,
    grow: optionFromNullable(t.number),
    highest: optionFromNullable(t.number),
  }),
});

const InsightFamilyDroppingDetailsValidator = t.type({
  tag: t.literal(INSIGHT_TAG_TYPE.FAMILY_DROPPING),
  params: t.type({
    family_name: t.string,
    pct_change: t.number,
    change_eur: t.number,
    fall: optionFromNullable(t.number),
    lowest: optionFromNullable(t.number),
  }),
});

const InsightTopProductsShortageDetailsValidator = t.type({
  tag: t.literal(INSIGHT_TAG_TYPE.TOP_PRODUCTS_SHORTAGE),
  params: t.type({
    products: t.array(
      t.type({
        description: optionFromNullable(t.string),
        product_code: ProductCodeValidator,
        eur: t.number,
      }),
    ),
  }),
});

export const InsightDetailsValidator = t.union([
  InsightTopProductsDetailsValidator,
  InsightFamilyBestDetailsValidator,
  InsightFamilyGrowingDetailsValidator,
  InsightFamilyDroppingDetailsValidator,
  InsightTopProductsShortageDetailsValidator,
]);
export type TInsightDetails = t.TypeOf<typeof InsightDetailsValidator>;
