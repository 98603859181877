import React from 'react';

import { PageSpinner, PageSpinnerProps } from './PageSpinner';

export interface PageSpinnerOverlayProps extends PageSpinnerProps {
  children?: never;
}

export const PageSpinnerOverlay: React.FC<PageSpinnerOverlayProps> = (props) => (
  <PageSpinner bg="rgba(255,255,255,0.6)" position="absolute" top="0" left="0" bottom="0" right="0" {...props} />
);
