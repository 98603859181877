import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Checkbox,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { TUser } from 'domain/user';
import { userDetailsToFormData, formDataToUserDetails, TUserFormData } from './utils';
import { useRoleList } from 'hooks/data';
import { InfoBox } from 'components/InfoBox';
import { SelectUserTypes } from 'components/Select';

interface TUserBaseProps {
  onSubmit: (userDetails: TUser) => void;
  cancelButtonLink: string;
}

interface TNewUserProps extends TUserBaseProps {
  variant: 'new';
}

interface TEditUserProps extends TUserBaseProps {
  variant: 'edit';
  initialValue: TUser;
}

type Props = TNewUserProps | TEditUserProps;

export const UserForm: React.VFC<Props> = ({ cancelButtonLink, onSubmit, ...rest }) => {
  const { t } = useTranslation('whisperme');
  const { variant } = rest;
  const [showPasswordInputs, setShowPasswordInputs] = useState(() => variant === 'new');
  const { data: { items: roles } = { items: [] } } = useRoleList({ suspense: true });

  const formOptions = useMemo(() => {
    const passwordFields = {
      password: Yup.string().required(t('VALIDATION.PASSWORD_REQUIRED')),
      password_confirm: Yup.string()
        .required(t('VALIDATION.PASSWORD_REQUIRED'))
        .oneOf([Yup.ref('password')], t('VALIDATION.PASSWORDS_DO_NOT_MATCH')),
    };

    const formSchema = Yup.object().shape({
      display_name: Yup.string().required(t('VALIDATION.FIELD_REQUIRED')),
      email: Yup.string().email(t('VALIDATION.INVALID_EMAIL')).required(t('VALIDATION.FIELD_REQUIRED')),
      user_type_id: Yup.number(),
      roles: Yup.array(Yup.number()),
      ...(showPasswordInputs ? passwordFields : {}),
    });

    return {
      resolver: yupResolver(formSchema),
    };
  }, [showPasswordInputs, t]);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<TUserFormData>({
    ...formOptions,
    defaultValues:
      variant === 'edit'
        ? userDetailsToFormData(rest.initialValue)
        : {
            is_admin: false,
            user_type_id: 2,
            roles: [],
          },
  });

  const selectedRoles = watch('roles');
  const userTypeId = watch('user_type_id');

  return (
    <Stack
      as="form"
      direction="column"
      onSubmit={handleSubmit((formData) => pipe(formData, formDataToUserDetails, onSubmit))}
      spacing="5"
    >
      <FormControl isInvalid={Boolean(errors.display_name)}>
        <FormLabel>{t('SETTINGS.USERS.DISPLAY_NAME_LABEL')}</FormLabel>
        <Input {...register('display_name')} />
        {errors.display_name && <FormErrorMessage>{errors.display_name.message}</FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={Boolean(errors.email)}>
        <FormLabel>{t('SETTINGS.USERS.EMAIL_LABEL')}</FormLabel>
        <Input {...register('email')} />
        {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
      </FormControl>
      {variant === 'edit' && (
        <Checkbox
          isChecked={showPasswordInputs}
          onChange={(ev) => {
            setShowPasswordInputs(ev.target.checked);
          }}
        >
          {t('SETTINGS.USERS.USER_FORM.CHANGE_USER_PASSWORD_LABEL')}
        </Checkbox>
      )}
      {showPasswordInputs && (
        <>
          <FormControl isInvalid={Boolean(errors.password)}>
            <FormLabel>{t('SETTINGS.USERS.USER_FORM.PASSWORD_LABEL')}</FormLabel>
            <Input type="password" {...register('password')} />
            {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={Boolean(errors.password_confirm)}>
            <FormLabel>{t('SETTINGS.USERS.USER_FORM.CONFIRM_PASSWORD_LABEL')}</FormLabel>
            <Input type="password" {...register('password_confirm')} />
            {errors.password_confirm && <FormErrorMessage>{errors.password_confirm.message}</FormErrorMessage>}
          </FormControl>
        </>
      )}
      <FormControl>
        <FormLabel>{t('SETTINGS.USERS.TYPE_LABEL')}</FormLabel>
        <SelectUserTypes value={userTypeId} onChange={(value: number) => setValue('user_type_id', value)} />
      </FormControl>
      {roles.length > 0 && (
        <Stack spacing="3">
          <Text as="span" fontSize="md">
            {t('SETTINGS.USERS.ROLES_LABEL')}
          </Text>
          <InfoBox maxHeight="500px" overflowY="auto" bg="gray.50">
            <Stack as="ul" spacing="1">
              {roles.map((r) => (
                <HStack as="li" key={r.id}>
                  <Checkbox
                    size="lg"
                    isChecked={selectedRoles.includes(r.id)}
                    onChange={(ev) => {
                      let newRoles = selectedRoles;

                      if (ev.target.checked) {
                        newRoles = [...newRoles, r.id];
                      } else {
                        newRoles = newRoles.filter((roleId) => roleId !== r.id);
                      }

                      setValue('roles', newRoles);
                    }}
                  >
                    {r.role.name}
                  </Checkbox>
                </HStack>
              ))}
            </Stack>
          </InfoBox>
        </Stack>
      )}
      <Stack direction="row" justifyContent="flex-end" pt="2">
        <Button as={Link} to={cancelButtonLink} variant="outline">
          {t('SETTINGS.USERS.USER_FORM.CANCEL_BUTTON_LABEL')}
        </Button>
        <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
          {t('SETTINGS.USERS.USER_FORM.SAVE_BUTTON_LABEL')}
        </Button>
      </Stack>
    </Stack>
  );
};
