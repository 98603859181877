import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

import * as PATHS from 'constants/paths';
import {
  AnalyticsIcon,
  SettingsIcon,
  FreeTimeIcon,
  MorningIcon,
  ChatIcon,
  DailyIcon,
  NeoEmailIcon,
  CartIcon,
  OperationsIcon,
} from 'components/Icons';
import { findNavigationMatch } from './utils';
import { useUserContext } from 'hooks';
import { NavigationMatch } from './types';
import {
  AppUser,
  hasAdminAccess,
  hasMonitorAccess,
  hasSectionManagerAccess,
  hasSupermarketManagerAccess,
} from 'domain/user';

export interface NavigationItem {
  label: string;
  path: string;
  icon: React.ComponentType;
  active?: boolean;
}

const hasSupermarketMAndSectionMAccess = (u: O.Option<AppUser>) =>
  O.isSome(u) && (hasSupermarketManagerAccess(u) || hasSectionManagerAccess(u));

const hasMonitorAndSectionMAccess = (u: O.Option<AppUser>) =>
  O.isSome(u) && (hasMonitorAccess(u) || hasSectionManagerAccess(u));

const isNotAdmin = (u: O.Option<AppUser>) => O.isSome(u) && !hasAdminAccess(u);

const userHasAccess = (accessScope: boolean, body: NavigationItem) => {
  return accessScope ? O.some(body) : O.none;
};

export const useMainNavigationItems = () => {
  const { t } = useTranslation('whisperme');
  const { pathname } = useLocation();
  const { user } = useUserContext();

  const supermarketAndSectionManagerScope = hasSupermarketMAndSectionMAccess(user);
  const monitorAndSectionManagerScope = hasMonitorAndSectionMAccess(user);

  return useMemo((): [NavigationItem[], NavigationItem[]] => {
    const navMatch = findNavigationMatch(pathname);

    return [
      pipe(
        [
          userHasAccess(supermarketAndSectionManagerScope, {
            path: PATHS.MORNING_TASKS.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.MORNING_TASKS_LABEL'),
            icon: MorningIcon,
            active: navMatch === NavigationMatch.MORNING_TASKS,
          }),
          userHasAccess(supermarketAndSectionManagerScope, {
            path: PATHS.DAILY_TASKS.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.DAILY_TASKS_LABEL'),
            icon: DailyIcon,
            active: navMatch === NavigationMatch.DAILY_TASKS,
          }),
          userHasAccess(supermarketAndSectionManagerScope, {
            path: PATHS.FREE_TASKS.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.FREE_TASKS_LABEL'),
            icon: FreeTimeIcon,
            active: navMatch === NavigationMatch.FREE_TASKS,
          }),
          userHasAccess(isNotAdmin(user), {
            path: PATHS.ANALYTICS.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.ANALYTICS.ROOT_LABEL'),
            icon: AnalyticsIcon,
            active: navMatch === NavigationMatch.ANALYTICS,
          }),
          userHasAccess(monitorAndSectionManagerScope, {
            path: PATHS.ASSORTMENT_MANAGEMENT.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.ASSORTMENT_MANAGEMENT.ROOT_LABEL'),
            icon: OperationsIcon,
            active: navMatch === NavigationMatch.ASSORTMENT_MANAGEMENT,
          }),
          O.some({
            path: PATHS.SETTINGS.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL'),
            icon: SettingsIcon,
            active: navMatch === NavigationMatch.SETTINGS,
          }),
        ],
        A.compact,
      ),
      pipe(
        [
          userHasAccess(supermarketAndSectionManagerScope, {
            path: PATHS.LLM.NEOEMAIL.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.LLM.NEOEMAIL.LABEL'),
            icon: NeoEmailIcon,
            active: navMatch === NavigationMatch.NEOEMAIL,
          }),
          userHasAccess(supermarketAndSectionManagerScope, {
            path: PATHS.LLM.SHOPPING.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.LLM.SHOPPING.ROOT_LABEL'),
            icon: CartIcon,
            active: navMatch === NavigationMatch.SHOPPING,
          }),
          userHasAccess(supermarketAndSectionManagerScope, {
            path: PATHS.LLM.NEOCHAT.ROOT.toPath(),
            label: t('GENERIC.NAVIGATION.LLM.NEOCHAT.LABEL'),
            icon: ChatIcon,
            active: navMatch === NavigationMatch.NEOCHAT,
          }),
        ],
        A.compact,
      ),
    ];
  }, [pathname, supermarketAndSectionManagerScope, t, user, monitorAndSectionManagerScope]);
};
