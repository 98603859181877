import { MinusIcon, TimesIcon } from 'components/Icons';
import { hasMonitorAccess } from 'domain/user';
import { useUserContext } from 'hooks';
import React from 'react';
import { ICONS_DEFAULT_ATTRIBUTES } from '../consts/css';

export const DisabledStateIcon: React.FC = () => {
  const { user } = useUserContext();
  const Icon = hasMonitorAccess(user) ? TimesIcon : MinusIcon;

  return <Icon color="red.700" {...ICONS_DEFAULT_ATTRIBUTES} />;
};
