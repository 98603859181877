import { flow } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import { fromCompare } from 'fp-ts/Ord';
import { StockState } from 'hooks/useStocksReducer';

const RandOrd = fromCompare<string>((a, b) => {
  if (a === b) return 0;
  if (a[0] > b[0]) return -1;
  if (a[0] < b[0]) return 1;
  if (a[2] < b[2]) return 1;

  return -1;
});

const isStockSaveFiled = (key: string, value: unknown): value is O.Option<number> =>
  ['start_of_day', 'wasted', 'stolen'].includes(key);

export const stateToSaveData: (a: StockState) => Record<string, (number | undefined)[]> = flow(
  R.filter(({ changed }) => Boolean(changed)),
  R.map(
    flow(
      R.filterMapWithIndex((key, value) => {
        if (!isStockSaveFiled(key, value)) return O.none;

        return O.some(value);
      }),
      R.collect(RandOrd)((_, value) => O.toUndefined(value)),
    ),
  ),
);

export const getOrZero = O.getOrElse(() => 0);

export const getOrFalse = O.getOrElse(() => false);

export const getOrEmptyString = O.getOrElse(() => '');
