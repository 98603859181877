import {
  Box,
  Collapse,
  HStack,
  Text,
  StackItem,
  useDisclosure,
  Table,
  Th,
  Tbody,
  Thead,
  Tr,
  Td,
  IconButton,
  Stack,
  Center,
} from '@chakra-ui/react';
import { exportOrders, TGroupedOrder, TGroupedOrdersList } from 'domain/predictions';
import React, { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import { contramap } from 'fp-ts/Ord';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { useUserSections, useUserStoreIds } from 'hooks';
import { ExportLink } from 'components/ExportLink';
import { getOrZero } from 'pages/MorningTasksPage/StocksPage/utils';
import { useTranslation } from 'react-i18next';

type OrderDayParams = {
  date: string;
  orders: TGroupedOrdersList;
};

export const OrderDay: React.VFC<OrderDayParams> = ({ date, orders }) => {
  const { t } = useTranslation('whisperme');
  const { isOpen, onToggle } = useDisclosure();
  const userStores = useUserStoreIds();
  const userSections = useUserSections();
  const userSectionIds = useMemo(
    () =>
      pipe(
        userSections,
        A.map(({ id }) => id),
      ),
    [userSections],
  );

  const [quantities, delivered] = useMemo(
    () =>
      pipe(
        orders,
        A.reduce([0, 0] as [number, number], (acc, { quantity, delivered }) => [
          acc[0] + quantity,
          acc[1] + getOrZero(delivered),
        ]),
      ),
    [orders],
  );

  return (
    <Box width="100%" borderRadius="base" border="1px" borderColor="gray.200" boxShadow="base" p="3">
      <HStack justifyContent="space-between">
        <StackItem>
          <Text size="md">
            {t('MORNING_TAKS.ORDER_DAY.ORDER_FROM_DATE_LABEL')}: {date}
          </Text>
        </StackItem>
        <HStack>
          <StackItem>
            <Text size="md">
              {t('MORNING_TAKS.ORDER_DAY.TOTAL_LABEL')}: {quantities}
            </Text>
          </StackItem>
          <StackItem>
            <Text size="md">
              {t('MORNING_TAKS.ORDER_DAY.DELIVERED_LABEL')}: {delivered}
            </Text>
          </StackItem>
          <StackItem>
            <IconButton
              icon={isOpen ? <ArrowUp /> : <ArrowDown />}
              onClick={onToggle}
              aria-label={t('MORNING_TAKS.ORDER_DAY.OPEN_ORDER_DETAILS_ARIA_LABEL')}
            />
          </StackItem>
        </HStack>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack maxH="30rem" w="100%" overflow="auto">
          <Center>
            <ExportLink
              href={exportOrders({ date, store_ids: userStores, section_ids: userSectionIds })}
              fileName="order.xlsx"
            />{' '}
          </Center>
          <Table>
            <Thead>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_ID')}</Th>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_EAN13')}</Th>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_PRODUCT_CODE')}</Th>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_PRODUCT_DESCRIPTION')}</Th>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_STORE')}</Th>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_ORDER_QUANTITY')}</Th>
              <Th>{t('MORNING_TAKS.ORDER_DAY.COLUMN_DELIVERED')}</Th>
            </Thead>
            <Tbody>
              {pipe(
                orders,
                A.filter(({ delivered, quantity }) => quantity > 0 || getOrZero(delivered) > 0),
                A.sort(
                  pipe(
                    N.Ord,
                    contramap(({ product_code }: TGroupedOrder) => product_code),
                  ),
                ),
                A.mapWithIndex((id, { product_code, ean13, description, store_name, quantity, delivered }) => (
                  <Tr key={`${product_code}_${quantity}_${delivered}_${id}`}>
                    <Td>{id + 1}</Td>
                    <Td>{ean13}</Td>
                    <Td>{product_code}</Td>
                    <Td>{description}</Td>
                    <Td>{store_name}</Td>
                    <Td>{quantity}</Td>
                    <Td>{getOrZero(delivered)}</Td>
                  </Tr>
                )),
              )}
            </Tbody>
          </Table>
        </Stack>
      </Collapse>
    </Box>
  );
};
