import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Slot } from 'nano-slots';

import { CSS_VARIABLES } from 'constants/css';
import { TITLE } from 'constants/slots';

interface Props extends React.ComponentPropsWithoutRef<typeof Flex> {
  variant: 'mobile' | 'desktop';
  subHeader?: string;
}

export const TopbarHeadingContainer: React.VFC<Props> = ({ variant, subHeader = '', ...rest }) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      fontSize={variant === 'desktop' ? '6xl' : '2xl'}
      overflow="hidden"
      {...rest}
    >
      <Flex>
        {variant === 'desktop' && (
          <Text
            as="span"
            color={`var(${CSS_VARIABLES.ACCENT_COLOR})`}
            fontFamily="cursive"
            lineHeight="none"
            fontSize="0.3em"
          >
            {subHeader ?? ''}
          </Text>
        )}
      </Flex>
      <Text
        as="h1"
        fontFamily="cursive"
        lineHeight="none"
        fontSize={variant === 'desktop' ? '0.5em' : '1em'}
        isTruncated={true}
      >
        <Slot name={TITLE} />
      </Text>
    </Flex>
  );
};
