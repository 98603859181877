import { Text } from '@chakra-ui/react';
import React from 'react';

export const RowTitle: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Text fontWeight="bold" p="2" borderTop="1px" borderColor="gray.200">
      {label}
    </Text>
  );
};
