import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { getProductList } from 'domain/products';
import { FamilyCodeValidator } from 'domain/core';
import { TProductFamily } from 'domain/product-family';

export const getProductListWithParams = (params: Parameters<typeof getProductList>[0]) => {
  const promise = getProductList(params);
  const transformedPromise = promise.then((data) => ({
    data,
    params,
  }));

  if ('cancel' in promise) {
    // @ts-ignore
    transformedPromise.cancel = promise.cancel;
  }

  return transformedPromise;
};

export const parseFamilyCodeValue = (value?: string) =>
  pipe(
    Number.parseInt(value ?? '', 10),
    FamilyCodeValidator.decode,
    O.fromEither,
    O.fold(() => undefined, identity),
  );

export const itemNameFormatter = (item: TProductFamily) => item.name;
export const itemIdFormatter = (item: TProductFamily) => item.id;
