import { Center, HStack, Input } from '@chakra-ui/react';
import { PageManagement } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { LeftArrow, RightArrow } from './components';
import { useTranslation } from 'react-i18next';

type PaginationComponentProps = {
  current: number;
  max: number;
} & PageManagement;

export const Pagination: FC<PaginationComponentProps> = ({ current, next, max, previous, set }) => {
  const { t } = useTranslation('whisperme');
  const [value, setValue] = useState('1');

  useEffect(() => {
    setValue(current.toString());
  }, [current]);

  return (
    <Center marginY="1rem">
      <HStack>
        {current > 1 && <LeftArrow onClick={() => previous()} aria-label={t('COMPONENTS.PREVIOUS_ARIA_LABEL')} />}
        <Input
          w="3rem"
          value={value}
          type="number"
          onChange={({ target }) => setValue(target.value)}
          onKeyDown={(event) => {
            if (event.key !== 'Enter') return;

            const newCurrent = parseInt(value || '1', 10);

            if (newCurrent > max) return set(max);

            if (newCurrent < 1) return set(1);

            set(newCurrent);
          }}
        />{' '}
        <span>/ {max}</span>
        {current < max && <RightArrow aria-label={t('COMPONENTS.NEXT_ARIA_LABEL')} onClick={() => next()} />}
      </HStack>
    </Center>
  );
};
