import { useQuery } from 'react-query';
import { createGoodToHaveKey } from 'constants/queryCacheKeys';
import { getGoodToHaveForStores, TGetGoodToHaveForStores } from 'domain/products';
import { TStoreId } from 'domain/stores';
import { TSectionCode } from 'domain/core';

export const useGoodToHave = (stores: TStoreId[], root_family_code?: TSectionCode) => {
  const params: TGetGoodToHaveForStores = {
    store_id: stores,
    section_id: root_family_code,
  };

  return useQuery(createGoodToHaveKey(params), () => getGoodToHaveForStores(params));
};
