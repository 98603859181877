import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Stack, FormControl, FormLabel, Input, FormErrorMessage, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { flow, pipe } from 'fp-ts/function';
import { TRoleDetails } from 'domain/roles';
import { useProductFamiliesMaps, useStoresData } from 'hooks/data';
import { formDataToRoleDetails, roleDetailsToFormData, StoreRolesFormData } from './utils';
import { byName } from 'utils/sort';
import { StoreRolesFormCategory } from './StoreRolesFormCategory';

interface Props {
  onSubmit: (roleDetails: TRoleDetails) => void;
  initialValue?: TRoleDetails;
  cancelButtonLink: string;
}

export const StoreRolesForm: React.VFC<Props> = ({ onSubmit, initialValue, cancelButtonLink }) => {
  const { t } = useTranslation('whisperme');

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<StoreRolesFormData>({
    defaultValues: initialValue ? roleDetailsToFormData(initialValue) : undefined,
  });
  const stores = useStoresData();
  const sortedStores = useMemo(() => pipe(stores, A.sortBy([byName])), [stores]);
  const { rootFamilies } = useProductFamiliesMaps();

  const filteredRootFamilies = pipe(
    rootFamilies,
    A.filter((section) =>
      pipe(
        section.children_ids,
        A.findFirst((id) => id !== -1),
        O.isSome,
      ),
    ),
  );

  return (
    <Stack as="form" direction="column" onSubmit={handleSubmit(flow(formDataToRoleDetails, onSubmit))} spacing="3">
      <FormControl id="role-name" isInvalid={Boolean(errors.name)} isRequired={true}>
        <FormLabel>{t('SETTINGS.ROLES.ROLE_FORM.ROLE_NAME_LABEL')}</FormLabel>
        <Input {...register('name', { required: t('SETTINGS.ROLES.ROLE_FORM.EMPTY_ROLE_NAME_ERROR_TEXT') })} />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <Text as="span" fontWeight="bold">
        {t('SETTINGS.ROLES.STORE_ROLES_LABEL')}
      </Text>
      <StoreRolesFormCategory category={sortedStores} categoryName={'store_privileges'} control={control} />
      <Text as="span" fontWeight="bold">
        {t('SETTINGS.ROLES.SECTION_ROLES_LABEL')}
      </Text>
      <StoreRolesFormCategory category={filteredRootFamilies} categoryName={'section_privileges'} control={control} />
      <Stack direction="row" justifyContent="flex-end" pt="2">
        <Button as={Link} to={cancelButtonLink} variant="outline">
          {t('SETTINGS.ROLES.ROLE_FORM.CANCEL_BUTTON_LABEL')}
        </Button>
        <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
          {t('SETTINGS.ROLES.ROLE_FORM.SAVE_BUTTON_LABEL')}
        </Button>
      </Stack>
    </Stack>
  );
};
