import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useFieldValidators = () => {
  const { t } = useTranslation('whisperme');

  return useMemo(
    () => ({
      ean: {
        validate: (v: unknown) => {
          if (typeof v !== 'string' || v === '') {
            return t('VALIDATION.FIELD_REQUIRED');
          }

          if (!/^[0-9]+$/.test(v)) {
            return t('VALIDATION.INVALID_VALUE');
          }
        },
      },
      productName: {
        validate: (v: unknown) => (typeof v !== 'string' || v === '' ? t('VALIDATION.FIELD_REQUIRED') : undefined),
      },
      familyCode: {
        validate: (v: unknown) => (typeof v !== 'string' || v === '' ? t('VALIDATION.FIELD_REQUIRED') : undefined),
      },
    }),
    [t],
  );
};
