import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as PATHS from 'constants/paths';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { useUserStoreIds } from 'hooks';
import { FreeTaskSales } from './FreeTaskSales';
import { ProductAnalyticsListPage } from 'pages/AnalyticsPage/ProductAnalyticsPage/ProductAnalyticsListPage';
import { FreeTaskShortageComparison } from './FreeTaskShortageComparison';
import { FreeTaskGoodToHave } from './FreeTaskGoodToHave';
import { FreeTaskNewProducts } from './FreeTaskNewProducts';
import { MatchUserAccess } from 'components/Permissions';

export const FreeTasksPage: React.FC = () => {
  const [userID] = useUserStoreIds();
  return (
    <Flex direction="column" gap={GRID_GAP_DEFAULT_VALUE}>
      <Switch>
        <Route path={PATHS.FREE_TASKS.LIST.PATH} exact={true}>
          <ProductAnalyticsListPage />
        </Route>
        <Route path={PATHS.FREE_TASKS.ROOT.PATH}>
          <MatchUserAccess sectionManager={<FreeTaskNewProducts />} />
          <FreeTaskShortageComparison storeId={userID} />
          <FreeTaskSales storeId={userID} variant={'low'} />
          <FreeTaskSales storeId={userID} variant={'top'} />
          <MatchUserAccess sectionManager={<FreeTaskGoodToHave storeId={userID} />} />
        </Route>
        <Redirect to={PATHS.FREE_TASKS.ROOT.toPath()} />
      </Switch>
    </Flex>
  );
};
