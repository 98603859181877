import { Button, Grid, GridProps, Text } from '@chakra-ui/react';
import { TNeoEmailProduct } from 'domain/neoemail';
import React, { FC } from 'react';

type InnerProps = {
  product: TNeoEmailProduct;
  onClick: (id: TNeoEmailProduct) => void;
  variant?: 'select' | 'remove';
  buttonLabel?: string;
};

type ProductSelectElementProps = InnerProps & Omit<GridProps, keyof InnerProps>;

export const ProductSelectElement: FC<ProductSelectElementProps> = ({
  variant = 'select',
  product,
  onClick,
  buttonLabel = 'Select product',
  ...rest
}) => {
  return (
    <Grid templateColumns="115px 200px 150px" gap={1} {...rest}>
      <Text fontSize="sm" align="left">
        {product.product_code}
      </Text>
      <Text fontSize="sm" align="left">
        {product.description}
      </Text>
      <Button
        size="sm"
        colorScheme={variant === 'select' ? 'green' : 'red'}
        onClick={() => {
          onClick(product);
        }}
      >
        {buttonLabel}
      </Button>
    </Grid>
  );
};
