import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { mergePlotMetrics, plotToChartData } from 'components/Charts';
import { Metric, TDataResolution, TDateOptions } from 'domain/core';
import { StatisticsPlot, STATISTIC_TYPE } from 'domain/statistics';
import { TStoreId } from 'domain/stores';
import { dateToYMD } from 'utils/date';

const filterStoreMetrics = (storeIds: TStoreId[]) => (metrics: Metric[]) =>
  metrics.filter((m) => storeIds.includes(m.name as TStoreId));

interface PrepareImportStatusChartDataArgs {
  data?: StatisticsPlot[];
  dataResolution: TDataResolution;
  dateOptions: TDateOptions;
  storeIds: TStoreId[];
  labels: {
    processed: string;
    failed: string;
  };
}

export const prepareImportStatusChartData = ({
  data,
  dataResolution,
  dateOptions,
  storeIds,
  labels,
}: PrepareImportStatusChartDataArgs) => {
  const filteredPlots = pipe(
    data,
    O.fromNullable,
    O.map(A.map((p) => ({ ...p, metrics: filterStoreMetrics(storeIds)(p.metrics) }) as typeof p)),
  );

  const chartData = pipe(
    filteredPlots,
    O.chain((plots) => {
      const failureMetric: Metric = pipe(
        plots,
        A.findFirst((s) => s.id === STATISTIC_TYPE.FAILED_RECEIPTS),
        O.map(mergePlotMetrics({ mergedMetricName: labels.failed })),
        O.chain((p) =>
          pipe(
            p.metrics,
            A.findFirst((m) => m.name === labels.failed),
          ),
        ),
        O.fold(
          () => ({
            name: labels.failed,
            series: [],
          }),
          identity,
        ),
      );

      return pipe(
        plots,
        A.findFirst((s) => s.id === STATISTIC_TYPE.PROCESSED_RECEIPTS),
        O.map(mergePlotMetrics({ mergedMetricName: labels.processed })),
        O.map((p) => ({ ...p, metrics: [...p.metrics.filter((m) => m.name === labels.processed), failureMetric] })),
      );
    }),
    O.map(plotToChartData({ resolution: dataResolution, dateOptions })),
    O.toNullable,
  );

  const lowerBoundData = pipe(
    filteredPlots,
    O.chain(A.findFirst((p) => p.id === STATISTIC_TYPE.PROCESSED_RECEIPTS_LOWER_BOUND)),
    O.map(mergePlotMetrics({ mergedMetricName: '', mergeStrategy: 'sum' })),
    O.map(plotToChartData({ resolution: dataResolution, dateOptions })),
    O.fold(
      () => [],
      (p) => p.data,
    ),
  );

  const lowerBoundChartData = pipe(
    chartData,
    O.fromNullable,
    O.map((cd) => cd.data),
    O.map(
      A.mapWithIndex((idx, entry) => ({
        x: entry.x instanceof Date ? dateToYMD(entry.x) : entry.x,
        y: pipe(
          lowerBoundData,
          A.lookup(idx),
          O.map((v) => v.y),
          O.chain(A.lookup(0)),
          O.chain((v) => (typeof v === 'number' ? O.some(v) : O.none)),
          O.fold(
            () => null,
            (lowerBoundValue) => {
              const processedValue = pipe(
                entry.y,
                A.lookup(0),
                O.chain((v) => (typeof v === 'number' ? O.some(v) : O.none)),
                O.getOrElse(() => 0),
              );

              // NOTE(m.kania): if processed < 'lower bound' then use y value 'above the bar' so that icon can fit
              if (processedValue < lowerBoundValue) {
                const failedValue = pipe(
                  entry.y,
                  A.lookup(1),
                  O.chain((v) => (typeof v === 'number' ? O.some(v) : O.none)),
                  O.getOrElse(() => 0),
                );

                return processedValue + failedValue;
              }

              // otherwise return null (no data point on chart)
              return null;
            },
          ),
        ),
      })),
    ),
    O.fold(() => [], identity),
  );

  return {
    chartData,
    lowerBoundData,
    lowerBoundChartData,
  };
};
