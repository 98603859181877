import { createRecipe, TRecipe } from 'domain/neoshopping';
import { useToast } from '@chakra-ui/react';
import { useReducer, useCallback } from 'react';

type RecipeState = {
  human_input: string;
  isFetching?: boolean;
  recipe?: TRecipe['recipe'];
  products?: TRecipe['products'];
};

const SET_HUMAN_INPUT = 'SET_HUMAN_INPUT';

export const setHumanInput = (human_input: string) => ({
  type: SET_HUMAN_INPUT,
  payload: human_input,
});

const matchSetHumanInput = (action: unknown): action is ReturnType<typeof setHumanInput> => {
  return (action as { type: string }).type === SET_HUMAN_INPUT;
};

const SET_RECEIPT_AND_PRODUCTS = 'SET_RECEIPT_AND_PRODUCTS';

export const setReceiptAndProducts = (receipt: TRecipe['recipe'], products: TRecipe['products']) => ({
  type: SET_RECEIPT_AND_PRODUCTS,
  payload: { receipt, products },
});

const matchSetReceiptAndProducts = (action: unknown): action is ReturnType<typeof setReceiptAndProducts> => {
  return (action as { type: string }).type === SET_RECEIPT_AND_PRODUCTS;
};

const SET_IS_FETCHING = 'SET_IS_FETCHING';

const setIsFetching = (isFetching: boolean) => ({
  type: SET_IS_FETCHING,
  payload: isFetching,
});

const matchSetIsFetching = (action: unknown): action is ReturnType<typeof setIsFetching> => {
  return (action as { type: string }).type === SET_IS_FETCHING;
};

const recipeReducer = (state: RecipeState, action: unknown): RecipeState => {
  if (matchSetHumanInput(action)) {
    return { ...state, human_input: action.payload };
  }

  if (matchSetReceiptAndProducts(action)) {
    return { ...state, recipe: action.payload.receipt, products: action.payload.products };
  }

  if (matchSetIsFetching(action)) {
    return { ...state, isFetching: action.payload };
  }

  return state;
};

const initialState: RecipeState = {
  human_input: '',
  isFetching: false,
};

export const useRecipeReducer = () => {
  const [state, dispatch] = useReducer(recipeReducer, initialState);
  const toast = useToast();

  const sendRequest = useCallback(() => {
    dispatch(setIsFetching(true));
    createRecipe({ human_input: state.human_input })
      .then((response) => {
        dispatch(setReceiptAndProducts(response.recipe, response.products));
      })
      .catch((error) => {
        toast({
          title: 'Something went wrong',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        });
      })
      .finally(() => {
        dispatch(setIsFetching(false));
      });
  }, [state.human_input, toast]);

  return {
    state,
    dispatch,
    sendRequest,
  };
};
