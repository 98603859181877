import React from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';

import { defaultFallbackRender } from './DefaultFallbackRender';

interface Props {
  fallbackRender?: (props: FallbackProps) => JSX.Element | null;
}

export const ErrorBoundary: React.FC<Props> = ({ children, fallbackRender }) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ReactErrorBoundary onReset={reset} fallbackRender={fallbackRender ?? defaultFallbackRender}>
        {children}
      </ReactErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);
