import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfISOWeek,
  format,
  getISOWeek,
  isSunday,
  isWithinInterval,
  parseISO,
  startOfISOWeek,
} from 'date-fns';

import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { createAnomalyCalendarKey } from 'constants/queryCacheKeys';
import { TProductCode } from 'domain/core';
import { getAnomalyCalendar, TGetAnomalyCalendarParams } from 'domain/objectives';
import { TStoreId } from 'domain/stores';
import { useAppConfig } from 'hooks';
import { PROBABILITY_THRESHOLD } from '../components';
import { dateToYMD } from 'utils/date';
import { noop } from 'utils/fn';
import { CELL_DATA_VARIANT, DataMapEntry } from './types';
import { anomalyToTimeOfDay } from './utils';
import { InfoBox } from 'components/InfoBox';

const emptyData: never[] = [];

const monday = parseISO('2022-02-14T12:00:00Z');

const WEEK_COL_SPAN = 3;

const booleanToNumber = (v: boolean) => (v ? 1 : 0);

const initialMapValue: DataMapEntry = {
  morning: CELL_DATA_VARIANT.OK,
  afternoon: CELL_DATA_VARIANT.OK,
  evening: CELL_DATA_VARIANT.OK,
};

interface Props {
  children?: never;
  storeId: TStoreId;
  productCode: TProductCode;
  startDate: Date;
  endDate: Date;
}

export const InventoryShortageMostAffectedProductCalendar: React.FC<Props> = ({
  storeId,
  productCode,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation('whisperme');

  const {
    viewport,
    // formatters: { currencyFormatter, numberFormatter },
    dateOptions,
  } = useAppConfig();

  const { params, weeks, first, end } = useMemo(() => {
    const first = pipe(startDate, startOfISOWeek);
    const end = pipe(endDate, endOfISOWeek);

    const params: TGetAnomalyCalendarParams = {
      store_id: storeId,
      probability_threshold: PROBABILITY_THRESHOLD,
      first,
      end,
      product_code: productCode,
    };

    return {
      params,
      weeks: eachWeekOfInterval({ start: first, end }, dateOptions),
      first,
      end,
    };
  }, [dateOptions, endDate, productCode, startDate, storeId]);

  const { data = emptyData, status } = useQuery(createAnomalyCalendarKey(params), () => getAnomalyCalendar(params));

  const prepareHints = useCallback(
    (dataInRange: typeof data) => {
      // https://neoinstinct.atlassian.net/wiki/spaces/NEOSUITE/pages/1105559553/
      const { mornings, afternoons, evenings, sundays } = dataInRange.reduce(
        ({ mornings, afternoons, evenings, sundays }, anomaly) => {
          const timeOfDay = pipe(anomalyToTimeOfDay(anomaly), O.toNullable);

          return {
            mornings: mornings + booleanToNumber(timeOfDay === 'morning'),
            afternoons: afternoons + booleanToNumber(timeOfDay === 'afternoon'),
            evenings: evenings + booleanToNumber(timeOfDay === 'evening'),
            //
            sundays: sundays + booleanToNumber(isSunday(anomaly.date)),
          };
        },
        {
          mornings: 0,
          afternoons: 0,
          evenings: 0,
          //
          sundays: 0,
        },
      );
      const weeksInScope = weeks.length;

      if (
        afternoons >= 2 * mornings &&
        evenings >= 2 * mornings &&
        afternoons >= 2 * weeksInScope &&
        evenings >= 2 * weeksInScope
      ) {
        // afternoon & evening
        return [
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_TEMPLATE', {
            time_of_a_day: t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_AFTERNOON_AND_EVENING'),
          }),
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.AFTERNOON_AND_EVENING_ADVICE'),
        ];
      }

      if (mornings >= afternoons + evenings && mornings >= 2 * weeksInScope) {
        // morning
        return [
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_TEMPLATE', {
            time_of_a_day: t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_MORNING'),
          }),
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.MORNING_ADVICE'),
        ];
      }

      if (afternoons >= mornings + evenings && afternoons >= 2 * weeksInScope) {
        // afternoon
        return [
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_TEMPLATE', {
            time_of_a_day: t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_AFTERNOON'),
          }),
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.AFTERNOON_ADVICE'),
        ];
      }

      if (evenings >= mornings + afternoons && evenings >= 2 * weeksInScope) {
        // evening
        return [
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_TEMPLATE', {
            time_of_a_day: t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.TIME_OF_DAY_EVENING'),
          }),
          t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.EVENING_ADVICE'),
        ];
      }

      if (sundays * 2 >= weeksInScope) {
        return [t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.ADVICE.SUNDAY_ADVICE')];
      }

      return [];
    },
    [t, weeks.length],
  );
  const { dataMap, hints } = useMemo(() => {
    const isDateInRange = (d: Date) => isWithinInterval(d, { start: startDate, end: endDate });

    // TODO(m.kania): handle 'empty' slots (eg. store closed?)
    const dataMap: Map<string, DataMapEntry> = new Map(
      eachDayOfInterval({ start: first, end }).map((d) => {
        let value = { ...initialMapValue };

        if (!isDateInRange(d)) {
          value = {
            morning: CELL_DATA_VARIANT.EMPTY,
            afternoon: CELL_DATA_VARIANT.EMPTY,
            evening: CELL_DATA_VARIANT.EMPTY,
          };
        } else if (isSunday(d)) {
          // NOTE(m.kania): stores are running only till noon on Sundays (hardcoded as requested by Marek)
          value = {
            morning: CELL_DATA_VARIANT.OK,
            afternoon: CELL_DATA_VARIANT.EMPTY,
            evening: CELL_DATA_VARIANT.EMPTY,
          };
        }

        return [dateToYMD(d), value];
      }),
    );

    const dataInRange = data.filter((d) => isDateInRange(d.date));

    dataInRange.forEach((d) => {
      pipe(
        anomalyToTimeOfDay(d),
        O.fold(noop, (timeOfDay) => {
          const ymd = dateToYMD(d.date);
          dataMap.set(ymd, { ...(dataMap.get(ymd) ?? initialMapValue), [timeOfDay]: CELL_DATA_VARIANT.ERROR });
        }),
      );
    });

    return { dataMap, hints: prepareHints(dataInRange) };
  }, [data, end, endDate, first, prepareHints, startDate]);

  // NOTE(m.kania): initial fetch
  if (data.length === 0 && status === 'loading') {
    return <Skeleton width="100%" height="100px" />;
  }

  const table = (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',

        'th, td': {
          fontSize: 'xs',
          px: '2',
          py: '1',
        },

        td: {
          borderRight: '1px',
          borderLeft: '1px',
          borderColor: 'gray.100',

          '&[data-cell-variant="ok"]': {
            bg: 'green.300',
          },

          '&[data-cell-variant="error"]': {
            bg: 'red.300',
          },

          '&[data-cell-variant="empty"]': {
            bg: 'gray.300',
          },
        },
      }}
    >
      <Thead>
        <Tr>
          <Th rowSpan={2} colSpan={WEEK_COL_SPAN}>
            {t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.WEEK_LABEL')}
          </Th>

          {Array.from({ length: 7 }).map((_v, idx) => (
            <Th key={idx} colSpan={3} textAlign="center">
              {format(addDays(monday, idx), 'EEE', dateOptions)}
            </Th>
          ))}
        </Tr>
        <Tr>
          {Array.from({ length: 7 }).map((_v, idx) => (
            <React.Fragment key={idx}>
              <Th textAlign="center">{t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.MORNING_LABEL')}</Th>
              <Th textAlign="center">{t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.AFTERNOON_LABEL')}</Th>
              <Th textAlign="center">{t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.EVENING_LABEL')}</Th>
            </React.Fragment>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {weeks.map((weekStartDate) => {
          const isoWeek = getISOWeek(weekStartDate);
          const weekEndDate = endOfISOWeek(weekStartDate);

          return (
            <Tr key={weekStartDate.toISOString()}>
              <Td colSpan={WEEK_COL_SPAN} whiteSpace="nowrap">
                {`${t('OBJECTIVES.SHORTAGE_LEVEL.SHORTAGE_CALENDAR.WEEK_TEMPLATE_LABEL', { value: isoWeek })} (${format(
                  weekStartDate,
                  'dd.MM',
                  dateOptions,
                )} - ${format(weekEndDate, 'dd.MM', dateOptions)})`}
              </Td>
              {Array.from({ length: 7 }).map((_v, idx) => {
                const mapKey = pipe(addDays(weekStartDate, idx), dateToYMD);
                const mapEntry = dataMap.get(mapKey) ?? initialMapValue;

                return (
                  <React.Fragment key={idx}>
                    <Td textAlign="center" data-cell-variant={mapEntry.morning} />
                    <Td textAlign="center" data-cell-variant={mapEntry.afternoon} />
                    <Td textAlign="center" data-cell-variant={mapEntry.evening} />
                  </React.Fragment>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );

  return (
    <Grid
      width="100%"
      gap={GRID_GAP_DEFAULT_VALUE}
      gridTemplateColumns={{
        base: '100%',
        [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
      }}
    >
      <GridItem
        colSpan={{
          [DESKTOP_BREAKPOINT]: hints.length === 0 ? 3 : 2,
        }}
      >
        <Stack position="relative">{viewport === 'mobile' ? <TableContainer>{table}</TableContainer> : table}</Stack>
      </GridItem>
      {hints.length > 0 && (
        <GridItem>
          <InfoBox bg="red.100">
            {hints.map((h) => (
              <Text as="span">{h}</Text>
            ))}
          </InfoBox>
        </GridItem>
      )}
    </Grid>
  );
};
