import React from 'react';
import { Group } from '@visx/group';
import { getStringWidth, Text } from '@visx/text';

import { TreemapNodeType } from './types';

export interface Props extends React.ComponentPropsWithoutRef<typeof Group> {
  node: TreemapNodeType;
  nodePercentage: string;
  margin: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
}

const FamiliesTreeMapNodeComponent: React.FC<Props> = ({ node, nodePercentage, margin, ...rest }) => {
  const nodeName = node.data.data.id;
  const nodeText = [nodeName, `(${nodePercentage})`].join(' ');
  const top = node.y0 + margin.top;
  const left = node.x0 + margin.left;
  const nodeWidth = node.x1 - node.x0;
  const nodeHeight = node.y1 - node.y0;
  const textWidth = nodeWidth - 10;

  const nodeTextWidth = getStringWidth(nodeText, { width: textWidth });
  let shouldScaleText = false;
  if (typeof nodeTextWidth === 'number' && nodeTextWidth > textWidth) {
    // NOTE(m.kania): if there is a chance that not everything fits,
    // check if all words fit on their own, if not then scale everything
    shouldScaleText = nodeText.split(/\s+/gim).some((s) => {
      const wordWidth = getStringWidth(s);

      return typeof wordWidth === 'number' ? wordWidth > textWidth : false;
    });
  }

  return (
    <Group
      key={nodeName}
      top={top}
      left={left}
      {...rest}
      data-treemap-clickable={typeof rest.onClick === 'function' ? 'true' : undefined}
    >
      <rect data-treemap-rect width={nodeWidth} height={nodeHeight} fill="currentColor" />
      <Text
        x={nodeWidth / 2}
        y={nodeHeight / 2}
        width={textWidth}
        scaleToFit={shouldScaleText}
        fill="#fff"
        textAnchor="middle"
        verticalAnchor="middle"
        pointerEvents="none"
        fontWeight="bold"
      >
        {nodeText}
      </Text>
    </Group>
  );
};

export const FamiliesTreeMapNode = React.memo(FamiliesTreeMapNodeComponent);
