import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { useTranslation } from 'react-i18next';

import { getAllProductFamilies, TProductFamily } from 'domain/product-family';
import { ONE_DAY } from 'constants/time';
import { UNKNOWN_SECTION_NAME } from 'constants/api';
import { createProductFamiliesKey } from 'constants/queryCacheKeys';

type DataType = TProductFamily[];
type ErrorType = Error;

export const useProductFamilies = <Data = DataType>(
  options: UseQueryOptions<DataType, ErrorType, Data, string[]> = {},
) => {
  const { t } = useTranslation('whisperme');
  const { select, ...rest } = options;
  const getMappedProductFamilies = useMemo(
    () => async () => {
      const data = await getAllProductFamilies();

      return pipe(
        data,
        A.map((pf) =>
          pf.id === -1 || pf.name === UNKNOWN_SECTION_NAME
            ? { ...pf, name: t('COMPONENTS.PRODUCT_SECTIONS.UNKNOWN_SECTION_LABEL') }
            : pf,
        ),
      );
    },
    [t],
  );

  return useQuery(createProductFamiliesKey(), getMappedProductFamilies, {
    suspense: true,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
    select: select || undefined,
    ...rest,
  });
};
