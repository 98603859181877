import { Box, LayoutProps, Skeleton } from '@chakra-ui/react';
import { Card, CardTitle } from 'components/Card';
import React from 'react';

interface Props {
  children?: never;
  chartMinHeight?: LayoutProps['minHeight'];
  chartMinWidth?: LayoutProps['minWidth'];
}

export const CardChartSkeleton: React.FC<Props> = ({ chartMinHeight = '100px', chartMinWidth = '200px' }) => (
  <Card>
    <CardTitle>
      <Skeleton width="66%" height="1.5em" />
    </CardTitle>
    <Box flex="1 0 auto" minWidth={chartMinWidth} minHeight={chartMinHeight}>
      <Skeleton width="100%" height={chartMinHeight} />
    </Box>
  </Card>
);
