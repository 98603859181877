import React from 'react';

import { I18nAppLanguage } from 'locales';
import { AppViewport, APP_VIEWPORT } from 'hooks/useViewport';
import { noop } from 'utils/fn';
import { DEFAULT_DATE_OPTIONS, TDateOptions } from 'domain/core';
import { TCharacteristicVariant } from 'domain/map';

interface AppConfig {
  language: I18nAppLanguage;
  dateOptions: TDateOptions;
  setLanguage: (newLanguage: I18nAppLanguage) => void;
  currencyCode: 'EUR';

  // visuals
  viewport: AppViewport;
  canAnimate: boolean;

  // formatting
  formatters: {
    currencyFormatter: Intl.NumberFormat;
    currencyExactFormatter: Intl.NumberFormat;
    currencyFormatterOptions: Intl.NumberFormatOptions;
    numberFormatter: Intl.NumberFormat;
    numberRoundingFormatter: Intl.NumberFormat;
    numberFormatterOptions: Intl.NumberFormatOptions;
    percentageFormatter: Intl.NumberFormat;
    percentageRoundingFormatter: Intl.NumberFormat;
    percentageFormatterOptions: Intl.NumberFormatOptions;
    createFormatter: (options?: Intl.NumberFormatOptions) => Intl.NumberFormat;
    characteristicFormatter: (
      characteristic: TCharacteristicVariant,
    ) => (value: number) => { label: string; formattedValue: string };
  };
}

// NOTE(m.kania): consider https://github.com/dai-shi/use-context-selector for better perf
export const AppConfigContext = React.createContext<AppConfig>({
  language: 'en-GB',
  dateOptions: DEFAULT_DATE_OPTIONS,
  setLanguage: noop,
  currencyCode: 'EUR',

  // visuals
  viewport: APP_VIEWPORT.MOBILE,
  canAnimate: false,

  // formatting
  formatters: {
    currencyFormatterOptions: { style: 'currency', currency: 'EUR' },
    currencyFormatter: new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    currencyExactFormatter: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' }),

    numberFormatter: new Intl.NumberFormat('en-GB', { style: 'decimal' }),
    numberRoundingFormatter: new Intl.NumberFormat('en-GB', {
      style: 'decimal',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
    numberFormatterOptions: { style: 'decimal' },

    percentageFormatter: new Intl.NumberFormat('en-GB', { style: 'percent' }),
    percentageRoundingFormatter: new Intl.NumberFormat('en-GB', {
      style: 'percent',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
    percentageFormatterOptions: { style: 'percent', minimumFractionDigits: 2 },

    createFormatter: (options) => new Intl.NumberFormat('en-GB', options),
    characteristicFormatter: (characteristic) => (value) => ({
      label: String(characteristic),
      formattedValue: String(value),
    }),
  },
});
