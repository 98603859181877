import { useQuery, UseQueryOptions } from 'react-query';

import { createReceiptErrorListKey } from 'constants/queryCacheKeys';
import { getReceiptErrorList, GetReceiptErrorListParams } from 'domain/statistics';

type DataType = Unpromisify<ReturnType<typeof getReceiptErrorList>>;
type ErrorType = Error;

export const useReceiptErrorList = <Data = DataType>(
  params: GetReceiptErrorListParams,
  options?: UseQueryOptions<DataType, ErrorType, Data>,
) => useQuery<DataType, ErrorType, Data>(createReceiptErrorListKey(params), () => getReceiptErrorList(params), options);

export const useValidatedReceiptErrorList = (params: GetReceiptErrorListParams) =>
  useQuery(createReceiptErrorListKey(params), () => getReceiptErrorList(params));
