import * as Eq from 'fp-ts/Eq';

import { TGetProductListParams } from './service';

export const GetProductListParamsEq = Eq.fromEquals<Partial<TGetProductListParams>>(
  (prev, next) =>
    prev.product_code_prefix === next.product_code_prefix &&
    prev.product_description_query === next.product_description_query &&
    prev.family_code === next.family_code &&
    prev.root_family_code === next.root_family_code &&
    prev.is_regional === next.is_regional &&
    prev.start_date === next.start_date &&
    prev.end_date === next.end_date,
);
