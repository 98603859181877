import { GridItem, Flex, Box, Input, Grid } from '@chakra-ui/react';
import { flow, pipe } from 'fp-ts/function';
import { useStocksReducer } from 'hooks/useStocksReducer';
import React, { useMemo, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { LevelInput } from './LevelInput';
import * as O from 'fp-ts/Option';
import { getOrZero } from '../utils';
import { StockHeader } from 'components/StockHeader';
import { useUserStoreIds } from 'hooks';
import { StockInfoTabs } from 'pages/MorningTasksPage/components/StockInfo';

type StockRowProps = {
  productCode: string;
};

const ROW_STYLES = {
  flexDirection: 'column',
  minW: '85px',
  justifyContent: 'center',
  gap: '1rem',
  display: 'flex',
};

const getOrUnknown = flow(O.getOrElse(() => 'UNKNOWN'));

export const ExpandedStockRow: VFC<StockRowProps> = ({ productCode }) => {
  const { t } = useTranslation('whisperme');
  const userStores = useUserStoreIds();

  const [state] = useStocksReducer();

  const { description, ifls, shelf, order_description, ean13, available, insights, expectedToday, packageSize } =
    useMemo(() => {
      const {
        description,
        ifls,
        shelf,
        order_description,
        ean13,
        start_of_day,
        wasted,
        stolen,
        delivered,
        always_available,
        package_size,
        insights,
      } = state[productCode];

      return {
        description,
        ifls: pipe(ifls, getOrUnknown),
        shelf: pipe(shelf, getOrUnknown),
        order_description: pipe(order_description, getOrUnknown),
        ean13: pipe(ean13, getOrZero, (val) => (!val ? 'UNKNOWN' : val)),
        morningLevel: getOrZero(start_of_day),
        wasted: getOrZero(wasted),
        stolen: getOrZero(stolen),
        expectedToday: getOrZero(delivered),
        packageSize: pipe(package_size, getOrZero, (val) => (!val ? 'UNKNOWN' : val)),
        available: pipe(
          always_available,
          O.getOrElse(() => false),
        ),
        insights: insights,
      };
    }, [state, productCode]);

  return (
    <GridItem
      bg="white"
      borderRadius="base"
      border={available ? '2px' : '1px'}
      borderColor={available ? 'green.100' : 'gray.200'}
      boxShadow="base"
      p="3"
    >
      <Flex direction="column" gap="2rem" width="100%">
        <StockHeader
          description={description}
          orderDescription={order_description}
          shelf={shelf}
          packageSize={packageSize}
          productCode={productCode}
          ean13={ean13}
          ifls={ifls}
        />
        <Grid
          templateColumns="repeat(2, 1fr)"
          maxW={{ xl: '70rem' }}
          gap="0.5rem"
          justifySelf="center"
          alignSelf="center"
        >
          <GridItem sx={ROW_STYLES}>
            <Box>{t('MORNING_TAKS.STOCKS_TABLE.STOCK_LEVEL')} </Box>
            <LevelInput type="start_of_day" productCode={productCode} />
          </GridItem>
          <GridItem sx={ROW_STYLES}>
            <Box>{t('MORNING_TAKS.STOCKS_TABLE.DELIVERY')} </Box>
            <Input size="lg" value={expectedToday} isReadOnly textAlign="center" />
          </GridItem>
        </Grid>
        <StockInfoTabs productCode={productCode} storeId={userStores[0]} insights={insights} />
      </Flex>
    </GridItem>
  );
};
