import { Stack } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { useGroupedOrders } from 'hooks/data/useGroupedOrders';
import React from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import { OrderDay } from './components';
import { contramap } from 'fp-ts/Ord';
import { TGroupedOrdersList } from 'domain/predictions';
import { PageSpinner } from 'components/Spinners';

export const HistoryOrdersPage: React.FC = () => {
  const { data, isFetching } = useGroupedOrders();

  return (
    <Card>
      <CardTitleSection>Orders</CardTitleSection>
      {!isFetching && data ? (
        <Stack gap="1rem">
          {pipe(
            Object.entries(data),
            A.sort(
              pipe(
                S.Ord,
                contramap(([date]: [string, TGroupedOrdersList]) => date),
              ),
            ),
            A.reverse,
            A.map(([date, value]) => <OrderDay date={date} orders={value} key={date} />),
          )}
        </Stack>
      ) : (
        <PageSpinner />
      )}
    </Card>
  );
};
