import React, { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchUserAccess } from 'components/Permissions';
import { useHistory, useLocation } from 'react-router-dom';
import { findMorningTasksNavigationMatch, morningTasksMatchMapping } from './utils';
import {
  DashboardTab,
  DashboardTabList,
  DashboardTabPanel,
  DashboardTabPanels,
  DashboardTabs,
  TopbarHeading,
} from 'components/Dashboard';
import { ErrorBoundary } from 'components/ErrorMessages/ErrorBoundary';
import { PageSpinner } from 'components/Spinners';
import { CardErrorBoundary } from 'components/ErrorMessages';
import { HistoryOrdersPage } from './HistoryOrdersPage';

const StocksPage = React.lazy(() => import('./StocksPage'));
const OrdersPage = React.lazy(() => import('./OrdersPage'));

export const MorningTasksPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const navigationMatch = findMorningTasksNavigationMatch(pathname);
  const [tabIndex, setTabIndex] = useState<number>(navigationMatch);

  const handleChangeTab = useCallback(
    (index: number) => {
      const [_newMatch, newPath] = morningTasksMatchMapping[index];
      if (typeof newPath === 'string') {
        replace(newPath);
        setTabIndex(index);
      }
    },
    [replace],
  );

  return (
    <MatchUserAccess
      supermarketManager={null}
      sectionManager={
        <DashboardTabs onChange={handleChangeTab} index={tabIndex}>
          <DashboardTabList>
            <DashboardTab>{t('GENERIC.NAVIGATION.MORNING_TAKS.STOCKS')}</DashboardTab>
            <DashboardTab>{t('GENERIC.NAVIGATION.MORNING_TAKS.ORDERS')}</DashboardTab>
            <DashboardTab>{t('GENERIC.NAVIGATION.MORNING_TAKS.HISTORY_ORDERS')}</DashboardTab>
          </DashboardTabList>

          <ErrorBoundary>
            <Suspense fallback={<PageSpinner />}>
              <DashboardTabPanels>
                <DashboardTabPanel key="stocks">
                  <TopbarHeading>
                    {[t('GENERIC.NAVIGATION.MORNING_TASKS_LABEL'), t('GENERIC.NAVIGATION.MORNING_TAKS.STOCKS')]}
                  </TopbarHeading>
                  <CardErrorBoundary>
                    <StocksPage />
                  </CardErrorBoundary>
                </DashboardTabPanel>
                <DashboardTabPanel key="orders">
                  <TopbarHeading>
                    {[t('GENERIC.NAVIGATION.MORNING_TASKS_LABEL'), t('GENERIC.NAVIGATION.MORNING_TAKS.ORDERS')]}
                  </TopbarHeading>
                  <CardErrorBoundary>
                    <OrdersPage />
                  </CardErrorBoundary>
                </DashboardTabPanel>
                <DashboardTabPanel key="history-orders">
                  <TopbarHeading>
                    {[t('GENERIC.NAVIGATION.MORNING_TASKS_LABEL'), t('GENERIC.NAVIGATION.MORNING_TAKS.HISTORY_ORDERS')]}
                  </TopbarHeading>
                  <CardErrorBoundary>
                    <HistoryOrdersPage />
                  </CardErrorBoundary>
                </DashboardTabPanel>
              </DashboardTabPanels>
            </Suspense>
          </ErrorBoundary>
        </DashboardTabs>
      }
    />
  );
};
