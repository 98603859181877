import { TCustomerRisk } from 'domain/customer';

export interface TObjectiveSeries<
  KeyType = number | string,
  XValueType = Date | string,
  ExtraProperties extends Record<string, unknown> = Record<string, unknown>,
> {
  key: KeyType;
  name: string;
  values: Array<{ x: XValueType; y: number } & ExtraProperties>;
}

export enum EObjectiveMode {
  SET_OBJECTIVE,
  TRACK_OBJECTIVE,
  RETURN_TO_LIST,
}

export interface TKeyedCustomerRisk extends TCustomerRisk {
  key: number;
}

export type TCustomerRiskChartMachineEvent =
  | { type: 'CLICK_GROUP'; group: TKeyedCustomerRisk }
  | { type: 'CLICK_MAP' }
  | { type: 'DESELECT_GROUP' }
  | { type: 'SELECT_GROUP'; group: TKeyedCustomerRisk };

export interface TCustomerRiskChartMachineContext {
  selectedGroup?: TKeyedCustomerRisk;
}

export interface RetentionRateCustomerGroupsChartTooltipData {
  variant: 'group';
  groupData: TCustomerRisk;
}
