import { Center, Grid, GridItem, Select, Stack, Text } from '@chakra-ui/react';
import { BarSeries, Grid as XYChartGrid, Tooltip } from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import { BaseXYChart, BaseXYChartProps, ParentRect } from 'components/Charts';
import { format, parseISO, startOfYesterday, subDays } from 'date-fns';
import { TStoreId } from 'domain/stores';
import { pipe } from 'fp-ts/function';
import { useAppConfig } from 'hooks';
import { useStockPoints } from 'hooks/data';
import React, { useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToDDMMEEE, dateToYMD, prepareDayStartValues, toDate } from 'utils/date';
import { theme } from '../consts/chartTheme';
import { TickRendererProps } from '@visx/axis';

type StockAvailabilityProps = {
  productCode: string;
  storeId: TStoreId;
};

type StockAvailabilityEntry = {
  time: string;
  quantity: number;
};

interface StockPointDatum {
  product_code: number;
  store_id: string;
  time: string;
  quantity: number;
}

const LAST_DAYS_OPTIONS = [1, 3, 7, 14];

const addDateToHHMM = (v: string) => {
  return format(toDate(v), 'dd-MM HH:mm');
};

export const StockPointsChart: VFC<StockAvailabilityProps> = ({ productCode, storeId }) => {
  const { t } = useTranslation('whisperme');
  const lastDaysOptions = LAST_DAYS_OPTIONS;
  const {
    dateOptions: { locale },
  } = useAppConfig();
  const [lastDays, setLastDays] = useState(lastDaysOptions[2]);
  const yesterday = startOfYesterday();
  const { data, isFetching } = useStockPoints({
    product_code: productCode,
    store_id: storeId,
    start_date: dateToYMD(subDays(yesterday, lastDays - 1)),
    end_date: dateToYMD(yesterday),
  });

  const xAccessor = (d: StockAvailabilityEntry) => d.time;
  const yAccessor = (d: StockAvailabilityEntry) => d.quantity;

  if (isFetching || !data) {
    return (
      <Center w="100%" minH="2vh">
        {t('COMPONENTS.CHARTS.LOADING_LABEL')}
      </Center>
    );
  }
  if (!isFetching && !data.length) {
    return (
      <Center w="100%" minH="2vh">
        {t('COMPONENTS.CHARTS.NO_DATA_LABEL')}
      </Center>
    );
  }
  const yDomain = [0, Math.max(...data.map((d) => d.quantity))];
  const xTickValues = data && lastDays > 1 ? prepareDayStartValues(lastDays, yesterday, data) : undefined;

  const chartProps = {
    xAxisProps: {
      tickFormat: (v: string) => (lastDays === 1 ? addDateToHHMM(v) : dateToDDMMEEE(toDate(v), locale)),
      tickValues: xTickValues,
      tickLabelProps: { fill: 'gray.800' },
      tickComponent: ({ formattedValue, x, y, ...props }: TickRendererProps) => (
        <svg x="0" y="0.125em">
          <text x={x} y={y} {...props}>
            {formattedValue?.split(' ').map((item, index) => (
              <tspan x={x} y={y * index + 15}>
                {item}
              </tspan>
            ))}
          </text>
        </svg>
      ),
    },
    theme,
    yAxisProps: {
      tickFormat: (v: string) => parseInt(v, 10).toString(),
      numTicks: Math.min(yDomain[1], 4),
      tickLabelProps: { fill: 'gray.800' },
    },
    yScale: { type: 'linear', domain: yDomain },
    xScale: { type: 'band', domain: data.map(({ time }) => time) },
  } as Partial<BaseXYChartProps>;

  const gridProps = {
    rows: false,
    columns: true,
    numTicks: xTickValues?.length,
    stroke: '#000',
    strokeWidth: 2,
    lineStyle: { border: '1px solid #000' },
    // NOTE: this is a hack that was required to make Grid work for our custom tick values
    tickValues: xTickValues,
  } as unknown as React.ComponentPropsWithoutRef<typeof XYChartGrid>;

  return (
    <Center w="100%" h="340px">
      <ParentRect minHeight="340px">
        {({ width, height }) => (
          <>
            <Grid p="3" justifyContent="end">
              <GridItem width="200px">
                <Select
                  size="sm"
                  value={lastDays}
                  onChange={(ev) => {
                    const maybeValue = pipe(ev.target.value, (v) => Number.parseInt(v, 10));
                    if (lastDaysOptions.includes(maybeValue)) {
                      setLastDays(maybeValue);
                    }
                  }}
                >
                  {lastDaysOptions.map((days) => (
                    <option key={days} value={days}>
                      {t('ANALYTICS.PRODUCTS.STATS_PAGE.TOP_PRODUCTS_TABLE.TIME_SELECTOR.LAST_DAYS_LABEL', {
                        count: days,
                      })}
                    </option>
                  ))}
                </Select>
              </GridItem>
            </Grid>
            <BaseXYChart width={width} height={height - 100} showGrid={false} {...chartProps}>
              <BarSeries data={data} dataKey="quantity" key={'quantity'} xAccessor={xAccessor} yAccessor={yAccessor} />
              <XYChartGrid {...gridProps} />
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showDatumGlyph
                renderTooltip={({ tooltipData }: RenderTooltipParams<StockPointDatum>) => {
                  const datumData = tooltipData?.nearestDatum?.datum;

                  return datumData ? (
                    <Stack spacing="4" w={250}>
                      <Stack fontWeight={'normal'} lineHeight={1.2}>
                        <Text as="span">
                          <Text as="span" fontWeight="bold">
                            {t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.DATE')}:
                          </Text>{' '}
                          {dateToYMD(parseISO(datumData.time))} {format(parseISO(datumData.time), 'HH:mm')}
                        </Text>
                        <Text as="span">
                          <Text as="span" fontWeight="bold">
                            {t('MORNING_TAKS.STOCKS_TABLE.TOOLTIP.QUANTITY')}:
                          </Text>{' '}
                          {datumData.quantity}
                        </Text>
                      </Stack>
                    </Stack>
                  ) : null;
                }}
              />
            </BaseXYChart>
          </>
        )}
      </ParentRect>
    </Center>
  );
};
