import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Stack, Skeleton, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import * as PATHS from 'constants/paths';
import { TRoleDetails } from 'domain/roles';
import { createUserListKey } from 'constants/queryCacheKeys';
import { getUsersList, TUserId } from 'domain/user';
import { useRoleDetailsMap } from 'hooks/data';
import { UserDeleteConfirmationModal } from './UserDeleteConfirmationModal';
import { useUserTypeIdToName } from 'hooks';
import { toUndefined } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
const EMPTY_ITEMS = { items: [] };
const nullUser = 0;

type TUserToDelete = TUserId;

export const UserListPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { data = EMPTY_ITEMS, isLoading } = useQuery(createUserListKey(), getUsersList);
  const userTypesToName = useUserTypeIdToName();
  const roleDetailsMap = useRoleDetailsMap();
  const [userToDelete, setUserToDelete] = useState<TUserToDelete>(nullUser);
  const onModalClose = useCallback(() => {
    setUserToDelete(nullUser);
  }, []);

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            <Button
              as={Link}
              to={PATHS.SETTINGS.USERS.NEW.toPath()}
              leftIcon={<AddIcon />}
              size="sm"
              colorScheme="blue"
            >
              {t('SETTINGS.USERS.ADD_NEW_USER_LABEL')}
            </Button>
          </CardTitleSectionSettings>
        }
      >
        {t('SETTINGS.USERS.TITLE')}
      </CardTitleSection>
      <Table variant="kpi">
        <Thead>
          <Tr>
            <Th>{t('SETTINGS.USERS.DISPLAY_NAME_LABEL')}</Th>
            <Th>{t('SETTINGS.USERS.EMAIL_LABEL')}</Th>
            <Th>{t('SETTINGS.USERS.TYPE_LABEL')}</Th>
            <Th>{t('SETTINGS.USERS.ROLES_LABEL')}</Th>
            <Th>{t('SETTINGS.USERS.ACTIONS_LABEL')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && data.items.length === 0
            ? Array.from({ length: 5 }).map((_v, idx) => (
                <Tr key={idx}>
                  {Array.from({ length: 5 }).map((_v, idx) => (
                    <Td key={idx}>
                      <Skeleton minHeight="1em" />
                    </Td>
                  ))}
                </Tr>
              ))
            : data.items.map(({ user, id }) => {
                const roleNames = user.roles.reduce(
                  (acc, roleId) => {
                    const maybeRole = roleDetailsMap.get(roleId);
                    if (maybeRole) {
                      return [...acc, maybeRole.name];
                    }
                    return acc;
                  },
                  [] as Array<TRoleDetails['name']>,
                );
                return (
                  <Tr key={id}>
                    <Td>{user.display_name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{pipe(user.user_type_id, userTypesToName, toUndefined)?.name}</Td>
                    <Td>
                      {roleNames.length > 0 ? (
                        <Stack as="ul">
                          {roleNames.map((roleName, idx) => (
                            <Text key={`${idx}-${roleName}`} as="li">
                              {roleName}
                            </Text>
                          ))}
                        </Stack>
                      ) : (
                        t('SETTINGS.USERS.NO_ROLES_LABEL')
                      )}
                    </Td>
                    <Td>
                      {/* NOTE(m.kania): tables should have only static content (no buttons etc) - for better wcag/a11y */}
                      <Stack direction="row" spacing={3}>
                        <Button
                          as={Link}
                          to={PATHS.SETTINGS.USERS.DETAILS.toPath({ userId: id })}
                          size="xs"
                          variant="outline"
                        >
                          {t('SETTINGS.USERS.VIEW_ACTION_LABEL')}
                        </Button>
                        <Button size="xs" textColor="red.600" variant="outline" onClick={() => setUserToDelete(id)}>
                          {t('SETTINGS.USERS.DELETE_USER_LABEL')}
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
        </Tbody>
      </Table>
      {userToDelete !== nullUser && <UserDeleteConfirmationModal userId={userToDelete} onClose={onModalClose} />}
    </Card>
  );
};
