import React, { useEffect, useRef, VFC } from 'react';
import { Flex } from '@chakra-ui/react';
import { TChatMessage } from 'domain/neochat';
import { ChatMessage } from './ChatMessage';
import { DisplayFormat } from 'domain/neochat';

interface ChatMessagesProps {
  messages: TChatMessage[];
  displayFormat: DisplayFormat;
  compact?: boolean;
}

export const ChatMessages: VFC<ChatMessagesProps> = ({ messages, displayFormat, compact = false }) => {
  const scrollAnchor = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollAnchor.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Flex
      w="100%"
      minH={{
        lg: '490px',
        '2xl': '540px',
      }}
      h={{
        '2xl': '540px',
      }}
      overflowY="scroll"
      flexDirection="column"
      p="3"
      border="1px"
      borderColor="gray.200"
    >
      {messages?.map((item, index) => (
        <ChatMessage
          key={index}
          index={index}
          questionId={item.question_id}
          source={item.src}
          message={item.data}
          displayFormat={displayFormat}
          compact={compact}
        />
      ))}
      <div id="scrollToAnchor" ref={scrollAnchor} />
    </Flex>
  );
};
