import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children?: never;
}

export const OneLineSkeleton: React.FC<Props> = () => (
  <Box flex="1 0 auto" minHeight="36px">
    <Skeleton width="100%" height="36px" />
  </Box>
);
