import { pipe } from 'fp-ts/function';

import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, urlWithQueryParams } from 'utils/request';

import { TStoreId } from '../stores';
import { InsightArrayValidator } from './types';

const API_URLS = {
  getInsights: () => `${API_BASE_URL}/insights/`,
};

export interface TGetInsightsParams {
  store_id?: TStoreId | Array<TStoreId>;
  date_from?: string;
  limit?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_insights__get
 */
export const getInsights = (queryParams: TGetInsightsParams) => {
  return pipe(
    API_URLS.getInsights(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(InsightArrayValidator),
  );
};
