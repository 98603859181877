import { pipe } from 'fp-ts/function';

import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch } from 'utils/request';

import { SuppliersValidator } from './types';

const API_URLS = {
  getSuppliersList: () => `${API_BASE_URL}/suppliers/`,
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_suppliers__get
 */
export const getSuppliersList = () => {
  return pipe(API_URLS.getSuppliersList(), createValidatedCancellableFetch(SuppliersValidator));
};
