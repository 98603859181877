import { GridItem } from '@chakra-ui/react';
import { StatisticsDetails, SupermarketDisplayProps } from 'domain/stores';
import React from 'react';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { Ord } from 'fp-ts/string';
import { DEFAULT_ROW_STULES } from './consts/css';

export const RowData: React.FC<{
  Display: React.FC<SupermarketDisplayProps>;
  data: Record<string, StatisticsDetails> | undefined;
}> = ({ Display, data }) => {
  return (
    <>
      {pipe(
        data ?? {},
        R.reduceWithIndex(Ord)(
          [] as React.ReactElement[],
          (store_id: string, acc: React.ReactElement[], value: StatisticsDetails) => {
            return [
              ...acc,
              <GridItem key={`products-${store_id}`} sx={DEFAULT_ROW_STULES}>
                <Display data={value} store_id={store_id} />
              </GridItem>,
            ];
          },
        ),
      )}
    </>
  );
};
