import React from 'react';
import { Text, Center, Stack, Box } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { ChartLoadingOverlay } from 'components/Charts';
import { AnimatePresence } from 'framer-motion';
import { DIMENSIONS } from './consts';
import { PredictionBarChart } from './PredictionBarChart';
import { useCustomersSectionPredictionData } from 'hooks/data';
import { TStoreId } from 'domain/stores';
import { TSectionCode } from 'domain/core';
import { useTranslation } from 'react-i18next';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';
import { CustomerPrediction } from 'domain/predictions';
import { RectData } from './types';

interface Props {
  date: string;
  storeId: TStoreId;
  sectionId: TSectionCode;
}

export const DailyTaskCustomersPrediction: React.FC<Props> = ({ storeId, sectionId, date }) => {
  const { t } = useTranslation('whisperme');
  const { data, isFetching } = useCustomersSectionPredictionData({
    date: date,
    store_id: storeId,
    section_id: sectionId,
  });

  const getCustomersLegendLabel = (labelText: string) => {
    const labelMap: Record<string, string> = {
      history: t('DAILY_TASKS.CUSTOMERS_PREDICTION.CUSTOMERS_LEGEND.NUMBER_OF_CUSTOMERS'),
      quantile_5: t('DAILY_TASKS.CUSTOMERS_PREDICTION.CUSTOMERS_LEGEND.MIN_PREDICTION'),
      quantile_95: t('DAILY_TASKS.CUSTOMERS_PREDICTION.CUSTOMERS_LEGEND.EXPECTED_CUSTOMERS'),
    };

    return labelMap[labelText];
  };

  const formatDataForCustomerChart = (data: CustomerPrediction | undefined): RectData[] => {
    if (
      !data ||
      !data.historic_customers_count ||
      !data.prediction_customers_count ||
      !data.historic_prediction_customers_count
    ) {
      return [];
    }

    const historicEntries = Object.entries(data.historic_customers_count).map(([key, value]) => ({
      date: key,
      history: typeof value === 'number' ? value : 0,
      quantile_5: 0,
      quantile_95: 0,
      budget: -1,
    }));

    const historicPredictionEntries = Object.entries(data.historic_prediction_customers_count).map(([key, value]) => {
      const historicPredictionCount = pipe(
        value,
        O.map((value) => value),
        O.toNullable,
      );

      return {
        date: key,
        history: 0,
        quantile_5: historicPredictionCount?.quantile_5 ?? 0,
        quantile_95: (historicPredictionCount?.quantile_95 || 0) - (historicPredictionCount?.quantile_5 || 0),
        budget: -1,
      };
    });

    const predictionCount = data.prediction_customers_count;

    const predictionEntry = {
      date: predictionCount.date || '',
      history: -1,
      quantile_5: predictionCount.quantile_5 || 0,
      quantile_95: (predictionCount.quantile_95 || 0) - (predictionCount.quantile_5 || 0),
      budget: -1,
    };

    return [...historicPredictionEntries, ...historicEntries, predictionEntry];
  };

  return (
    <Card>
      <CardTitleSection as="h2">
        {t('DAILY_TASKS.CUSTOMERS_PREDICTION.CUSTOMERS_PREDICTION_LABEL', { date: date })}
      </CardTitleSection>
      <Stack
        flexDirection="column"
        flex="1 1 auto"
        minWidth={DIMENSIONS.minWidth}
        minHeight={DIMENSIONS.minHeight}
        position="relative"
      >
        {!!data && O.isSome(data.prediction_customers_count) && data.historic_customers_count ? (
          <Box w="100%" h="400px">
            <PredictionBarChart
              isCurrencySymbolUsed={false}
              parseDataForChart={formatDataForCustomerChart}
              labelGetter={getCustomersLegendLabel}
              data={pipe(
                data,
                ({ historic_customers_count, prediction_customers_count, historic_prediction_customers_count }) => ({
                  historic_customers_count,
                  historic_prediction_customers_count,
                  prediction_customers_count: pipe(
                    prediction_customers_count,
                    O.map((prediction) => prediction),
                    O.getOrElse(() => {
                      return {
                        date: date,
                        quantile_5: 0,
                        quantile_25: 0,
                        quantile_50: 0,
                        quantile_75: 0,
                        quantile_95: 0,
                      };
                    }),
                  ),
                }),
              )}
            />
          </Box>
        ) : (
          <Center>
            <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
          </Center>
        )}
        <AnimatePresence>{isFetching && <ChartLoadingOverlay />}</AnimatePresence>
      </Stack>
    </Card>
  );
};
