import { SETTINGS_PATH } from './settings';

const ROOT_PATH = `${SETTINGS_PATH}/assortment-analysis` as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const STORES_GROUPS_MANAGEMENT_PATH = `${ROOT_PATH}/stores-group-management` as const;
export const STORES_GROUP_MANAGEMENT_ROOT = {
  PATH: STORES_GROUPS_MANAGEMENT_PATH,
  toPath: () => STORES_GROUPS_MANAGEMENT_PATH,
};
