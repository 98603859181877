import React, { Suspense } from 'react';
import '@fontsource/bebas-neue/400.css';
import './print.css';

import { WaitForAssets } from 'components/WaitForAssets';
import { PageSpinner } from 'components/Spinners';
import { ErrorBoundary } from 'components/ErrorMessages';
import { AppProviders } from './AppProviders';
import { Pages } from './pages';
import { UserContextProvider } from 'components/Contexts';

export const App: React.FC = () => (
  <AppProviders>
    <ErrorBoundary>
      <Suspense fallback={<PageSpinner />}>
        <WaitForAssets />
        {/* NOTE(m.kania): user provider checks for login data, so it has to be inside <Suspense /> */}
        <UserContextProvider>
          <Pages />
        </UserContextProvider>
      </Suspense>
    </ErrorBoundary>
  </AppProviders>
);
