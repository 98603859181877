import React from 'react';
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { FaTrash } from 'react-icons/fa';
import { useAppConfig, useUserSections, useUserStoreIds } from 'hooks';
import { createNewProductsList } from 'constants/queryCacheKeys';
import { getNewProductsList, stopTracking } from 'domain/products';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Card, CardTitleSection } from 'components/Card';
import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { t } from 'i18next';

const SkeletonRow = () => (
  <Tr>
    <Td>
      <Skeleton height="20px" width="auto" />
    </Td>
    <Td>
      <Skeleton height="20px" width="auto" />
    </Td>
    <Td isNumeric={true}>
      <Skeleton height="20px" width="auto" />
    </Td>
    <Td isNumeric={true}>
      <Skeleton height="20px" width="auto" />
    </Td>
    <Td isNumeric={true}>
      <Skeleton height="20px" width="auto" />
    </Td>
  </Tr>
);

export const FreeTaskNewProducts: React.FC = () => {
  const { viewport } = useAppConfig();
  const userSections = useUserSections();
  const userStoreIds = useUserStoreIds();
  const rootFamilyId = userSections?.[0].id;
  const storeId = userStoreIds?.[0];
  const { data, isFetching } = useQuery(createNewProductsList(rootFamilyId), () =>
    getNewProductsList(rootFamilyId, [storeId]),
  );
  const queryClient = useQueryClient();

  const buttonProps = Object.assign({
    'aria-label': t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TABLE.STOP_TRACKING_LABEL'),
    mr: '2',
    size: 'sm',
    variant: 'ghost',
  });
  const toast = useToast();
  const { mutate: deleteTracking } = useMutation(stopTracking, {
    onSuccess: () => {
      queryClient.invalidateQueries(createNewProductsList(rootFamilyId));
      toast({
        title: t('OBJECTIVES.COMMON.OBJECTIVE_SAVE_SUCCESS_MESSAGE'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('OBJECTIVES.COMMON.OBJECTIVE_SAVE_FAILURE_MESSAGE'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    },
  });

  const rows =
    isFetching || !data
      ? Array.from({ length: 10 }).map((_, i) => <SkeletonRow key={i} />)
      : data.map((row, i) => (
          <Tr key={i}>
            <Td>{row.product_code}</Td>
            <Td>{row.description}</Td>
            <Td isNumeric={true}>
              {pipe(
                row.sales_today,
                O.getOrElse(() => 0),
              )}
            </Td>
            <Td isNumeric={true}>
              {pipe(
                row.sales_week,
                O.getOrElse(() => 0),
              )}
            </Td>
            <Td>
              <IconButton {...buttonProps} onClick={() => deleteTracking([row.product_code, row.store_id])}>
                <FaTrash />
              </IconButton>
            </Td>
          </Tr>
        ));

  const table = (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',
        tableLayout: viewport !== 'mobile' ? 'fixed' : undefined,
        '& [data-cell-wrap]': {
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Thead>
        <Tr>
          <Th data-cell-wrap>{t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TABLE.EAN_LABEL')}</Th>
          <Th data-cell-wrap>{t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TABLE.DESCRIPTION_LABEL')}</Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TABLE.SALES_LAST_DAY_LABEL')}
          </Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TABLE.SALES_LAST_7_DAYS_LABEL')}
          </Th>
          <Th data-cell-wrap>{t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TABLE.MANAGE_LABEL')}</Th>
        </Tr>
      </Thead>
      <Tbody>{rows}</Tbody>
    </Table>
  );

  return (
    <Card>
      <Stack spacing="4">
        <Grid
          gap={GRID_GAP_DEFAULT_VALUE}
          alignItems="center"
          gridTemplateColumns={{
            base: '100%',
            [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
          }}
        ></Grid>
        <CardTitleSection>{t('FREE_TASKS.NEW_PRODUCTS_TASK.NEW_PRODUCTS_TASK_LABEL')}</CardTitleSection>
        <Stack position="relative">{viewport === 'mobile' ? <TableContainer>{table}</TableContainer> : table}</Stack>
      </Stack>
    </Card>
  );
};
