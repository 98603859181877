import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Text, TextProps } from '@chakra-ui/react';

import { CSS_VARIABLES } from 'constants/css';

interface Props extends TextProps {
  children?: never;
}

export const AppName: React.FC<Props> = (props) => {
  const { t } = useTranslation('whisperme');

  return (
    <Text as="span" textTransform="uppercase" fontFamily="cursive" lineHeight="none" {...props}>
      <Trans
        t={t}
        i18nKey={'GENERIC.APP_NAME_LABEL'}
        components={{
          accent: <Text as="span" color={`var(${CSS_VARIABLES.ACCENT_COLOR})`} />,
        }}
      />
    </Text>
  );
};
