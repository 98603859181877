import * as t from 'io-ts';
import { Locale } from 'date-fns';
import { optionFromNullable, DateFromISOString } from 'io-ts-types';
import { I18nAppLanguage } from 'locales';

// TODO(m.kania): split this to separate files/domains once more endpoints are available
export type Series = Array<number | null>;

export interface Metric {
  name: string;
  series: Series;
}

export const DATA_RESOLUTION = {
  DAY: 'day',
  WEEK: 'week',
  // NOTE(m.kania): BE swagger doesn't say it supports monthly resolution
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
} as const;
export type TDataResolution = (typeof DATA_RESOLUTION)[keyof typeof DATA_RESOLUTION];
export const DATA_RESOLUTION_ORDER = [
  // DATA_RESOLUTION.HOUR,
  DATA_RESOLUTION.DAY,
  DATA_RESOLUTION.WEEK,
  DATA_RESOLUTION.MONTH,
  DATA_RESOLUTION.QUARTER,
  DATA_RESOLUTION.YEAR,
] as const;

export const PLOT_STYLE = {
  BAR: 'bar',
  LINE: 'line',
  SQUARE: 'square',
} as const;
export type TPlotStyle = (typeof PLOT_STYLE)[keyof typeof PLOT_STYLE];

export const DataResolutionValidator = t.union([
  t.literal(DATA_RESOLUTION.DAY),
  t.literal(DATA_RESOLUTION.WEEK),
  t.literal(DATA_RESOLUTION.MONTH),
  t.literal(DATA_RESOLUTION.QUARTER),
  t.literal(DATA_RESOLUTION.YEAR),
]);

export const PlotMetricSeriesValidator = t.array(t.union([t.null, t.number]));

export const PlotMetricValidator = t.type({
  name: t.string,
  series: PlotMetricSeriesValidator,
});
export type TPlotMetric = t.TypeOf<typeof PlotMetricValidator>;

export const PlotValidator = t.type({
  metrics: t.array(PlotMetricValidator),
  style: t.union([t.literal(PLOT_STYLE.BAR), t.literal(PLOT_STYLE.LINE), t.literal(PLOT_STYLE.SQUARE)]),

  date_from: DateFromISOString,
  date_to: DateFromISOString,

  data_resolution: optionFromNullable(DataResolutionValidator),
  default_aggregation: optionFromNullable(DataResolutionValidator),
});
export type TPlot = t.TypeOf<typeof PlotValidator>;

export interface Plot {
  metrics: Metric[];
  style: TPlotStyle;
  // TODO(m.kania): model dates as opaque/unique type
  date_from: string;
  date_to: string;

  data_resolution?: TDataResolution | null;
  default_aggregation?: TDataResolution | null;
}

interface ProductCodeBrand {
  readonly productCode: unique symbol;
}

export const ProductCodeValidator = t.brand(
  t.number,
  (v: number): v is t.Branded<number, ProductCodeBrand> => typeof v === 'number' && Number.isInteger(v),
  'productCode',
);

export type TProductCode = t.TypeOf<typeof ProductCodeValidator>;

interface FamilyCodeBrand {
  readonly familyCode: unique symbol;
}

export const FamilyCodeValidator = t.brand(
  t.number,
  (v: number): v is t.Branded<number, FamilyCodeBrand> => typeof v === 'number' && Number.isInteger(v),
  'familyCode',
);

export const RoleSectionCodeValidator = t.brand(
  t.string,
  (v: string): v is t.Branded<string, FamilyCodeBrand> => typeof v === 'string' && v.length === 6,
  'familyCode',
);

export type TSectionCode = t.TypeOf<typeof RoleSectionCodeValidator>;

export type TFamilyCode = t.TypeOf<typeof FamilyCodeValidator>;

export type TDayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export interface TDateOptions {
  locale?: Locale;
  weekStartsOn?: TDayNumber;
}

export type TGetAnyCsvParams = {
  lang?: I18nAppLanguage;
};

export type ColumnSortDirection = 'ASC' | 'DESC';
