import React from 'react';

import { useStoresDataMap } from 'hooks/data';
import { StoreSelectBaseProps } from './types';

export interface StoreSelectOptionsProps extends Pick<StoreSelectBaseProps, 'selectableStoreIds'> {}

export const StoreSelectOptions: React.FC<StoreSelectOptionsProps> = ({ selectableStoreIds }) => {
  const storesMap = useStoresDataMap();

  return (
    <>
      {selectableStoreIds.map((id) => (
        <option key={id} value={id}>
          {storesMap.get(id)?.name ?? id}
        </option>
      ))}
    </>
  );
};
