import React from 'react';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import {
  AreaManagerAppUser,
  SupermarketManagerAppUser,
  AppUser,
  AdminAppUser,
  MonitorAppUser,
  SectionManagerAppUser,
  CLevelAppUser,
} from 'domain/user';
import { useUserContext } from 'hooks';

type RenderOutput = JSX.Element | null;
interface Props {
  noAccess?: RenderOutput | (() => RenderOutput);
  anyAccess?: RenderOutput | ((user: AppUser) => RenderOutput);
  admin?: RenderOutput | ((manager: AdminAppUser | AppUser) => RenderOutput);
  areaManager?: RenderOutput | ((manager: AreaManagerAppUser | AppUser) => RenderOutput);
  supermarketManager?: RenderOutput | ((manager: SupermarketManagerAppUser | AppUser) => RenderOutput);
  monitor?: RenderOutput | ((manager: MonitorAppUser | AppUser) => RenderOutput);
  sectionManager?: RenderOutput | ((manager: SectionManagerAppUser | AppUser) => RenderOutput);
  cLevel?: RenderOutput | ((manager: CLevelAppUser | AppUser) => RenderOutput);
}

const MatchUserAccessComponent: React.FC<Props> = ({
  noAccess = null,
  admin = null,
  areaManager = null,
  supermarketManager = null,
  monitor = null,
  sectionManager = null,
  cLevel = null,
  anyAccess = null,
}) =>
  pipe(
    useUserContext().user,
    O.fold(
      () => (typeof noAccess === 'function' ? noAccess() : noAccess),
      (user) => {
        const anyAccessFallback = typeof anyAccess === 'function' ? anyAccess(user) : anyAccess;

        const accessLevels = {
          admin: admin,
          'area-manager': areaManager,
          'supermarket-manager': supermarketManager,
          monitor: monitor,
          'section-manager': sectionManager,
          'c-level': cLevel,
        };

        const access = accessLevels[user.access];

        if (typeof access === 'function') {
          return access(user);
        }

        return access ?? anyAccessFallback;
      },
    ),
  );

export const MatchUserAccess = React.memo<Props>(MatchUserAccessComponent);
