import { createNeoEmailCampaigneHistoryKey } from 'constants/queryCacheKeys';
import { getCampaigneHistory, GetHistoryParams } from 'domain/neoemail';
import { useQuery } from 'react-query';

export const useNeoEmailHistory = (params: GetHistoryParams) => {
  return useQuery(createNeoEmailCampaigneHistoryKey(params), () => getCampaigneHistory(params), {
    refetchInterval: 1000 * 60 * 2,
    refetchIntervalInBackground: true,
  });
};
