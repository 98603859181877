import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { INSIGHT_TAG_TYPE, TInsightDetails } from 'domain/insights';
import { useAppConfig } from 'hooks';

interface Props {
  details: TInsightDetails;
}

export const InsightDetails: React.FC<Props> = ({ details }) => {
  const { t } = useTranslation('whisperme-api');
  const {
    formatters: { numberFormatter, currencyFormatter, percentageFormatter },
  } = useAppConfig();

  switch (details.tag) {
    case INSIGHT_TAG_TYPE.TOP_PRODUCTS:
      const { products } = details.params;
      return (
        <Stack direction="column" spacing="2">
          <Text as="span">
            {t('INSIGHTS.TOP_PRODUCTS.PRODUCTS_SOLD_LAST_DAY_LABEL', {
              count: products.length,
              quantity: numberFormatter.format(products.length),
            })}
          </Text>
          <Stack
            as="ul"
            direction="column"
            spacing="2"
            sx={{
              pl: '3',
              listStyle: 'disc',
              listStylePosition: 'inside',
            }}
          >
            {products.map((p, idx) => (
              <Text key={`${p.product_code}-${idx}`} as="li">
                {t('INSIGHTS.TOP_PRODUCTS.PRODUCT_LABEL', {
                  productName: pipe(
                    p.description,
                    O.fold(() => '', identity),
                  ),
                  productCode: p.product_code,
                  quantity: numberFormatter.format(p.quantity),
                  count: p.quantity,
                  soldValue: currencyFormatter.format(p.eur),
                })}
              </Text>
            ))}
          </Stack>
        </Stack>
      );
    case INSIGHT_TAG_TYPE.FAMILY_BEST:
      const { best_last_week, best_this_week } = details.params;

      const familyBestText = pipe(
        [
          pipe(
            best_last_week,
            O.map((familyName) => t('INSIGHTS.FAMILY_BEST.LAST_WEEK_LABEL', { familyName })),
          ),
          pipe(
            best_this_week,
            O.map((familyName) => t('INSIGHTS.FAMILY_BEST.THIS_WEEK_LABEL', { familyName })),
          ),
        ],
        A.compact,
      ).join(' ');

      return <Text as="span">{familyBestText}</Text>;
    case INSIGHT_TAG_TYPE.FAMILY_DROPPING: {
      const { family_name, pct_change, change_eur, fall, lowest } = details.params;

      return (
        <Stack direction="column" spacing="2">
          <Text as="span">
            {t('INSIGHTS.FAMILY_DROP.FAMILY_LABEL', {
              familyName: family_name,
              dropPercentage: percentageFormatter.format(pct_change / 100),
              dropValue: currencyFormatter.format(change_eur),
            })}
          </Text>
          {pipe(
            fall,
            O.map((count) => t('INSIGHTS.FAMILY_DROP.DROP_STARTED_LABEL', { count })),
            O.toNullable,
          )}
          {pipe(
            lowest,
            O.map((count) => t('INSIGHTS.FAMILY_DROP.LAST_WEEK_LOWEST_SALES_LABEL', { count })),
            O.toNullable,
          )}
        </Stack>
      );
    }
    case INSIGHT_TAG_TYPE.FAMILY_GROWING: {
      const { family_name, pct_change, change_eur, grow, highest } = details.params;

      return (
        <Stack direction="column" spacing="2">
          <Text as="span">
            {t('INSIGHTS.FAMILY_GROW.FAMILY_LABEL', {
              familyName: family_name,
              growPercentage: percentageFormatter.format(pct_change / 100),
              growValue: currencyFormatter.format(change_eur),
            })}
          </Text>
          {pipe(
            grow,
            O.map((count) => t('INSIGHTS.FAMILY_GROW.GROW_STARTED_LABEL', { count })),
            O.toNullable,
          )}
          {pipe(
            highest,
            O.map((count) => t('INSIGHTS.FAMILY_GROW.LAST_WEEK_HIGHEST_SALES_LABEL', { count })),
            O.toNullable,
          )}
        </Stack>
      );
    }
    case INSIGHT_TAG_TYPE.TOP_PRODUCTS_SHORTAGE: {
      const { products } = details.params;
      return (
        <Stack direction="column" spacing="2">
          <Text as="span">
            {t('INSIGHTS.TOP_PRODUCTS_SHORTAGE.POSSIBLE_SHORTAGE_LAST_WEEK_LABEL', {
              count: products.length,
              quantity: numberFormatter.format(products.length),
            })}
          </Text>
          <Stack
            as="ul"
            direction="column"
            spacing="2"
            sx={{
              pl: '3',
              listStyle: 'disc',
              listStylePosition: 'inside',
            }}
          >
            {products.map((p, idx) => (
              <Text key={`${p.product_code}-${idx}`} as="li">
                {t('INSIGHTS.TOP_PRODUCTS_SHORTAGE.PRODUCT_LABEL', {
                  productName: pipe(
                    p.description,
                    O.fold(() => '', identity),
                  ),
                  productCode: p.product_code,
                  soldValue: currencyFormatter.format(p.eur),
                })}
              </Text>
            ))}
          </Stack>
        </Stack>
      );
    }
    default:
      return null;
  }
};
