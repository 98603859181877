import { Select, SelectProps } from '@chakra-ui/react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type TShortagesSortOption = {
  value: string;
  label: string;
};

export const generateDefaultOptions = (): TShortagesSortOption[] => [
  { value: 'shelf', label: 'Shelf + IFLS' },
  { value: 'ean', label: 'EAN7' },
  { value: 'ean13', label: 'EAN13' },
  { value: 'ifls', label: 'IFLS' },
  { value: 'name', label: 'Ticket desc.' },
  { value: 'order_description', label: 'BLI desc.' },
  { value: 'subcategory', label: 'Subcategory' },
  { value: 'stock', label: 'Stock' },
  { value: 'position', label: 'Position' },
];

export const StocksAndOrdersPageSortOptions = (): TShortagesSortOption[] => [
  { value: 'shelf', label: 'Shelf + IFLS' },
  { value: 'ean', label: 'EAN7' },
  { value: 'ifls', label: 'IFLS' },
  { value: 'name', label: 'Name' },
  { value: 'stock', label: 'Stock' },
];

type SortProps<T = string> = {
  onChange: (value: T) => void;
  value: string;
  options?: TShortagesSortOption[];
  useDefaultOptions?: boolean;
} & Omit<SelectProps, 'children' | 'onChange' | 'value'>;

export const StocksSort = <T extends string>({
  onChange,
  value,
  options = [],
  useDefaultOptions = true,
  ...rest
}: SortProps<T>) => {
  const { t } = useTranslation();
  const optionValues = useMemo(
    () =>
      pipe(
        useDefaultOptions ? [...generateDefaultOptions(), ...options] : options,
        A.map(({ value }) => (
          <option value={value} key={value}>
            {t(`MORNING_TAKS.STOCKS_SORT.${value.toUpperCase()}_LABEL`)}
          </option>
        )),
      ),
    [useDefaultOptions, options],
  );

  const handleSelected = useCallback<Required<SelectProps>['onChange']>(
    (evt) => {
      onChange(evt.target.value as unknown as T);
    },
    [onChange],
  );

  return (
    <Select value={value} w="8rem" size="xs" onChange={handleSelected} {...rest}>
      {optionValues}
    </Select>
  );
};
