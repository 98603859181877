import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { THistorialProductStockCalendar, TStockInsight, TStocksAPIData } from './types';

export const stockLevelsToHistoricalRecord = (data: TStocksAPIData): THistorialProductStockCalendar =>
  pipe(
    data,
    A.reduce({}, (acc, { product_code, store_id: _s, date, ...historyRecord }) => ({ ...acc, [date]: historyRecord })),
  );

interface Matcher<M extends TStockInsight, T> {
  run: (s: M) => T;
  match: (s: TStockInsight) => s is M;
}

export const createStockInsightsMatcher = <M extends TStockInsight, T>(
  match: TStockInsight['insight_type'],
  run: (s: M['insight_details']) => T,
): Matcher<M, T> => ({
  run: (s: M) => run(s.insight_details),
  match: (s: TStockInsight): s is M => s.insight_type === match,
});

export const foldStockInsights =
  <T>(noMatch: (stock: TStockInsight) => T, ...match: Matcher<any, T>[]) =>
  (stock: TStockInsight): T => {
    for (const matcher of match) {
      if (matcher.match(stock)) {
        return matcher.run(stock);
      }
    }

    return noMatch(stock);
  };
