import { generatePath } from 'react-router';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

import { SETTINGS_PATH } from './settings';
import { TUserId, UserIdValidator } from 'domain/user';

const ROOT_PATH = `${SETTINGS_PATH}/users` as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const NEW_PATH = `${ROOT_PATH}/new` as const;
export const NEW = {
  PATH: NEW_PATH,
  toPath: () => NEW_PATH,
};

interface TUserDetailsPathParams {
  userId: TUserId;
}

const DETAILS_PATH = `${ROOT_PATH}/:userId` as const;
export const DETAILS = {
  PATH: DETAILS_PATH,
  toPath: ({ userId }: TUserDetailsPathParams) => generatePath(DETAILS_PATH, { userId }),
  validateParams: (params?: Record<string, unknown> | null) => ({
    userId: pipe(
      params?.userId,
      String,
      (code) => Number.parseInt(+code as any, 10),
      UserIdValidator.decode,
      O.fromEither,
    ),
  }),
};

interface TUserEditPathParams {
  userId: TUserId;
}

const EDIT_PATH = `${DETAILS_PATH}/edit` as const;
export const EDIT = {
  PATH: EDIT_PATH,
  toPath: ({ userId }: TUserEditPathParams) => generatePath(EDIT_PATH, { userId }),
  validateParams: DETAILS.validateParams,
};
