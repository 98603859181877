import React, { Fragment, useMemo, useState } from 'react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { t } from 'i18next';
import { i18nInstance } from 'locales';
import {
  Flex,
  Grid,
  GridItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tfoot,
  Tr,
  Switch,
  HStack,
  Button,
} from '@chakra-ui/react';
import { addWeeks, startOfISOWeek, format, addDays, eachDayOfInterval } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { dateToYMD, getCurrentDate } from 'utils/date';
import { AnimatePresence } from 'framer-motion';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { ChartLoadingOverlay } from 'components/Charts';
import { TStoreId } from 'domain/stores';
import { CASHIER_RISK_SHRESHOLD_LEVELS } from 'pages/AnalyticsPage/ObjectivesPage/ObjectivesInventoryShortagePage';
import { CashierEntry } from './types';
import { TABLE_CALENDAR_STYLES, DIMENSIONS, TOTAL_CASH_REGISTERS, DEFAULT_CASHIER_HOUR_RANGES } from './consts';
import { useCashierPredictionData } from 'hooks/data';
import { getCashierCellVariant, getWeekRangeLabel } from './utils';

interface Props {
  storeId: TStoreId;
}

export const DailyTaskCashierAdvice: React.FC<Props> = ({ storeId }) => {
  const [isRisky, setIsRisky] = useState<boolean>(true);
  const [isCurrentWeek, setIsCurrentWeek] = useState<boolean>(true);

  const date = addWeeks(startOfISOWeek(getCurrentDate()), isCurrentWeek ? 0 : 2);
  const { data, isFetched } = useCashierPredictionData({ storeIds: storeId, date: dateToYMD(date) });

  const twoWeekRangeYMD = eachDayOfInterval({
    start: date,
    end: addDays(date, 13),
  }).map(dateToYMD);

  const cashierDataMap = useMemo(() => {
    const newCashierDataMap = new Map();

    if (isFetched && !!data && data.length) {
      data.forEach((el) => {
        const startDate = el.shift.start.split(' ')[0];

        if (!newCashierDataMap.has(startDate)) {
          newCashierDataMap.set(startDate, []);
        }

        newCashierDataMap.get(startDate).push(el);
      });
    }

    return newCashierDataMap;
  }, [isFetched, data]);

  const riskVariant = isRisky ? 'risky' : 'safe';

  const table = useMemo(() => {
    return (
      <Table variant="products" size="sm" table-layout="fixed" sx={TABLE_CALENDAR_STYLES}>
        <Thead>
          <Tr>
            <Th colSpan={3} rowSpan={2} style={{ padding: '0.5em' }}>
              {t('DAILY_TASKS.CASHIER_ADVICE.HOURS_LABEL')}
            </Th>
            {pipe(
              twoWeekRangeYMD,
              A.map((header) => (
                <Th key={header} colSpan={1} textAlign="center" whiteSpace="pre-wrap">
                  {format(new Date(header), 'EEE dd.MM', {
                    locale: i18nInstance.language === 'en-GB' ? enGB : fr,
                  })}
                </Th>
              )),
            )}
          </Tr>
        </Thead>
        <Tbody>
          {DEFAULT_CASHIER_HOUR_RANGES.map((hourRange: string, hourIdx: number) => (
            <Tr key={hourIdx}>
              <Td colSpan={3}>{hourRange}</Td>
              {isFetched &&
                !!data &&
                data.length > 0 &&
                twoWeekRangeYMD.map((dateKey, dateIdx) => {
                  const hourRangeStart = parseInt(hourRange.split(' - ')[0].substring(0, 2), 10);
                  const hourRangeEnd = parseInt(hourRange.split(' - ')[1].substring(0, 2), 10);

                  const shiftWithinRange = cashierDataMap.get(dateKey)?.find((entry: CashierEntry) => {
                    const shiftHour = parseInt(format(new Date(entry.shift.start), 'kk'), 10);
                    return hourRangeStart <= shiftHour && hourRangeEnd >= shiftHour;
                  });

                  const cashiers_qty = shiftWithinRange?.cashiers_quantity[riskVariant] ?? 0;

                  return (
                    <Td
                      key={dateIdx}
                      colSpan={1}
                      textAlign="center"
                      data-cell-variant={getCashierCellVariant(
                        cashiers_qty,
                        TOTAL_CASH_REGISTERS[storeId as keyof typeof TOTAL_CASH_REGISTERS],
                      )}
                      flex="1"
                      borderWidth={1}
                      borderColor="white"
                      borderTopWidth={0}
                    >
                      {!!cashiers_qty ? cashiers_qty : 0}
                    </Td>
                  );
                })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [storeId, cashierDataMap, data, riskVariant, isFetched, twoWeekRangeYMD]);

  return (
    <Card width="100%">
      <HStack justifyContent={'space-between'}>
        <CardTitleSection as="h2">
          {t('DAILY_TASKS.CASHIER_ADVICE.CASHIER_ADVICE_TASK_LABEL', {
            week_range: getWeekRangeLabel(date, t),
            date_range: `(${format(date, 'dd.MM')} - ${format(addDays(date, 13), 'dd.MM')})`,
          })}
        </CardTitleSection>
        <CardTitleSectionSettings>
          <Button onClick={() => setIsCurrentWeek((curr) => !curr)}>
            {t('DAILY_TASKS.CASHIER_ADVICE.WEEK_SELECTOR', {
              week_range: getWeekRangeLabel(addWeeks(date, isCurrentWeek ? 2 : -2), t),
            })}
          </Button>
        </CardTitleSectionSettings>
      </HStack>
      <Flex pt="3" width="100%">
        <Stack
          flexDirection="column"
          flex="1 1 auto"
          minWidth={DIMENSIONS.minWidth}
          minHeight={DIMENSIONS.minHeight}
          position="relative"
          overflowY="auto"
        >
          <CardTitleSectionSettings>
            {t('DAILY_TASKS.CASHIER_ADVICE.SWITCH_RISK', {
              risk: isRisky
                ? t('DAILY_TASKS.CASHIER_ADVICE.QUEUES_LABEL')
                : t('DAILY_TASKS.CASHIER_ADVICE.CASHIERS_LABEL'),
            })}
            <Switch
              size="sm"
              id="toggleRisk"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                ev.preventDefault();
                setIsRisky((prevRisk) => !prevRisk);
              }}
            />
          </CardTitleSectionSettings>
          <Grid
            width="100%"
            gap={GRID_GAP_DEFAULT_VALUE}
            gridTemplateColumns={{ base: '100%', xl: 'repeat(3, minmax(250px, 1fr))' }}
          >
            <GridItem colSpan={3}>
              <TableContainer>{table}</TableContainer>
            </GridItem>
            <GridItem colSpan={3}>
              <TableContainer>
                <Table variant="products" size="sm" table-layout="fixed" sx={TABLE_CALENDAR_STYLES}>
                  <Tfoot>
                    <Tr style={{ textAlign: 'center' }}>
                      {pipe(
                        Object.entries(CASHIER_RISK_SHRESHOLD_LEVELS),
                        A.map(([id, { variant, description }]) => (
                          <Fragment key={id}>
                            <Td
                              whiteSpace="pre-wrap"
                              colSpan={3}
                              textAlign="right"
                              borderColor="white"
                              style={{ fontSize: 'x-small' }}
                            >
                              {t(description)}
                            </Td>
                            <Td
                              colSpan={2}
                              textAlign="center"
                              minWidth="25px"
                              data-cell-variant={variant}
                              flex="1"
                              borderWidth={3}
                              borderColor="white"
                              borderTopWidth={0}
                            />
                          </Fragment>
                        )),
                      )}
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </GridItem>
          </Grid>
          <AnimatePresence>{!isFetched && <ChartLoadingOverlay h="97%" />}</AnimatePresence>
        </Stack>
      </Flex>
    </Card>
  );
};
