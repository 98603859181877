import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { createClusterMapStorePositionsKey } from 'constants/queryCacheKeys';
import {
  getClusterMapStorePositions,
  GetClusterMapStorePositionsParams,
  TClusterMapStoreWeeklyPositions,
} from 'domain/map';
import { ONE_DAY } from 'constants/time';
import { useStoresDataMap } from './useStores';

type DataType = TClusterMapStoreWeeklyPositions;
type ErrorType = Error;

export const useClusterMapStorePositions = <Data = DataType>(
  params: GetClusterMapStorePositionsParams,
  options?: UseQueryOptions<DataType, ErrorType, Data>,
) => {
  // NOTE(m.kania): WHIS-102 - filter out 'hyper' stores
  const storesMap = useStoresDataMap();
  const select = useCallback((data: DataType) => data.filter((d) => storesMap.has(d.store_id)), [storesMap]);

  return useQuery(createClusterMapStorePositionsKey(params), () => getClusterMapStorePositions(params), {
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
    // @ts-ignore
    select,
    ...options,
  });
};
