import { useMemo } from 'react';
import { useQuery } from 'react-query';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { getAllStores, TStore, TStoreId } from 'domain/stores';
import { byName } from 'utils/sort';

const EMPTY_STORES: never[] = [];

const selectData = (data: TStore[]): TStore[] => pipe(data, A.sortBy([byName]));

export const useStoresData = (): TStore[] => {
  // TODO(m.kania): if this endpoint returns different data for each user, then add user id to key
  const { data: stores = EMPTY_STORES } = useQuery(['all-stores'], getAllStores, {
    useErrorBoundary: true,
    staleTime: 24 * 60 * 60 * 1000,
    select: selectData,
    suspense: true,
  });

  return stores;
};

export const createStoresDataMap = (stores: TStore[]): Map<TStoreId, TStore> => new Map(stores.map((s) => [s.id, s]));

export const useStoresDataMap = (): Map<TStoreId, TStore> => {
  const stores = useStoresData();

  return useMemo(() => createStoresDataMap(stores), [stores]);
};
