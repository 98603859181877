import { TTopAnomalousArray } from 'domain/objectives';

export const useAnomalousTotals = (filteredData: TTopAnomalousArray) =>
  filteredData.reduce(
    (acc: { totalIncidents: number; totalLoss: number }, curr: any) => {
      acc.totalIncidents += curr.incident_count;
      acc.totalLoss += curr.loss;

      return acc;
    },
    { totalIncidents: 0, totalLoss: 0 },
  );
