import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

import { StoreIdValidator } from '../stores';

export const ClusterMapGroupIdValidator = t.Int;
export type TClusterMapGroupIdEntry = t.TypeOf<typeof ClusterMapGroupIdValidator>;

export const ClusterMapValidator = t.array(t.array(ClusterMapGroupIdValidator));
export type TClusterMap = t.TypeOf<typeof ClusterMapValidator>;

export const ClusterIdValidator = t.number;
export type TClusterId = t.TypeOf<typeof ClusterIdValidator>;

export const ClusterMapStorePositionValidator = t.type({
  store_id: StoreIdValidator,
  x: t.number,
  y: t.number,
  cluster: ClusterIdValidator,
});
export const ClusterMapStorePositionsValidator = t.array(ClusterMapStorePositionValidator);
export type TClusterMapStorePosition = t.TypeOf<typeof ClusterMapStorePositionValidator>;
export type TClusterMapStorePositions = t.TypeOf<typeof ClusterMapStorePositionsValidator>;

export type TClusterMapStorePositionCoordinates = Pick<TClusterMapStorePosition, 'x' | 'y'>;

const WeekYearValidator = t.type({
  // NOTE(m.kania): not sure if this is worth modelling as custom/branded types?
  year: t.Int,
  week: t.Int,
});

export const ClusterMapWeeklyStorePositionValidator = t.intersection([
  ClusterMapStorePositionValidator,
  WeekYearValidator,
]);
export const ClusterMapStoreWeeklyPositionsValidator = t.array(ClusterMapWeeklyStorePositionValidator);
export type TClusterMapStoreWeeklyPosition = t.TypeOf<typeof ClusterMapWeeklyStorePositionValidator>;
export type TClusterMapStoreWeeklyPositions = t.TypeOf<typeof ClusterMapStoreWeeklyPositionsValidator>;

export const CHARACTERISTICS = {
  SALES_EURO: 'sales_eur',
  AVERAGE_CART: 'avg_cart',
  DISCOUNT_RATIO: 'discount_ratio',
  REGIONAL_RATIO: 'regional_ratio',
  NUMBER_OF_RECEIPTS: 'no_of_receipts',
  NUMBER_OF_ITEMS: 'no_of_items',
  NUMBER_OF_DISCOUNTS: 'no_of_discounts',
  NUMBER_OF_REGIONAL_ITEMS: 'no_of_regional_items',

  BAZAR: 'bazar',
  BOUCHERIE_LS: 'boucherie_ls',
  BOUCHERIE_TRAD: 'boucherie_trad',
  BOULANGERIE_LS: 'boulangerie_ls',
  BOULANGERIE_TRAD: 'boulangerie_trad',
  CHARCUTERIE_LS: 'charcuterie_ls',
  CHARCUTERIE_TRAD: 'charcuterie_trad',
  CULTURE_PRESSE: 'culture_presse',
  DPH: 'dph',
  EPCS: 'epcs',
  EPICERIE: 'epicerie',
  FROMAGE_LS: 'fromage_ls',
  FROMAGE_TRAD: 'fromage_trad',
  FRUIT_AND_LEGUME: 'fruit_and_legume',
  LIQUIDE: 'liquide',
  OTHER: 'other',
  POISSONNERIE_LS: 'poissonnerie_ls',
  POISSONNERIE_TRAD: 'poissonnerie_trad',
  TEXTILE: 'textile',
} as const;
export type TCharacteristicVariant = (typeof CHARACTERISTICS)[keyof typeof CHARACTERISTICS];
export const CHARACTERISTICS_KEYS = Object.values(CHARACTERISTICS);

// TODO(m.kania): find a way to use CHARACTERISTICS_KEYS above in a way that satisfies TS/io-ts
export const CharacteristicIdValidator = t.string;
export const CharacteristicsValidator = t.record(CharacteristicIdValidator, optionFromNullable(t.number));
export type TCharacteristics = t.TypeOf<typeof CharacteristicsValidator>;

export const ClusterMapGroupValidator = t.type({
  group_id: ClusterMapGroupIdValidator,
  // map_id: optionFromNullable(t.string),
  description: optionFromNullable(t.string),
  characteristics: CharacteristicsValidator,
});
export const ClusterMapGroupsValidator = t.array(ClusterMapGroupValidator);
export type TClusterMapGroup = t.TypeOf<typeof ClusterMapGroupValidator>;
export type TClusterMapGroups = t.TypeOf<typeof ClusterMapGroupsValidator>;

export const ClusterMapStoreCharacteristicValidator = t.intersection([
  t.type({
    store_id: StoreIdValidator,
    characteristics: CharacteristicsValidator,
  }),
  WeekYearValidator,
]);
export const ClusterMapStoreCharacteristicsValidator = t.array(ClusterMapStoreCharacteristicValidator);
export type TClusterMapStoreCharacteristics = t.TypeOf<typeof ClusterMapStoreCharacteristicsValidator>;

export const CLUSTER_GROUPS_MAP_LAYER_NAME = 'group' as const;

export const MapLayerValidator = t.array(t.array(t.number));
export type TMapLayer = t.TypeOf<typeof MapLayerValidator>;

const CharacteristicMapLayers = t.record(CharacteristicIdValidator, MapLayerValidator);
export type TCharacteristicMapLayers = t.TypeOf<typeof CharacteristicMapLayers>;

export const MapLayersValidator = t.intersection([
  t.type({
    [CLUSTER_GROUPS_MAP_LAYER_NAME]: ClusterMapValidator,
  }),
  CharacteristicMapLayers,
]);
export type TMapLayers = t.TypeOf<typeof MapLayersValidator>;

export type MapPointData = {
  x: number;
  y: number;
  color: string;
  id: string;
  [key: string]: number | string;
};

export type MapPointDataList = MapPointData[];

export type CustomerData = {
  behaviorData?: Record<string, number>;
  customerData?: Record<string, number>;
};

export type CustomerDataMap = Map<string, CustomerData>;
