import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { useCalendarDateRange, useUserStoreIds } from 'hooks';
import { ImportFailureList, ImportStatusCard, DataStatusCard } from './components';

export const ProcessingPage: React.FC = () => {
  const userStoreIds = useUserStoreIds();
  const { calendarProps: processingCalendarProps } = useCalendarDateRange();

  const [selectedStoreIds, setSelectedStoreIds] = useState(() => userStoreIds);

  return (
    <Flex direction="column" gap={GRID_GAP_DEFAULT_VALUE}>
      <DataStatusCard storeIds={userStoreIds} />
      <ImportStatusCard
        storeIds={userStoreIds}
        selectedStore={selectedStoreIds}
        onSelectIds={setSelectedStoreIds}
        calendarProps={processingCalendarProps}
      />
      <ImportFailureList storeIds={selectedStoreIds} calendarProps={processingCalendarProps} />
    </Flex>
  );
};
