import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Box,
  Skeleton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useReceiptSource } from 'hooks/data';
import { TErrorId } from 'domain/statistics';

interface Props {
  errorId: TErrorId | undefined;
  onClose: () => void;
}

const ImportFailureListReceiptModalComponent: React.VFC<Props> = ({ errorId, onClose }) => {
  const { t } = useTranslation('whisperme');

  const { data, status } = useReceiptSource(errorId);

  return (
    <React.Fragment>
      <Modal isOpen={!!errorId} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent style={{ minWidth: '450px', wordBreak: 'break-all', hyphens: 'auto' }}>
          <ModalHeader
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.RECEIPT_NUMBER')} <Text as="blockquote">{errorId}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack paddingBottom={2}>
              <Text as="strong">{t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.RECEIPT_DETAILS')}:</Text>
              <Skeleton isLoaded={status === 'success'} paddingBottom={3}>
                <Box
                  style={{
                    overflowY: 'auto',
                    minHeight: '300px',
                    maxHeight: '40vh',
                  }}
                >
                  <Text as="span">
                    {data?.details.map((value) => (
                      <>
                        {value}
                        <br />
                      </>
                    ))}
                  </Text>
                </Box>
              </Skeleton>
              <Text as="strong">{t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.FAILURE_MESSAGE_LABEL')}:</Text>
              <Skeleton isLoaded={status === 'success'}>
                <Box
                  style={{
                    overflowY: 'auto',
                    height: '50px',
                    maxHeight: '100px',
                  }}
                >
                  <Text as="span">{data?.message}</Text>
                </Box>
              </Skeleton>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export const ImportFailureListReceiptModal = React.memo(ImportFailureListReceiptModalComponent);
