import { API_BASE_URL } from 'constants/env';
import { pipe } from 'fp-ts/function';
import { makeCancellableFetch, urlWithQueryParams, createValidatedCancellableFetch } from 'utils/request';
import {
  PostFeedbackParams,
  PostPromptParams,
  PostCommandParams,
  NeochatPromptResponseValidator,
  NeochatExampleQueriesValidator,
  TNeochatExampleQueriesParams,
} from './types';

const API_URLS = {
  postPrompt: () => `${API_BASE_URL}/neochat/prompt`,
  postCommand: () => `${API_BASE_URL}/command`,
  postFeedback: () => `${API_BASE_URL}/neochat/feedback`,
  getExampleQueries: () => `${API_BASE_URL}/neochat/example-queries`,
};

export const sendNeoChatPrompt = (params: PostPromptParams) =>
  pipe(API_URLS.postPrompt(), (url) =>
    makeCancellableFetch<NeochatPromptResponseValidator>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  );

export const sendNeoChatCommand = (params: PostCommandParams) =>
  pipe(API_URLS.postCommand(), (url) =>
    makeCancellableFetch<NeochatPromptResponseValidator>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  );

export const getExampleQueries = (params: TNeochatExampleQueriesParams) =>
  pipe(
    API_URLS.getExampleQueries(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(NeochatExampleQueriesValidator),
  );

export const sendNeoChatResponseFeedback = (params: PostFeedbackParams) =>
  pipe(API_URLS.postFeedback(), (url) =>
    makeCancellableFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  );
