import { ShortageProductsListParams } from 'domain/stocks';
import { flow } from 'fp-ts/lib/function';
import { useReducer, useMemo } from 'react';

type FilterParamsState = {
  columnSort: Required<ShortageProductsListParams>['column_sort'];
  order?: ShortageProductsListParams['order'];
};

const INITIAL_STATE: FilterParamsState = {
  columnSort: 'shortage_time',
  order: 'DESC',
};

const SET_COLUMN_SORT = 'SET_COLUMN_SORT';

const setColumnSort = (columnSort: FilterParamsState['columnSort']) => ({
  type: SET_COLUMN_SORT,
  payload: columnSort,
});

const matchSetColumnSort = (action: unknown): action is ReturnType<typeof setColumnSort> => {
  return (action as { type: string }).type === SET_COLUMN_SORT;
};

const SET_ORDER = 'SET_ORDER';

const setOrder = (order?: FilterParamsState['order']) => ({
  type: SET_ORDER,
  payload: order,
});

const matchSetOrder = (action: unknown): action is ReturnType<typeof setOrder> => {
  return (action as { type: string }).type === SET_ORDER;
};

function reducer(state: FilterParamsState, action: unknown) {
  if (matchSetColumnSort(action)) {
    return { ...state, columnSort: action.payload };
  }

  if (matchSetOrder(action)) {
    return { ...state, order: action.payload };
  }

  return state;
}

export const useFilterParams = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return useMemo(
    () => ({
      ...state,
      onChangeColumnSort: flow(setColumnSort, dispatch),
      onChangeOrder: flow(setOrder, dispatch),
    }),
    [state],
  );
};
