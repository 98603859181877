import React from 'react';
import { Skeleton, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import * as PATHS from 'constants/paths';
import { Card, CardTitleSection } from 'components/Card';
import { useUserDetails } from 'hooks/data';
import { UserForm } from './components';
import { createUserDetailsKey, createUserListKey } from 'constants/queryCacheKeys';
import { TUserId, updateUserDetails } from 'domain/user';

interface Props {
  userId: TUserId;
}

export const UserEditPage: React.VFC<Props> = ({ userId }) => {
  const { t } = useTranslation('whisperme');
  const { data, isLoading, isFetching } = useUserDetails({ userId });
  const toast = useToast();
  const { mutateAsync } = useMutation(updateUserDetails, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.USERS.USER_FORM.USER_CHANGED_SUCCESS_MESSAGE'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('SETTINGS.USERS.USER_FORM.USER_CHANGED_FAILURE_MESSAGE'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    },
  });
  const queryClient = useQueryClient();
  const { push } = useHistory();

  const goBackLink = PATHS.SETTINGS.USERS.DETAILS.toPath({ userId });

  if (!data || isLoading || isFetching) {
    return (
      <Card>
        <CardTitleSection backButtonLink={goBackLink} width="30%">
          {<Skeleton height="1em" width="100%" />}
        </CardTitleSection>
        <Skeleton width="100%" minHeight="400px" />
      </Card>
    );
  }

  return (
    <Card>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {t('SETTINGS.USERS.USER_EDIT_TITLE')}
      </CardTitleSection>
      <UserForm
        variant="edit"
        initialValue={data}
        cancelButtonLink={goBackLink}
        onSubmit={(payload) =>
          mutateAsync(
            { userId, payload },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(createUserDetailsKey({ userId }));
                queryClient.invalidateQueries(createUserListKey());
                push(goBackLink);
              },
            },
          )
        }
      />
    </Card>
  );
};
