import { GetStockProductHistory, getStockProductHistory, stockLevelsToHistoricalRecord } from 'domain/stocks';
import { getCurrentDate } from 'utils/date';
import { useQuery } from 'react-query';
import { createProductStockDataKey } from 'constants/queryCacheKeys';
import { addDays, subDays } from 'date-fns';
import { pipe } from 'fp-ts/function';

export const useHistoricalProductStocks = (params: Omit<GetStockProductHistory, 'start_date' | 'end_date'>) => {
  const now = getCurrentDate();

  const queryParams = {
    ...params,
    start_date: subDays(now, 6),
    end_date: addDays(now, 1),
  };

  return useQuery(createProductStockDataKey(queryParams), () => getStockProductHistory(queryParams), {
    select: (data) => pipe(data, stockLevelsToHistoricalRecord),
  });
};
