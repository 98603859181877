import {
  parseISO,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachYearOfInterval,
  areIntervalsOverlapping,
} from 'date-fns';
import { pipe } from 'fp-ts/function';
import { mostReadable } from '@ctrl/tinycolor';

import {
  DATA_RESOLUTION,
  DEFAULT_DATE_OPTIONS,
  findMinimumDataResolution,
  Metric,
  Plot,
  Series,
  TDataResolution,
  TDateOptions,
} from 'domain/core';
import { TStore } from 'domain/stores';
import { CartesianCoordinate, ChartData, ChartDataEntry, ChartMetric, PolarCoordinate } from './types';
import { TStatisticsPlot } from 'domain/statistics';

export const xAccessor = <Data, Entry extends { x: Data }>(entry: Entry) => entry?.x;

export const yIndexAccessor =
  <Data, Entry extends { y: Data[] }>(index: number) =>
  (entry: Entry) =>
    entry.y[index];
export const nullAccessor = <Data, Entry extends { y: Data[] }>(_entry: Entry) => null;

export const createMetricDataKey = (index: number) => String(index);

export const getDataResolutionHelpers = (dataResolution: TDataResolution) => {
  switch (dataResolution) {
    case DATA_RESOLUTION.DAY:
      return {
        interval: eachDayOfInterval,
        end: endOfDay,
        start: startOfDay,
      };
    case DATA_RESOLUTION.WEEK:
      return {
        interval: eachWeekOfInterval,
        end: endOfWeek,
        start: startOfWeek,
      };
    case DATA_RESOLUTION.MONTH:
      return {
        interval: eachMonthOfInterval,
        end: endOfMonth,
        start: startOfMonth,
      };
    case DATA_RESOLUTION.QUARTER:
      return {
        interval: eachQuarterOfInterval,
        end: endOfQuarter,
        start: startOfQuarter,
      };
    case DATA_RESOLUTION.YEAR:
      return {
        interval: eachYearOfInterval,
        end: endOfYear,
        start: startOfYear,
      };
  }
};

const sumMetricSeries = (series: Series) =>
  series.reduce((acc, value) => (typeof value === 'number' ? (acc ?? 0) + value : acc), null);

export interface AggregateChartDataArgs {
  mergeStrategy?: 'sum' | 'average';
  plotDataResolution: TDataResolution;
  outputResolution: TDataResolution;
  dateOptions: TDateOptions;
}

export const aggregateChartData =
  <PlotType extends Pick<Plot | TStatisticsPlot, 'metrics' | 'date_from' | 'date_to'>>({
    mergeStrategy = 'sum',
    plotDataResolution,
    outputResolution,
    dateOptions,
  }: AggregateChartDataArgs) =>
  (plot: PlotType) => {
    const { metrics, date_from, date_to } = plot;

    const plotDateFrom = date_from instanceof Date ? date_from : parseISO(date_from);
    const plotDateTo = date_to instanceof Date ? date_to : parseISO(date_to);

    const {
      interval: getPlotInterval,
      start: getPlotIntervalStart,
      end: getPlotIntervalEnd,
    } = getDataResolutionHelpers(plotDataResolution);
    const plotInterval = getPlotInterval({ start: plotDateFrom, end: plotDateTo }, dateOptions);

    const {
      interval: getInterval,
      start: getIntervalStart,
      end: getIntervalEnd,
    } = getDataResolutionHelpers(outputResolution);
    const outputInterval = getInterval({ start: plotDateFrom, end: plotDateTo }, dateOptions);

    const metricsCount = metrics.length;
    const data: ChartDataEntry<Date>[] = [];

    for (let plotIdx = 0, outputIdx = 0, outputLen = outputInterval.length; outputIdx < outputLen; outputIdx++) {
      const currentInterval = {
        start: getIntervalStart(outputInterval[outputIdx], dateOptions),
        end: getIntervalEnd(outputInterval[outputIdx], dateOptions),
      };

      const temporaryY: Array<Array<number | null>> = Array.from({ length: metricsCount }).map(() => []);

      while (
        typeof plotInterval[plotIdx] !== 'undefined' &&
        areIntervalsOverlapping(
          currentInterval,
          {
            start: getPlotIntervalStart(plotInterval[plotIdx], dateOptions),
            end: getPlotIntervalEnd(plotInterval[plotIdx], dateOptions),
          },
          {
            inclusive: true,
          },
        )
      ) {
        for (let i = 0; i < metricsCount; i++) {
          temporaryY[i].push(metrics[i].series[plotIdx]);
        }

        plotIdx++;
      }

      data.push({
        x: currentInterval.start,
        y: temporaryY.map((metricValues) => {
          const sum = sumMetricSeries(metricValues);

          if (typeof sum === 'number' && mergeStrategy === 'average') {
            return sum / metricValues.length;
          }

          return sum;
        }),
      });
    }

    return data;
  };

export interface PlotToChartDataOptions {
  mergeStrategy?: 'sum' | 'average';
  resolution: TDataResolution;
  dateOptions: TDateOptions;
}

const PLOT_TO_CHART_DATA_DEFAULT_OPTIONS: PlotToChartDataOptions = {
  mergeStrategy: 'sum',
  resolution: DATA_RESOLUTION.DAY,
  dateOptions: DEFAULT_DATE_OPTIONS,
};

export const plotToChartData =
  (options?: Partial<PlotToChartDataOptions>) =>
  (plot: Plot): ChartData => {
    const { dateOptions, mergeStrategy = 'sum' } = { ...PLOT_TO_CHART_DATA_DEFAULT_OPTIONS, ...options };
    const { style, metrics } = plot;

    const chartMetrics: ChartMetric[] = metrics.map((m, index) => ({
      dataKey: createMetricDataKey(index),
      index,
      name: m.name,
    }));

    const plotDataResolution = plot.data_resolution ?? DATA_RESOLUTION.DAY;
    const outputResolution = findMinimumDataResolution({
      outputResolution: options?.resolution ?? DATA_RESOLUTION.DAY,
      plotDataResolution,
    });

    return {
      data: pipe(
        plot,
        aggregateChartData({
          plotDataResolution,
          outputResolution,
          dateOptions,
          mergeStrategy,
        }),
      ),
      plotDataResolution,
      dataResolution: outputResolution,
      type: style,
      chartMetrics,
    };
  };

export const labelChartDataStoreMetrics =
  <Store extends Pick<TStore, 'id' | 'name'>>(stores: Store[]) =>
  (chartData: ChartData): ChartData => ({
    ...chartData,
    chartMetrics: chartData.chartMetrics.map((m) => ({
      ...m,
      name: stores.find((s) => s.id === m.name)?.name ?? m.name,
    })),
  });

interface MergePlotMetricsOptions {
  mergedMetricName: string;
  mergeStrategy?: 'sum' | 'average';
}

export const mergePlotMetrics =
  <PlotType extends Plot>(opts: MergePlotMetricsOptions) =>
  (plot: PlotType): PlotType => {
    const mergeStrategy = opts.mergeStrategy ?? 'sum';
    const metricsCount = plot.metrics.length;
    const seriesLength = plot.metrics[0]?.series.length ?? 0;
    const mergedSeries = Array.from({ length: seriesLength }).fill(() => null) as Array<number | null>;

    for (let i = 0; i < seriesLength; i++) {
      let sum: number | null = null;
      for (let j = 0; j < metricsCount; j++) {
        const metricValue = plot.metrics[j].series[i];

        if (typeof metricValue === 'number') {
          sum = (sum ?? 0) + metricValue;
        }
      }

      if (mergeStrategy === 'average' && typeof sum === 'number') {
        mergedSeries[i] = sum / metricsCount;
      } else {
        mergedSeries[i] = sum;
      }
    }

    return {
      ...plot,
      metrics: [
        {
          name: opts.mergedMetricName,
          series: mergedSeries,
        },
      ],
    };
  };

export const transformPlotMetrics =
  <PlotType extends Plot>(transformFn: (series: Metric, idx?: number) => Metric) =>
  (plot: PlotType): PlotType => ({
    ...plot,
    metrics: plot.metrics.map((m, idx) => transformFn(m, idx)),
  });

export const polarToCartesian = ({ radius, angle }: PolarCoordinate): CartesianCoordinate => ({
  x: radius * Math.cos(angle),
  y: radius * Math.sin(angle),
});

export const getMostReadableColor = (color: string) =>
  mostReadable(
    color,
    ['#F7FAFC', '#EDF2F7', '#E2E8F0', '#CBD5E0', '#A0AEC0', '#718096', '#4A5568', '#2D3748', '#1A202C', '#171923'],
    {
      includeFallbackColors: true,
      level: 'AA',
      size: 'small',
    },
  )?.toHexString();
