import { createGroupedOrdersKey } from 'constants/queryCacheKeys';
import { getGroupedOrders, OrdersParams } from 'domain/predictions';
import { useUserStoreIds } from 'hooks';
import { useUserSections } from 'hooks/useUserContext';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { useQuery } from 'react-query';

export const useGroupedOrders = () => {
  const userStores = useUserStoreIds();
  const userSections = useUserSections();

  const params: OrdersParams = {
    store_ids: userStores,
    section_ids: pipe(
      userSections,
      A.map(({ id }) => id),
    ),
  };

  return useQuery(createGroupedOrdersKey(params), () => getGroupedOrders(params), { refetchOnMount: 'always' });
};
