import { TStockData, TStocksData } from 'domain/stocks';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { createReducerContext } from 'react-use';

export type StockStateValue = Omit<TStockData, 'product_code'> & { changed: boolean | undefined };

export type StockState = Record<string, StockStateValue>;

export const SET_STORE_STOCK = 'SET_STORE_STOCK';

export const setStoreStock = (stocks: TStocksData) => ({
  payload: stocks,
  type: SET_STORE_STOCK,
});

const matchSetStoreStock = (action: unknown): action is ReturnType<typeof setStoreStock> =>
  (action as { type: string }).type === SET_STORE_STOCK;

const UPDATE_STORE_STOCK = 'UPDATE_STORE_STOCK';

export const updateStoreStock = (stocks: TStocksData) => ({
  payload: stocks,
  type: UPDATE_STORE_STOCK,
});

const matchUpdateStoreStock = (action: unknown): action is ReturnType<typeof updateStoreStock> =>
  (action as { type: string }).type === UPDATE_STORE_STOCK;

const UPDATE_STOCK = 'UPDATE_STOCK';

export const updateStock = (
  params: [product_code: string, params: Partial<Pick<TStockData, 'start_of_day' | 'wasted' | 'stolen'>>],
) => ({
  type: UPDATE_STOCK,
  payload: params,
});

const matchUpdateStock = (action: unknown): action is ReturnType<typeof updateStock> =>
  (action as { type: string }).type === UPDATE_STOCK;

const DELETE_STOCK = 'DELETE_STOCK';

export const deleteProductStock = (productCode: string) => ({
  type: DELETE_STOCK,
  payload: productCode,
});

const matchDeleteStock = (action: unknown): action is ReturnType<typeof deleteProductStock> =>
  (action as { type: string }).type === DELETE_STOCK;

function stockReducer(state: StockState, action: unknown): StockState {
  if (matchUpdateStoreStock(action)) {
    return {
      ...pipe(
        action.payload,
        A.map(({ product_code, ...rest }) => [product_code, rest]),
        (data) => Object.fromEntries(data),
      ),
      ...state,
    };
  }

  //NOTE(malysad): redundant code, but it's left in case of bussines needs
  if (matchSetStoreStock(action)) {
    return {
      ...pipe(
        action.payload,
        A.map(({ product_code, ...rest }) => [product_code, rest]),
        (data) => Object.fromEntries(data),
      ),
    };
  }

  if (matchUpdateStock(action)) {
    const [product_code, params] = action.payload;
    return {
      ...state,
      [product_code]: {
        ...state[product_code],
        ...params,
        changed: true,
      },
    };
  }

  if (matchDeleteStock(action)) {
    const newState = { ...state };
    delete newState[action.payload];
    return newState;
  }

  return state;
}

export const [useStocksReducer, StocksReducerProvider] = createReducerContext(stockReducer, {});
