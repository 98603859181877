import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export type CardProps = BoxProps;

export const Card: React.FC<CardProps> = ({ children, ...rest }) => (
  <Box
    bg="white"
    borderRadius="base"
    border="1px"
    borderColor="gray.200"
    boxShadow="base"
    p="3"
    flexGrow={1}
    width="100%"
    height="100%"
    {...rest}
  >
    {children}
  </Box>
);
