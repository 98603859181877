import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SlotsProvider } from 'nano-slots';

import { AppConfigProvider } from 'components/Contexts';
import { chakraTheme } from 'components/Theme';

import 'locales/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 10 * 60 * 1000,
      useErrorBoundary: true,
    },
  },
});

export const AppProviders: React.FC = ({ children }) => {
  const { t } = useTranslation('whisperme');

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet title={t('GENERIC.DEFAULT_PAGE_TITLE')} titleTemplate={t('GENERIC.PAGE_TITLE_TEMPLATE')} />
        <BrowserRouter>
          <ChakraProvider resetCSS={true} theme={chakraTheme}>
            <AppConfigProvider>
              <SlotsProvider>{children}</SlotsProvider>
            </AppConfigProvider>
          </ChakraProvider>
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  );
};
