import { createMachine, assign, send } from 'xstate';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { TProductsMachineContext, TProductsMachineEvent, TProductsMachineState, EProductsMachineState } from './types';

export const productsListMachine = createMachine<TProductsMachineContext, TProductsMachineEvent, TProductsMachineState>(
  {
    id: 'products-list-machine',
    initial: EProductsMachineState.INIT,
    context: {
      products: [],
      productFamilies: [],
      selectedProductCodes: new Set(),
      productEditValues: new Map(),
      productEditErrors: new Map(),
    },
    states: {
      [EProductsMachineState.INIT]: {},
      [EProductsMachineState.VIEW]: {
        on: {
          PRODUCT_SELECTION_NONE: {
            actions: 'productSelectionEmpty',
          },
          PRODUCT_SELECTION_ALL: {
            actions: 'productSelectionAll',
          },
          PRODUCT_SELECTION_TOGGLE: {
            actions: 'productSelectionToggle',
          },
          EDIT: [
            {
              target: EProductsMachineState.EDIT_SINGLE,
              actions: 'setSingleEditValue',
              cond: (ctx, ev) =>
                ctx.selectedProductCodes.size === 0 ||
                (ctx.selectedProductCodes.size === 1 && ctx.selectedProductCodes.has(ev.productCode)),
            },
            {
              target: EProductsMachineState.EDIT_MULTIPLE,
              actions: (_ctx, ev) => send({ type: 'PRODUCT_SELECTION_ADD', productCode: ev.productCode }),
            },
          ],
        },
      },
      [EProductsMachineState.EDIT_SINGLE]: {
        exit: ['clearEditValues'],
        initial: 'idle',
        states: {
          idle: {
            on: {
              CANCEL: {
                target: 'done',
              },
              SET_EDIT_VALUE: {
                actions: ['updateEditPayload', 'validateEditValues'],
              },
              SAVE: {
                target: 'saving',
                cond: 'noEditErrors',
              },
            },
          },
          saving: {
            invoke: {
              id: 'saveProducts-single',
              src: 'saveProducts',
              onDone: { target: 'done' },
              onError: { target: 'idle' },
            },
          },
          done: { type: 'final' },
        },
        onDone: {
          target: EProductsMachineState.VIEW,
        },
      },
      [EProductsMachineState.EDIT_MULTIPLE]: {
        entry: ['updateEditValues'],
        exit: ['clearEditValues'],
        initial: 'idle',
        states: {
          idle: {
            on: {
              CANCEL: {
                target: 'done',
              },
              PRODUCT_SELECTION_NONE: {
                target: 'done',
                actions: 'productSelectionEmpty',
              },
              PRODUCT_SELECTION_ALL: {
                actions: ['productSelectionAll', 'updateEditValues'],
              },
              PRODUCT_SELECTION_TOGGLE: [
                {
                  actions: [send('PRODUCT_SELECTION_NONE')],
                  cond: (ctx, ev) =>
                    ctx.selectedProductCodes.size === 1 && ctx.selectedProductCodes.has(ev.productCode),
                },
                {
                  actions: ['productSelectionToggle', 'updateEditValues'],
                },
              ],
              SET_EDIT_VALUE: {
                actions: ['updateEditPayload', 'updateEditValuesFamilyCodes', 'validateEditValues'],
              },
              SAVE: {
                target: 'saving',
                cond: 'noEditErrors',
              },
            },
          },
          saving: {
            invoke: {
              id: 'saveProducts-multiple',
              src: 'saveProducts',
              onDone: {
                actions: 'productSelectionEmpty',
                target: 'done',
              },
              onError: { target: 'idle' },
            },
          },
          done: { type: 'final' },
        },
        onDone: {
          target: EProductsMachineState.VIEW,
        },
      },
    },
    on: {
      SET_PRODUCTS: {
        actions: 'setProducts',
        target: EProductsMachineState.VIEW,
      },
      SET_PRODUCT_FAMILIES: {
        actions: 'setProductsFamilies',
      },
      PRODUCT_SELECTION_ADD: {
        actions: 'productSelectionAdd',
      },
    },
  },
  {
    actions: {
      setProducts: assign((ctx, ev) => {
        if (ev.type !== 'SET_PRODUCTS') {
          return ctx;
        }

        const { products } = ev;
        const existingProducts = new Set(products.map((p) => p.product_code));

        return {
          products,
          selectedProductCodes: new Set(Array.from(ctx.selectedProductCodes).filter((pc) => existingProducts.has(pc))),
        };
      }),
      setProductsFamilies: assign((ctx, ev) => {
        if (ev.type !== 'SET_PRODUCT_FAMILIES') {
          return ctx;
        }

        return {
          productFamilies: ev.productFamilies,
        };
      }),
      productSelectionEmpty: assign((_ctx, _ev) => {
        return {
          selectedProductCodes: new Set(),
        };
      }),
      productSelectionAll: assign((ctx, _ev) => {
        return {
          selectedProductCodes: new Set(ctx.products.map((p) => p.product_code)),
        };
      }),
      productSelectionToggle: assign((ctx, ev) => {
        if (ev.type !== 'PRODUCT_SELECTION_TOGGLE') {
          return ctx;
        }

        const selectedProductCodes = new Set(ctx.selectedProductCodes);
        const { productCode } = ev;
        if (selectedProductCodes.has(productCode)) {
          selectedProductCodes.delete(productCode);
        } else {
          selectedProductCodes.add(productCode);
        }

        return {
          selectedProductCodes,
        };
      }),
      productSelectionAdd: assign((ctx, ev) => {
        if (ev.type !== 'PRODUCT_SELECTION_ADD') {
          return ctx;
        }

        const newSelectedProductCodes = new Set(ctx.selectedProductCodes);
        newSelectedProductCodes.add(ev.productCode);

        return {
          selectedProductCodes: newSelectedProductCodes,
        };
      }),
      setSingleEditValue: assign((ctx, ev) => {
        if (ev.type !== 'EDIT') {
          return ctx;
        }

        const product = ctx.products.find((p) => p.product_code === ev.productCode);

        if (!product) {
          return ctx;
        }

        const newProductEditValues: TProductsMachineContext['productEditValues'] = new Map();
        newProductEditValues.set(product.product_code, {
          ...product,
          rootFamilyCode: pipe(
            ctx.productFamilies,
            A.findFirst((pf) => pf.id === product.family_code),
            O.chain((family) => family.parent_id),
            O.toUndefined,
          ),
        });

        return {
          productEditValues: newProductEditValues,
          selectedProductCodes: new Set(),
        };
      }),
      updateEditValues: assign((ctx) => {
        const productCodes = Array.from(ctx.selectedProductCodes);
        const previousValues = ctx.productEditValues;

        const newProductEditValues: TProductsMachineContext['productEditValues'] = new Map();

        productCodes.forEach((code) => {
          const prev = previousValues.get(code);

          if (prev) {
            newProductEditValues.set(code, { ...prev });
          } else {
            const product = ctx.products.find((p) => p.product_code === code);
            newProductEditValues.set(code, {
              ...product,
              rootFamilyCode: pipe(
                ctx.productFamilies,
                A.findFirst((pf) => pf.id === product?.family_code),
                O.chain((family) => family.parent_id),
                O.toUndefined,
              ),
            });
          }
        });

        return {
          productEditValues: newProductEditValues,
        };
      }),
      clearEditValues: assign((_ctx, _ev) => ({
        productEditValues: new Map(),
        productEditErrors: new Map(),
      })),
      updateEditPayload: assign((ctx, ev) => {
        if (ev.type !== 'SET_EDIT_VALUE') {
          return ctx;
        }

        const newProductEditValues = new Map(ctx.productEditValues);
        newProductEditValues.set(ev.productCode, ev.payload);

        return {
          productEditValues: newProductEditValues,
        };
      }),
      updateEditValuesFamilyCodes: assign((ctx, ev) => {
        if (ev.type !== 'SET_EDIT_VALUE') {
          return ctx;
        }

        const newProductEditValues = new Map(ctx.productEditValues);
        const { family_code, rootFamilyCode } = ev.payload;

        Array.from(newProductEditValues.entries()).forEach(([id, p]) => {
          newProductEditValues.set(id, { ...p, family_code, rootFamilyCode });
        });

        return {
          productEditValues: newProductEditValues,
        };
      }),
    },
    guards: {
      noEditErrors: (ctx, _ev) => ctx.productEditErrors.size === 0,
    },
  },
);
