import { API_BASE_URL } from 'constants/env';
import { TProduct } from 'domain/products';
import { TStoreId } from 'domain/stores';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { dateToYMD } from 'utils/date';
import {
  createValidatedCancellableFetch,
  getExportLink,
  makeCancellableFetch,
  urlWithQueryParams,
} from 'utils/request';
import {
  Commons,
  DeleteStocksForProduct,
  GetStockExportLink,
  GetStockProductHistory,
  GetStocksForStore,
  SaveStocksForStore,
  StocksDataListValidator,
  StocksPointsParams,
  StockPointsListValidator,
  ShortageProductPointsParams,
  ShortageProductsListParams,
  ShortageProductsListValidator,
  ShortageProductPointsListValidator,
  AssortmentAnalysisListValidator,
  IndividualShortagesPointsValidator,
  IndividualShortagesChartPointsParams,
} from './types';

const API_URLS = {
  assortmentAnalysisList: (store_id: string) => `${API_BASE_URL}/stocks/${store_id}/assortment`,
  stocksForStore: (store_id: string) => `${API_BASE_URL}/stocks/${store_id}`,
  stocksProductHistory: (store_id: string, product_code: string) =>
    `${API_BASE_URL}/stocks/${store_id}/history/${product_code}`,
  deleteStocksForStore: (store_id: string, product_code: string) =>
    `${API_BASE_URL}/stocks/${store_id}/${product_code}`,
  addNewStocks: () => `${API_BASE_URL}/stocks/add_many`,
  stockPoints: () => `${API_BASE_URL}/stock_points`,
  productShortage: () => `${API_BASE_URL}/stocks/shortage`,
  shortageProductPoints: () => `${API_BASE_URL}/stock_shortage_points`,
  individualShortagesPoints: () => `${API_BASE_URL}/stock_points/availability`,
  shortageProductsList: () => `${API_BASE_URL}/stock_points/shortage`,
};

export const getAssortmentAnalysisList = ({ store_id }: Commons) =>
  pipe(API_URLS.assortmentAnalysisList(store_id), createValidatedCancellableFetch(AssortmentAnalysisListValidator));

export const getStocksForStore = ({ store_id, ...queryParams }: GetStocksForStore) =>
  pipe(
    API_URLS.stocksForStore(store_id),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(StocksDataListValidator),
  );

export const getShortageProductPoints = ({ ...queryParams }: ShortageProductPointsParams) =>
  pipe(
    API_URLS.shortageProductPoints(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(ShortageProductPointsListValidator),
  );

export const getShortageProductList = ({ ...queryParams }: ShortageProductsListParams) =>
  pipe(
    API_URLS.shortageProductsList(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(ShortageProductsListValidator),
  );

export const getIndividualShortagesPoints = ({ ...queryParams }: IndividualShortagesChartPointsParams) =>
  pipe(
    API_URLS.individualShortagesPoints(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(IndividualShortagesPointsValidator),
  );

export const saveStocksForStore = ({ store_id, data }: SaveStocksForStore) =>
  makeCancellableFetch(API_URLS.stocksForStore(store_id), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

export const getStockExportLink = ({ store_id, start_date, end_date, ...queryParams }: GetStockExportLink) =>
  pipe(
    API_URLS.stocksForStore(store_id),
    getExportLink(),
    urlWithQueryParams({ start_date: dateToYMD(start_date), end_date: dateToYMD(end_date), ...queryParams }),
  );

export const getStockProductHistory = ({ store_id, product_code, ...queryParams }: GetStockProductHistory) =>
  pipe(
    API_URLS.stocksProductHistory(store_id, String(product_code)),
    urlWithQueryParams({ start_date: dateToYMD(queryParams.start_date), end_date: dateToYMD(queryParams.end_date) }),
    createValidatedCancellableFetch(StocksDataListValidator),
  );

export const deleteStocksForStore = ({ store_id, product_code }: DeleteStocksForProduct) =>
  pipe(API_URLS.deleteStocksForStore(store_id, String(product_code)), (url: string) =>
    makeCancellableFetch(url, { method: 'DELETE' }),
  );

export const addNewStocks = ({
  product_ids,
  store_id,
}: {
  product_ids: TProduct['product_code'][];
  store_id: TStoreId;
}) =>
  pipe(API_URLS.stocksForStore(store_id), (url) =>
    makeCancellableFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: pipe(
        product_ids,
        A.map((id) => [id, [undefined, undefined, 0]]),
        (data) => JSON.stringify(Object.fromEntries(data)),
      ),
    }),
  );

export const getStockPoints = ({ ...queryParams }: StocksPointsParams) =>
  pipe(
    API_URLS.stockPoints(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(StockPointsListValidator),
  );
