import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Button,
  Center,
  Input,
  VStack,
  Text,
  Select,
  Switch,
  HStack,
  Flex,
  useToast,
  FormLabel,
} from '@chakra-ui/react';
import { DateRangePicker } from 'components/DateRangePicker';
import { SearchIcon } from '@chakra-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { useMachine } from '@xstate/react';
import { assign } from 'xstate';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { subDays } from 'date-fns';
import { dateToYMD, getCurrentDate } from 'utils/date';

import { useInfiniteProductList } from './useInfiniteProductList';
import { TProductListFiltersState, useProductListFilters } from './useProductListFilters';
import { PageSpinnerOverlay } from 'components/Spinners';
import { ProductFamiliesStats } from './ProductFamiliesStats';
import { byName } from 'utils/sort';
import { EProductListFilterActionType, EProductsMachineState } from './types';
import { itemNameFormatter, parseFamilyCodeValue } from './utils';
import { ProductFamiliesTable } from './ProductFamiliesTable';
import { ProductFamiliesTableHeaderGroup } from './ProductFamiliesTableHeaderGroup';
import { ProductFamiliesFamilyCodeSelect } from './ProductFamiliesFamilyCodeSelect';
import { ProductFamiliesListItem } from './ProductFamiliesListItem';
import { CSS_VARIABLES } from 'constants/css';
import { productsListMachine } from './productsMachine';
import { createProducts, TGetProductListParams, TNewProduct, TNewProducts, TProduct } from 'domain/products';
import { ProductFamiliesListEditItem } from './ProductFamiliesListEditItem';
import { createProductsListKey } from 'constants/queryCacheKeys';
import { useFieldValidators } from './useFieldValidators';
import { ProductFamiliesDeleteConfirmationModal } from './ProductFamiliesDeleteConfirmationModal';
import { useProductFamiliesMaps } from 'hooks/data';
import { useAppConfig, useCalendarDateRange, useUserContext, useUserSections } from 'hooks';
import { hasAdminAccess } from 'domain/user';
import { ExportLink } from 'components/ExportLink';
import { getProductFamiliesCSVLink } from 'domain/products/service';

// FIXME(r.leykam): refactor
// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProductFamiliesList: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { language } = useAppConfig();
  const toast = useToast();
  const { user } = useUserContext();
  const { pfMap, allSubfamilies, productFamilies } = useProductFamiliesMaps();
  const [productToDelete, setProductToDelete] = useState<TProduct | null>(null);
  const onModalClose = useCallback(() => {
    setProductToDelete(null);
  }, []);
  const [params, dispatch] = useProductListFilters();
  const { calendarProps, dateParams } = useCalendarDateRange({
    initialStartDate: subDays(getCurrentDate(), 7),
    initialEndDate: getCurrentDate(),
  });
  const isAdmin = hasAdminAccess(user);
  const queryClient = useQueryClient();
  const userRootFamilies = useUserSections();
  const authSections = userRootFamilies.map((section) => section.id);
  const filteredParams = useMemo((): TGetProductListParams => {
    const paramsSections = authSections.length === 1 ? { root_family_code: authSections[0] } : {};
    let paramsData: TGetProductListParams & TProductListFiltersState = { ...params, ...paramsSections };

    if (paramsData.use_date) {
      paramsData = {
        ...paramsData,
        start_date: dateToYMD(dateParams.startDate),
        end_date: dateParams.endDate ? dateToYMD(dateParams.endDate) : undefined,
      };
    }
    delete paramsData.use_date;

    return paramsData;
  }, [params, authSections, dateParams]);

  const { mutateAsync: saveAsync, status: saveStatus } = useMutation(createProducts, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(createProductsListKey());
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_SUCCESS_TEXT'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_FAIL_TEXT'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const fieldValidators = useFieldValidators();

  const userSubFamiliesList = userRootFamilies.flatMap((section) => section.children_ids);
  const userSubfamilies = allSubfamilies.filter((family) => userSubFamiliesList.includes(family.id));

  const filteredSubfamilies = useMemo(() => {
    let filteredSubfamilies = userSubfamilies;

    if (filteredParams.root_family_code) {
      filteredSubfamilies = pipe(
        userSubfamilies,

        A.filter((s) =>
          pipe(
            s.parent_id,
            O.fold(
              () => false,
              (id) => id === filteredParams.root_family_code,
            ),
          ),
        ),
      );
    }
    //FIXME(r.leykam): Should sort by N.Ord
    return pipe(filteredSubfamilies, A.sort(byName));
  }, [userSubfamilies, filteredParams.root_family_code]);

  const {
    onChangeRootFamilyCode,
    onChangeFamilyCode,
    onChangeRegional,
    onChangeUseDate,
    onChangeMinimumStock,
    onChangeWeekendProduct,
    onChangeAlwaysAvailble,
  } = useMemo(
    () => ({
      onChangeRootFamilyCode: (ev: React.ChangeEvent<HTMLSelectElement>) => {
        const payload = parseFamilyCodeValue(ev.target.value);
        dispatch({ type: EProductListFilterActionType.ROOT_FAMILY_CODE, payload });
      },
      onChangeFamilyCode: (ev: React.ChangeEvent<HTMLSelectElement>) => {
        const payload = parseFamilyCodeValue(ev.target.value);
        dispatch({ type: EProductListFilterActionType.FAMILY_CODE, payload });
      },
      onChangeRegional: (ev: React.ChangeEvent<HTMLSelectElement>) => {
        let payload: boolean | undefined;

        switch (ev.target.value) {
          case 'true':
            payload = true;
            break;
          case 'false':
            payload = false;
            break;
        }

        dispatch({ type: EProductListFilterActionType.IS_REGIONAL, payload });
      },
      onChangeUseDate: (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.preventDefault();

        dispatch({ type: EProductListFilterActionType.USE_DATE, payload: ev.target.checked });
      },
      onChangeMinimumStock: (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.preventDefault();
        dispatch({ type: EProductListFilterActionType.MINIMUM_STOCK, payload: ev.target.checked });
      },
      onChangeWeekendProduct: (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.preventDefault();
        dispatch({ type: EProductListFilterActionType.WEEKEND_PRODUCT, payload: ev.target.checked });
      },
      onChangeAlwaysAvailble: (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.preventDefault();

        dispatch({ type: EProductListFilterActionType.ALWAYS_AVAILABLE, payload: ev.target.checked });
      },
    }),
    [dispatch],
  );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isPreviousData, status } =
    useInfiniteProductList(filteredParams);

  const [state, send] = useMachine(productsListMachine, {
    actions: {
      validateEditValues: assign((ctx, _ev) => {
        const newProductEditErrors: typeof ctx.productEditErrors = new Map();

        Array.from(ctx.productEditValues.entries()).forEach(([key, value]) => {
          const nameError = fieldValidators.productName.validate(String(value.description ?? ''));
          const familyCodeError = fieldValidators.familyCode.validate(String(value.family_code ?? ''));

          if (typeof nameError !== 'undefined' || typeof familyCodeError !== 'undefined') {
            newProductEditErrors.set(key, {
              description: nameError,
              family_code: familyCodeError,
            });
          }
        });

        return {
          productEditErrors: newProductEditErrors,
        };
      }),
    },
    services: {
      saveProducts: (ctx) => {
        const products = Array.from(ctx.productEditValues.values()).reduce((acc, currentProduct) => {
          const {
            description,
            family_code,
            is_regional,
            product_code,
            minimum_stock,
            weekend_product,
            always_available,
            supplier_id,
          } = currentProduct;
          const maybeFamilyCode = parseFamilyCodeValue(String(family_code));

          if (
            description &&
            typeof maybeFamilyCode !== 'undefined' &&
            typeof is_regional !== 'undefined' &&
            typeof product_code !== 'undefined' &&
            typeof minimum_stock !== 'undefined' &&
            typeof weekend_product !== 'undefined' &&
            typeof always_available !== 'undefined' &&
            typeof supplier_id !== 'undefined'
          ) {
            return [
              ...acc,
              {
                description,
                family_code: maybeFamilyCode,
                is_regional,
                product_code,
                minimum_stock,
                weekend_product,
                always_available,
                supplier_id,
              } as TNewProduct,
            ];
          }

          return acc;
        }, [] as TNewProducts);

        return saveAsync({ products });
      },
    },
  });
  const { products, selectedProductCodes, productEditValues, productEditErrors } = state.context;

  useLayoutEffect(() => {
    send({
      type: 'SET_PRODUCT_FAMILIES',
      productFamilies,
    });
  }, [productFamilies, send]);

  useLayoutEffect(() => {
    send({
      type: 'SET_PRODUCTS',
      products: data?.pages.reduce((acc, page) => [...acc, ...page.data], [] as TProduct[]) ?? [],
    });
  }, [data, send]);

  const checkedProductsCount = selectedProductCodes.size;
  const hasProducts = products.length > 0;
  const allChecked = hasProducts && checkedProductsCount === products.length;
  const someChecked = hasProducts && !allChecked && checkedProductsCount > 0;
  const isViewMode = state.matches(EProductsMachineState.VIEW);
  const isCheckboxDisabled = !(
    state.matches(EProductsMachineState.VIEW) || state.matches(EProductsMachineState.EDIT_MULTIPLE)
  );
  const isRequestPending = saveStatus === 'loading';

  return (
    <>
      <Box m="auto" my={3} textAlign="center">
        <ExportLink
          href={getProductFamiliesCSVLink({ ...filteredParams, lang: language })}
          fileName="product-famililes.xlsx"
          aria-label={t('SETTINGS.PRODUCT_FAMILIES.XLS_EXPORT_LABEL')}
        />
      </Box>
      <ProductFamiliesStats />
      <Box minHeight="450px">
        <ProductFamiliesTable>
          <ProductFamiliesTableHeaderGroup
            position="sticky"
            top={`calc(var(${CSS_VARIABLES.TOPBAR.HEIGHT}, 72px) + 28px)`}
            zIndex={1}
            shadow="sm"
          >
            <div role="row">
              <div role="columnheader"></div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.EAN_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_NAME_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.FAMILY_CODE_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.FAMILY_NAME_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.MINIMUM_STOCK_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.SUPPLIER_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.CREATION_DATE_LABEL')}</div>

              <Box role="columnheader">
                {isAdmin ? (
                  <Flex justifyContent="flex-end">{t('SETTINGS.PRODUCT_FAMILIES.LIST_ACTIONS_LABEL')}</Flex>
                ) : null}
              </Box>
            </div>
            <div role="row">
              <div role="columnheader">
                <Checkbox
                  bgColor="#fff"
                  isChecked={allChecked}
                  isIndeterminate={someChecked}
                  disabled={isCheckboxDisabled}
                  onChange={() => {
                    send({ type: allChecked ? 'PRODUCT_SELECTION_NONE' : 'PRODUCT_SELECTION_ALL' });
                  }}
                />
              </div>
              <div role="columnheader">
                <Input
                  name="ean-filter"
                  size="xs"
                  variant="outline"
                  maxWidth="120px"
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.EAN_FILTER_PLACEHOLDER')}
                  disabled={!isViewMode}
                  onChange={(ev) => {
                    dispatch({ type: EProductListFilterActionType.EAN, payload: ev.target.value });
                  }}
                />
              </div>
              <div role="columnheader">
                <Input
                  name="product-name-filter"
                  type="text"
                  size="xs"
                  variant="outline"
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_NAME_FILTER_PLACEHOLDER')}
                  disabled={!isViewMode}
                  onChange={(ev) => {
                    dispatch({ type: EProductListFilterActionType.PRODUCT_NAME, payload: ev.target.value });
                  }}
                />
              </div>
              <div role="columnheader">
                <ProductFamiliesFamilyCodeSelect
                  name="subfamily-code-filter"
                  value={String(filteredParams.family_code ?? '')}
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.FAMILY_CODE_FILTER_PLACEHOLDER')}
                  onChange={onChangeFamilyCode}
                  items={filteredSubfamilies}
                  disabled={!isViewMode}
                />
              </div>
              <div role="columnheader">
                <ProductFamiliesFamilyCodeSelect
                  name="family-code-filter"
                  value={String(filteredParams.root_family_code ?? '')}
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_FILTER_PLACEHOLDER')}
                  onChange={onChangeRootFamilyCode}
                  items={userRootFamilies}
                  formatLabel={itemNameFormatter}
                  disabled={!isViewMode}
                />
              </div>
              <div role="columnheader">
                <ProductFamiliesFamilyCodeSelect
                  name="subfamily-name-filter"
                  value={String(filteredParams.family_code ?? '')}
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.FAMILY_NAME_FILTER_PLACEHOLDER')}
                  onChange={onChangeFamilyCode}
                  items={filteredSubfamilies}
                  formatLabel={itemNameFormatter}
                  disabled={!isViewMode}
                />
              </div>
              <div role="columnheader">
                <Select
                  name="regional-filter"
                  value={String(filteredParams.is_regional ?? '')}
                  size="xs"
                  variant="outline"
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_PLACEHOLDER')}
                  onChange={onChangeRegional}
                  disabled={!isViewMode}
                >
                  <option value="true">{t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_TRUE_LABEL')}</option>
                  <option value="false">{t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_FALSE_LABEL')}</option>
                </Select>
              </div>
              <div role="columnheader">
                <HStack>
                  <FormLabel htmlFor="minimumStockSwitch" textAlign="center" fontSize="xs" mb={0} marginInlineEnd={0}>
                    {t('SETTINGS.PRODUCT_FAMILIES.USE_FILTER_LABEL')}
                  </FormLabel>
                  <Switch
                    size="sm"
                    id="minimumStockSwitchSwitch"
                    checked={params.minimum_stock}
                    onChange={onChangeMinimumStock}
                  />
                </HStack>
              </div>
              <div role="columnheader">
                <HStack>
                  <FormLabel
                    htmlFor="isWeekendProductSwitch"
                    textAlign="center"
                    fontSize="xs"
                    mb={0}
                    marginInlineEnd={0}
                  >
                    {t('SETTINGS.PRODUCT_FAMILIES.USE_FILTER_LABEL')}
                  </FormLabel>
                  <Switch
                    size="sm"
                    id="isWeekendProductSwitch"
                    checked={params.weekend_product}
                    onChange={onChangeWeekendProduct}
                  />
                </HStack>
              </div>
              <div role="columnheader">
                <HStack>
                  <FormLabel
                    htmlFor="isAlwaysAvailableSwitch"
                    textAlign="center"
                    fontSize="xs"
                    mb={0}
                    marginInlineEnd={0}
                  >
                    {t('SETTINGS.PRODUCT_FAMILIES.USE_FILTER_LABEL')}
                  </FormLabel>
                  <Switch
                    size="sm"
                    id="isAlwaysAvailableSwitch"
                    checked={params.always_available}
                    onChange={onChangeAlwaysAvailble}
                  />
                </HStack>
              </div>
              <div role="columnheader">
                <Input
                  name="supplier_name-filter"
                  type="text"
                  size="xs"
                  variant="outline"
                  placeholder={t('SETTINGS.PRODUCT_FAMILIES.SUPPLIER_PLACEHOLDER')}
                  disabled={!isViewMode}
                  onChange={(ev) => {
                    dispatch({ type: EProductListFilterActionType.SUPPLIER_NAME, payload: ev.target.value });
                  }}
                />
              </div>
              <div role="columnheader">
                <HStack>
                  <FormLabel htmlFor="useDate" textAlign="center" fontSize="xs" mb={0} marginInlineEnd={0}>
                    {t('SETTINGS.PRODUCT_FAMILIES.USE_DATE_LABEL')}
                  </FormLabel>
                  <Switch size="sm" id="useDate" checked={params.use_date} onChange={onChangeUseDate} />
                  <div style={{ width: '2rem' }} />
                  <DateRangePicker {...calendarProps} size="xs" />
                </HStack>
              </div>
              <div role="columnheader" />
            </div>
          </ProductFamiliesTableHeaderGroup>
          <Box
            as="ul"
            role="rowgroup"
            sx={{
              display: 'table-row-group',
              position: 'relative',

              '& [role="row"]': {
                display: 'table-row',

                _hover: {
                  bg: 'gray.50',
                },
              },

              '& [role="gridcell"]': {
                display: 'table-cell',
                textTransform: 'uppercase',
              },
            }}
          >
            {products.map((p) => {
              const onCheckedChange = () => {
                send({ type: 'PRODUCT_SELECTION_TOGGLE', productCode: p.product_code });
              };

              const editValue = productEditValues.get(p.product_code);
              if (editValue) {
                return (
                  <ProductFamiliesListEditItem
                    as="li"
                    key={p.product_code}
                    role="row"
                    product={editValue}
                    checked={selectedProductCodes.has(p.product_code)}
                    onCheckedChange={onCheckedChange}
                    isCheckboxDisabled={isCheckboxDisabled}
                    variant={state.matches(EProductsMachineState.EDIT_SINGLE) ? 'single' : 'multiple'}
                    allSubfamilies={filteredSubfamilies}
                    productFamilies={productFamilies}
                    rootFamilies={userRootFamilies}
                    validationErrors={productEditErrors.get(p.product_code)}
                    onChange={(payload) => {
                      send({ type: 'SET_EDIT_VALUE', productCode: p.product_code, payload });
                    }}
                  >
                    <HStack justifyContent="flex-end">
                      <Button
                        size="xs"
                        colorScheme="red"
                        variant="ghost"
                        disabled={isRequestPending}
                        onClick={() => {
                          send({ type: 'CANCEL' });
                        }}
                      >
                        {t('SETTINGS.PRODUCT_FAMILIES.CANCEL_BUTTON_LABEL')}
                      </Button>
                      <Button
                        size="xs"
                        variant="ghost"
                        isLoading={saveStatus === 'loading'}
                        onClick={() => {
                          send({ type: 'SAVE' });
                        }}
                      >
                        {t('SETTINGS.PRODUCT_FAMILIES.SAVE_BUTTON_LABEL')}
                      </Button>
                    </HStack>
                  </ProductFamiliesListEditItem>
                );
              }

              const family = pipe(pfMap.get(p.family_code), O.fromNullable);
              const rootFamily = pipe(
                family,
                O.chain((f) => f.parent_id),
                O.chain((id) => {
                  const root = pfMap.get(id);

                  return root ? O.some(root) : O.none;
                }),
              );

              return (
                <ProductFamiliesListItem
                  as="li"
                  key={p.product_code}
                  role="row"
                  family={family}
                  rootFamily={rootFamily}
                  product={p}
                  checked={selectedProductCodes.has(p.product_code)}
                  onCheckedChange={onCheckedChange}
                  isCheckboxDisabled={isCheckboxDisabled}
                >
                  {isAdmin ? (
                    <HStack justifyContent="flex-end">
                      <Button
                        size="xs"
                        colorScheme="red"
                        variant="ghost"
                        disabled={!isViewMode || isRequestPending}
                        onClick={() => {
                          setProductToDelete(p);
                        }}
                      >
                        {t('SETTINGS.PRODUCT_FAMILIES.DELETE_ACTION_LABEL')}
                      </Button>
                      <Button
                        size="xs"
                        variant="ghost"
                        disabled={!isViewMode || isRequestPending}
                        onClick={() => {
                          send({ type: 'EDIT', productCode: p.product_code });
                        }}
                      >
                        {t('SETTINGS.PRODUCT_FAMILIES.EDIT_ACTION_LABEL')}
                      </Button>
                    </HStack>
                  ) : null}
                </ProductFamiliesListItem>
              );
            })}
            {(status === 'loading' || state.matches(EProductsMachineState.INIT) || (isPreviousData && isFetching)) && (
              <li role="presentation" aria-hidden="true">
                <PageSpinnerOverlay minHeight="200px" variant="fast" />
              </li>
            )}
          </Box>
        </ProductFamiliesTable>
        {status === 'success' && !isFetching && (data?.pages.length === 0 || data?.pages[0]?.data.length === 0) && (
          <Center px="6" py="10">
            <VStack>
              <SearchIcon boxSize="80px" color="gray.300" />
              <Text as="span">{t('SETTINGS.PRODUCT_FAMILIES.NO_RESULTS_LABEL')}</Text>
            </VStack>
          </Center>
        )}
        {hasNextPage && (
          <Center mt="5">
            <Button
              size="sm"
              colorScheme="blue"
              isLoading={isFetchingNextPage}
              disabled={isPreviousData || !isViewMode}
              onClick={() => {
                fetchNextPage();
              }}
            >
              {t('SETTINGS.PRODUCT_FAMILIES.LOAD_MORE_BUTTON_LABEL')}
            </Button>
          </Center>
        )}
      </Box>
      <ProductFamiliesDeleteConfirmationModal
        product={productToDelete}
        onClose={onModalClose}
        productFamilies={productFamilies}
      />
    </>
  );
};
