import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useFieldArray, useForm, NestedValue } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { identity, pipe } from 'fp-ts/function';

import * as PATHS from 'constants/paths';
import { Card, CardTitleSection } from 'components/Card';
import {
  itemNameFormatter,
  parseFamilyCodeValue,
  ProductFamiliesFamilyCodeSelect,
  ProductFamiliesTable,
  ProductFamiliesTableHeaderGroup,
  useFieldValidators,
} from './components';
import { createProducts, TCreateProduct, TNewProduct, TNewProducts } from 'domain/products';
import { byName } from 'utils/sort';
import { useProductFamiliesMaps } from 'hooks/data';
import { NullableSingleSupplierSelect } from 'components/Select';
import { dateToYMD } from 'utils/date';

const FORM_ID = 'new-products-form';

interface TFormData {
  products: NestedValue<
    Array<
      Partial<TCreateProduct> & {
        rootFamilyCode?: string;
      }
    >
  >;
}

export const ProductFamiliesNewProductsPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const goBackLink = PATHS.SETTINGS.PRODUCT_FAMILIES.ROOT.toPath();
  const { allSubfamilies, rootFamilies, pfMap } = useProductFamiliesMaps();
  const { mutateAsync } = useMutation(createProducts, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_SUCCESS_TEXT'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      push(PATHS.SETTINGS.PRODUCT_FAMILIES.ROOT.toPath());
    },
    onError: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_FAIL_TEXT'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const toast = useToast();
  // const counterRef = useRef(1);
  const { push } = useHistory();
  const validators = useFieldValidators();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<TFormData>({
    defaultValues: {
      products: [
        {
          is_regional: O.none,
          minimum_stock: O.none,
          weekend_product: O.none,
          always_available: O.none,
          supplier_id: O.none,
          first_appearance_date: O.some(dateToYMD(new Date())),
        },
      ],
    },
  });
  const { fields: arrayFields, append, remove } = useFieldArray({ control, name: 'products' });

  const products = watch('products');

  const fields = arrayFields.map((field, idx) => ({
    ...field,
    ...products[idx],
  }));

  return (
    <Card>
      <CardTitleSection backButtonLink={goBackLink} as="h2">
        {t('SETTINGS.PRODUCT_FAMILIES.NEW_PRODUCTS_TITLE')}
      </CardTitleSection>
      <form
        id={FORM_ID}
        onSubmit={handleSubmit(async (d) => {
          const payload = Array.from(d.products).reduce((acc, currentProduct) => {
            const {
              description,
              family_code,
              is_regional,
              product_code,
              minimum_stock,
              weekend_product,
              always_available,
              supplier_id,
              first_appearance_date,
            } = currentProduct;
            const maybeFamilyCode = parseFamilyCodeValue(String(family_code));

            if (
              description &&
              typeof maybeFamilyCode !== 'undefined' &&
              typeof is_regional !== 'undefined' &&
              typeof product_code !== 'undefined' &&
              typeof minimum_stock !== 'undefined' &&
              typeof weekend_product !== 'undefined' &&
              typeof always_available !== 'undefined' &&
              typeof supplier_id !== 'undefined' &&
              typeof first_appearance_date !== 'undefined'
            ) {
              return [
                ...acc,
                {
                  description,
                  family_code: maybeFamilyCode,
                  is_regional,
                  product_code,
                  minimum_stock,
                  weekend_product,
                  always_available,
                  supplier_id,
                  first_appearance_date,
                } as TNewProduct,
              ];
            }

            return acc;
          }, [] as TNewProducts);

          await mutateAsync({ products: payload });
        })}
      >
        <ProductFamiliesTable>
          <ProductFamiliesTableHeaderGroup>
            <div role="row">
              <div role="columnheader"></div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.EAN_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_NAME_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.FAMILY_CODE_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.FAMILY_NAME_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.MINIMUM_STOCK_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_LABEL')}</div>
              <div role="columnheader">{t('SETTINGS.PRODUCT_FAMILIES.SUPPLIER_LABEL')}</div>
              <div role="columnheader"></div>
            </div>
          </ProductFamiliesTableHeaderGroup>

          <Box
            role="rowgroup"
            sx={{
              display: 'table-row-group',
              position: 'relative',

              '& [role="row"]': {
                display: 'table-row',
              },

              '& [role="gridcell"]': {
                display: 'table-cell',
              },
            }}
          >
            {/* FIXME(m.kania): refactor */}
            {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
            {fields.map((currentProduct, currentProductIndex) => {
              const prefix = `products.${currentProductIndex}` as const;
              const productCodeField = `${prefix}.product_code` as const;
              const productNameField = `${prefix}.description` as const;
              const productFamilyCodeField = `${prefix}.family_code` as const;
              const productIsRegionalField = `${prefix}.is_regional` as const;
              const productRootFamilyCodeField = `${prefix}.rootFamilyCode` as const;
              const productMinimumStockField = `${prefix}.minimum_stock` as const;
              const productWeekendProductField = `${prefix}.weekend_product` as const;
              const productAlwaysAvailableField = `${prefix}.always_available` as const;
              const productSupplierIdField = `${prefix}.supplier_id` as const;

              const rootFamily = pipe(
                currentProduct?.rootFamilyCode,
                O.fromNullable,
                O.chain((code) => {
                  const maybeFamily = rootFamilies.find((f) => String(f.id) === code);

                  return maybeFamily ? O.some(maybeFamily) : O.none;
                }),
              );
              const rootFamilyCode = pipe(
                rootFamily,
                O.fold(
                  () => undefined,
                  (f) => f.id,
                ),
              );

              const filteredSubfamilies = pipe(
                allSubfamilies,
                (subfamilies) =>
                  typeof rootFamilyCode === 'undefined'
                    ? subfamilies
                    : pipe(
                        subfamilies,
                        A.filter((s) =>
                          pipe(
                            s.parent_id,
                            O.fold(
                              () => false,
                              (id) => id === rootFamilyCode,
                            ),
                          ),
                        ),
                      ),
                A.sort(byName),
              );

              const isRegional = pipe(
                currentProduct?.is_regional,
                O.fromNullable,
                O.chain(identity),
                O.fold(() => false, identity),
              );

              const minimumStock = pipe(
                currentProduct?.minimum_stock,
                O.fromNullable,
                O.chain(identity),
                O.fold(() => 0, identity),
              );

              const weekendProduct = pipe(
                currentProduct?.weekend_product,
                O.fromNullable,
                O.chain(identity),
                O.fold(() => false, identity),
              );

              const alwaysAvailable = pipe(
                currentProduct?.always_available,
                O.fromNullable,
                O.chain(identity),
                O.fold(() => false, identity),
              );

              const supplier = pipe(
                currentProduct?.supplier_id,
                O.fromNullable,
                O.chain(identity),
                O.fold(() => undefined, identity),
              );

              const onFamilyCodeChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
                const maybeCode = parseFamilyCodeValue(ev.target.value);

                if (maybeCode) {
                  const newFamily = pfMap.get(maybeCode);

                  if (newFamily) {
                    const newRootFamilyId = pipe(
                      newFamily.parent_id,
                      O.fold(() => undefined, identity),
                    );

                    if (typeof newRootFamilyId !== 'undefined') {
                      // @ts-ignore
                      setValue(productRootFamilyCodeField, String(newRootFamilyId));
                    }
                  }
                }
              };

              // FIXME(m.kania)
              // @ts-ignore
              const productErrors = errors.products?.[currentProductIndex] as any;
              const productCodeError = productErrors?.product_code?.message;
              const productNameError = productErrors?.description?.message;
              const productFamilyCodeError = productErrors?.family_code?.message;
              const minimumStockError = productErrors?.minimum_stock?.message;

              return (
                <div key={currentProduct.id} role="row">
                  <div role="gridcell"></div>
                  <div role="gridcell">
                    <FormControl isInvalid={Boolean(productCodeError)} isRequired={true}>
                      <Input
                        size="xs"
                        placeholder={t('SETTINGS.PRODUCT_FAMILIES.EAN_INPUT_PLACEHOLDER')}
                        isReadOnly={isSubmitting}
                        {...register(productCodeField, {
                          ...validators.ean,
                        })}
                      />
                      {productCodeError && <FormErrorMessage>{productCodeError}</FormErrorMessage>}
                    </FormControl>
                  </div>
                  <div role="gridcell">
                    <FormControl isInvalid={Boolean(productNameError)} isRequired={true}>
                      <Input
                        size="xs"
                        placeholder={t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_NAME_INPUT_PLACEHOLDER')}
                        isReadOnly={isSubmitting}
                        {...register(productNameField, { ...validators.productName })}
                      />
                      {productNameError && <FormErrorMessage>{productNameError}</FormErrorMessage>}
                    </FormControl>
                  </div>
                  <div role="gridcell">
                    <FormControl isInvalid={Boolean(productFamilyCodeError)} isRequired={true}>
                      <ProductFamiliesFamilyCodeSelect
                        placeholder={t('SETTINGS.PRODUCT_FAMILIES.FAMILY_CODE_INPUT_PLACEHOLDER')}
                        items={allSubfamilies}
                        isDisabled={isSubmitting}
                        {...register(productFamilyCodeField, {
                          ...validators.familyCode,
                          deps: [productRootFamilyCodeField],
                          onChange: onFamilyCodeChange,
                        })}
                      />
                      {productFamilyCodeError && <FormErrorMessage>{productFamilyCodeError}</FormErrorMessage>}
                    </FormControl>
                  </div>
                  <div role="gridcell">
                    <ProductFamiliesFamilyCodeSelect
                      formatLabel={itemNameFormatter}
                      placeholder={t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_INPUT_PLACEHOLDER')}
                      items={rootFamilies}
                      isDisabled={isSubmitting}
                      {...register(productRootFamilyCodeField, {
                        onChange: (ev: React.ChangeEvent<HTMLSelectElement>) => {
                          const { value } = ev.target;

                          // NOTE(m.kania): if root family doesn't 'fit' to current subfamily, then deselect it
                          if (O.isSome(rootFamily) && String(rootFamily.value.id) !== value) {
                            // @ts-ignore
                            setValue(productFamilyCodeField, undefined);
                          }
                        },
                      })}
                    />
                  </div>
                  <div role="gridcell">
                    <ProductFamiliesFamilyCodeSelect
                      formatLabel={itemNameFormatter}
                      placeholder={t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_INPUT_PLACEHOLDER')}
                      items={filteredSubfamilies}
                      disabledPlaceholder={true}
                      isDisabled={isSubmitting}
                      value={String(currentProduct?.family_code ?? '')}
                      onChange={(ev) => {
                        // @ts-ignore
                        setValue(productFamilyCodeField, ev.target.value);
                        onFamilyCodeChange(ev);
                      }}
                    />
                  </div>
                  <div role="gridcell">
                    <Switch
                      name={productIsRegionalField}
                      isChecked={isRegional}
                      isReadOnly={isSubmitting}
                      onChange={(ev) => {
                        // @ts-ignore
                        setValue(productIsRegionalField, O.some(ev.target.checked));
                      }}
                    />
                    <Text as="span" ml="1ch">
                      {isRegional
                        ? t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_TRUE_LABEL')
                        : t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_FALSE_LABEL')}
                    </Text>
                  </div>
                  <div role="gridcell">
                    <FormControl isInvalid={Boolean(minimumStockError)} isRequired={false}>
                      <Input
                        size="xs"
                        type="number"
                        value={minimumStock}
                        placeholder={t('SETTINGS.PRODUCT_FAMILIES.MINIMUM_STOCK_PLACEHOLDER')}
                        isReadOnly={isSubmitting}
                        name={productMinimumStockField}
                        onChange={(ev) => {
                          setValue(
                            productMinimumStockField,
                            parseInt(ev.target.value, 10) ? O.some(parseInt(ev.target.value, 10)) : O.none,
                          );
                        }}
                      />
                      {minimumStockError && <FormErrorMessage>{minimumStockError}</FormErrorMessage>}
                    </FormControl>
                  </div>
                  <div role="gridcell">
                    <Switch
                      name={productWeekendProductField}
                      isChecked={weekendProduct}
                      isReadOnly={isSubmitting}
                      onChange={(ev) => {
                        // @ts-ignore
                        setValue(productWeekendProductField, O.some(ev.target.checked));
                      }}
                    />
                    <Text as="span" ml="1ch">
                      {weekendProduct
                        ? t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_TRUE_LABEL')
                        : t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_FALSE_LABEL')}
                    </Text>
                  </div>
                  <div role="gridcell">
                    <Switch
                      name={productAlwaysAvailableField}
                      isChecked={alwaysAvailable}
                      isReadOnly={isSubmitting}
                      onChange={(ev) => {
                        // @ts-ignore
                        setValue(productAlwaysAvailableField, O.some(ev.target.checked));
                      }}
                    />
                    <Text as="span" ml="1ch">
                      {alwaysAvailable
                        ? t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_TRUE_LABEL')
                        : t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_FALSE_LABEL')}
                    </Text>
                  </div>
                  <div role="gridcell">
                    <NullableSingleSupplierSelect
                      name={productSupplierIdField}
                      value={supplier}
                      onChange={(newSupplierId) => {
                        setValue(productSupplierIdField, O.fromNullable(newSupplierId));
                      }}
                    />
                  </div>
                  <div role="gridcell">
                    <IconButton
                      aria-label={t('SETTINGS.PRODUCT_FAMILIES.DELETE_ACTION_LABEL')}
                      size="sm"
                      variant="outline"
                      disabled={isSubmitting || fields.length < 2}
                      onClick={() => {
                        remove(currentProductIndex);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          </Box>
        </ProductFamiliesTable>
      </form>
      <Center pt="5" pb="3">
        <Button
          disabled={isSubmitting}
          onClick={() => {
            append({
              is_regional: O.none,
            });
          }}
        >
          {t('SETTINGS.PRODUCT_FAMILIES.ADD_ANOTHER_PRODUCT_LABEL')}
        </Button>
      </Center>

      <HStack pt="3" justifyContent="flex-end">
        <Button as={Link} to={goBackLink} variant="outline">
          {t('SETTINGS.PRODUCT_FAMILIES.CANCEL_BUTTON_LABEL')}
        </Button>
        <Button colorScheme="blue" type="submit" form={FORM_ID} isLoading={isSubmitting}>
          {t('SETTINGS.PRODUCT_FAMILIES.SAVE_BUTTON_LABEL')}
        </Button>
      </HStack>
    </Card>
  );
};
