import { Checkbox, GridItem, Tooltip } from '@chakra-ui/react';
import { getWeek } from 'date-fns';
import { TWeeklyProductManagement } from 'domain/products';
import { hasMonitorAccess, hasSectionManagerAccess } from 'domain/user';
import * as O from 'fp-ts/Option';
import { useUserContext } from 'hooks';
import { getValueFromStores, getValueFromStoresAvailable } from 'pages/AssortmentManagementPage/utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { WeeklyProductListManagement } from '../hooks';
import { CustomIconButton, MarkIconButton } from './MarkIconButton';
import { useEditStore } from '../hooks/useEditStore';

type ProductManagementColumnProps = {
  productsList: WeeklyProductListManagement;
  item: TWeeklyProductManagement;
  store?: string | null;
  editable?: boolean;
};

export const ProductManagementColumn: FC<ProductManagementColumnProps> = ({
  productsList,
  item,
  store,
  editable = false,
}) => {
  const { t } = useTranslation('whisperme');
  const [editStore, { handleSetEditStore }] = useEditStore();
  const currentWeek = getWeek(new Date());
  const { user } = useUserContext();
  const sectionMangerScope = hasSectionManagerAccess(user);
  const monitorScope = hasMonitorAccess(user);

  if (!item || !store) return null;

  const storesAvailableValue = getValueFromStoresAvailable(editStore, productsList, {
    year: productsList.year,
    week: productsList.week,
    product_code: item.product_code,
    storeId: store,
  });
  const storesValue = getValueFromStores(editStore, productsList, {
    year: productsList.year,
    week: productsList.week,
    product_code: item.product_code,
    storeId: store,
  });
  const tooltipLabel =
    (O.toUndefined(storesValue) ?? 'nodata') === 'disabled'
      ? t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.DISABLED_STATUS_LIMITATIONS')
      : t('ANALYTICS.PRODUCTS.LIST_PAGE.ALWAYS_AVAILABLE_LABEL');

  return (
    <GridItem
      key={`${item.product_code}-${store}-${productsList.week}-${productsList.year}`}
      borderLeft={'1px'}
      borderColor={'grey.200'}
    >
      {editable ? (
        <>
          <MarkIconButton
            variant={storesValue}
            onClick={handleSetEditStore(String(item.product_code), store, productsList.week, productsList.year)}
            editable={
              productsList.week >= currentWeek &&
              (monitorScope || (sectionMangerScope && !item.stores_always_available[store]))
            }
            allowDisabledState={storesAvailableValue}
          />
          <Tooltip label={tooltipLabel} shouldWrapChildren m={0} p={0}>
            <Checkbox
              isChecked={storesAvailableValue}
              isDisabled={
                (O.toUndefined(storesValue) ?? 'nodata') === 'disabled' ||
                !monitorScope ||
                (sectionMangerScope && !item.stores_always_available[store])
              }
              onChange={(ev) => {
                handleSetEditStore(
                  String(item.product_code),
                  store,
                  productsList.week,
                  productsList.year,
                )(ev.target.checked);
              }}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <CustomIconButton variant={O.toUndefined(storesValue) ?? 'nodata'} disabled />
          <Checkbox
            isChecked={productsList.dataMap?.get(item.product_code)?.stores_always_available[store]}
            isDisabled
          />
        </>
      )}
    </GridItem>
  );
};
