import React, { useMemo } from 'react';
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Button,
  Text,
  HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AppUser } from 'domain/user';
import { LanguageItem, AppLanguageFlag } from 'components/AppLanguageMenu';
import { useAppConfig } from 'hooks';
import { isValidLanguageKey } from 'locales';

interface Props {
  user: AppUser;
  onLogout?: () => void;
}

export const DashboardProfileActions: React.FC<Props> = ({ user, onLogout }) => {
  const { t } = useTranslation('whisperme');
  const { language, setLanguage } = useAppConfig();

  const items = useMemo(
    () =>
      [
        {
          code: 'en-GB',
          label: t('COMPONENTS.LANGUAGE_MENU.ENGLISH'),
        },
        {
          code: 'fr',
          label: t('COMPONENTS.LANGUAGE_MENU.FRENCH'),
        },
      ] as LanguageItem[],
    [t],
  );

  return (
    <Menu isLazy={true}>
      <MenuButton as={Button} variant="ghost" size="sm" rightIcon={<Avatar size="sm" name={user.display_name} />}>
        {user.display_name}
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuOptionGroup
            value={language}
            title={t('COMPONENTS.LANGUAGE_MENU.BUTTON_LABEL')}
            type="radio"
            onChange={(v) => {
              if (typeof v === 'string' && isValidLanguageKey(v)) {
                setLanguage(v);
              }
            }}
          >
            {items.map((i) => (
              <MenuItemOption key={i.code} value={i.code}>
                <HStack as="span" spacing="2">
                  <Text as="span">{i.label}</Text>
                  <AppLanguageFlag language={i.code} />
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />
          <MenuItem onClick={onLogout}>{t('GENERIC.NAVIGATION.LOGOUT_LABEL')}</MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
