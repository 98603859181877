import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { FormControl, FormLabel, Grid, GridItem } from '@chakra-ui/react';
import { StoreRoleRadioGroup } from './StoreRoleRadioGroup';
import { DESKTOP_BREAKPOINT } from 'constants/css';
import { StoreRolesFormData, TRoleCategories } from './utils';
import { TStoresArray } from 'domain/stores';
import { TProductFamilyArray } from 'domain/product-family';

interface Props {
  category: TStoresArray | TProductFamilyArray;
  categoryName: TRoleCategories;
  control: Control<StoreRolesFormData>;
}

export const StoreRolesFormCategory: React.FC<Props> = ({ category, categoryName, control }) => {
  return (
    <>
      <Grid
        justifyItems="center"
        gridTemplateColumns={{
          base: '100%',
          md: 'repeat(2, minmax(250px, 1fr))',
          [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
        }}
        gridRowGap="8"
        gridColumnGap="2"
      >
        {category.map((role) => (
          <GridItem key={String(role.id)}>
            <Controller
              control={control}
              name={`${categoryName}.${String(role.id)}`}
              render={({ field: { name, onChange, value } }) => (
                <FormControl as="fieldset">
                  <FormLabel as="legend">{role.name}</FormLabel>
                  <StoreRoleRadioGroup name={name} onChange={onChange} value={value} />
                </FormControl>
              )}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
