import React from 'react';
import { Center, CenterProps, Spinner, SpinnerProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionCenter = motion(Center);

export interface PageSpinnerProps extends CenterProps {
  children?: never;
  variant?: 'default' | 'fast';
  size?: SpinnerProps['size'];
}

const variants = {
  default: 0.3,
  fast: 0.1,
};

export const PageSpinner: React.FC<PageSpinnerProps> = ({ variant = 'default', size, ...props }) => (
  <MotionCenter
    p="5"
    width="100%"
    height="100%"
    initial={{ opacity: 0 }}
    exit={{ opacity: 0 }}
    animate={{
      opacity: 1,
    }}
    transition={{
      delay: variants[variant] ?? variants.default,
    }}
    {...props}
  >
    <Spinner size={size} />
  </MotionCenter>
);
