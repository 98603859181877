import { useCustomerMapData } from 'hooks/data';
import { useMemo } from 'react';
import { TCustomerMapData } from 'domain/customer';
import { TStoreId } from 'domain/stores';
import { CustomerData, MapPointDataList } from 'domain/map';
import { TProductCode } from 'domain/core';
import { useCustomerSimilarityData } from './useCustomerSimilarityData';

type UseNeoEmailPointsDataListsType = [
  points: MapPointDataList,
  selected: string[],
  allData: Map<string, CustomerData & { index?: number }>,
];

type UseNeoEmailReturnType = [...UseNeoEmailPointsDataListsType, { isFetching: boolean }];

const SELECTED_COLOR = 'red';
const EXCLUDED_COLOR = '#646464';

/**
 * Return updated point data list with current highlighted point count.
 * @param categoriesData - Customer categories data
 * @param selected - list of selected
 * @param selectedLength - length of selected customer ids
 */
const updatePoints = (
  categoriesData: TCustomerMapData,
  selected: string[],
  selectedLength: number,
): UseNeoEmailPointsDataListsType => {
  const acc = [[], [], new Map()] as UseNeoEmailPointsDataListsType;

  for (let index = 0; index < categoriesData.positions.length; index++) {
    const id = categoriesData.ids[index];
    acc[0][index] = {
      x: categoriesData.positions[index][0],
      y: categoriesData.positions[index][1],
      color: EXCLUDED_COLOR,
      id,
    };

    acc[2].set(id, {
      customerData: categoriesData.values[index].reduce(
        (acc, value, index) => {
          acc[categoriesData.labels[index]] = value;
          return acc;
        },
        {} as Record<string, number>,
      ),
      index,
    });
  }

  const range = Math.min(selectedLength, selected.length);

  for (let index = 0; index < range; index++) {
    const currSelected = selected[index];
    const pointIndex = acc[2].get(currSelected)?.index;
    if (pointIndex !== undefined) {
      acc[1][index] = selected[index];
      acc[0][pointIndex].color = SELECTED_COLOR;
    }
  }

  return acc;
};

/**
 * Hook for generating point data lists per map
 */
export const useNeoEmailPointsData = (
  storeId: TStoreId,
  selectedLength: number,
  setDefaultSelected: (length: number) => void,
  productCode?: TProductCode,
): UseNeoEmailReturnType => {
  const params = { store_id: storeId };
  const { data: categoriesData, status: categoriesStatus } = useCustomerMapData(params);
  const {
    data: customersData,
    isFetching,
    status: selectedStatus,
  } = useCustomerSimilarityData(params.store_id, productCode, ({ default_scroll_index }) => {
    setDefaultSelected(default_scroll_index);
  });

  const lists = useMemo(() => {
    if (categoriesStatus === 'success' && categoriesData) {
      return updatePoints(categoriesData, productCode && customersData ? customersData.customers : [], selectedLength);
    }

    return [[], [], new Map()];
  }, [categoriesStatus, categoriesData, customersData, selectedStatus, selectedLength]);

  return useMemo(() => [...lists, { isFetching }] as UseNeoEmailReturnType, [lists, isFetching]);
};
