import React from 'react';
import { Stack } from '@chakra-ui/react';
import { ComboCheckboxListComponent } from './ComboCheckboxList';
import { SelectableItem } from './types';

type MultiSelectComponentProps<T> = {
  values?: T[];

  onChange: (values: T[]) => void;

  items: SelectableItem[];
};

export const MultiSelectComponent = <T extends string>({ values, onChange, items }: MultiSelectComponentProps<T>) => {
  const handleChange = (value: string) => {
    if (!values) {
      onChange([value as T]);
      return;
    }

    if (values.includes(value as T)) {
      onChange(values.filter((v) => v !== value));
      return;
    }

    onChange([...values, value as T]);
  };

  return (
    <Stack>
      <ComboCheckboxListComponent values={values} onChange={handleChange} isStatic items={items} disabled={values} />
    </Stack>
  );
};
