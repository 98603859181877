import { useCallback } from 'react';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { useStoresData } from 'hooks/data';
import { ChartData } from './types';
import { labelChartDataStoreMetrics } from './utils';

export const useChartDataMetricsStoreLabeller = () => {
  const stores = useStoresData();

  return useCallback(
    (chartData: O.Option<ChartData>) => pipe(chartData, O.map(labelChartDataStoreMetrics(stores))),
    [stores],
  );
};
