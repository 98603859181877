import { getValidatedList } from './analytics/products';

const ROOT_PATH = '/free-tasks' as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const PRODUCTS_LIST_PATH = `${ROOT_PATH}/list/`;

export const LIST = getValidatedList(PRODUCTS_LIST_PATH);
