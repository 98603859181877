import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { ROLE_CODES } from '../roles';
import { TStoreId } from '../stores';
import { AppUser } from './types';
import { TSectionCode } from 'domain/core';

export const userHasAccessToStore =
  (user: O.Option<AppUser>) =>
  (storeId: TStoreId): boolean =>
    pipe(
      user,
      O.map((u) => {
        if (u.is_admin || u.user_type_id === 5) {
          return true;
        }

        return (
          storeId in u.store_privileges &&
          (u.store_privileges[storeId].includes(ROLE_CODES.READ) ||
            u.store_privileges[storeId].includes(ROLE_CODES.ADMIN))
        );
      }),
      O.fold(() => false, identity),
    );

export const userHasAccessToSection = (user: O.Option<AppUser>) => (sectionId: TSectionCode) =>
  pipe(
    user,
    O.map((u) => {
      if (u.is_admin || !Object.keys(u.section_privileges).length) {
        return true;
      }

      return (
        sectionId in u.section_privileges &&
        (u.section_privileges[sectionId].includes(ROLE_CODES.READ) ||
          u.section_privileges[sectionId].includes(ROLE_CODES.ADMIN))
      );
    }),
    O.getOrElse(() => false),
  );

export const userHasAccessLevel = (access: AppUser['access']) => (user: O.Option<AppUser>) =>
  pipe(
    user,
    O.map((u) => u.access === access),
    O.getOrElse(() => false),
  );

export const hasCLevelAccess = userHasAccessLevel('c-level');
export const hasAreaManagerAccess = userHasAccessLevel('area-manager');
export const hasSupermarketManagerAccess = userHasAccessLevel('supermarket-manager');
export const hasMonitorAccess = userHasAccessLevel('monitor');
export const hasSectionManagerAccess = userHasAccessLevel('section-manager');
export const hasAdminAccess = userHasAccessLevel('admin');
