import { createUserTypesKey } from 'constants/queryCacheKeys';
import { getUserTypes, TUserType } from 'domain/user';
import { useQuery } from 'react-query';
import { pipe } from 'fp-ts/function';
import { findFirst } from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { useMemo } from 'react';

export const useUserTypes = () => useQuery(createUserTypesKey(), () => getUserTypes());

export const useUserTypeIdToName = () => {
  const { data } = useUserTypes();

  return useMemo(
    () =>
      pipe(
        data,
        O.fromNullable,
        O.match(
          () => (_: number) => O.none,
          (list) => (id: number) => findFirst((value: TUserType) => value.user_type_id === id)(list),
        ),
      ),
    [data],
  );
};
