import React, { useMemo } from 'react';
import { XYChart, Grid, Axis } from '@visx/xychart';

import { theme as defaultTheme } from './chartThemes';

type XYChartProps = React.ComponentPropsWithoutRef<typeof XYChart>;
type AxisProps = React.ComponentPropsWithoutRef<typeof Axis>;
export interface BaseXYChartProps extends XYChartProps {
  width: number;
  height: number;
  showGrid?: boolean;
  showAxes?: 'none' | 'x' | 'y' | 'both';
  xLabel?: string;
  yLabel?: string;
  showLabels?: boolean;
  chartVariant?: 'standard' | 'trend';
  numTicks?: number;
  xAxisProps?: Partial<AxisProps>;
  yAxisProps?: Partial<AxisProps>;
}

const getChartDefaults = (chartVariant: BaseXYChartProps['chartVariant'] = 'standard') => {
  if (chartVariant === 'trend') {
    const trendMargin = 15;
    return {
      xScale: { type: 'band', padding: 0.1 },
      yScale: { type: 'linear', padding: 0.1 },
      margin: { bottom: trendMargin, left: trendMargin, top: trendMargin, right: trendMargin },
      labelOffset: {
        x: undefined,
      },
    } as const;
  }

  const my = 45;
  const mx = 50;
  return {
    xScale: { type: 'band', padding: 0.2, nice: true },
    yScale: { type: 'linear', padding: 0.2, nice: true },
    margin: { top: my, bottom: my, left: mx, right: mx },
    labelOffset: {
      x: 21,
    },
  } as const;
};

const widthToNumTicks = (width: number) => {
  if (width < 150) {
    return 1;
  }

  if (width < 320) {
    return 2;
  }

  if (width < 600) {
    return 3;
  }

  return 4;
};

export const BaseXYChart: React.FC<BaseXYChartProps> = ({
  children,
  showGrid = true,
  showAxes = 'both',
  xScale = {},
  yScale = {},
  margin,
  showLabels,
  xLabel,
  yLabel,
  width,
  height,
  chartVariant = 'standard',
  numTicks: propNumTicks,
  xAxisProps,
  yAxisProps,
  theme = defaultTheme,
  ...rest
}) => {
  const { scales, defaults } = useMemo(() => {
    const defaults = getChartDefaults(chartVariant);

    return {
      scales: {
        xScale: { ...defaults.xScale, ...xScale },
        yScale: { ...defaults.yScale, ...yScale },
      },
      defaults,
    };
  }, [chartVariant, xScale, yScale]);
  const numTicks = typeof propNumTicks === 'number' ? propNumTicks : widthToNumTicks(width);

  return (
    <XYChart {...scales} margin={margin ?? defaults.margin} theme={theme} width={width} height={height} {...rest}>
      {chartVariant === 'standard' && showGrid && <Grid columns={true} rows={true} numTicks={numTicks} />}
      {(showAxes === 'both' || showAxes === 'y') && (
        <Axis orientation="left" numTicks={numTicks} label={showLabels ? yLabel : undefined} {...yAxisProps} />
      )}
      {(showAxes === 'both' || showAxes === 'x') && (
        <Axis orientation="bottom" numTicks={numTicks} label={showLabels ? xLabel : undefined} {...xAxisProps} />
      )}
      {children}
    </XYChart>
  );
};
