import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Skeleton, Stack, Text, HStack } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';

import { Card, CardProps, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { BarChart, ChartDataEntry, InteractiveChartMetric } from 'components/Charts';
import { DataResolutionSelect, NullableSingleStoreSelect } from 'components/Select';
import { DateRangePicker } from 'components/DateRangePicker';
import { STATISTIC_TYPE } from 'domain/statistics';
import { TStoreId } from 'domain/stores';
import { useStoreStatistics } from 'hooks/data';
import { useAppConfig, useDataResolutionProps } from 'hooks';
import { prepareImportStatusChartData } from './utils';
import { DATA_RESOLUTION, getDataResolutionFormat } from 'domain/core';
import { buildColorTheme } from 'components/Charts/chartThemes';
import { ImportStatusWarningSeries } from './ImportStatusWarningSeries';
import { AlertIcon } from 'components/Icons';
import { DateRangePickerKind } from 'components/DateRangePicker/DateRangePicker';

const DIMENSIONS = {
  minWidth: '200px',
  minHeight: '300px',
};

const chartThemeColors = ['#68D391', '#F56565'];

interface Props extends CardProps {
  storeIds: TStoreId[];
  selectedStore: TStoreId[];
  calendarProps: DateRangePickerKind;
  onSelectIds: (newStoreId: TStoreId[]) => void;
  children?: never;
}

export const ImportStatusCard: React.FC<Props> = ({ storeIds, onSelectIds, calendarProps, selectedStore, ...rest }) => {
  const { t } = useTranslation('whisperme');
  const {
    language,
    dateOptions,
    formatters: { numberFormatter },
  } = useAppConfig();

  const { data, isFetching } = useStoreStatistics(
    {
      storeIds,
      statisticIds: [
        STATISTIC_TYPE.PROCESSED_RECEIPTS,
        STATISTIC_TYPE.FAILED_RECEIPTS,
        STATISTIC_TYPE.PROCESSED_RECEIPTS_LOWER_BOUND,
      ],
      date_to: calendarProps.endDate ?? undefined,
      date_from: calendarProps.startDate,
    },
    {
      keepPreviousData: true,
    },
  );

  const { dataResolutionSelectProps, dataResolution } = useDataResolutionProps(data, DATA_RESOLUTION.DAY);

  const { chartData, lowerBoundData, lowerBoundChartData } = useMemo(
    () =>
      prepareImportStatusChartData({
        dataResolution,
        dateOptions,
        storeIds: selectedStore,
        data,
        labels: {
          processed: t('SETTINGS.PROCESSING_PAGE.IMPORT_STATUS_SUCCESS_LABEL'),
          failed: t('SETTINGS.PROCESSING_PAGE.IMPORT_STATUS_FAILURE_LABEL'),
        },
      }),
    [dataResolution, dateOptions, selectedStore, data, t],
  );

  const barChartProps = useMemo(() => {
    const formatYAxisValue = new Intl.NumberFormat(language, { notation: 'compact' });

    return {
      theme: buildColorTheme(chartThemeColors),
      barStackProps: {
        order: 'none' as const,
      },
      yScale: { type: 'linear', nice: true, round: true } as const,
      xAxisProps: {
        tickFormat: (v: string) =>
          pipe(v, parseISO, (d) => format(d, getDataResolutionFormat(dataResolution), dateOptions)),
      },
      yAxisProps: {
        tickFormat: (v: number) => formatYAxisValue.format(v),
      },
    };
  }, [dataResolution, dateOptions, language]);

  const renderTooltip = useCallback(
    ({ tooltipData, colorScale }: RenderTooltipParams<ChartDataEntry>, metrics: InteractiveChartMetric[]) => {
      if (typeof tooltipData?.nearestDatum === 'undefined') {
        return null;
      }

      const { index: dataIndex } = tooltipData.nearestDatum;

      return (
        <Stack spacing="4" maxWidth="320px">
          <Text as="span" fontSize="md">
            {barChartProps.xAxisProps.tickFormat(String(tooltipData.nearestDatum.datum.x))}
          </Text>
          <Stack as="ul" direction="column">
            {tooltipData.nearestDatum.datum.y.map((val, metricIndex) => {
              const { visible, name, dataKey } = metrics[metricIndex] ?? {};

              return visible ? (
                <HStack key={metricIndex} as="li" spacing="1" fontSize="sm">
                  <Text as="strong" color={colorScale?.(dataKey)}>
                    {name}:
                  </Text>
                  <Text as="span">{numberFormatter.format(val ?? 0)}</Text>
                </HStack>
              ) : null;
            })}
          </Stack>
          {pipe(
            lowerBoundChartData,
            A.lookup(dataIndex),
            O.chain((chartLB) => (typeof chartLB.y === 'number' ? O.some(chartLB.y) : O.none)),
            O.chain(() =>
              pipe(
                lowerBoundData,
                A.lookup(dataIndex),
                O.chain((lb) => (typeof lb.y[0] === 'number' ? O.some(lb.y[0]) : O.none)),
              ),
            ),
            O.fold(
              () => null,
              (lowerBound) => (
                <Stack>
                  <HStack>
                    <AlertIcon fontSize="36px" fill="orange.300" />

                    <Text as="span" fontSize="sm" lineHeight="5">
                      {t('SETTINGS.PROCESSING_PAGE.IMPORT_STATUS_WARNING_HINT')}
                    </Text>
                  </HStack>

                  <HStack as="li" spacing="1" fontSize="sm">
                    <Text as="strong">{t('STATISTIC_TYPE.processed_receipts_lower_bound')}:</Text>
                    <Text as="span">{numberFormatter.format(lowerBound)}</Text>
                  </HStack>
                </Stack>
              ),
            ),
          )}
        </Stack>
      );
    },
    [barChartProps.xAxisProps, lowerBoundData, lowerBoundChartData, numberFormatter, t],
  );

  return (
    <Card
      {...rest}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            {storeIds.length > 1 && (
              <NullableSingleStoreSelect
                selectableStoreIds={storeIds}
                value={storeIds.length === 1 ? storeIds[0] : undefined}
                onChange={(newStoreId) => {
                  onSelectIds(typeof newStoreId !== 'undefined' ? [newStoreId] : storeIds);
                }}
              />
            )}
            <DateRangePicker {...calendarProps} />
            <DataResolutionSelect {...dataResolutionSelectProps} />
          </CardTitleSectionSettings>
        }
      >
        {t('SETTINGS.PROCESSING_PAGE.IMPORT_STATUS_TITLE')}
      </CardTitleSection>
      <Flex flexDirection="column" flex="1 1 auto" minWidth={DIMENSIONS.minWidth} minHeight={DIMENSIONS.minHeight}>
        {chartData === null ? (
          <Skeleton width="100%" height="100%" flex="1 1 auto" />
        ) : (
          <BarChart
            animated={false}
            chartData={chartData}
            isLoading={isFetching}
            {...barChartProps}
            groupVariant="stack"
            renderTooltip={renderTooltip}
          >
            <ImportStatusWarningSeries data={lowerBoundChartData} />
          </BarChart>
        )}
      </Flex>
    </Card>
  );
};
