import { Select } from '@chakra-ui/react';
import { TProductFamily } from 'domain/product-family';
import { flow } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import React, { ReactElement } from 'react';
import { parseFamilyCodeValue } from 'domain/core';
import { useTranslation } from 'react-i18next';

type SelectFamiliesProps = {
  value: ReturnType<typeof parseFamilyCodeValue>;
  onChange: (value?: ReturnType<typeof parseFamilyCodeValue>) => void;
  options: TProductFamily[];
  allPlaceholder?: string;
};

const parseFamilyCodes = flow(
  A.map<TProductFamily, ReactElement>((f) => (
    <option value={f.id} key={f.id}>
      {f.name}
    </option>
  )),
);

export const SelectFamilies: React.VFC<SelectFamiliesProps> = ({ value, onChange, options, allPlaceholder }) => {
  const { t } = useTranslation('whisperme');

  return (
    <Select
      size="sm"
      maxWidth="200px"
      value={String(value ?? '')}
      onChange={(ev) => onChange(parseFamilyCodeValue(ev.target.value))}
    >
      <option value="">{allPlaceholder || t('OBJECTIVES.COMMON.SELECTORS.SELECT_SECTION_PLACEHOLDER')}</option>
      {parseFamilyCodes(options)}
    </Select>
  );
};
