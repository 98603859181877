import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Input, Stack, FormLabel, Button, HStack } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';

interface Props {
  onSubmit: () => void;
  mealName: string;
  setMealName: (mealName: string) => void;
  isFetching?: boolean;
}

export const MealBuilderRequest: React.VFC<Props> = ({ onSubmit, mealName, setMealName, isFetching }) => {
  const { t } = useTranslation('whisperme');

  return (
    <Stack>
      <Card>
        <CardTitleSection>{t('SHOPPING.TITLE')}</CardTitleSection>
        <Stack>
          <FormControl size="md">
            <FormLabel fontSize="md">{t('SHOPPING.MEAL_BUILDER_REQUEST.MEAL_NAME')}</FormLabel>
            <Input
              size="md"
              type="text"
              value={mealName}
              onChange={(ev) => {
                setMealName(ev.target.value);
              }}
            />
          </FormControl>
          <HStack justifyContent="end" mt={2}>
            <Button size="md" isLoading={isFetching} colorScheme="blue" onClick={() => onSubmit()}>
              {t('SHOPPING.MEAL_BUILDER_REQUEST.BUTTON_CONFIRM')}
            </Button>
          </HStack>
        </Stack>
      </Card>
    </Stack>
  );
};
