import { API_BASE_URL } from 'constants/env';
import { TStoreId } from 'domain/stores';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { createValidatedCancellableFetch, makeCancellableFetch, urlWithQueryParams } from 'utils/request';
import { TFamilyCode, TProductCode, TSectionCode } from '../core';
import {
  GoodToHaveDataValidator,
  ProductAnomalyDetailsStatisticsValidatorArray,
  ProductDetailsStatisticsValidatorArray,
  ProductDetailsValidator,
  ProductFamiliesStatsValidator,
  ProductKPIDailyValidator,
  ProductsValidator,
  SaveWeeklyProductManagementList,
  StoreProductWeeklyWeekCheckValidator,
  TaskNewProductsValidator,
  TCreateNewWeekQueryParams,
  TNewWeekQueryParams,
  TCreateProduct,
  TGetProductExtendedInfoCSVParams,
  TNewProducts,
  TWeeklyProductManagementParams,
  WeeklyProductManagementListValidator,
  ProductWeeklyCheckResponseValidator,
  ProductWeeklyAssortmentUploadValidator,
  TCreateWeekQueryParams,
  ProductAssortmentStatisticsListValidator,
  TAssortmentStatisticsParams,
} from './types';

const API_URLS = {
  getProductsFamiliesStats: () => `${API_BASE_URL}/product/stats`,
  getProductList: () => `${API_BASE_URL}/product/`,
  createProducts: () => `${API_BASE_URL}/product/`,
  uploadFileWithProducts: () => `${API_BASE_URL}/product_upload/`,
  getExportableProductFamiliesCSV: () => `${API_BASE_URL}/product/xlsx`,
  getProductDetails: (productCode: TProductCode) => `${API_BASE_URL}/product/${productCode}`,
  getProductFullDetails: (productCode: TProductCode) => `${API_BASE_URL}/product_details/${productCode}`,
  getWeekProductStatistics: () => `${API_BASE_URL}/product/statistcs/last/week`,
  getMonthProductStatistics: () => `${API_BASE_URL}/product/statistcs/last/month`,
  getSalesAnomalyProductStats: () => `${API_BASE_URL}/sales/product_anomaly`,
  getStoreProductWeeklyWeekCheck: (year: number, week: number) =>
    `${API_BASE_URL}/store_product_weekly/year/${year}/week/${week}`,
  getWeeklyProductManagement: () => `${API_BASE_URL}/store_product_weekly`,
  getGoodToHave: () => `${API_BASE_URL}/good_product/`,
  deleteProduct: (productCode: TProductCode) => `${API_BASE_URL}/product/${productCode}`,
  getNewProductsList: () => `${API_BASE_URL}/new_product`,
  stopTracking: (productCode: TProductCode, storeId: TStoreId) =>
    `${API_BASE_URL}/new_product/${productCode}/${storeId}`,
  getAssortmentStatistics: (year: number, week: number) =>
    `${API_BASE_URL}/store_product_weekly/year/${year}/week/${week}/statistics`,
  getValidateMainAssortmentList: (year: number, week: number) =>
    `${API_BASE_URL}/store_product_weekly/year/${year}/week/${week}/validate`,
  uploadFileWithWeeklyAssortment: (year: number, week: number) =>
    `${API_BASE_URL}/store_product_weekly/year/${year}/week/${week}`,
};

export interface TGetProductListParams {
  product_code_prefix?: string;
  product_description_query?: string;
  family_code?: TFamilyCode;
  root_family_code?: TFamilyCode;
  is_regional?: boolean;
  start_date?: string;
  end_date?: string;
  minimum_stock?: boolean;
  weekend_product?: boolean;
  always_available?: boolean;
  supplier_name?: string;
  paging_bookmark?: number;
  paging_forward?: boolean;
  limit?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_product__stats__get
 */
export const getProductsFamiliesStats = () => {
  return pipe(API_URLS.getProductsFamiliesStats(), createValidatedCancellableFetch(ProductFamiliesStatsValidator));
};

const defaultListParams: Partial<TGetProductListParams> = {
  limit: 100,
  paging_forward: true,
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_product__get
 */
export const getProductList = (params: TGetProductListParams) => {
  const queryParams = { ...defaultListParams, ...params };

  return pipe(
    API_URLS.getProductList(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(ProductsValidator),
  );
};

export interface TGetProductDetailsParams {
  productCode: TProductCode;
}

export interface TProductDetailsStatistics {
  productCode: TProductCode;
  storeId: TStoreId;
}

export type TProductAnomaliesRequestQueryParams = {
  product_code: TProductCode;
  store_ids?: TStoreId[];
  length?: number;
};

export type TProductStatisticsRequestQueryParams = {
  product_codes: TProductCode[];
  store_ids?: TStoreId[];
  date: string;
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_product__product_code__get
 */
export const getProductDetails = ({ productCode }: TGetProductDetailsParams) =>
  pipe(API_URLS.getProductDetails(productCode), createValidatedCancellableFetch(ProductDetailsValidator));

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_product__product_full_code__get
 */
export const getProductFullDetails = ({ productCode }: TGetProductDetailsParams) =>
  pipe(API_URLS.getProductFullDetails(productCode), createValidatedCancellableFetch(ProductKPIDailyValidator));

// FIXME: resolve URL path
/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/????
 */
export const getWeekProductAnalyticsDetails = (queryParams: TProductStatisticsRequestQueryParams) =>
  pipe(
    API_URLS.getWeekProductStatistics(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(ProductDetailsStatisticsValidatorArray),
  );

// FIXME: resolve URL path
/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/????
 */
export const getMonthProductAnalyticsDetails = (queryParams: TProductStatisticsRequestQueryParams) =>
  pipe(
    API_URLS.getMonthProductStatistics(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(ProductDetailsStatisticsValidatorArray),
  );

export const getSalesAnomalyProductStats = (queryParams: TProductAnomaliesRequestQueryParams) =>
  pipe(
    API_URLS.getSalesAnomalyProductStats(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(ProductAnomalyDetailsStatisticsValidatorArray),
  );

export interface TDeleteProductParams {
  productCode: TProductCode;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/delete_product__product_code__delete
 */
export const deleteProduct = ({ productCode }: TDeleteProductParams) =>
  pipe(API_URLS.deleteProduct(productCode), (url) =>
    makeCancellableFetch(url, {
      method: 'DELETE',
    }),
  );

interface TProductUpdatePayload extends Pick<TCreateProduct, 'description' | 'family_code'> {
  is_regional: boolean;
}

export interface TCreateProductsParams {
  products: TNewProducts;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/save_many_product__post
 */
export const createProducts = ({ products }: TCreateProductsParams) => {
  const payload = products.reduce(
    (acc, p) => ({
      ...acc,
      [p.product_code]: {
        description: p.description,
        family_code: p.family_code,
        is_regional: pipe(
          p.is_regional,
          O.getOrElse(() => false),
        ),
        minimum_stock: pipe(
          p.minimum_stock,
          O.getOrElse(() => 0),
        ),
        weekend_product: pipe(
          p.weekend_product,
          O.getOrElse(() => false),
        ),
        always_available: pipe(
          p.weekend_product,
          O.getOrElse(() => false),
        ),
        ...(O.isSome(p.supplier_id)
          ? {
              supplier_id: pipe(
                p.supplier_id,
                O.getOrElse(() => 0),
              ),
            }
          : {}),
        ...(O.isSome(p.first_appearance_date)
          ? {
              first_appearance_date: pipe(
                p.first_appearance_date,
                O.getOrElse(() => ''),
              ),
            }
          : {}),
      },
    }),
    {} as Record<TProductCode, TProductUpdatePayload>,
  );

  return makeCancellableFetch(API_URLS.createProducts(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export interface TUploadFileWithProductsParams {
  file: File;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/save_many_product_upload__post
 */
export const uploadFileWithProducts = ({ file }: TUploadFileWithProductsParams) => {
  const body = new FormData();

  body.append('csv_file', file);

  return makeCancellableFetch(API_URLS.uploadFileWithProducts(), {
    method: 'POST',
    body,
  });
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_top_anomalous_products_sales_top_anomalous_csv_get
 */
export const getProductFamiliesCSVLink = (queryParams: TGetProductExtendedInfoCSVParams) =>
  pipe(API_URLS.getExportableProductFamiliesCSV(), urlWithQueryParams({ ...queryParams }));

export type TGetGoodToHaveForStores = {
  store_id: TStoreId[];
  family_code?: string;
  section_id?: TSectionCode;
  limit?: number;
  offset?: number;
};

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_good_product__get
 */
export const getGoodToHaveForStores = (queryParams: TGetGoodToHaveForStores) =>
  pipe(
    API_URLS.getGoodToHave(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(GoodToHaveDataValidator),
  );

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/find_new_product__get
 */
export const getNewProductsList = (root_family_code?: TFamilyCode, store_ids?: TStoreId[], limit = 10) =>
  pipe(
    API_URLS.getNewProductsList(),
    urlWithQueryParams({ root_family_code, store_ids, limit }),
    createValidatedCancellableFetch(TaskNewProductsValidator),
  );

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/delete_new_product__product_code__delete
 */
export const stopTracking = (params: [TProductCode, TStoreId]) => {
  return makeCancellableFetch(API_URLS.stopTracking(params[0], params[1]), { method: 'delete' });
};

export const getWeeklyProductManagement = (params: TWeeklyProductManagementParams) =>
  pipe(
    API_URLS.getWeeklyProductManagement(),
    urlWithQueryParams({ ...params }),
    createValidatedCancellableFetch(WeeklyProductManagementListValidator),
  );

export const saveWeeklyProductManagement = (data: SaveWeeklyProductManagementList) => {
  return makeCancellableFetch<{ message: string }>(API_URLS.getWeeklyProductManagement(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const getStoreProductWeeklyWeekCheck = (year: number, week: number, params: TNewWeekQueryParams) =>
  pipe(
    API_URLS.getStoreProductWeeklyWeekCheck(year, week),
    urlWithQueryParams({ ...params }),
    createValidatedCancellableFetch(StoreProductWeeklyWeekCheckValidator),
  );

export const saveStoreProductWeeklyWeekCheck = (year: number, week: number, params: TCreateNewWeekQueryParams) =>
  pipe(
    API_URLS.getStoreProductWeeklyWeekCheck(year, week),
    urlWithQueryParams({ ...params }),
    createValidatedCancellableFetch(ProductWeeklyCheckResponseValidator, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }),
  );

export const validateMainAssortmentList = ({ year, week }: { year: number; week: number }) => {
  return makeCancellableFetch(API_URLS.getValidateMainAssortmentList(year, week), {
    method: 'POST',
  });
};

export const uploadFileWithWeeklyAssortment = ({
  year,
  week,
  file,
  params,
}: {
  year: number;
  week: number;
  file: File;
  params: TCreateWeekQueryParams;
}) => {
  const body = new FormData();
  body.append('excel_file', file);

  return pipe(
    API_URLS.uploadFileWithWeeklyAssortment(year, week),
    urlWithQueryParams({ ...params }),
    createValidatedCancellableFetch(ProductWeeklyAssortmentUploadValidator, {
      method: 'POST',
      body,
    }),
  );
};

export const getAssortmentStatistics = (params: TAssortmentStatisticsParams) =>
  pipe(
    API_URLS.getAssortmentStatistics(params.year, params.week),
    urlWithQueryParams({ ...params }),
    createValidatedCancellableFetch(ProductAssortmentStatisticsListValidator),
  );
