const prefixVariable = (v: string) => `--wm-${v}`;

export const CSS_VARIABLES = {
  GRID_GAP_DEFAULT: prefixVariable('grid-gap-default'),
  ACCENT_COLOR: prefixVariable('accent-color'),
  SIDE_BAR: {
    BACKGROUND_ACTIVE: prefixVariable('sidebar-bg-active'),
    WIDTH: prefixVariable('sidebar-width'),
    WIDTH_COLLAPSED: prefixVariable('sidebar-width-collapsed'),
  },
  TOPBAR: {
    HEIGHT: prefixVariable('topbar-height'),
  },
  STORE_CLUSTERS: {
    RECOMMENDATION_COLOR: prefixVariable('clusters-recommendation-color'),
    RECOMMENDATION_OUTLINE_COLOR: prefixVariable('clusters-recommendation-outline'),
    TRAJECTORY_PATH_STROKE_COLOR: prefixVariable('trajectory-stroke-color'),
    STEPS_PATH_STROKE_COLOR: prefixVariable('steps-stroke-color'),
  },
} as const;

export const DESKTOP_BREAKPOINT = 'xl' as const;

export const GRID_GAP_DEFAULT_VALUE = `var(${CSS_VARIABLES.GRID_GAP_DEFAULT}, 14px)`;
