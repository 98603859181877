import React from 'react';
import { Box, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { PROBABILITY_THRESHOLD } from 'pages/AnalyticsPage/AreaShortagePage/utils';
import { useTranslation } from 'react-i18next';
import { formatProcentage } from 'utils/formatters';

type ProbablitySliderThresholdProps = {
  probabilityThreshold: number;
  setProbabilityThreshold: (threshold: number) => void;
};

const labelStyles = {
  mt: '2',
  ml: '-2',
  fontSize: 'xs',
};

export const ProbablitySliderThreshold: React.VFC<ProbablitySliderThresholdProps> = ({
  setProbabilityThreshold,
  probabilityThreshold,
}) => {
  const { t } = useTranslation('whisperme');

  return (
    <Box flexGrow={1} paddingX="1rem" flexDirection="row" alignItems="center" display="flex">
      <Text marginRight="1rem">{t('OBJECTIVES.COMMON.SELECTORS.SELECT_PROBABLITY_THRESHOLD')}</Text>
      <Slider
        size="sm"
        step={0.01}
        min={0.01}
        maxW="200px"
        defaultValue={PROBABILITY_THRESHOLD}
        onChange={setProbabilityThreshold}
        max={0.2}
      >
        <SliderMark value={0.01} {...labelStyles}>
          1%
        </SliderMark>
        <SliderMark value={0.1} {...labelStyles}>
          10%
        </SliderMark>
        <SliderMark value={0.2} {...labelStyles}>
          20%
        </SliderMark>
        <SliderMark
          value={probabilityThreshold}
          textAlign="center"
          bg="blue.300"
          borderRadius="5px"
          color="white"
          mt="-7"
          ml="-5"
          w="10"
          fontSize="xs"
        >
          {formatProcentage(probabilityThreshold)}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack bg="blue.300" />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Box>
  );
};
