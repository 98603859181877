import { useQuery } from 'react-query';
import { createProductsListParamKey } from 'constants/queryCacheKeys';
import { getProductList, TGetProductListParams } from 'domain/products';
import { useDebounce } from 'react-use';
import { useState } from 'react';
import { TFamilyCode } from 'domain/core';

/**
 * Hook for quering data from
 */
export const useProductSearch = (query: string, rootFamilyParams?: TFamilyCode) => {
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const params: TGetProductListParams = {
    limit: 30,
    root_family_code: rootFamilyParams,
  };

  useDebounce(() => setCurrentQuery(query), 300, [query]);

  if (currentQuery && Number.isInteger(parseInt(currentQuery, 10))) {
    params.product_code_prefix = query;
  } else if (currentQuery) {
    params.product_description_query = query;
  }

  return useQuery(createProductsListParamKey(params), () => getProductList(params), {
    enabled: !!currentQuery,
  });
};
