import { Select, SelectProps } from '@chakra-ui/react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type TSortOption = {
  value: string;
  label: string;
};

export const generateShortagesSortDefaultOptions = (): TSortOption[] => [
  { value: 'shortage_time', label: 'Shortage Time' },
  { value: 'ean', label: 'EAN7' },
  { value: 'shelf', label: 'Shelf + IFLS' },
];

type SortProps<T = string> = {
  onChange: (value: T) => void;
  value: string;
  options?: TSortOption[];
  useDefaultOptions?: boolean;
} & Omit<SelectProps, 'children' | 'onChange' | 'value'>;

export const ShortagesSort = <T extends string>({
  onChange,
  value,
  options = [],
  useDefaultOptions = true,
  ...rest
}: SortProps<T>) => {
  const { t } = useTranslation('whisperme');
  const defaultOptions = generateShortagesSortDefaultOptions().map((item) => {
    return {
      value: item.value,
      label: t(`DAILY_TASKS.SHORTAGE.SORT_BY_${item.value.toUpperCase()}_LABEL`),
    };
  });
  const optionValues = useMemo(
    () =>
      pipe(
        useDefaultOptions ? [...defaultOptions, ...options] : options,
        A.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        )),
      ),
    [useDefaultOptions, defaultOptions, options],
  );

  const handleSelected = useCallback<Required<SelectProps>['onChange']>(
    (evt) => {
      onChange(evt.target.value as unknown as T);
    },
    [onChange],
  );

  return (
    <Select value={value} w="8rem" size="xs" onChange={handleSelected} {...rest}>
      {optionValues}
    </Select>
  );
};
