import { TableColumnHeaderProps } from '@chakra-ui/react';
import { SortedT } from 'components/TableHeader';
import { useMemo, useState } from 'react';

const directions = {
  ASC: 'DESC',
  DESC: undefined,
} as const;

type SortState = [column: string | undefined, direction: SortedT];

const lookupDirection = (name: string, direciton: SortedT): SortState => {
  const newDirection = direciton ? directions[direciton] : 'ASC';

  return newDirection !== undefined ? [name, newDirection] : [undefined, undefined];
};

/**
 * Hook for creating simple sort state.
 */
export const useSortTable = () => {
  const [[column, direction], setSort] = useState<SortState>([undefined, undefined]);
  return useMemo(() => {
    const createColumnOnClick =
      (name: string): TableColumnHeaderProps['onClick'] =>
      (event) => {
        event.stopPropagation();
        setSort(column === name ? lookupDirection(name, direction) : [name, 'ASC']);
      };

    const isSorted = (name: string) => {
      if (column === name) return direction;

      return undefined;
    };

    return {
      column,
      direction,
      createColumnOnClick,
      isSorted,
    };
  }, [column, direction, setSort]);
};

export type SortTableT = ReturnType<typeof useSortTable>;
