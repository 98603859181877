import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { APP_ROOT_ID } from 'constants/env';
import { CSS_VARIABLES, DESKTOP_BREAKPOINT } from 'constants/css';
// NOTE(m.kania): direct import to avoid potential cycles
import { contentPadding, tabsMargin } from 'components/Dashboard/styles';
import { DATE_PICKER_PORTAL_ID } from 'components/DateRangePicker';

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

export const chakraTheme = extendTheme({
  styles: {
    global: {
      'html, body': {
        height: '100%',
        overflow: 'hidden',
      },
      body: {
        // NOTE(m.kania): xd design uses #c40f39, while chakra's red.600 is #C53030
        [CSS_VARIABLES.ACCENT_COLOR]: 'colors.red.600',
        [CSS_VARIABLES.TOPBAR.HEIGHT]: {
          base: '72px',
        },
        [CSS_VARIABLES.GRID_GAP_DEFAULT]: 'space.4',
        [CSS_VARIABLES.STORE_CLUSTERS.RECOMMENDATION_COLOR]: 'colors.blue.100',
        [CSS_VARIABLES.STORE_CLUSTERS.RECOMMENDATION_OUTLINE_COLOR]: 'colors.blue.500',

        [`#${DATE_PICKER_PORTAL_ID} .react-datepicker-popper, .visx-tooltip`]: {
          zIndex: 'popover',
        },
        '.react-datepicker': {
          display: 'flex',
          flexDirection: 'column',

          '.react-datepicker__navigation-icon': {
            '&:before': {
              top: '10px',
            },
          },
        },
        '.react-datepicker__portal': {
          '.react-datepicker__header': {
            padding: '2px 0',
          },
          '.react-datepicker__navigation-icon': {
            '&:before': {
              top: '12px',
            },
          },
        },
      },
      [`#${APP_ROOT_ID}`]: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      },

      ul: {
        listStyleType: 'none',
      },
      '.rbc-off-range-bg': {
        bg: 'gray.50',
      },
      '.rbc-month-header': {
        bg: 'gray.100',
      },
      '.rbc-date-cell': {
        fontSize: 'sm',
      },
      '.visx-tooltip': {
        '&:not(.visx-crosshair,.visx-tooltip-glyph)': {
          color: 'gray.600',
          border: '1px',
          borderColor: 'gray.200',
        },
      },
      'body [data-hidden-print]': {
        '@media print': {
          display: 'none !important',
        },
      },
    },
  },
  fonts: {
    cursive: 'Bebas Neue, cursive',
  },
  components: {
    Progress: {
      variants: {
        increase: {
          track: {
            borderRadius: '6',
          },
          filledTrack: {
            bg: 'green.300',
          },
        },
        decrease: {
          track: {
            borderRadius: '6',
          },
          filledTrack: {
            bg: 'red.300',
          },
        },
        equal: {
          track: {
            borderRadius: '6',
          },
          filledTrack: {
            bg: 'yellow.300',
          },
        },
      },
    },
    Table: {
      variants: {
        kpi: (props: Record<string, unknown>) => {
          const { colorScheme: c } = props;

          return {
            table: {
              tableLayout: 'fixed',
            },
            thead: {
              bg: 'gray.100',
            },
            th: {
              px: '2',
              py: '1',
              lineHeight: '4',
              fontSize: 'min(10px, 0.75rem)',
              ...numericStyles,
            },
            td: {
              px: '2',
              py: '2',
              fontSize: 'xs',
              lineHeight: '4',
              borderBottom: '1px',
              borderColor: `${c}.100`,
              ...numericStyles,
            },
            caption: {
              px: '2',
              py: '1',
              fontSize: 'xs',
            },
          };
        },
        // TODO(m.kania): rename variants when all types of tables are know
        products: (props: Record<string, unknown>) => {
          const { colorScheme: c } = props;

          let variant: 'md' | 'sm' = 'md';
          if ('size' in props && props.size === 'sm') {
            variant = 'sm';
          }

          return {
            table: {
              // tableLayout: 'fixed',
            },
            thead: {
              bg: 'gray.100',
            },
            th: {
              px: variant === 'sm' ? '3' : '4',
              py: variant === 'sm' ? '2' : '3',
              lineHeight: '4',
              fontSize: 'xs',
              ...numericStyles,
            },
            td: {
              px: variant === 'sm' ? '3' : '4',
              py: variant === 'sm' ? '1' : '2',
              fontSize: 'sm',
              lineHeight: '5',
              borderBottom: '1px',
              borderColor: `${c}.100`,
              ...numericStyles,
            },
            caption: {
              px: '2',
              py: '1',
              fontSize: 'xs',
            },
          };
        },
      },
    },
    Tabs: {
      variants: {
        dashboard: (props: Record<string, unknown>) => {
          const { colorScheme: c } = props;

          return {
            root: {
              ...tabsMargin,
            },
            tab: {
              py: 1,
              px: {
                base: 3,
                [DESKTOP_BREAKPOINT]: 4,
              },
              fontSize: 'sm',
              whiteSpace: 'nowrap',

              '& > svg': {
                fontSize: '1.15em',
                mr: '2',
              },

              _selected: {
                color: mode(`${c}.500`, `${c}.300`)(props),
                borderColor: 'currentColor',
              },
              _active: {
                bg: mode('gray.200', 'whiteAlpha.300')(props),
              },
              _disabled: {
                opacity: 0.4,
                cursor: 'not-allowed',
              },
            },
            tablist: {
              px: contentPadding.px,
              borderTop: '1px',
              borderTopColor: 'gray.200',
              bg: 'white',
              boxShadow: 'md',
              position: 'sticky',
              zIndex: 'docked',
              top: `var(${CSS_VARIABLES.TOPBAR.HEIGHT})`,
              overflowX: 'auto',
              scrollbarWidth: 'thin',
            },
            tabpanel: {
              ...contentPadding,
            },
          };
        },
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          zIndex: 'popover',
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          zIndex: 'popover',
        },
      },
    },
  },
});

export type ChakraTheme = typeof chakraTheme;
