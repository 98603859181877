import { pipe } from 'fp-ts/function';

import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, makeCancellableFetch, urlWithQueryParams } from 'utils/request';
import { dateToISOString } from 'utils/date';
import { TFamilyCode, TGetAnyCsvParams, TProductCode } from '../core';
import { TStoreId } from '../stores';
import {
  AnomalyCalendarArrayValidator,
  ESalesAnomalyDataResolution,
  SalesAnomalyArrayValidator,
  TopAnomalousArrayValidator,
  LogisticAnomalyArrayValidator,
  TAreaIdType,
  TTagType,
  AllObjectivesArrayValidator,
  SectionsAnomalyArrayValidator,
} from './types';

const API_URLS = {
  getSalesAnomalies: () => `${API_BASE_URL}/sales/anomaly`,
  getTopAnomalous: () => `${API_BASE_URL}/sales/top_anomalous`,
  getTopAnomalousCSV: () => `${API_BASE_URL}/sales/top_anomalous/xlsx`,
  getAnomalyCalendar: () => `${API_BASE_URL}/sales/anomaly_calendar`,
  getLogisticAnomalies: () => `${API_BASE_URL}/sales/logistic_anomalies`,
  getSectionAnomalies: () => `${API_BASE_URL}/sales/section_anomaly_calendar`,

  getObjective: ({ area_id, tag }: TGetObjectiveParams) => `${API_BASE_URL}/objective/${tag}/${area_id}`,
  getObjectivesList: () => `${API_BASE_URL}/objective/`,
};

export interface TGetSalesAnomaliesParams {
  // TODO(m.kania): iso string types?
  first: string;
  end: string;
  family_id?: TFamilyCode;
  root_family_id?: TFamilyCode;
  resolution: ESalesAnomalyDataResolution;
  probability_threshold?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_anomalies_sales_anomaly_get
 */
export const getSalesAnomalies = (queryParams: TGetSalesAnomaliesParams) => {
  return pipe(
    API_URLS.getSalesAnomalies(),
    urlWithQueryParams({ ...queryParams }),
    createValidatedCancellableFetch(SalesAnomalyArrayValidator),
  );
};

export interface TGetTopAnomalousParams {
  store_id: TStoreId | TStoreId[];
  probability_threshold?: number;
  logistic_threshold?: number;
  first?: Date;
  end?: Date;
  family_id?: TFamilyCode;
  root_family_id?: TFamilyCode;
  is_local?: boolean;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_top_anomalous_products_sales_top_anomalous_get
 */
export const getTopAnomalous = ({ first, end, ...queryParams }: TGetTopAnomalousParams) =>
  pipe(
    API_URLS.getTopAnomalous(),
    urlWithQueryParams({
      first: dateToISOString(first),
      end: dateToISOString(end),
      ...queryParams,
    }),
    createValidatedCancellableFetch(TopAnomalousArrayValidator),
  );

export interface TGetTopAnomalousCSVParams extends TGetTopAnomalousParams, TGetAnyCsvParams {}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_top_anomalous_products_sales_top_anomalous_csv_get
 */
export const getTopAnomalousCSVLink = ({ first, end, ...queryParams }: TGetTopAnomalousCSVParams) =>
  pipe(
    API_URLS.getTopAnomalousCSV(),
    urlWithQueryParams({
      first: dateToISOString(first),
      end: dateToISOString(end),
      ...queryParams,
    }),
  );

export interface TGetAnomalyCalendarParams {
  product_code: TProductCode;
  store_id: TStoreId | TStoreId[];
  probability_threshold?: number;
  logistic_threshold?: number;
  first?: Date;
  end?: Date;
  is_local?: boolean;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_anomaly_calendar_sales_anomaly_calendar_get
 */
export const getAnomalyCalendar = ({ first, end, ...queryParams }: TGetAnomalyCalendarParams) =>
  pipe(
    API_URLS.getAnomalyCalendar(),
    urlWithQueryParams({
      first: dateToISOString(first),
      end: dateToISOString(end),
      ...queryParams,
    }),
    createValidatedCancellableFetch(AnomalyCalendarArrayValidator),
  );

export interface TGetSectionAnomaliesParams {
  store_id: TStoreId[];
  probability_threshold?: number;
  first?: Date;
  end?: Date;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_anomaly_calendar_sales_anomaly_calendar_get
 */
export const getSectionAnomalies = ({ first, end, ...queryParams }: TGetSectionAnomaliesParams) =>
  pipe(
    API_URLS.getSectionAnomalies(),
    urlWithQueryParams({
      first: dateToISOString(first),
      end: dateToISOString(end),
      ...queryParams,
    }),
    createValidatedCancellableFetch(SectionsAnomalyArrayValidator),
  );

export interface TGetLogisticAnomaliesParams {
  store_id: TStoreId | TStoreId[];
  probability_threshold?: number;
  resolution: ESalesAnomalyDataResolution;
  family_id?: TFamilyCode;
  root_family_id?: TFamilyCode;
  first?: Date;
  end?: Date;
  logistic_threshold?: number;
  problematic_threshold?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_logistic_anomaly_stats_sales_logistic_anomalies_get
 */
export const getLogisticAnomalies = ({ first, end, ...queryParams }: TGetLogisticAnomaliesParams) =>
  pipe(
    API_URLS.getLogisticAnomalies(),
    urlWithQueryParams({
      first: dateToISOString(first),
      end: dateToISOString(end),
      ...queryParams,
    }),
    createValidatedCancellableFetch(LogisticAnomalyArrayValidator),
  );

export interface TGetObjectiveParams {
  area_id: TAreaIdType;
  tag: TTagType;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_objective_objective__tag___area_id__get
 */
export const getObjective = (params: TGetObjectiveParams) => pipe(API_URLS.getObjective(params), makeCancellableFetch);

export interface TUpdateObjectiveParams extends TGetObjectiveParams {
  value: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/set_objective_objective__tag___area_id__put
 */
export const putObjective = ({ area_id, tag, value }: TUpdateObjectiveParams) =>
  pipe(API_URLS.getObjective({ area_id, tag }), (url) =>
    makeCancellableFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value }),
    }),
  );

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/delete_objective_objective__tag___area_id__delete
 */
export const deleteObjective = (params: TGetObjectiveParams) =>
  pipe(API_URLS.getObjective(params), (url) =>
    makeCancellableFetch(url, {
      method: 'DELETE',
    }),
  );

export interface TGetAllObjectivesParams {
  area_id: TAreaIdType | TAreaIdType[];
  tag: TTagType | TTagType[];
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/find_objectives_objective__get
 */
export const getAllObjectives = (params: TGetAllObjectivesParams) =>
  pipe(
    API_URLS.getObjectivesList(),
    urlWithQueryParams({ ...params }),
    createValidatedCancellableFetch(AllObjectivesArrayValidator),
  );
