import * as O from 'fp-ts/Option';

import { TAnomalyCalendar } from 'domain/objectives';
import { DataMapEntry } from './types';

export const anomalyToTimeOfDay = (anomaly: TAnomalyCalendar): O.Option<keyof DataMapEntry> => {
  switch (anomaly.category) {
    case '08-12':
      return O.some('morning');
    case '13-16':
      return O.some('afternoon');
    case '17-23':
      return O.some('evening');
    default:
      return O.none;
  }
};

export const LEGEND_THRESHOLD_LEVELS = {
  THRESHOLD_0: { variant: 'empty', description: 'OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.THRESHOLD_0' },
  THRESHOLD_1: { variant: 'ok', description: 'OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.THRESHOLD_1' },
  THRESHOLD_2: {
    variant: 'possible',
    description: 'OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.THRESHOLD_2',
  },
  THRESHOLD_3: { variant: 'error', description: 'OBJECTIVES.AREA_SHORTAGE_LEVEL.TABLE.THRESHOLD_3' },
};

export const CASHIER_RISK_SHRESHOLD_LEVELS = {
  THRESHOLD_0: { variant: 'risky', description: 'DAILY_TASKS.CASHIER_ADVICE.RISK_LEGEND.RISKY' },
  THRESHOLD_1: {
    variant: 'safe',
    description: 'DAILY_TASKS.CASHIER_ADVICE.RISK_LEGEND.SAFE',
  },
  THRESHOLD_2: { variant: 'max', description: 'DAILY_TASKS.CASHIER_ADVICE.RISK_LEGEND.MAX' },
  THRESHOLD_3: { variant: 'error', description: 'DAILY_TASKS.CASHIER_ADVICE.RISK_LEGEND.ERROR' },
};
