import * as t from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

export const makeCancellableFetch = <T>(url: string, opts?: RequestInit): Promise<T> => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const promise = new Promise<T>(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'get',
        ...opts,
        signal,
      });

      if (response.ok) {
        if (response.status === 204 || response.status === 202) {
          resolve(null as any);
        } else {
          const data = await response.json();
          resolve(data);
        }
      }

      if (response.status === 401) {
        reject(new UnauthorizedHttpError());
      }
      reject(new HttpError(`[HttpError] - ${response.status} ${response.statusText}`));
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log('[makeCancellableFetch error]', err);
      }
      // NOTE(m.kania): request wasn't cancelled, so reject
      if (err instanceof Error && err.name !== 'AbortError') {
        reject(err);
      }
    }
  });

  // @ts-ignore
  promise.cancel = () => abortController.abort();

  return promise;
};

export const createValidatedCancellableFetch =
  <T, O, I>(validator: t.Type<T, O, I>, opts?: RequestInit) =>
  (...params: Parameters<typeof makeCancellableFetch>) => {
    const [url, paramsOpts] = params;
    const response = makeCancellableFetch(url, { ...opts, ...paramsOpts });

    const promise = response.then((data) =>
      pipe(data as any, validator.decode, (decodedResult) => {
        if (E.isLeft(decodedResult)) {
          const errorMessages = PathReporter.report(decodedResult);
          throw new Error(errorMessages.join('\n'));
        }

        return decodedResult.right;
      }),
    );

    // @ts-ignore
    promise.cancel = response.cancel;

    return promise;
  };

interface TIsValidQueryParamValueParams {
  allowEmptyString?: boolean;
}

const isValidQueryParamValue = (v: unknown, { allowEmptyString }: TIsValidQueryParamValueParams = {}) =>
  (typeof v === 'string' && (allowEmptyString || v.length > 0)) || typeof v === 'number' || typeof v === 'boolean';

export const recordToQueryString = (obj: Record<string, unknown>, params?: TIsValidQueryParamValueParams): string => {
  const keys = Object.keys(obj);

  if (keys.length > 0) {
    return keys
      .reduce((acc, key) => {
        const value = obj[key];

        if (Array.isArray(value) && value.length > 0) {
          value.forEach((v) => {
            if (isValidQueryParamValue(v, params)) {
              acc.append(key, String(v));
            }
          });
        } else if (isValidQueryParamValue(value, params)) {
          acc.append(key, String(value));
        }

        return acc;
      }, new URLSearchParams())
      .toString();
  }

  return '';
};

export const urlWithQueryParams =
  (queryParams: Record<string, unknown | unknown[]>, params?: TIsValidQueryParamValueParams) => (url: string) => {
    const qs = recordToQueryString(queryParams, params);

    if (qs !== '') {
      return [url, qs].join('?');
    }

    return url;
  };

export const queryStringToRecord = (queryString: string): Record<string, string> =>
  Object.fromEntries(new URLSearchParams(queryString).entries());

export type TParamsToOptions<Params> = {
  readonly [Param in keyof Params]-?: O.Option<NonNullable<Params[Param]>>;
};

export type TQueryStringParamsType = Record<string, unknown> | null;

export type TQueryStringParamsTypeValidator<ParamsType> = (
  params?: TQueryStringParamsType,
) => TParamsToOptions<ParamsType>;

export const createQueryStringParamsValidator =
  <ParamsType>(validateParams: TQueryStringParamsTypeValidator<ParamsType>) =>
  (search = '') =>
    pipe(search, queryStringToRecord, validateParams);

export class HttpError extends Error {}
export class UnauthorizedHttpError extends HttpError {
  constructor() {
    super('[HttpError] - 401 Unauthorized');
  }
}

export const getExportLink =
  (path = 'xlsx') =>
  (link: string) => {
    return `${link}/${path}`;
  };
