import {
  DashboardTab,
  DashboardTabList,
  DashboardTabPanel,
  DashboardTabPanels,
  DashboardTabs,
  TopbarHeading,
} from 'components/Dashboard';
import { hasMonitorAccess } from 'domain/user';
import { useUserContext } from 'hooks';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { SupermarketSelectedAssortment } from './SupermarketSelectedAssortment';
import { AssortmentManagementMapping, findAssortmentManagementMatch } from './utils';
import { WeeklyProductListManagement } from './WeeklyProductList';

export const AssortmentManagementPage: React.FC = () => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('whisperme');
  const navigationMatcher = findAssortmentManagementMatch();
  const navigationMatch = navigationMatcher(pathname);
  const [tabIndex, setTabIndex] = useState<number>(navigationMatch);
  const { user } = useUserContext();
  const monitorScope = hasMonitorAccess(user);

  const handleTabsChange = useCallback(
    (index: number) => {
      const [_newMatch, newPath] = AssortmentManagementMapping[index];
      if (typeof newPath === 'string') {
        replace(newPath);
        setTabIndex(index);
      }
    },
    [replace, setTabIndex],
  );

  return (
    <DashboardTabs index={tabIndex} onChange={handleTabsChange}>
      {monitorScope ? (
        <DashboardTabList>
          <DashboardTab>{t('SETTINGS.ASSORTMENT_ANALYSIS.ASSORTMENT_MANAGEMENT_TAB_LABEL')}</DashboardTab>
          <DashboardTab>{t('SETTINGS.ASSORTMENT_ANALYSIS.SELECTED_ASSORTMENT_BY_SUPERMARKET_TAB_LABEL')}</DashboardTab>
        </DashboardTabList>
      ) : null}
      <DashboardTabPanels>
        <DashboardTabPanel>
          <TopbarHeading>{t('SETTINGS.ASSORTMENT_ANALYSIS.ASSORTMENT_MANAGEMENT_TAB_LABEL')}</TopbarHeading>
          <WeeklyProductListManagement />
        </DashboardTabPanel>
        <DashboardTabPanel>
          <TopbarHeading>
            {t('SETTINGS.ASSORTMENT_ANALYSIS.SELECTED_ASSORTMENT_BY_SUPERMARKET_TAB_LABEL')}
          </TopbarHeading>
          <SupermarketSelectedAssortment />
        </DashboardTabPanel>
      </DashboardTabPanels>
    </DashboardTabs>
  );
};
