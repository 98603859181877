import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Select, Stack, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { CardChartSkeleton } from 'components/Skeletons';
import { createInsightsKey } from 'constants/queryCacheKeys';
import { ChartLoadingOverlay } from 'components/Charts';
import { getInsights, INSIGHT_TYPE_VALUES, isInsightType, TGetInsightsParams } from 'domain/insights';
import { NullableSingleStoreSelect } from 'components/Select';
import { useStoresDataMap } from 'hooks/data';
import { InsightFilterValue } from './types';
import { InsightObservationTag } from './InsightObservationTag';
import { TStoreId } from 'domain/stores';
import { useUserStoreIds } from 'hooks';
import { InsightDetails } from './InsightDetails';

const emptyData: never[] = [];

const LIMIT = 50;

interface Props {
  children?: never;
}

export const InsightsPage: React.FC<Props> = () => {
  const { t } = useTranslation('whisperme');
  const [observationType, setObservationType] = useState<InsightFilterValue>('all');
  const userStoreIds = useUserStoreIds();
  const [storeId, setStoreId] = useState<TStoreId | undefined>(() =>
    userStoreIds.length === 1 ? userStoreIds[0] : undefined,
  );
  const storesMap = useStoresDataMap();

  const params: TGetInsightsParams = useMemo(() => {
    const ids = typeof storeId === 'undefined' ? userStoreIds : [storeId];

    return {
      store_id: ids,
    };
  }, [storeId, userStoreIds]);

  const {
    data = emptyData,
    status,
    isFetching,
  } = useQuery(createInsightsKey(params), () => getInsights(params), {
    keepPreviousData: true,
  });

  const filteredData = useMemo(
    () => pipe(observationType === 'all' ? data : data.filter((d) => d.type === observationType), A.takeLeft(LIMIT)),
    [data, observationType],
  );

  // NOTE(m.kania): initial fetch
  if (data.length === 0 && status === 'loading') {
    return <CardChartSkeleton chartMinWidth="200px" chartMinHeight="300px" />;
  }

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            {userStoreIds.length > 1 && (
              <NullableSingleStoreSelect
                size="sm"
                selectableStoreIds={userStoreIds}
                value={storeId}
                onChange={setStoreId}
                placeholder={t('INSIGHTS.ALL_STORES_PLACEHOLDER')}
              />
            )}
            <Select
              size="sm"
              value={observationType}
              onChange={(ev) => {
                const { value } = ev.target;

                if (isInsightType(value) || value === 'all') {
                  setObservationType(value);
                }
              }}
            >
              <option value="all">{t('INSIGHTS.OBSERVATION_TYPE.all')}</option>
              {INSIGHT_TYPE_VALUES.map((v) => (
                <option key={v} value={v}>
                  {t(`INSIGHTS.OBSERVATION_TYPE.${v}` as const)}
                </option>
              ))}
            </Select>
          </CardTitleSectionSettings>
        }
      >
        {t('INSIGHTS.TITLE')}
      </CardTitleSection>
      <Stack position="relative">
        <Table variant="products">
          <Thead>
            <Tr>
              <Th>{t('INSIGHTS.TABLE.DATE_LABEL')}</Th>
              <Th>{t('INSIGHTS.TABLE.STORE_NAME_LABEL')}</Th>
              <Th>{t('INSIGHTS.TABLE.OBSERVATION_LABEL')}</Th>
              <Th>{t('INSIGHTS.TABLE.DETAILS_LABEL')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((d, idx) => {
              return (
                <Tr key={`${d.type}-${d.date}-${idx}`}>
                  <Td whiteSpace="nowrap">{d.date}</Td>
                  <Td whiteSpace="nowrap">
                    <Stack as="ul">
                      {d.store_ids.map((storeId) => (
                        <Text as="li">{storesMap.get(storeId)?.name ?? storeId}</Text>
                      ))}
                    </Stack>
                  </Td>
                  <Td>
                    <InsightObservationTag variant={d.type} />
                  </Td>
                  <Td>
                    <InsightDetails details={d.details} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {status === 'success' && filteredData.length === 0 && (
          <Center>
            <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
          </Center>
        )}
        <AnimatePresence>{isFetching && <ChartLoadingOverlay />}</AnimatePresence>
      </Stack>
    </Card>
  );
};
