import * as t from 'io-ts';
import { TProductWeeklyCheckResponseValidator } from 'domain/products';

export type PostPromptParams = {
  question: string;
  llm_model_type?: string;
  user_id?: string;
};

export type PostCommandParams = {
  command: string;
  llm_type?: string;
  user_id?: string;
  week: number;
  year: number;
  group_ids?: number[];
  store_ids?: string[];
};

export type PostFeedbackParams = {
  question_id: number;
  status: string;
};

export enum ChatSource {
  QUESTION = 'question',
  ANSWER = 'answer',
  SQL = 'sql',
  DATA = 'data',
}

export type TChatMessage = {
  src: ChatSource;
  data: string;
  question_id?: number;
  time?: string;
};

export type TNeochatExampleQueriesParams = {
  n_queries: number;
};

export type NeochatPromptResponseValidator = {
  question: string;
  question_id: number;
  data: string[];
  sql: string;
  answer: string;
  status_code: number;
  detail?: string;
};

export type NeochatCommandResponseValidator = {
  question: string;
  data: TProductWeeklyCheckResponseValidator[];
  status_code?: number;
  detail?: string;
};

export enum DisplayFormat {
  SIMPLE = 'simple',
  ADVANCED = 'advanced',
}

export enum RequestedModel {
  LOCAL_LLM = 'local_llm',
  OPENAI = 'openai',
}

export enum RequestType {
  PROMPT = 'prompt',
  COMMAND = 'command',
}

export enum ExampleType {
  QUERIES = 'example_queries',
  COMMANDS = 'example_commands',
}

export const NeochatExampleQueriesValidator = t.record(t.string, t.array(t.string));
