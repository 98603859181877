import { ViewVariant, WeeklyProductManagementFilterParams } from 'domain/products';
import { flow } from 'fp-ts/lib/function';
import { useReducer, useMemo } from 'react';
import { TStoreId } from 'domain/stores';
import { getWeek } from 'date-fns';

type FilterParamsState = {
  columnSort: Required<WeeklyProductManagementFilterParams>['column_sort'];
  week: WeeklyProductManagementFilterParams['week'];
  variant?: WeeklyProductManagementFilterParams['variant'];
  storeId: TStoreId | null;
  groupId?: WeeklyProductManagementFilterParams['group_id'] | null;
  columnDisplay?: WeeklyProductManagementFilterParams['column_display'];
  order?: WeeklyProductManagementFilterParams['order'];
  shelf?: WeeklyProductManagementFilterParams['shelf'];
  types: WeeklyProductManagementFilterParams['types'];
};

const INITIAL_STATE: FilterParamsState = {
  columnSort: 'shelf',
  storeId: null,
  groupId: null,
  order: 'ASC',
  variant: ViewVariant.TIME,
  week: 0,
  columnDisplay: 'group/',
  types: ['standard', 'promo', 'disabled', 'nodata'],
};

const SET_COLUMN_SORT = 'SET_COLUMN_SORT';

const setColumnSort = (columnSort: FilterParamsState['columnSort']) => ({
  type: SET_COLUMN_SORT,
  payload: columnSort,
});

const matchSetColumnSort = (action: unknown): action is ReturnType<typeof setColumnSort> => {
  return (action as { type: string }).type === SET_COLUMN_SORT;
};

const SET_ORDER = 'SET_ORDER';

const setOrder = (order?: FilterParamsState['order']) => ({
  type: SET_ORDER,
  payload: order,
});

const matchSetOrder = (action: unknown): action is ReturnType<typeof setOrder> => {
  return (action as { type: string }).type === SET_ORDER;
};

const SET_SHELF = 'SET_SHELF';

const matchSetShelf = (action: unknown): action is ReturnType<typeof setShelf> => {
  return (action as { type: string }).type === SET_SHELF;
};

const setShelf = (shelf?: FilterParamsState['shelf']) => ({
  type: SET_SHELF,
  payload: shelf,
});

const SET_GROUP_ID = 'SET_GROUP_ID';

const setGroupId = (groupId: FilterParamsState['groupId']) => ({
  type: SET_GROUP_ID,
  payload: groupId,
});

const matchSetGroupId = (action: unknown): action is ReturnType<typeof setGroupId> => {
  return (action as { type: string }).type === SET_GROUP_ID;
};

const SET_COLUMNS_DISPLAY = 'SET_COLUMNS_DISPLAY';

const setColumnsDisplay = (columnDisplay: FilterParamsState['columnDisplay']) => ({
  type: SET_COLUMNS_DISPLAY,
  payload: columnDisplay,
});

const matchSetColumnDisplay = (action: unknown): action is ReturnType<typeof setColumnsDisplay> => {
  return (action as { type: string }).type === SET_COLUMNS_DISPLAY;
};

const SET_STORE_ID = 'SET_STORE_ID';

const setStoreId = (storeId: FilterParamsState['storeId']) => ({
  type: SET_STORE_ID,
  payload: storeId,
});

const matchSetStoreId = (action: unknown): action is ReturnType<typeof setStoreId> => {
  return (action as { type: string }).type === SET_STORE_ID;
};

const SET_VARIANT = 'SET_VARIANT';

const setVariant = (variant?: FilterParamsState['variant']) => ({
  type: SET_VARIANT,
  payload: variant,
});

const matchSetVariant = (action: unknown): action is ReturnType<typeof setVariant> => {
  return (action as { type: string }).type === SET_VARIANT;
};

const SET_WEEK = 'SET_WEEK';

const setWeek = (week: FilterParamsState['week']) => ({
  type: SET_WEEK,
  payload: week,
});

const matchSetWeek = (action: unknown): action is ReturnType<typeof setWeek> => {
  return (action as { type: string }).type === SET_WEEK;
};

const SET_TYPE = 'SET_TYPE';

const setTypes = (types: FilterParamsState['types']) => ({
  type: SET_TYPE,
  payload: types,
});

const matchSetTypes = (action: unknown): action is ReturnType<typeof setTypes> => {
  return (action as { type: string }).type === SET_TYPE;
};

function reducer(state: FilterParamsState, action: unknown): FilterParamsState {
  if (matchSetColumnSort(action)) {
    return { ...state, columnSort: action.payload };
  }

  if (matchSetOrder(action)) {
    return { ...state, order: action.payload };
  }

  if (matchSetShelf(action)) {
    return { ...state, shelf: action.payload };
  }

  if (matchSetStoreId(action)) {
    return { ...state, storeId: action.payload };
  }

  if (matchSetGroupId(action)) {
    return { ...state, groupId: action.payload };
  }

  if (matchSetColumnDisplay(action)) {
    return { ...state, columnDisplay: action.payload };
  }

  if (matchSetVariant(action)) {
    return { ...state, variant: action.payload };
  }

  if (matchSetWeek(action)) {
    return { ...state, week: action.payload };
  }

  if (matchSetTypes(action)) {
    return { ...state, types: action.payload };
  }

  return state;
}

export const useFilterParams = () => {
  const [state, dispatch] = useReducer(
    reducer,
    INITIAL_STATE,
    (state): FilterParamsState => ({ ...state, week: getWeek(new Date()) }),
  );

  return useMemo(
    () => ({
      ...state,
      groupId: state.groupId,
      onChangeVariant: flow(setVariant, dispatch),
      onChangeColumnSort: flow(setColumnSort, dispatch),
      onChangeShelf: flow(setShelf, dispatch),
      onChangeWeek: flow(setWeek, dispatch),
      onChangeStoreId: flow(setStoreId, dispatch),
      onChangeGroupId: flow(setGroupId, dispatch),
      onChangeColumnDisplay: flow(setColumnsDisplay, dispatch),
      onChangeTypes: flow(setTypes, dispatch),
    }),
    [state],
  );
};
