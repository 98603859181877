import { createMachine, assign, InterpreterFrom, StateFrom } from 'xstate';

import { TFamilyCode, TProductCode } from 'domain/core';

export interface TMostAffectedProductsMachineContext {
  productsSection?: TFamilyCode;
  productsFamily?: TFamilyCode;
  selectedProductCode?: TProductCode;
}

export type TMostAffectedProductsMachineEvent =
  | {
      type: 'SET_SECTION';
      payload: TFamilyCode;
    }
  | { type: 'CLEAR_SECTION' }
  | {
      type: 'SET_FAMILY';
      payload: TFamilyCode;
    }
  | { type: 'CLEAR_FAMILY' }
  | {
      type: 'SELECT_PRODUCT';
      payload: TProductCode;
    }
  | {
      type: 'DESELECT_PRODUCT';
    };

export const mostAffectedProductsMachine = createMachine(
  {
    tsTypes: {} as import('./mostAffectedProductsMachine.typegen').Typegen0,
    schema: {
      context: {} as TMostAffectedProductsMachineContext,
      events: {} as TMostAffectedProductsMachineEvent,
    },
    context: {},
    initial: 'idle',
    states: {
      idle: {
        on: {
          SET_SECTION: {
            actions: 'setSection',
          },
          SET_FAMILY: {
            actions: 'setFamily',
          },
          SELECT_PRODUCT: {
            actions: 'selectProduct',
            target: 'selectedProduct',
          },
          CLEAR_SECTION: {
            actions: ['clearFamily', 'clearSection'],
          },
          CLEAR_FAMILY: {
            actions: ['clearFamily'],
          },
        },
      },
      selectedProduct: {
        on: {
          DESELECT_PRODUCT: {
            actions: 'deselectProduct',
            target: 'idle',
          },
          SET_SECTION: {
            actions: ['deselectProduct', 'setSection'],
          },
          SET_FAMILY: {
            actions: ['deselectProduct', 'setFamily'],
          },
          CLEAR_SECTION: {
            actions: ['deselectProduct', 'clearSection', 'clearFamily'],
          },
          CLEAR_FAMILY: {
            actions: ['deselectProduct', 'clearFamily'],
          },
        },
      },
    },
  },
  {
    actions: {
      setSection: assign({
        productsSection: (_ctx, ev) => ev.payload,
      }),
      clearSection: assign({
        productsSection: (_ctx, _ev) => undefined,
      }),
      setFamily: assign({
        productsFamily: (_ctx, ev) => ev.payload,
      }),
      clearFamily: assign({
        productsFamily: (_ctx, _ev) => undefined,
      }),
      selectProduct: assign({
        selectedProductCode: (_ctx, ev) => ev.payload,
      }),
      deselectProduct: assign({
        selectedProductCode: (_ctx, _ev) => undefined,
      }),
    },
  },
);

export type TMostAffectedProductsMachine = typeof mostAffectedProductsMachine;
export type TMostAffectedProductsService = InterpreterFrom<TMostAffectedProductsMachine>;
export type TMostAffectedProductsSelectorState = StateFrom<TMostAffectedProductsMachine>;
