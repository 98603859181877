import { Box, Checkbox, Input, InputGroup, InputRightElement, List, ListItem, Spinner } from '@chakra-ui/react';
import styled from '@emotion/styled';
import theme from '@chakra-ui/theme';
import { useCombobox } from 'downshift';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SelectableItem } from './types';

/**
 * Provides styling for the autocomplete list
 */
const StyledList = styled(List)`
  border-radius: 0.25rem;
  border-width: 1px;
  max-height: 10rem;
  background: '#fff';
  width: 100%;
  overflow-y: auto;
  margin-top: 0.1rem;
  position: absolute;
  top: 32px;
  z-index: 9999;
  background-color: #fff;
`;

/**
 * Provides styling for an autocomplete list item
 */
const StyledListItem = styled(ListItem)`
  padding: 0.5rem;
  &:hover,
  &:active {
    background: ${theme.colors.gray['100']};
    cursor: default;
  }
  &[aria-selected='true'] {
    background: ${theme.colors.gray['200']};
  }
  &[disabled] {
    background: ${theme.colors.gray['300']};
    cursor: not-allowed;
  }
`;

/**
 * Text input component props.
 */
export type ComboCheckboxListComponentProps = {
  /** Current value of input. */
  values: string[] | undefined;

  /**
   * Input change event handler.
   * @param value Current value of input.
   */
  onChange: (value: string) => void;

  /**
   * The results fetcher generator function
   * @param value the current value of the input
   */
  autocompleteResultsFetcher?: (value: string | undefined) => Promise<SelectableItem[]>;

  /** Sets select to use static list for using autocomplete. */
  isStatic?: boolean;

  /** List of all items. */
  items?: SelectableItem[];

  /** List od disabled datasets. */
  disabled?: string[];
};

/**
 * Combo text input component with an autocomplete feature
 * @param arg0 props of component.
 * @returns Input component.
 */
export const ComboCheckboxListComponent: FC<ComboCheckboxListComponentProps> = ({
  values,
  onChange,
  autocompleteResultsFetcher,
  isStatic,
  items,
  disabled,
}) => {
  const [selectableItems, setSelectableItems] = useState(items || []);
  const [currentValue, setCurrentValue] = useState(values || []);
  const [valuesLoading, setValuesLoading] = useState(false);

  const triggerAutocomplete: (arg0: string) => void = (fieldValue) => {
    if (isStatic && items) {
      setSelectableItems(items.filter(({ label }) => label.toLowerCase().startsWith(fieldValue.toLowerCase())));
      return;
    }

    if (fieldValue.length < 3) {
      setSelectableItems([]);
      return;
    }

    setValuesLoading(true);
    autocompleteResultsFetcher?.(fieldValue).then((data) => {
      setSelectableItems(data);
      setValuesLoading(false);
    });
  };

  useEffect(() => {
    if (values) setCurrentValue(values);
  }, [values]);

  useEffect(() => {
    if (items) setSelectableItems(items);
  }, [items]);

  const { isOpen, getInputProps, getMenuProps, getItemProps } = useCombobox({
    inputValue: items
      ?.filter(({ value }) => currentValue.includes(value))
      .map(({ label }) => label)
      .join(', '),
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: (changes) => {
      if (isStatic) {
        onChange(changes.selectedItem.value);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      if (isStatic) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            const selectedItemValue = changes?.selectedItem?.value ?? '';
            if (selectedItemValue) onChange(selectedItemValue);
            return {
              ...changes,
              isOpen: true,
              highlightedIndex: state.highlightedIndex,
              inputValue: selectedItemValue,
            };
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: '',
            };
          default:
            return changes;
        }
      }
      return changes;
    },
    items: selectableItems,
  });

  const debounceWaitTimeMillis = 750;
  const autocompleteDebounced = useCallback(
    debounce((nextValue) => triggerAutocomplete(nextValue), debounceWaitTimeMillis),
    [],
  );

  return (
    <Box position={'relative'} h={'32px'}>
      <InputGroup size={'sm'}>
        <InputRightElement pointerEvents="none">{valuesLoading && <Spinner size="sm" />}</InputRightElement>
        <Input onKeyUp={(e) => autocompleteDebounced(e.currentTarget.value)} {...getInputProps()} readOnly />
      </InputGroup>
      {isOpen && selectableItems.length > 0 && (
        <StyledList {...getMenuProps({}, { suppressRefError: true })}>
          {selectableItems.map((item, index) => {
            return (
              <StyledListItem
                {...getItemProps({
                  item,
                  index,
                  disabled: disabled ? disabled.includes(item.value) : false,
                })}
                key={`${index}${item.label}`}
              >
                <Checkbox defaultChecked={values?.includes(item.value)}>{item.label}</Checkbox>
              </StyledListItem>
            );
          })}
        </StyledList>
      )}
    </Box>
  );
};
