import { List, ListItem, Text } from '@chakra-ui/react';
import { SupermarketDisplayProps } from 'domain/stores';
import React, { useRef } from 'react';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { Ord } from 'fp-ts/string';
import { useTranslation } from 'react-i18next';

export const ShelvesList: React.FC<SupermarketDisplayProps> = ({ data }) => {
  const { t } = useTranslation('whisperme');
  const shelvesSummaryRef = useRef(0);

  return (
    <List textAlign="left">
      {pipe(
        data?.store_active_products ?? {},
        R.reduceWithIndex(Ord)(
          [] as React.ReactElement[],
          (shelfId: string, acc: React.ReactElement[], productsOnShelves: number) => {
            shelvesSummaryRef.current += productsOnShelves;
            return [
              ...acc,
              <ListItem key={`store_shelves-${shelfId}`}>
                {shelfId}: {productsOnShelves}
              </ListItem>,
            ];
          },
        ),
      )}
      <ListItem key={`store_shelves-total`} bgColor="gray.200" p="2">
        <Text as="strong">
          {t('SETTINGS.ASSORTMENT_ANALYSIS.SUPERMARKET_SELECTED_ASSORTMENT.SHELVES_SUMMARY_TOTAL_LABEL')}:
        </Text>{' '}
        {shelvesSummaryRef.current}
      </ListItem>
      ,
    </List>
  );
};
