import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { useQuery } from 'react-query';
import { LinearGradient } from '@visx/gradient';
import { scaleOrdinal } from '@visx/scale';
import { Tooltip } from '@visx/xychart';
import { AnimatePresence } from 'framer-motion';
import { scaleSequential } from 'd3-scale';
import { interpolateRainbow } from 'd3-scale-chromatic';
import { isSameDay } from 'date-fns';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import { PatternLines } from '@visx/pattern';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { DataResolutionSelect } from 'components/Select';
import { CardChartSkeleton } from 'components/Skeletons';
import { TDataResolution } from 'domain/core';
import { useAppConfig, useChartDateRangeLabel, useLiveRef, useUserSections, useUserStoreIds } from 'hooks';
import { ESalesAnomalyDataResolution, getSalesAnomalies, TGetSalesAnomaliesParams } from 'domain/objectives';
import { createSaleAnomaliesKey } from 'constants/queryCacheKeys';
import { TStoreId } from 'domain/stores';
import { ObjectiveAreaChart, DEFAULT_MARGIN, EObjectiveMode, TObjectiveSeries } from '../components';
import { ChartLegend, ChartLoadingOverlay } from 'components/Charts';
import { useStoresDataMap, useSectionParams } from 'hooks/data';
import { dateToYMD, getCurrentDate, getCurrentDayOfWeek } from 'utils/date';
import { DateRangeContextType } from './ObjectivesInventoryShortagePage';
import { TMostAffectedProductsMachineContext } from './mostAffectedProductsMachine';

const DIMENSIONS = {
  minWidth: '200px',
  minHeight: '300px',
};

const MARGIN = { ...DEFAULT_MARGIN, left: 40 };

const isValidDataResolution = (r: TDataResolution): r is ESalesAnomalyDataResolution =>
  [ESalesAnomalyDataResolution.DAY, ESalesAnomalyDataResolution.WEEK, ESalesAnomalyDataResolution.MONTH].includes(
    r as any,
  );

const emptyData: never[] = [];

enum StackAreaDataKeys {
  PRODUCTS = 'p',
  INCIDENTS = 'i',
}

interface Props {
  children?: string | JSX.Element | JSX.Element[];
  probabilityThreshold: number;
  storeId: TStoreId;
  thresholdValue?: number;
  variant: EObjectiveMode;
  backButtonLink?: string;
  dateRange: DateRangeContextType;
  stockState: TMostAffectedProductsMachineContext;
}

const GRADIENT_COLORS = {
  [StackAreaDataKeys.PRODUCTS]: '#319795',
  [StackAreaDataKeys.INCIDENTS]: '#ED8936',
};

const dataKeys = [StackAreaDataKeys.PRODUCTS, StackAreaDataKeys.INCIDENTS];

const areaColorScale = scaleOrdinal({
  domain: dataKeys,
  range: [`url(#gradient-${StackAreaDataKeys.PRODUCTS})`, `url(#pattern-${StackAreaDataKeys.INCIDENTS})`],
});

const areaLineColorScale = scaleOrdinal({
  domain: dataKeys,
  range: dataKeys.map((key) => GRADIENT_COLORS[key]),
});

export const InventoryShortageChartCard: React.FC<Props> = ({
  thresholdValue = 0,
  dateRange,
  stockState,
  storeId,
  probabilityThreshold,
}) => {
  const { t } = useTranslation('whisperme');
  const { shortageDateParams } = dateRange;
  const [dataResolution, setDataResolution] = useState(ESalesAnomalyDataResolution.DAY);
  const { dateOptions } = useAppConfig();
  const userStoreIds = useUserStoreIds();
  const userRootFamilies = useUserSections();
  const authSections = userRootFamilies.map((section) => section.id);
  const [dataVisibility, setDataVisibility] = useState<{
    hiddenAreas: Set<StackAreaDataKeys>;
    visibleLines: Set<TStoreId>;
  }>(() => ({
    hiddenAreas: new Set(),
    visibleLines: new Set(),
  }));

  const storesMap = useStoresDataMap();
  const { productsFamily, productsSection } = stockState;
  const {
    formatters: { currencyFormatter, numberFormatter },
  } = useAppConfig();

  const otherUserStoreIds = useMemo(() => userStoreIds.filter((s) => s !== storeId), [userStoreIds, storeId]);

  const { startDate, endDate, params } = useMemo(() => {
    const startDate = shortageDateParams.startDate;

    const endDate = pipe(shortageDateParams.endDate ?? getCurrentDate(), (d) => {
      return d;
    });

    const params: TGetSalesAnomaliesParams = {
      first: startDate.toISOString(),
      end: endDate.toISOString(),
      resolution: dataResolution,
      family_id: productsFamily,
      root_family_id: productsSection,
      probability_threshold: probabilityThreshold,
    };

    return {
      startDate,
      endDate,
      params,
    };
  }, [dataResolution, shortageDateParams, productsFamily, probabilityThreshold, productsSection]);

  const dateRangeLabel = useChartDateRangeLabel(startDate, endDate);

  const filteredParams = useSectionParams(params, authSections, productsSection);

  const {
    data = emptyData,
    status,
    isFetching,
  } = useQuery(createSaleAnomaliesKey(filteredParams), () => getSalesAnomalies(filteredParams), {
    keepPreviousData: true,
    select: (d) => d.filter((s) => userStoreIds.includes(s.store_id)),
  });

  const { stackAreaData, lineData } = useMemo(() => {
    const { left: otherStoresData, right: currentStoreData } = pipe(
      data,
      A.partition((d) => d.store_id === storeId),
    );
    const stackAreaData: TObjectiveSeries<StackAreaDataKeys, Date, Record<'estimatedLoss', number>>[] = [
      {
        key: StackAreaDataKeys.PRODUCTS,
        name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.PRODUCTS_LABEL'),
        values: currentStoreData.map((d) => ({
          x: d.date_from,
          y: d.product_count,
          estimatedLoss: d.estimated_loss,
        })),
      },
      {
        key: StackAreaDataKeys.INCIDENTS,
        name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.INCIDENTS_LABEL'),
        values: currentStoreData.map((d) => ({
          x: d.date_from,
          y: d.anomaly_count,
          estimatedLoss: d.estimated_loss,
        })),
      },
    ];

    const lineData: TObjectiveSeries<TStoreId, Date>[] = !otherUserStoreIds
      ? []
      : otherUserStoreIds
          .map((id) => ({
            key: id,
            name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.STORE_INCIDENTS_TEMPLATE_LABEL', {
              store: storesMap.get(id)?.name ?? id,
            }),
            values: otherStoresData.reduce(
              (acc, currentValue) => {
                if (currentValue.store_id === id) {
                  return [...acc, { x: currentValue.date_from, y: currentValue.anomaly_count }];
                }

                return acc;
              },
              [] as TObjectiveSeries<TStoreId, Date>['values'],
            ),
          }))
          .filter((d) => d.values.length > 0);

    return {
      stackAreaData,
      lineData,
    };
  }, [data, t, otherUserStoreIds, storeId, storesMap]);

  const lineColorScale = useMemo(() => {
    const length = userStoreIds.length;
    const colorScale = scaleSequential(interpolateRainbow).domain([0, length]);

    return scaleOrdinal({
      domain: userStoreIds,
      range: Array.from({ length }).map((_v, idx) => colorScale(idx)),
    });
  }, [userStoreIds]);

  const { visibleStackAreaData, visibleLineData } = useMemo(() => {
    return {
      visibleStackAreaData: stackAreaData.filter((s) => !dataVisibility.hiddenAreas.has(s.key)),
      visibleLineData: lineData.filter((s) => dataVisibility.visibleLines.has(s.key)),
    };
  }, [stackAreaData, dataVisibility, lineData]);

  const tooltipRef = useLiveRef({ visibleLineData, visibleStackAreaData });
  const renderTooltip = useCallback(
    ({ tooltipOpen, tooltipData }: RenderTooltipParams<(typeof visibleLineData)[0]['values'][0]>) => {
      if (
        tooltipOpen &&
        typeof tooltipData !== 'undefined' &&
        typeof tooltipData.nearestDatum !== 'undefined' &&
        tooltipData.nearestDatum.datum.x instanceof Date
      ) {
        const nearest = tooltipData.nearestDatum;
        const nearestDay = nearest.datum.x;
        const { visibleStackAreaData, visibleLineData } = tooltipRef.current;

        const areaEntries = pipe(
          visibleStackAreaData,
          A.filterMap((data) => {
            const maybeData = pipe(
              data.values,
              A.findFirst((d) => isSameDay(d.x, nearestDay)),
            );

            if (O.isSome(maybeData)) {
              return O.some({
                key: data.key,
                label: data.name,
                value: maybeData.value.y,
                estimatedLoss: maybeData.value.estimatedLoss,
              });
            }

            return O.none;
          }),
        );

        const lineEntries = pipe(
          visibleLineData,
          A.filterMap((data) => {
            const maybeData = pipe(
              data.values,
              A.findFirst((d) => isSameDay(d.x, nearestDay)),
            );

            if (O.isSome(maybeData)) {
              return O.some({
                key: data.key,
                label: data.name,
                value: maybeData.value.y,
              });
            }

            return O.none;
          }),
        );

        return (
          <Stack direction="column" spacing="3">
            <Text as="span" fontWeight="bold" fontSize="md">
              {dateToYMD(nearest.datum.x)}
            </Text>
            <Text as="span" fontWeight="bold" fontSize="md">
              {getCurrentDayOfWeek(nearest.datum.x, dateOptions.locale)}
            </Text>

            {areaEntries.length > 0 && (
              <Stack as="ul" direction="column">
                {areaEntries.map((e) => (
                  <HStack key={e.label} as="li" spacing="1" fontSize="sm">
                    <Text as="strong" color={GRADIENT_COLORS[e.key]}>
                      {e.label}:
                    </Text>
                    <Text as="span">{numberFormatter.format(e.value)}</Text>
                  </HStack>
                ))}
                <HStack as="li" spacing="1" fontSize="sm">
                  <strong>{t('OBJECTIVES.COMMON.ESTIMATED_LOSS_LABEL')}:</strong>
                  {/* NOTE(m.kania): loss is returned in cents */}
                  <Text as="span">{currencyFormatter.format(areaEntries[0].estimatedLoss / 100)}</Text>
                </HStack>
              </Stack>
            )}

            {lineEntries.length > 0 && (
              <Stack as="ul" direction="column">
                {lineEntries.map((e) => (
                  <HStack key={e.label} as="li" spacing="1" fontSize="sm">
                    <Text as="strong" color={lineColorScale(e.key)}>
                      {e.label}:
                    </Text>
                    <Text as="span">{numberFormatter.format(e.value)}</Text>
                  </HStack>
                ))}
              </Stack>
            )}
          </Stack>
        );
      }

      return null;
    },
    [tooltipRef, t, currencyFormatter, numberFormatter, lineColorScale, dateOptions],
  );

  // NOTE(m.kania): initial fetch
  if (data.length === 0 && status === 'loading') {
    return <CardChartSkeleton chartMinWidth={DIMENSIONS.minWidth} chartMinHeight={DIMENSIONS.minHeight} />;
  }

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            <DataResolutionSelect
              isAllowedResolution={isValidDataResolution}
              value={dataResolution}
              onChange={(newResolution) => {
                if (isValidDataResolution(newResolution)) {
                  setDataResolution(newResolution);
                }
              }}
            />
          </CardTitleSectionSettings>
        }
      >
        {t('OBJECTIVES.SHORTAGE_LEVEL.INVENTORY_SHORTAGE_TITLE')} {dateRangeLabel}
      </CardTitleSection>
      <svg width={0} height={0}>
        <LinearGradient
          id={`gradient-${StackAreaDataKeys.PRODUCTS}`}
          from={GRADIENT_COLORS[StackAreaDataKeys.PRODUCTS]}
          to={GRADIENT_COLORS[StackAreaDataKeys.PRODUCTS]}
          fromOpacity={0.6}
          toOpacity={0.05}
        />
        <PatternLines
          id={`pattern-${StackAreaDataKeys.INCIDENTS}`}
          height={6}
          width={6}
          stroke={GRADIENT_COLORS[StackAreaDataKeys.INCIDENTS]}
          strokeWidth={1}
          orientation={['diagonal']}
        />
      </svg>
      <Stack
        flexDirection="column"
        flex="1 1 auto"
        minWidth={DIMENSIONS.minWidth}
        minHeight={DIMENSIONS.minHeight}
        position="relative"
      >
        <ChartLegend
          direction="row"
          onLegendItemClick={(v) => {
            const key = v === 0 ? StackAreaDataKeys.PRODUCTS : StackAreaDataKeys.INCIDENTS;

            setDataVisibility((s) => {
              const newSet = new Set(s.hiddenAreas);

              if (newSet.has(key)) {
                newSet.delete(key);
              } else {
                newSet.add(key);
              }

              return { ...s, hiddenAreas: newSet };
            });
          }}
          isMetricVisible={(v) => {
            const key = v === 0 ? StackAreaDataKeys.PRODUCTS : StackAreaDataKeys.INCIDENTS;

            return !dataVisibility.hiddenAreas.has(key);
          }}
          items={[
            {
              name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.PRODUCTS_LABEL'),
              color: areaLineColorScale(StackAreaDataKeys.PRODUCTS),
            },
            {
              name: t('OBJECTIVES.SHORTAGE_LEVEL.CHART.INCIDENTS_LABEL'),
              color: areaLineColorScale(StackAreaDataKeys.INCIDENTS),
            },
          ]}
        />
        <ObjectiveAreaChart
          dataResolution={dataResolution}
          lineData={visibleLineData}
          lineDataColorScale={lineColorScale}
          areaData={visibleStackAreaData}
          areaColorScale={areaColorScale}
          areaLineColorScale={areaLineColorScale}
          startDate={startDate}
          endDate={endDate}
          thresholdValue={thresholdValue}
          margin={MARGIN}
        >
          <Tooltip showVerticalCrosshair={true} renderTooltip={renderTooltip} />
        </ObjectiveAreaChart>
        <AnimatePresence>{isFetching && <ChartLoadingOverlay key="overlay" />}</AnimatePresence>
      </Stack>
    </Card>
  );
};
