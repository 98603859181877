import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';

import { MealBuilderRecipe } from './MealBuilderRecipe';
import { MealBuilderRequest } from './MealBuilderRequest';
import { setHumanInput, useRecipeReducer } from './hooks';
import { flow } from 'fp-ts/function';

export const MealBuilderLayout: React.FC = () => {
  const { state, dispatch, sendRequest } = useRecipeReducer();

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={3}>
      <GridItem>
        <MealBuilderRequest
          onSubmit={sendRequest}
          mealName={state.human_input}
          setMealName={flow(setHumanInput, dispatch)}
          isFetching={state.isFetching}
        />
      </GridItem>
      <GridItem>
        {state.recipe && state.products && <MealBuilderRecipe products={state.products} recipe={state.recipe} />}
      </GridItem>
    </Grid>
  );
};
