import { FamilyCodeValidator, ProductCodeValidator } from 'domain/core';
import * as t from 'io-ts';
import { DateFromISOString, optionFromNullable } from 'io-ts-types';

import { StoreIdValidator } from '../stores';

export const SalesAnomalyValidator = t.type({
  date_from: DateFromISOString,
  store_id: StoreIdValidator,
  anomaly_count: t.number,
  product_count: t.number,
  estimated_loss: t.number,
});

export type TSalesAnomaly = t.TypeOf<typeof SalesAnomalyValidator>;

export const SalesAnomalyArrayValidator = t.array(SalesAnomalyValidator);
export type TSalesAnomalyArray = t.TypeOf<typeof SalesAnomalyArrayValidator>;

export enum ESalesAnomalyDataResolution {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const TopAnomalousValidator = t.type({
  product_code: ProductCodeValidator,
  description: optionFromNullable(t.string),
  family_id: optionFromNullable(FamilyCodeValidator),
  root_family_id: optionFromNullable(FamilyCodeValidator),
  incident_count: t.number,
  cause: t.string,
  loss: t.number,
});
export type TTopAnomalous = t.TypeOf<typeof TopAnomalousValidator>;

export const TopAnomalousArrayValidator = t.array(TopAnomalousValidator);
export type TTopAnomalousArray = t.TypeOf<typeof TopAnomalousArrayValidator>;

export const AnomalyCalendarValidator = t.type({
  week: t.number,
  date: DateFromISOString,
  store_id: StoreIdValidator,
  // category: t.string,
  category: t.union([t.literal('08-12'), t.literal('13-16'), t.literal('17-23')]),
  probability: t.number,
  actual: t.number,
  expected: t.number,
});

export const SectionsAnomaliesValidator = t.type({
  date: DateFromISOString,
  store_id: StoreIdValidator,
  probability: t.number,
  actual: t.number,
  expected: t.number,
  root_family_id: optionFromNullable(FamilyCodeValidator),
});

export type TSectionsAnomaly = t.TypeOf<typeof SectionsAnomaliesValidator>;

export type TAnomalyCalendar = t.TypeOf<typeof AnomalyCalendarValidator>;

export const AnomalyCalendarArrayValidator = t.array(AnomalyCalendarValidator);
export const SectionsAnomalyArrayValidator = t.array(SectionsAnomaliesValidator);
export type TAnomalyCalendarArray = t.TypeOf<typeof AnomalyCalendarValidator>;

export const LogisticAnomalyValidator = t.type({
  date_from: DateFromISOString,
  store_id: StoreIdValidator,
  affected_product_count: t.number,
});
export type TLogisticAnomaly = t.TypeOf<typeof LogisticAnomalyValidator>;

export const LogisticAnomalyArrayValidator = t.array(LogisticAnomalyValidator);
export type TLogisticAnomalyArray = t.TypeOf<typeof LogisticAnomalyArrayValidator>;

export const AREA_MANAGER_OBJECTIVE_TAGS = {
  AREA_INVENTORY_SHORTAGE: 'AL1',
} as const;
export const SUPERMARKET_MANAGER_OBJECTIVE_TAGS = {
  INVENTORY_SHORTAGE: 'L1',
  CUSTOMER_GROUPS: 'L2',
  RECAPTURE_CUSTOMERS: 'L3',
} as const;

export const OBJECTIVE_TAGS = {
  ...AREA_MANAGER_OBJECTIVE_TAGS,
  ...SUPERMARKET_MANAGER_OBJECTIVE_TAGS,
} as const;

// FIXME: store hierarchy should be done on BE side, but according to Marek it's not needed 'for the next few months'
export const INITIAL_AREA_ID = 'area1';

export const AreaIdValidator = t.string;
export type TAreaIdType = t.TypeOf<typeof AreaIdValidator>;
export const TagTypeValidator = t.string;
export type TTagType = t.TypeOf<typeof TagTypeValidator>;

export const AllObjectivesValidator = t.type({
  area_id: AreaIdValidator,
  tag: TagTypeValidator,
  value: t.number,
});
export type TAllObjectivesEntry = t.TypeOf<typeof AllObjectivesValidator>;

export const AllObjectivesArrayValidator = t.array(AllObjectivesValidator);
export type TAllObjectivesArray = t.TypeOf<typeof AllObjectivesArrayValidator>;
