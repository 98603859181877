import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Skeleton, Wrap, WrapItem, Text, Tooltip } from '@chakra-ui/react';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';
import { format, subDays } from 'date-fns';

import { Card, CardTitleSection } from 'components/Card';
import { useStoresDataMap, useValidatedStoreStatistics } from 'hooks/data';
import { GetStoreStatisticsParams, STATISTIC_TYPE } from 'domain/statistics';
import { getCurrentDate } from 'utils/date';
import { TStoreId } from 'domain/stores';
import { EDataStatus } from './types';
import { useAppConfig } from 'hooks';

const empty: never[] = [];

interface Props {
  storeIds: TStoreId[];
}

export const DataStatusCard: React.FC<Props> = ({ storeIds }) => {
  const { t } = useTranslation('whisperme');
  const storesDataMap = useStoresDataMap();
  const { dateOptions } = useAppConfig();

  const params = useMemo((): GetStoreStatisticsParams => {
    const today = getCurrentDate();
    // NOTE(m.kania): as explained by Daniel - in the mornings we load data for previous day(s), so it's 'impossible'
    // to get any number for 'today', so we should query for 'yesterday'
    const date_from = subDays(today, 1);

    return {
      storeIds,
      date_from,
      date_to: today,
      statisticIds: [STATISTIC_TYPE.PROCESSED_RECEIPTS, STATISTIC_TYPE.FAILED_RECEIPTS],
    };
  }, [storeIds]);

  const { data = empty, status } = useValidatedStoreStatistics(params);

  const dataStatusMap = useMemo(() => {
    const processed = pipe(
      data,
      A.findFirst((p) => p.id === STATISTIC_TYPE.PROCESSED_RECEIPTS),
      O.map((p) => p.metrics),
      O.fold(() => empty, identity),
    );

    const failed = pipe(
      data,
      A.findFirst((p) => p.id === STATISTIC_TYPE.FAILED_RECEIPTS),
      O.map((p) => p.metrics),
      O.fold(() => empty, identity),
    );

    return new Map<TStoreId, EDataStatus>(
      storeIds.map((storeId) => {
        const p = pipe(
          processed,
          A.findFirst((m) => m.name === storeId),
          O.map((m) => m.series),
          O.chain(A.head),
          O.chain((v) => (typeof v === 'number' && v > 0 ? O.some(v) : O.none)),
        );

        const failCount = pipe(
          failed,
          A.findFirst((m) => m.name === storeId),
          O.map((m) => m.series),
          O.chain(A.head),
          O.chain((v) => (typeof v === 'number' ? O.some(v) : O.none)),
          O.getOrElse(() => 0),
        );

        const dataStatus = pipe(
          p,
          O.map((processedCount) => {
            // https://neoinstinct.atlassian.net/wiki/spaces/NEOSUITE/pages/1134166017/Settings+-+Data+processing
            const failRatio = failCount / processedCount;

            if (failRatio < 0.01) {
              // less than 1% -> green
              return EDataStatus.GREEN;
            }

            // between 1% and 3% -> orange
            if (failRatio >= 0.01 && failRatio < 0.03) {
              return EDataStatus.ORANGE;
            }

            // above 3% -> red
            return EDataStatus.RED;
          }),
          // no data -> gray
          O.getOrElse(() => EDataStatus.GRAY),
        );

        return [storeId, dataStatus];
      }),
    );
  }, [data, storeIds]);

  return (
    <Card>
      <CardTitleSection>
        {t('SETTINGS.PROCESSING_PAGE.DATA_STATUS_TITLE')}
        {params.date_from instanceof Date ? ` (${format(params.date_from, 'PPP', dateOptions)})` : null}
      </CardTitleSection>
      {status === 'loading' && <Skeleton width="100%" minHeight="150px" />}
      {status === 'success' && (
        <Wrap spacing="3">
          {storeIds.map((sId) => {
            const storeStatus = dataStatusMap.get(sId) ?? EDataStatus.GRAY;
            let tooltipLabel = t('SETTINGS.PROCESSING_PAGE.DATA_STATUS_MESSAGES.GRAY_STATUS_MESSAGE');

            if (storeStatus === EDataStatus.GREEN) {
              tooltipLabel = t('SETTINGS.PROCESSING_PAGE.DATA_STATUS_MESSAGES.GREEN_STATUS_MESSAGE');
            } else if (storeStatus === EDataStatus.ORANGE) {
              tooltipLabel = t('SETTINGS.PROCESSING_PAGE.DATA_STATUS_MESSAGES.ORANGE_STATUS_MESSAGE');
            } else if (storeStatus === EDataStatus.RED) {
              tooltipLabel = t('SETTINGS.PROCESSING_PAGE.DATA_STATUS_MESSAGES.RED_STATUS_MESSAGE');
            }

            return (
              <WrapItem key={sId}>
                <Tooltip label={tooltipLabel}>
                  <HStack spacing="1">
                    <Box
                      data-status={storeStatus}
                      sx={{
                        width: '1em',
                        height: '1em',
                        borderRadius: 'full',
                        fontSize: '16px',

                        [`&[data-status="${EDataStatus.GRAY}"]`]: {
                          backgroundColor: 'gray.300',
                        },

                        [`&[data-status="${EDataStatus.GREEN}"]`]: {
                          backgroundColor: 'green.400',
                        },

                        [`&[data-status="${EDataStatus.ORANGE}"]`]: {
                          backgroundColor: 'orange.300',
                        },

                        [`&[data-status="${EDataStatus.RED}"]`]: {
                          backgroundColor: 'red.500',
                        },
                      }}
                    />
                    <Text as="span" fontSize="sm">
                      {storesDataMap.get(sId)?.name ?? sId}
                    </Text>
                  </HStack>
                </Tooltip>
              </WrapItem>
            );
          })}
        </Wrap>
      )}
    </Card>
  );
};
