import { useQuery, UseQueryOptions } from 'react-query';

import { createClusterMapGroupsKey } from 'constants/queryCacheKeys';
import { getClusterMapGroups, TClusterMapGroups } from 'domain/map';
import { ONE_DAY } from 'constants/time';

type DataType = TClusterMapGroups;
type ErrorType = Error;

export const useClusterMapGroups = <Data = DataType>(options?: UseQueryOptions<DataType, ErrorType, Data>) =>
  useQuery<DataType, ErrorType, Data>(createClusterMapGroupsKey(), getClusterMapGroups, {
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
    ...options,
  });
