import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Checkbox } from '@chakra-ui/react';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { TProduct } from 'domain/products';
import { TProductFamily } from 'domain/product-family';
import { formatFirstAppearanceDate } from '../utils';
import { getOrZero } from 'pages/MorningTasksPage/StocksPage/utils';

interface Props extends BoxProps {
  product: TProduct;
  family: O.Option<TProductFamily>;
  rootFamily: O.Option<TProductFamily>;
  checked?: boolean;
  onCheckedChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckboxDisabled?: boolean;
}

export const ProductFamiliesListItem: React.FC<Props> = ({
  product,
  family,
  rootFamily,
  checked,
  onCheckedChange,
  isCheckboxDisabled,
  children,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');

  return (
    <Box {...rest}>
      <div role="gridcell">
        <Checkbox isChecked={Boolean(checked)} onChange={onCheckedChange} disabled={isCheckboxDisabled} />
      </div>
      <div role="gridcell">{product.product_code}</div>
      <div role="gridcell">{product.description}</div>
      <div role="gridcell">{product.family_code}</div>
      <div role="gridcell">
        {pipe(
          rootFamily,
          O.fold(
            () => '',
            (f) => f.name,
          ),
        )}
      </div>
      <div role="gridcell">
        {pipe(
          family,
          O.fold(
            () => '',
            (f) => f.name,
          ),
        )}
      </div>
      <div role="gridcell">
        {pipe(
          product.is_regional,
          O.getOrElse(() => false),
          (isRegional) =>
            isRegional
              ? t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_TRUE_LABEL')
              : t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_FALSE_LABEL'),
        )}
      </div>
      <div role="gridcell">{pipe(product.minimum_stock, getOrZero)}</div>
      <div role="gridcell">
        {pipe(
          product.weekend_product,
          O.getOrElse(() => false),
          (isWeekendProduct) =>
            isWeekendProduct
              ? t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_TRUE_LABEL')
              : t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_FALSE_LABEL'),
        )}
      </div>
      <div role="gridcell">
        {pipe(
          product.always_available,
          O.getOrElse(() => false),
          (isAlwaysAvailable) =>
            isAlwaysAvailable
              ? t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_TRUE_LABEL')
              : t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_FALSE_LABEL'),
        )}
      </div>
      <div role="gridcell">
        {pipe(
          product.supplier_name,
          O.getOrElse(() => ''),
          (supplierName) => supplierName,
        )}
      </div>
      <div role="gridcell">{formatFirstAppearanceDate(t, product.first_appearance_date)}</div>
      <Box role="gridcell" textAlign="right">
        {children}
      </Box>
    </Box>
  );
};
