import { TFamilyCode, TProductCode } from 'domain/core';
import { TProductFamily } from 'domain/product-family';
import { TCreateProduct, TProduct } from 'domain/products';

export enum EProductListFilterActionType {
  EAN,
  PRODUCT_NAME,
  FAMILY_CODE,
  ROOT_FAMILY_CODE,
  IS_REGIONAL,
  USE_DATE,
  MINIMUM_STOCK,
  WEEKEND_PRODUCT,
  ALWAYS_AVAILABLE,
  SUPPLIER_NAME,
}

export type TProductListFilterAction =
  | { type: EProductListFilterActionType.EAN; payload: string }
  | { type: EProductListFilterActionType.PRODUCT_NAME; payload: string }
  | { type: EProductListFilterActionType.FAMILY_CODE; payload?: TFamilyCode }
  | { type: EProductListFilterActionType.ROOT_FAMILY_CODE; payload?: TFamilyCode }
  | { type: EProductListFilterActionType.IS_REGIONAL; payload?: boolean }
  | { type: EProductListFilterActionType.MINIMUM_STOCK; payload?: boolean }
  | { type: EProductListFilterActionType.WEEKEND_PRODUCT; payload?: boolean }
  | { type: EProductListFilterActionType.ALWAYS_AVAILABLE; payload?: boolean }
  | { type: EProductListFilterActionType.SUPPLIER_NAME; payload?: string }
  | { type: EProductListFilterActionType.USE_DATE; payload?: boolean };

export type TProductsMachineEditValue = Partial<TCreateProduct & { rootFamilyCode: TFamilyCode }>;

export interface TProductsMachineValidationErrors {
  description?: string;
  family_code?: string;
  minimum_stock?: number;
}

export interface TProductsMachineContext {
  products: TProduct[];
  selectedProductCodes: Set<TProduct['product_code']>;
  productEditValues: Map<TProductCode, TProductsMachineEditValue>;
  productEditErrors: Map<TProductCode, TProductsMachineValidationErrors>;
  productFamilies: TProductFamily[];
}

export type TProductsMachineEvent =
  | { type: 'PRODUCT_SELECTION_TOGGLE'; productCode: TProductCode }
  | { type: 'PRODUCT_SELECTION_ADD'; productCode: TProductCode }
  | { type: 'PRODUCT_SELECTION_NONE' }
  | { type: 'PRODUCT_SELECTION_ALL' }
  | { type: 'EDIT'; productCode: TProductCode }
  | { type: 'SET_EDIT_VALUE'; productCode: TProductCode; payload: TProductsMachineEditValue }
  | { type: 'SAVE' }
  | { type: 'CANCEL' }
  | { type: 'SET_PRODUCTS'; products: TProduct[] }
  | { type: 'SET_PRODUCT_FAMILIES'; productFamilies: TProductFamily[] };

export enum EProductsMachineState {
  INIT = 'init',
  VIEW = 'view',
  EDIT_SINGLE = 'edit-single',
  EDIT_MULTIPLE = 'edit-multiple',
}

export interface TProductsMachineState {
  value: EProductsMachineState;
  context: TProductsMachineContext;
}
