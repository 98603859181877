import { useQuery } from 'react-query';

import { getSuppliersList, TSuppliers } from 'domain/suppliers';
import { createSuppliersListKey } from 'constants/queryCacheKeys';

export const useSuppliers = () => {
  return useQuery(createSuppliersListKey(), getSuppliersList, {
    useErrorBoundary: true,
    staleTime: 24 * 60 * 60 * 1000,
    select: createSuppliersMap,
  });
};

export const createSuppliersMap = (suppliers: TSuppliers): Map<number, string> =>
  new Map(suppliers.map((s) => [s.id, s.name]));
