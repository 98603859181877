import { useReducer } from 'react';
import { subDays } from 'date-fns';

import { getCurrentDate } from 'utils/date';

interface State {
  temporary: {
    startDate: Date;
    endDate: Date | null;
  };
  params: {
    startDate: Date;
    endDate: Date | null;
  };
}

type ActionTypes =
  | {
      type: 'set-temporary';
      payload: State['temporary'];
    }
  | {
      type: 'set-params';
      payload: State['params'];
    };

const reducer = (state: State, action: ActionTypes): State => {
  if (action.type === 'set-temporary') {
    return { ...state, temporary: action.payload };
  }

  if (action.type === 'set-params') {
    return { ...state, params: action.payload };
  }

  return state;
};

interface UseCalendarDataRangeArgs {
  initialStartDate?: Date;
  initialEndDate?: Date;
}

const defaultArgs = {
  endDate: getCurrentDate(),
  startDate: subDays(getCurrentDate(), 14),
};

export const useCalendarDateRange = (args?: UseCalendarDataRangeArgs) => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      temporary: {
        startDate: defaultArgs.startDate,
        endDate: defaultArgs.endDate,
      },
      params: {
        startDate: defaultArgs.startDate,
        endDate: defaultArgs.endDate,
      },
    },
    () => {
      const endDate = args?.initialEndDate ?? defaultArgs.endDate;
      const startDate = args?.initialStartDate ?? subDays(endDate, 14);

      return {
        temporary: {
          startDate,
          endDate,
        },
        params: {
          startDate,
          endDate,
        },
      };
    },
  );

  return {
    dateParams: state.params,
    calendarProps: {
      ...state.temporary,
      onChange: (payload: State['temporary']) => dispatch({ type: 'set-temporary', payload }),
      onCalendarClose: () => dispatch({ type: 'set-params', payload: state.temporary }),
    },
  };
};
