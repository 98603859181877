import { Button, HStack, useToast, Box } from '@chakra-ui/react';
import {
  SaveWeeklyProductManagement,
  saveWeeklyProductManagement,
  SaveWeeklyProductManagementList,
  TWeeklyProductManagement,
  TWeeklyProductManagementList,
  TWeeklyProductManagementParams,
  validateMainAssortmentList,
} from 'domain/products';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { EditStore, useEditStore } from '../hooks/useEditStore';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import { useUserContext } from 'hooks';
import { hasMonitorAccess } from 'domain/user';

type FooterProps = {
  year: number;
  week: number;
};

const isNotEmpty = (value: EditStore): boolean => {
  for (const year in value) {
    return true;
  }
  return false;
};

const parseToSave = (state: EditStore, monitorScope: boolean): SaveWeeklyProductManagementList => {
  return Object.entries(state).flatMap(([year, weeksValue]) => {
    return Object.entries(weeksValue).flatMap(([week, productValues]) => {
      return Object.entries(productValues).map(([productCode, data]) => {
        const stores = pipe(
          R.toArray(data.stores),
          A.map(([_storeId, status]) => (status === null ? 'nodata' : status)),
          A.filterMap(O.fromNullable),
        );
        const storesAlwaysAvailable = pipe(
          R.toArray(data.stores_always_available),
          A.map(([_storeId, isAlwaysAvailable]) => isAlwaysAvailable),
          A.filterMap(O.fromNullable),
        );
        const allStoresvalues = { ALL: stores[0] };
        const allStoresAlwaysAvailable = { ALL: storesAlwaysAvailable[0] };
        return {
          product_code: parseInt(productCode),
          year: parseInt(year),
          week: parseInt(week),
          stores: monitorScope ? allStoresvalues : data.stores,
          stores_always_available: monitorScope ? allStoresAlwaysAvailable : data.stores_always_available,
        } as unknown as SaveWeeklyProductManagement;
      });
    });
  });
};

export const Footer: FC<FooterProps> = ({ year, week }) => {
  const queryClient = useQueryClient();
  const [state, { handleResetEditStore }] = useEditStore();
  const toast = useToast();
  const { t } = useTranslation('whisperme');
  const { user } = useUserContext();
  const monitorScope = hasMonitorAccess(user);

  const { mutateAsync } = useMutation(saveWeeklyProductManagement, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.SAVE_SUCCESS_MESSAGE'),
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.SAVE_ERROR_MESSAGE'),
        status: 'error',
      });
    },
  });

  const { mutate: mutateValidation } = useMutation(validateMainAssortmentList, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.LIST_VALIDATED_SUCCESS_MESSAGE'),
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.LIST_VALIDATED_ERROR_MESSAGE'),
        status: 'error',
      });
    },
  });

  const haveDataToSave = isNotEmpty(state);

  return (
    <HStack mt={2} gap={2} justifyContent="space-between">
      {/*TODO(malysad): this should have better solution without use of before or after in css */}
      <Box width="80px"></Box>
      <HStack mt={2} gap={2} justifyContent="end">
        <Button
          colorScheme="blue"
          disabled={!haveDataToSave}
          onClick={() => {
            const stateToSave = parseToSave(state, monitorScope);
            mutateAsync(stateToSave, {
              onSuccess: async (_data) => {
                await queryClient.cancelQueries(['assortment-analysis', 'weekly-product-management']);
                const values = queryClient.getQueriesData(['assortment-analysis', 'weekly-product-management']);

                values.forEach(([key]) => {
                  const params = key[2] as unknown as TWeeklyProductManagementParams;

                  if (!state[params.year][params.week]) return;

                  queryClient.setQueryData(key, (values: TWeeklyProductManagementList | undefined) => {
                    return (
                      values?.map((record) => {
                        const stores = state[record.year]?.[record.week]?.[record.product_code]?.stores;
                        if (!stores) {
                          return record;
                        }

                        const optionatedStores: TWeeklyProductManagement['stores'] = pipe(
                          Object.entries(stores),
                          A.map(([storeId, value]) => {
                            return [storeId, O.fromNullable(value)];
                          }),
                          (data) => Object.fromEntries(data),
                        );

                        const storesAlwaysAvailable =
                          state[record.year]?.[record.week]?.[record.product_code]?.stores_always_available;

                        return {
                          ...record,
                          stores: { ...record.stores, ...optionatedStores },
                          stores_always_available: { ...record.stores_always_available, ...storesAlwaysAvailable },
                        };
                      }) ?? []
                    );
                  });
                });
                handleResetEditStore();
              },
            });
          }}
        >
          {t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.SAVE_BUTTON_LABEL')}
        </Button>
        {monitorScope ? (
          <Button
            onClick={() => {
              mutateValidation({ year, week });
            }}
            disabled={haveDataToSave}
          >
            {t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.VALIDATE_BUTTON_LABEL')}
          </Button>
        ) : null}
      </HStack>
    </HStack>
  );
};
