import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { STOCK_COMPRESSED_TEMPLATE_COLUMNS } from '../../consts/css';
import { GridTableHeader } from 'components/GridTableHeader';

type StockHeaderRowProps = {
  variant: string;
};

export const StockTableHeader: VFC<StockHeaderRowProps> = ({ variant }) => {
  const { t } = useTranslation('whisperme');

  return variant === 'compressed' ? (
    <GridTableHeader
      rowProps={{
        width: '100%',
        gap: 1,
        templateColumns: STOCK_COMPRESSED_TEMPLATE_COLUMNS,
        paddingLeft: 0,
      }}
      cellProps={[{ paddingLeft: '8px' }, { paddingLeft: '6px' }]}
      items={[
        t('MORNING_TAKS.STOCKS_TABLE.IFLS_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.EAN7_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.BLI_DESC_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.SHELF_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.MORNING_STOCK_LEVEL_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.DELIVERY'),
        '',
      ]}
    />
  ) : null;
};
