import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { UploadIcon } from 'components/Icons';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWeekModalProps } from './types';
import { useMutation, useQueryClient } from 'react-query';
import {
  TCreateWeekQueryParams,
  TWeeklyProductManagementList,
  TWeeklyProductManagementParams,
  uploadFileWithWeeklyAssortment,
} from 'domain/products';
import { useUserContext, useUserStoreIds } from 'hooks';
import { hasMonitorAccess } from 'domain/user';

export const NewWeekModal: React.FC<NewWeekModalProps> = ({ isOpen, onClose, year, week }) => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const userStoreIds = useUserStoreIds();
  const { user } = useUserContext();
  const monitorScope = hasMonitorAccess(user);
  const queryClient = useQueryClient();

  const actionButtonProps = {
    variant: 'ghost',
    colorScheme: 'blue',
    border: '1px',
    borderColor: 'blue.800',
  };
  const params: TCreateWeekQueryParams = {
    strategy: 'clear_promo',
    returning: true,
    store_ids: monitorScope ? ['ALL'] : userStoreIds,
  };

  const { mutateAsync, status } = useMutation(uploadFileWithWeeklyAssortment, {
    onSuccess: async (data) => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_SUCCESS_TEXT'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await queryClient.cancelQueries(['assortment-analysis', 'weekly-product-management']);
      const values = queryClient.getQueriesData(['assortment-analysis', 'weekly-product-management']);

      values.forEach(([key]) => {
        const params = key[2] as unknown as TWeeklyProductManagementParams;
        if (params.week !== week) return;

        queryClient.setQueryData(key, (values: TWeeklyProductManagementList | undefined) => {
          return (
            values?.map((record) => {
              const stores = data[0][record.product_code];
              const storesAlwaysAvailable = data[1][record.product_code];
              if (!stores && !storesAlwaysAvailable) {
                return record;
              }

              return {
                ...record,
                stores: { ...record.stores, ...stores },
                stores_always_available: { ...record.stores_always_available, ...storesAlwaysAvailable },
              };
            }) ?? []
          );
        });
      });
      onClose();
    },
    onError: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_SAVED_FAIL_TEXT'),
        description: t('SETTINGS.PRODUCT_FAMILIES.PRODUCTS_UPLOAD_HINT'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    },
    onSettled: () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pb={3}>
        <ModalHeader>{t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.MODAL.TITLE_LABEL')}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <Stack>
            <Button {...actionButtonProps} onClick={() => onClose()}>
              {t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.MODAL.OPTION_CREATE_EMPTY_WEEK_LABEL')}
            </Button>

            <Box>
              <input
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
                name="weekly_assortment_management-file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(ev) => {
                  const maybeFile = ev.target.files?.[0];
                  if (maybeFile) {
                    mutateAsync({ year, week, file: maybeFile, params });
                  }
                }}
              />
              <Box alignContent="center" justifyContent="center">
                <Button
                  leftIcon={<UploadIcon />}
                  w="100%"
                  isLoading={status === 'loading'}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  {...actionButtonProps}
                  colorScheme="blue"
                >
                  {t(
                    'SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT.IMPORT_WEEKLY_ASSORTMENT_FILE_BUTTON_LABEL',
                  )}
                </Button>
              </Box>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
