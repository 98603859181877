import React, { FC, PropsWithChildren } from 'react';
import { TableColumnHeaderProps, Th } from '@chakra-ui/react';
import { ArrowUp, ArrowDown } from 'components/Icons';
import { isEmpty } from 'lodash';

export type SortedT = 'ASC' | 'DESC' | undefined;

type SortedProps = {
  isSorted?: SortedT;
};

type TableHeaderProps = PropsWithChildren<SortedProps & TableColumnHeaderProps>;

const isAsc = (type: SortedT) => Boolean(type && type === 'ASC');

const SortIndicator: FC<SortedProps> = ({ isSorted }) => {
  if (!isEmpty(isSorted)) return isAsc(isSorted) ? <ArrowUp /> : <ArrowDown />;

  return null;
};

export const TableHeader: FC<TableHeaderProps> = ({ isSorted, children, onClick, ...props }) => {
  return (
    <Th
      css={{
        '&:hover': {
          cursor: onClick === undefined ? 'auto' : 'pointer',
        },
      }}
      onClick={onClick}
      {...props}
    >
      {children}
      {isSorted ? <SortIndicator isSorted={isSorted} /> : null}
    </Th>
  );
};
