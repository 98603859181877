import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {}

export const ProductFamiliesTable: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Box
      role="grid"
      sx={{
        display: 'table',
        width: '100%',

        '& [role="row"]': {
          display: 'table-row',
        },

        '& [role="gridcell"]': {
          px: '3',
          py: '2',
          lineHeight: '4',
          fontSize: 'xs',
          display: 'table-cell',
          textAlign: 'start',
          borderBottom: '1px',
          borderColor: 'gray.100',
        },

        '& [role="columnheader"]': {
          px: '3',
          py: '2',
          lineHeight: '4',
          fontSize: 'xs',
          display: 'table-cell',
          fontFamily: 'heading',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: 'wider',
          textAlign: 'start',
          color: 'gray.600',
          borderBottom: '1px',
          borderColor: 'gray.100',
        },

        '& [role="gridcell"], & [role="columnheader"]': {
          '&:first-of-type': {
            width: '20px',
            px: '3',
          },
        },

        'input, select': {
          bg: '#fff',
          borderColor: 'gray.200',
          borderRadius: 'md',
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
