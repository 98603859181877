import { useStocksReducer, updateStock } from 'hooks/useStocksReducer';
import React, { VFC, useState } from 'react';
import { getOrZero } from '../utils';
import { useDebounce } from 'react-use';
import * as O from 'fp-ts/Option';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
} from '@chakra-ui/react';

type LevelInputProps = {
  productCode: string;
  type: 'start_of_day' | 'wasted' | 'stolen';
  max?: number;
  size?: NumberInputProps['size'];
};
export const LevelInput: VFC<LevelInputProps> = ({ productCode, type, max, size = 'lg' }) => {
  const [state, dispatch] = useStocksReducer();

  const [value, setValue] = useState<number>(getOrZero(state[productCode][type]));

  useDebounce(
    () => {
      if (value !== getOrZero(state[productCode]?.[type] || O.none))
        dispatch(updateStock([productCode, { [type]: O.some(value) }]));
    },
    500,
    [value],
  );

  return (
    <NumberInput size={size} value={value} step={1} max={max} min={0} onChange={(_, value) => setValue(value)}>
      <NumberInputField textAlign="center" />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
