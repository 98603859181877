import React, { useEffect } from 'react';
import { Center, CenterProps, Text, Button, VStack } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { HttpError } from 'utils/request';
import { useHistory } from 'react-router-dom';

export const DefaultFallbackRender: React.FC<FallbackProps & CenterProps> = ({
  error,
  resetErrorBoundary,
  ...centerProps
}) => {
  const { t } = useTranslation('whisperme');
  const history = useHistory();

  useEffect(
    () =>
      history.listen(() => {
        resetErrorBoundary();
      }),
    [history, resetErrorBoundary],
  );

  if (error instanceof HttpError || error instanceof TypeError) {
    return (
      <Center p="5" minHeight="200px" {...centerProps}>
        <VStack>
          <Text as="span">{t('COMPONENTS.ERROR_MESSAGES.FAILED_TO_LOAD_DATA_TEXT')}</Text>
          <Button onClick={() => resetErrorBoundary()} colorScheme="blue" size="sm">
            {t('COMPONENTS.ERROR_MESSAGES.TRY_AGAIN_BUTTON_LABEL')}
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Center p="5" width="100%" height="100%" minHeight="200px" {...centerProps}>
      <Text as="span">{t('COMPONENTS.ERROR_MESSAGES.GENERIC_TEXT')}</Text>
    </Center>
  );
};

export const defaultFallbackRender = (props: FallbackProps) => <DefaultFallbackRender {...props} />;
