import React from 'react';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TStoreId } from 'domain/stores';
import { StoreSelectBaseProps } from './types';
import { StoreSelectOptions } from './StoreSelectOptions';

export interface SingleStoreSelectProps extends StoreSelectBaseProps {
  onChange?: (newStoreId: TStoreId) => void;
  value: TStoreId;
}

export const SingleStoreSelect: React.FC<SingleStoreSelectProps> = ({
  selectableStoreIds,
  onChange,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');

  return (
    <Select
      size="sm"
      onChange={(ev) => {
        const newValue = ev.target.value as any;
        if (selectableStoreIds.includes(newValue)) {
          onChange?.(newValue);
          ev.preventDefault();
          ev.stopPropagation();
        }
      }}
      {...rest}
    >
      <option value="" disabled={true}>
        {placeholder ?? t('COMPONENTS.STORE_SELECT.SELECT_STORE_PLACEHOLDER')}
      </option>
      <StoreSelectOptions selectableStoreIds={selectableStoreIds} />
    </Select>
  );
};
