import * as t from 'io-ts';

export const SupplierValidator = t.type({
  id: t.number,
  name: t.string,
});
export const SuppliersValidator = t.array(SupplierValidator);

export type TSupplier = t.TypeOf<typeof SupplierValidator>;
export type TSuppliers = t.TypeOf<typeof SuppliersValidator>;
export type TSupplierValidator = t.TypeOf<typeof SuppliersValidator>;
