import { createMachine, assign, InterpreterFrom, StateFrom } from 'xstate';

export interface TAreaCalendarMachineContext {
  weekIndex: number;
  totalWeeks: number;
}

export type TAreaCalendarMachineEvent =
  | {
      type: 'SET_TOTAL_WEEKS';
      totalWeeks: number;
    }
  | { type: 'NEXT_WEEK' }
  | { type: 'PREV_WEEK' };

export const areaCalendarMachine = createMachine(
  {
    tsTypes: {} as import('./areaCalendarMachine.typegen').Typegen0,
    schema: {
      context: {} as TAreaCalendarMachineContext,
      events: {} as TAreaCalendarMachineEvent,
    },
    context: {
      weekIndex: 0,
      totalWeeks: 0,
    },
    initial: 'main',
    states: {
      main: {
        on: {
          SET_TOTAL_WEEKS: {
            actions: 'setTotalWeeks',
          },
          NEXT_WEEK: {
            actions: 'nextWeek',
            cond: 'canMoveToNextWeek',
          },
          PREV_WEEK: {
            actions: 'prevWeek',
            cond: 'canMoveToPrevWeek',
          },
        },
      },
    },
  },
  {
    actions: {
      setTotalWeeks: assign({
        weekIndex: (_ctx, _ev) => 0,
        totalWeeks: (_ctx, ev) => ev.totalWeeks,
      }),
      nextWeek: assign({
        weekIndex: (ctx, _ev) => ctx.weekIndex + 1,
      }),
      prevWeek: assign({
        weekIndex: (ctx, _ev) => ctx.weekIndex - 1,
      }),
    },
    guards: {
      canMoveToNextWeek: (ctx, _ev) => ctx.weekIndex < ctx.totalWeeks,
      canMoveToPrevWeek: (ctx, _ev) => ctx.weekIndex >= 0,
    },
  },
);
export type TAreaCalendarMachine = typeof areaCalendarMachine;
export type TAreaCalendarervice = InterpreterFrom<TAreaCalendarMachine>;
export type TAreaCalendarelectorState = StateFrom<TAreaCalendarMachine>;
