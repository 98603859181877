import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, HStack, Skeleton, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import { pipe } from 'fp-ts/function';
import { EditIcon } from '@chakra-ui/icons';
import * as PATHS from 'constants/paths';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { hasRoleCode, ROLE_CODES, TRoleId, TRoleSectionPrivileges, TRoleStorePrivileges } from 'domain/roles';
import { useProductFamiliesMaps, useRoleDetails, useStoresDataMap } from 'hooks/data';

interface Props {
  roleId: TRoleId;
}

interface GetRoleDetails<T extends Record<string, number[]>> {
  id: keyof T;
  hasRead: boolean;
  hasAdmin: boolean;
}

export const RoleDetailPage: React.VFC<Props> = ({ roleId }) => {
  const { t } = useTranslation('whisperme');
  const storesMap = useStoresDataMap();
  const sectionsMap = useProductFamiliesMaps();
  const { data, isLoading } = useRoleDetails({ roleId });

  const getRoleDetails = <T extends Record<string, number[]>>(privileges: T): GetRoleDetails<T>[] =>
    pipe(
      R.toArray(privileges),
      A.map(([id, roles]) => ({
        id,
        hasRead: hasRoleCode(ROLE_CODES.READ)(roles),
        hasAdmin: hasRoleCode(ROLE_CODES.ADMIN)(roles),
      })),
      A.filterMap(O.fromNullable),
    );

  const storeItems: GetRoleDetails<TRoleStorePrivileges>[] = useMemo(() => {
    const storePrivileges = data?.store_privileges ?? {};
    return getRoleDetails(storePrivileges);
  }, [data?.store_privileges]);

  const sectionItems: GetRoleDetails<TRoleSectionPrivileges>[] = useMemo(() => {
    const sectionPrivileges = data?.section_privileges ?? {};
    return getRoleDetails(sectionPrivileges);
  }, [data?.section_privileges]);

  if (!data || isLoading) {
    return (
      <Card>
        <CardTitleSection backButtonLink={PATHS.SETTINGS.ROLES.ROOT.toPath()}>
          <Skeleton width="30%" />
        </CardTitleSection>
        <Skeleton width="100%" minHeight="250px" />
      </Card>
    );
  }

  return (
    <Card>
      <CardTitleSection
        as="h2"
        backButtonLink={PATHS.SETTINGS.ROLES.ROOT.toPath()}
        settings={
          <CardTitleSectionSettings>
            <Button
              as={Link}
              to={PATHS.SETTINGS.ROLES.EDIT.toPath({ roleId })}
              leftIcon={<EditIcon />}
              size="sm"
              variant="outline"
            >
              {t('SETTINGS.ROLES.EDIT_ACTION_LABEL')}
            </Button>
          </CardTitleSectionSettings>
        }
      >
        {data.name}
      </CardTitleSection>

      <Table variant="kpi">
        <Thead>
          <Tr>
            <Th>{t('SETTINGS.ROLES.STORE_LABEL')}</Th>
            <Th>{t('SETTINGS.ROLES.ROLES_LABEL')}</Th>
          </Tr>
        </Thead>
        {storeItems.length === 0 ? (
          <Text as="span">
            {t('SETTINGS.ROLES.EMPTY_ROLES_TEXT', { value: t('SETTINGS.ROLES.STORES_LABEL').toLowerCase() })}
          </Text>
        ) : (
          <Tbody>
            {storeItems.map((item) => (
              <Tr key={item.id}>
                <Td>{storesMap.get(item.id)?.name}</Td>
                <Td>
                  <HStack spacing="2">
                    {item.hasRead && <Badge>{t('SETTINGS.ROLES.READ_ROLE_LABEL')}</Badge>}
                    {item.hasAdmin && <Badge colorScheme="green">{t('SETTINGS.ROLES.ADMIN_ROLE_LABEL')}</Badge>}
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>

      <Table variant="kpi">
        <Thead>
          <Tr>
            <Th>{t('SETTINGS.ROLES.SECTION_LABEL')}</Th>
            <Th>{t('SETTINGS.ROLES.ROLES_LABEL')}</Th>
          </Tr>
        </Thead>
        {sectionItems.length === 0 ? (
          <Text as="span">
            {t('SETTINGS.ROLES.EMPTY_ROLES_TEXT', { value: t('SETTINGS.ROLES.SECTIONS_LABEL').toLowerCase() })}
          </Text>
        ) : (
          <Tbody>
            {sectionItems.map((item) => (
              <Tr key={item.id}>
                <Td>{sectionsMap.rootFamilies.find((family) => Number(family.id) === Number(item.id))?.name ?? ''}</Td>
                <Td>
                  <HStack spacing="2">
                    {item.hasRead && <Badge>{t('SETTINGS.ROLES.READ_ROLE_LABEL')}</Badge>}
                    {item.hasAdmin && <Badge colorScheme="green">{t('SETTINGS.ROLES.ADMIN_ROLE_LABEL')}</Badge>}
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    </Card>
  );
};
