import { useQuery } from 'react-query';
import { createReceiptSourceLinkKey } from 'constants/queryCacheKeys';
import { getReceiptSourceLink, TErrorId } from 'domain/statistics';

export const useReceiptSource = (errorId: TErrorId | undefined) => {
  const params = { error_id: errorId };

  return useQuery(createReceiptSourceLinkKey(params), () => getReceiptSourceLink(params), {
    enabled: typeof errorId !== 'undefined',
  });
};
