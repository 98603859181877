import { API_BASE_URL } from 'constants/env';
import { pipe } from 'fp-ts/function';
import {
  createValidatedCancellableFetch,
  getExportLink,
  makeCancellableFetch,
  urlWithQueryParams,
} from 'utils/request';
import {
  CashierPredictionListValidator,
  CashierPredictionParams,
  SectionPredictionParams,
  CustomersPredictionValidator,
  GroupedOrdersValidator,
  OrderDataList,
  OrdersParams,
  SalesPredictionPageValidator,
  SalesPredictionParams,
  GrossIncomePredictionValidator,
  BudgetInfoListValidator,
} from './types';

const API_URLS = {
  getSalesPrediction: () => `${API_BASE_URL}/sales_prediction`,
  getCashiersPrediction: () => `${API_BASE_URL}/prediction/cashiers`,
  getCustomersPrediction: () => `${API_BASE_URL}/prediction/section_customers`,
  getSectionSalesPrediction: () => `${API_BASE_URL}/prediction/section_sales`,
  getBudgetInfo: () => `${API_BASE_URL}/budget-info`,
  saveSalesOrders: () => `${API_BASE_URL}/order/save-all`,
  getOrders: () => `${API_BASE_URL}/order`,
  getGroupedOrders: () => `${API_BASE_URL}/order/grouped`,
};

export const getSalesPrediction = (params: SalesPredictionParams) =>
  pipe(
    API_URLS.getSalesPrediction(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(SalesPredictionPageValidator),
  );

export const getCashiersPrediction = (params: CashierPredictionParams) =>
  pipe(
    API_URLS.getCashiersPrediction(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(CashierPredictionListValidator),
  );

export const getCustomersPrediction = (params: SectionPredictionParams) =>
  pipe(
    API_URLS.getCustomersPrediction(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(CustomersPredictionValidator),
  );

export const getSectionSalesPrediction = (params: SectionPredictionParams) =>
  pipe(
    API_URLS.getSectionSalesPrediction(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(GrossIncomePredictionValidator),
  );

export const getBudgetInfo = (params: SectionPredictionParams) =>
  pipe(API_URLS.getBudgetInfo(), urlWithQueryParams(params), createValidatedCancellableFetch(BudgetInfoListValidator));

export const saveSalesOrders = (orders: OrderDataList) =>
  pipe(API_URLS.saveSalesOrders(), (url) =>
    makeCancellableFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orders),
    }),
  );

export const getGroupedOrders = (params: OrdersParams) =>
  pipe(
    API_URLS.getGroupedOrders(),
    urlWithQueryParams(params),
    createValidatedCancellableFetch(GroupedOrdersValidator),
  );

export const exportOrders = (params: Omit<SalesPredictionParams, 'page' | 'size'>) =>
  pipe(API_URLS.getOrders(), getExportLink(), urlWithQueryParams(params));
