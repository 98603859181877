import React from 'react';
import { ProductRow } from './components';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { TRecipeProduct } from 'domain/neoshopping';
import { useReactToPrint } from 'react-to-print';

export const ProductsTable: React.FC<{ products: TRecipeProduct[] }> = ({ products }) => {
  const { t } = useTranslation('whisperme');
  const productsRef = React.useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => productsRef.current,
  });

  return (
    <>
      <Table
        variant="products"
        ref={productsRef}
        sx={{
          ['@media print']: {
            margin: '3rem',
            width: '45rem',
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>{t('SHOPPING.MEAL_BUILDER_RECIPE.INGREDIENT')}</Th>
            <Th>{t('SHOPPING.MEAL_BUILDER_RECIPE.EAN')}</Th>
            <Th>{t('SHOPPING.MEAL_BUILDER_RECIPE.RECOMMENDED_PRODUCT')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((product) => (
            <ProductRow
              product={product}
              key={`${product.llm_product}_${pipe(
                product.product_code,
                O.getOrElse(() => 0),
              )}`}
            />
          ))}
        </Tbody>
      </Table>
      <HStack justifyContent="end" mt={4}>
        <Button
          size="md"
          colorScheme="blue"
          onClick={() => {
            handlePrint();
          }}
        >
          {t('SHOPPING.MEAL_BUILDER_RECIPE.PRINT_INGREDIENTS')}
        </Button>
      </HStack>
    </>
  );
};
