import { useToast } from '@chakra-ui/react';
import React, { useState, VFC } from 'react';
import { ChatFooter } from './ChatFooter';
import { ChatHeader } from './ChatHeader';
import { ChatMessages } from './ChatMessages';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/Card';
import { ChatSource, TChatMessage, sendNeoChatPrompt, DisplayFormat, RequestedModel } from 'domain/neochat';

const MESSAGES_INITIAL = [{ src: ChatSource.ANSWER, data: 'Hello, my name is Neochat' } as unknown as TChatMessage];

type ChatCardProps = {
  showExampleQueries?: boolean;
  compact?: boolean;
};

export const ChatCard: VFC<ChatCardProps> = ({ showExampleQueries = false, compact = false }) => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const [messages, setMessages] = useState(MESSAGES_INITIAL);
  const [isWorking, setIsWorking] = useState(false);
  const [displayFormat, setDisplayFormat] = useState(DisplayFormat.SIMPLE);
  const [requestedModel, setRequestedModel] = useState(RequestedModel.LOCAL_LLM);

  const sendRequest = (inputPrompt: string) => {
    setIsWorking(true);
    sendNeoChatPrompt({ question: inputPrompt, llm_model_type: requestedModel })
      .then((response) => {
        if (response.status_code === 500 && response.detail) {
          toast({
            title: t('COMPONENTS.ERROR_MESSAGES.PROMPT_TITLE_ERROR'),
            description: t('COMPONENTS.ERROR_MESSAGES.PROMPT_DETAILED_ERROR'),
            status: 'error',
          });
          return;
        }
        const userRequest = { data: inputPrompt, src: ChatSource.QUESTION };
        const responseAnswer = { data: response.answer, src: ChatSource.ANSWER, question_id: response.question_id };
        const responseSQL = { data: response.sql, src: ChatSource.SQL };
        const responseData = { data: JSON.stringify(response.data, null, ' '), src: ChatSource.DATA };
        if (response.answer) {
          setMessages([...messages, userRequest, responseSQL, responseData, responseAnswer]);
        }
      })
      .catch(() => {
        toast({
          title: t('COMPONENTS.ERROR_MESSAGES.PROMPT_TITLE_ERROR'),
          description: t('COMPONENTS.ERROR_MESSAGES.PROMPT_DETAILED_ERROR'),
          status: 'error',
        });
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  return (
    <Card fontSize={compact ? '0.8rem' : '1rem'}>
      <ChatHeader />
      <ChatMessages messages={messages} displayFormat={displayFormat} compact={compact} />
      <ChatFooter
        handleSendMessage={sendRequest}
        isWorking={isWorking}
        displayFormat={displayFormat}
        setDisplayFormat={setDisplayFormat}
        requestedModel={requestedModel}
        setRequestedModel={setRequestedModel}
        compact={compact}
        showExampleQueries={showExampleQueries}
      />
    </Card>
  );
};
