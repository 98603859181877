import { useQuery, UseQueryOptions } from 'react-query';

import { createStoreProductStatisticsKey } from 'constants/queryCacheKeys';
import { getStoreProductStatistics, GetStoreProductStatisticsParams } from 'domain/statistics';

export type DataType = Unpromisify<ReturnType<typeof getStoreProductStatistics>>;
type ErrorType = Error;

export const useStoreProductStatistics = (
  params: GetStoreProductStatisticsParams,
  options?: UseQueryOptions<DataType, ErrorType, any>,
) =>
  useQuery<DataType, ErrorType>(
    createStoreProductStatisticsKey(params),
    () => getStoreProductStatistics(params),
    options,
  );
