import React, { useMemo } from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { Treemap, hierarchy, stratify, treemapSquarify } from '@visx/hierarchy';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { AnimatePresence } from 'framer-motion';

import { useAppConfig, useLiveRef } from 'hooks';
import { PageSpinnerOverlay } from 'components/Spinners';
import { ParentRect } from '../ParentRect';
import { colors } from '../chartThemes';
import { FamiliesDataEntry, TreemapNodeType } from './types';
import { FamiliesTreeMapNode, Props as TreeMapNodeProps } from './FamiliesTreeMapNode';

const margin = { top: 10, left: 10, right: 10, bottom: 10 };

interface Props {
  data: FamiliesDataEntry[];
  treeRootId: string;
  isLoading?: boolean;
  createNodeProps?: (node: TreemapNodeType) => Partial<TreeMapNodeProps>;
  titleHint?: React.ReactNode;
}

export const FamiliesTreeMap: React.FC<Props> = ({ data, treeRootId, isLoading, createNodeProps, titleHint }) => {
  const { root, colorScale } = useMemo(() => {
    const treeData = stratify<(typeof data)[0]>()
      .id((d) => d.id)
      .parentId((d) => d.parent)(data)
      .sum((d) => d.value);

    return {
      root: hierarchy(treeData).sort((a, b) => (b.value ?? 0) - (a.value ?? 0)),
      colorScale: scaleOrdinal({
        domain: data.filter((d) => d.id !== treeRootId).map((d) => d.id),
        range: colors,
      }),
    };
  }, [data, treeRootId]);

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    debounce: 10,
  });
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipTop, tooltipLeft } =
    useTooltip<FamiliesDataEntry>();
  const containerBoundsRef = useLiveRef(containerBounds);
  const {
    formatters: { percentageFormatter },
  } = useAppConfig();

  return (
    <Stack width="100%" height="100%" flexGrow={1}>
      <ParentRect
        minHeight="200px"
        sx={{
          position: 'relative',

          '[data-treemap-rect]:hover': {
            filter: 'brightness(75%)',
          },

          '[data-treemap-clickable]': {
            cursor: 'pointer',
          },
        }}
      >
        {({ width, height }) => {
          const xMax = width - margin.left - margin.right;
          const yMax = height - margin.top - margin.bottom;

          return (
            <>
              <svg width={width} height={height} ref={containerRef} onPointerOut={hideTooltip}>
                <Treemap top={margin.top} root={root} size={[xMax, yMax]} tile={treemapSquarify} round paddingInner={2}>
                  {(treemap) => (
                    <Group>
                      {treemap
                        .descendants()
                        .reverse()
                        .map((node) => {
                          if (node.depth !== 1) {
                            return null;
                          }

                          const nodeId = node.data.data.id;
                          const nodePercentage = percentageFormatter.format(node.data.data.percentage / 100);

                          return (
                            <FamiliesTreeMapNode
                              key={nodeId}
                              node={node}
                              nodePercentage={nodePercentage}
                              margin={margin}
                              color={colorScale(nodeId)}
                              onPointerMove={(ev) => {
                                const containerX = ('clientX' in ev ? ev.clientX : 0) - containerBoundsRef.current.left;
                                const containerY = ('clientY' in ev ? ev.clientY : 0) - containerBoundsRef.current.top;

                                showTooltip({
                                  tooltipTop: containerY,
                                  tooltipLeft: containerX,
                                  tooltipData: node.data.data,
                                });
                              }}
                              {...createNodeProps?.(node)}
                            />
                          );
                        })}
                    </Group>
                  )}
                </Treemap>
              </svg>
              <AnimatePresence>{isLoading && <PageSpinnerOverlay />}</AnimatePresence>
              {tooltipOpen && tooltipData && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
                  <Stack direction="column" spacing="3">
                    <HStack>
                      <Text as="strong" fontWeight="bold">
                        {tooltipData.id}
                      </Text>
                      {titleHint}
                    </HStack>
                    <Text as="span">{percentageFormatter.format(tooltipData.percentage / 100)}</Text>
                  </Stack>
                </TooltipInPortal>
              )}
            </>
          );
        }}
      </ParentRect>
    </Stack>
  );
};
