import { AppUser, TAuthenticatedUserData } from 'domain/user';

export const getAppAccessLevel = (user: TAuthenticatedUserData): AppUser['access'] => {
  switch (user.user_type_id) {
    case 0:
      return 'c-level';
    case 1:
      return 'area-manager';
    case 2:
      return 'supermarket-manager';
    case 3:
      return 'monitor';
    case 4:
      return 'section-manager';
    case 5:
      return 'admin';
    default:
      return 'supermarket-manager';
  }
};

export const toAppUser = (user: TAuthenticatedUserData): AppUser => ({ ...user, access: getAppAccessLevel(user) });
