import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';

import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { ChangePasswordCard } from './components';

export const GeneralPage: React.FC = () => {
  return (
    <Grid
      templateColumns={{
        base: '100%',
        [DESKTOP_BREAKPOINT]: 'repeat(3, 1fr)',
      }}
      gap={GRID_GAP_DEFAULT_VALUE}
    >
      <GridItem>
        <ChangePasswordCard />
      </GridItem>
    </Grid>
  );
};
