import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, TagProps, TagLabel } from '@chakra-ui/react';

import { INSIGHT_TYPE, TInsightType } from 'domain/insights';
import { assertNever } from 'utils/fn';

const variantToTagProps = (variant: TInsightType): Partial<TagProps> => {
  switch (variant) {
    case INSIGHT_TYPE.DROP:
      return {
        colorScheme: 'orange',
      };
    case INSIGHT_TYPE.GROW:
      return {
        colorScheme: 'blue',
      };
    case INSIGHT_TYPE.LOW:
      return {
        colorScheme: 'red',
      };
    case INSIGHT_TYPE.TOP:
      return {
        colorScheme: 'purple',
      };
    case INSIGHT_TYPE.SHORTAGE:
      return {
        colorScheme: 'yellow',
      };
    default:
      assertNever(variant);
      return {};
  }
};

interface Props {
  variant: TInsightType;
}

export const InsightObservationTag: React.FC<Props> = ({ variant }) => {
  const { t } = useTranslation('whisperme');

  return (
    <Tag {...variantToTagProps(variant)}>
      <TagLabel whiteSpace="nowrap" fontWeight="bold" fontSize="smaller">
        {t(`INSIGHTS.OBSERVATION_TYPE.${variant}` as const)}
      </TagLabel>
    </Tag>
  );
};
