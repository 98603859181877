import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Skeleton, Text, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '@chakra-ui/icons';
import * as O from 'fp-ts/Option';
import * as PATHS from 'constants/paths';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { useRoleDetailsMap, useUserDetails } from 'hooks/data';
import { TUserId } from 'domain/user';
import { useUserTypeIdToName } from 'hooks/useUserTypes';

interface Props {
  userId: TUserId;
}

export const UserDetailsPage: React.VFC<Props> = ({ userId }) => {
  const { t } = useTranslation('whisperme');
  const { data: userData, isLoading } = useUserDetails({ userId });
  const roleDetailsMap = useRoleDetailsMap();
  const getUserType = useUserTypeIdToName();

  if (!userData || isLoading || O.isNone(getUserType(userData.user_type_id))) {
    return (
      <Card>
        <CardTitleSection backButtonLink={PATHS.SETTINGS.USERS.ROOT.toPath()}>
          <Skeleton width="30%" />
        </CardTitleSection>
        <Skeleton width="100%" minHeight="250px" />
      </Card>
    );
  }

  return (
    <Card>
      <CardTitleSection
        as="h2"
        backButtonLink={PATHS.SETTINGS.USERS.ROOT.toPath()}
        settings={
          <CardTitleSectionSettings>
            <Button
              as={RouterLink}
              to={PATHS.SETTINGS.USERS.EDIT.toPath({ userId })}
              leftIcon={<EditIcon />}
              size="sm"
              variant="outline"
            >
              {t('SETTINGS.USERS.EDIT_ACTION_LABEL')}
            </Button>
          </CardTitleSectionSettings>
        }
      >
        {t('SETTINGS.USERS.USER_DETAILS_TITLE')}
      </CardTitleSection>
      <Stack spacing="3" as="ul">
        <Stack as="li" spacing="1">
          <Text as="span" fontSize="md" fontWeight="bold">
            {t('SETTINGS.USERS.DISPLAY_NAME_LABEL')}
          </Text>
          <Text as="span" fontSize="md">
            {userData.display_name}
          </Text>
        </Stack>

        <Stack as="li" spacing="1">
          <Text as="span" fontSize="md" fontWeight="bold">
            {t('SETTINGS.USERS.EMAIL_LABEL')}
          </Text>
          <Text as="span" fontSize="md">
            {userData.email}
          </Text>
        </Stack>

        <Stack as="li" spacing="1">
          <Text as="span" fontSize="md" fontWeight="bold">
            {t('SETTINGS.USERS.TYPE_LABEL')}
          </Text>
          <Text as="span" fontSize="md">
            {O.toUndefined(getUserType(userData.user_type_id))?.name}
          </Text>
        </Stack>

        <Stack as="li" spacing="1">
          <Text as="span" fontSize="md" fontWeight="bold">
            {t('SETTINGS.USERS.ROLES_LABEL')}
          </Text>
          {userData.roles.length === 0 ? (
            <Text as="span" fontSize="md">
              {t('SETTINGS.USERS.NO_ROLES_LABEL')}
            </Text>
          ) : (
            <Stack as="ul">
              {userData.roles.map((roleId) => (
                <Text as="span">{roleDetailsMap.get(roleId)?.name ?? roleId}</Text>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
