import React from 'react';
import { Flex } from '@chakra-ui/react';

interface Props {}

export const CardTitleSectionSettings: React.FC<Props> = ({ children }) => (
  <Flex
    sx={{
      flexGrow: 1,
      flexWrap: 'wrap',
      flexBasis: 'auto',
      justifyContent: 'flex-end',
      ml: 'auto',

      '& > *': {
        margin: '1',
        width: 'fit-content',
        maxWidth: 'max-content',
      },
    }}
  >
    {children}
  </Flex>
);
