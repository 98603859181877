import React from 'react';
import { Text } from '@chakra-ui/react';

interface Props extends React.ComponentPropsWithoutRef<typeof Text> {}

export const CardTitle: React.FC<Props> = ({ children, as = 'span', fontSize = 'lg', ...rest }) => (
  <Text as={as} color="gray.600" fontSize={fontSize} mb="3" display="flex" flexWrap="wrap" {...rest}>
    {children}
  </Text>
);
