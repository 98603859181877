import { LLM_PATH } from './llm';

const ROOT_PATH = `${LLM_PATH}/neoemail` as const;
export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const HISTORY_PATH = `${ROOT_PATH}/history` as const;
export const HISTORY = {
  PATH: HISTORY_PATH,
  toPath: () => HISTORY_PATH,
};
