import React, { useMemo } from 'react';
import { Stack, Grid, TableContainer, Skeleton, Table, Tbody, Td, Th, Thead, Tr, Center } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { GRID_GAP_DEFAULT_VALUE, DESKTOP_BREAKPOINT } from 'constants/css';
import { TStoreId } from 'domain/stores';
import { useAppConfig, useUserSectionIds, useSimilarStores } from 'hooks';
import { useGoodToHave } from 'hooks/useGoodToHave';
import { useTranslation } from 'react-i18next';

interface Props {
  storeId: TStoreId;
}

export const FreeTaskGoodToHave: React.FC<Props> = ({ storeId }) => {
  const {
    viewport,
    formatters: { currencyFormatter },
  } = useAppConfig();
  const { t } = useTranslation('whisperme');
  const sectionIds = useUserSectionIds();

  const { data, isFetching } = useGoodToHave([storeId], sectionIds[0]);
  const { data: similarStoresData } = useSimilarStores(storeId);

  const rows = useMemo(() => {
    if (!data || isFetching)
      return Array.from({ length: 10 }).map((_, i) => (
        <Tr key={i}>
          <Td>
            <Skeleton height="20px" width="auto" />
          </Td>
          <Td>
            <Skeleton height="20px" width="auto" />
          </Td>
          <Td isNumeric={true}>
            <Skeleton height="20px" width="auto" />
          </Td>
          <Td isNumeric={true}>
            <Skeleton height="20px" width="auto" />
          </Td>
        </Tr>
      ));

    if (!data.length) return <Center h="200px">{t('COMPONENTS.NO_DATA_LABEL')}</Center>;

    return data.map((p) => (
      <Tr key={p.product_code}>
        <Td>{p.product_code}</Td>
        <Td>{p.description}</Td>
        <Td isNumeric={true}>{p.quantity_sum}</Td>
        <Td isNumeric={true}>{currencyFormatter.format(p.gross_sales)}</Td>
      </Tr>
    ));
  }, [data, isFetching, currencyFormatter, t]);

  const table = (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',
        tableLayout: viewport !== 'mobile' ? 'fixed' : undefined,
        '& [data-cell-wrap]': {
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Thead>
        <Tr>
          <Th data-cell-wrap>{t('FREE_TASKS.GOOD_TO_HAVE_TASK.GOOD_PERFORMERS_TABLE.EAN_LABEL')}</Th>
          <Th data-cell-wrap>{t('FREE_TASKS.GOOD_TO_HAVE_TASK.GOOD_PERFORMERS_TABLE.DESCRIPTION_LABEL')}</Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.GOOD_TO_HAVE_TASK.GOOD_PERFORMERS_TABLE.UNITS_LABEL')}
          </Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.GOOD_TO_HAVE_TASK.GOOD_PERFORMERS_TABLE.REVENUE_LABEL')}
          </Th>
        </Tr>
      </Thead>
      <Tbody>{rows}</Tbody>
    </Table>
  );

  const similarStores = similarStoresData?.map(({ name }) => name).join(', ') || null;

  return (
    <Card>
      <Stack spacing="4">
        <Grid
          gap={GRID_GAP_DEFAULT_VALUE}
          alignItems="center"
          gridTemplateColumns={{
            base: '100%',
            [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
          }}
        ></Grid>
        <CardTitleSection>
          {t('FREE_TASKS.GOOD_TO_HAVE_TASK.GOOD_TO_HAVE_LABEL')} {t('FREE_TASKS.GOOD_TO_HAVE_TASK.BASED_ON')}:{' '}
          {similarStores}
        </CardTitleSection>
        <Stack position="relative">{viewport === 'mobile' ? <TableContainer>{table}</TableContainer> : table}</Stack>
      </Stack>
    </Card>
  );
};
