import { Flex, Text } from '@chakra-ui/react';
import React, { VFC } from 'react';
import { pipe } from 'fp-ts/function';
import { useTranslation } from 'react-i18next';
import type { TProductCode } from 'domain/core';
import { useAppConfig } from 'hooks';

type StockHeaderProps = {
  description?: string;
  orderDescription?: string;
  shelf?: string;
  packageSize?: string | number;
  productCode?: string | TProductCode;
  ean13?: string | number;
  ifls?: string;
  productLatestPrice?: number;
};

export const StockHeader: VFC<StockHeaderProps> = ({
  description,
  orderDescription,
  shelf,
  packageSize,
  productCode,
  ean13,
  ifls,
  productLatestPrice,
}) => {
  const { t } = useTranslation('whisperme');
  const getOrUnknown = (value?: unknown): string => (value ? `${value}` : t('COMPONENTS.STOCK_HEADER.UNKNOWN'));
  const {
    formatters: { currencyExactFormatter },
  } = useAppConfig();

  return (
    <Flex direction="row" width="100%" justifyContent="space-between" alignItems="start">
      <Flex direction="column">
        <Text as="h5" display="block">
          {t('COMPONENTS.STOCK_HEADER.TICKET_DESCRIPTION')}: <Text as="strong">{pipe(description, getOrUnknown)}</Text>
        </Text>
        <Text as="h5" display="block">
          {t('COMPONENTS.STOCK_HEADER.BLI_DESCRIPTION')}:{' '}
          <Text as="strong">{pipe(orderDescription, getOrUnknown)}</Text>
        </Text>
        <Text as="h5" display="block">
          {t('COMPONENTS.STOCK_HEADER.LAST_2_WEEKS_MAX_PRICE')}:{' '}
          <Text as="strong">{pipe(productLatestPrice ?? 0, currencyExactFormatter.format)}</Text>
        </Text>
      </Flex>
      <Text as="h5" display="block">
        {t('COMPONENTS.STOCK_HEADER.SHELF')}: <Text as="strong">{pipe(shelf, getOrUnknown)}</Text>
      </Text>
      <Text as="h5" display="block">
        {t('COMPONENTS.STOCK_HEADER.PACKAGE_SIZE')}: <Text as="strong">{pipe(packageSize, getOrUnknown)}</Text>
      </Text>
      <Flex direction="column">
        <Text as="h5" display="block">
          {t('COMPONENTS.STOCK_HEADER.EAN7')}: <Text as="strong">{pipe(productCode, getOrUnknown)}</Text>
        </Text>
        <Text as="h5" display="block">
          {t('COMPONENTS.STOCK_HEADER.EAN13')}: <Text as="strong">{pipe(ean13, getOrUnknown)}</Text>
        </Text>
        <Text as="h5" display="block">
          {t('COMPONENTS.STOCK_HEADER.IFLS')}: <Text as="strong">{pipe(ifls, getOrUnknown)}</Text>
        </Text>
      </Flex>
    </Flex>
  );
};
