import React from 'react';
import { Text, Wrap, WrapProps, WrapItem, HStack } from '@chakra-ui/react';

import { LegendOval } from './LegendOval';

interface Item {
  color: string;
  name: string;
}

export interface ChartLegendProps {
  onLegendItemClick?: (index: number) => void;
  isMetricVisible: (index: number) => boolean;
  direction?: WrapProps['direction'];
  items: Item[];
}

// NOTE(m.kania): I'm not entirely sure how a11y should look for chart (and it's legend items)
export const ChartLegend: React.FC<ChartLegendProps> = ({
  onLegendItemClick,
  isMetricVisible,
  direction = 'row',
  items,
}) => {
  const hasClickHandler = typeof onLegendItemClick === 'function';

  return (
    <Wrap direction={direction}>
      {items.map((item, i) => {
        const visible = isMetricVisible(i);

        return (
          <WrapItem key={i}>
            <HStack
              cursor={hasClickHandler ? 'pointer' : undefined}
              userSelect="none"
              spacing="1"
              onClick={() => {
                onLegendItemClick?.(i);
              }}
            >
              <LegendOval isVisible={visible} color={item.color} />
              <Text as="span">{item.name}</Text>
            </HStack>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};
