import * as t from 'io-ts';

import { RoleIdValidator, RoleSectionPrivilegesValidator, RoleStorePrivilegesValidator } from '../roles';

export const UserTypeIdValidator = t.number;
export type TUserTypeId = t.TypeOf<typeof UserTypeIdValidator>;

export const UserTypeValidator = t.type({
  user_type_id: UserTypeIdValidator,
  name: t.string,
});
export type TUserType = t.TypeOf<typeof UserTypeValidator>;

export const UserTypesValidator = t.array(UserTypeValidator);
export type TUserTypes = t.TypeOf<typeof UserTypesValidator>;

export const UserIdValidator = t.number;
export type TUserId = t.TypeOf<typeof UserIdValidator>;

export const AuthenticatedUserDataValidator = t.type({
  user_id: UserIdValidator,
  // session_id: t.string,
  is_admin: t.boolean,
  display_name: t.string,
  store_privileges: RoleStorePrivilegesValidator,
  user_type_id: t.number,
  section_privileges: RoleSectionPrivilegesValidator,
});
export type TAuthenticatedUserData = t.TypeOf<typeof AuthenticatedUserDataValidator>;

export const UserValidator = t.intersection([
  t.type({
    is_admin: t.boolean,
    email: t.string,
    display_name: t.string,
    user_type_id: UserTypeIdValidator,
    roles: t.array(RoleIdValidator),
  }),
  t.partial({
    password: t.string,
  }),
]);
export type TUser = t.TypeOf<typeof UserValidator>;

export const UserListValidator = t.type({
  items: t.array(
    t.type({
      id: UserIdValidator,
      user: UserValidator,
    }),
  ),
});
export type TUserList = t.TypeOf<typeof UserListValidator>;

interface UserAccess<AccessLevel extends string> {
  readonly access: AccessLevel;
}

export interface AreaManagerAppUser extends TAuthenticatedUserData, UserAccess<'area-manager'> {}
export interface SupermarketManagerAppUser extends TAuthenticatedUserData, UserAccess<'supermarket-manager'> {}
export interface MonitorAppUser extends TAuthenticatedUserData, UserAccess<'monitor'> {}
export interface SectionManagerAppUser extends TAuthenticatedUserData, UserAccess<'section-manager'> {}
export interface CLevelAppUser extends TAuthenticatedUserData, UserAccess<'c-level'> {}
export interface AdminAppUser extends TAuthenticatedUserData, UserAccess<'admin'> {}

export type AppUser =
  | AreaManagerAppUser
  | SupermarketManagerAppUser
  | AdminAppUser
  | MonitorAppUser
  | SectionManagerAppUser
  | CLevelAppUser;
