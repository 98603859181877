import React, { useState } from 'react';
import { Group } from '@visx/group';
import { useAppConfig } from 'hooks';
import { SeriesPoint } from '@visx/shape/lib/types';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Grid } from '@visx/grid';
import { BarStack, Line } from '@visx/shape';
import { AxisBottom, AxisRight } from '@visx/axis';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { LegendOrdinal } from '@visx/legend';
import { localPoint } from '@visx/event';
import { format } from 'date-fns';
import { CustomerPrediction, GrossIncomePrediction, TStoreBudgets } from 'domain/predictions';
import { RectData } from './types';
import { Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { dateToYMD, getCurrentDate } from 'utils/date';

const DEFAULT_MARGIN = { top: 40, right: 0, bottom: 0, left: 0 };

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: '#ffffffd2',
  color: 'black',
};

type KeyNames = 'history' | 'quantile_5' | 'quantile_95';

type TooltipData = {
  bar: SeriesPoint<RectData>;
  key: KeyNames;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

let tooltipTimeout: number;

type ChartData = GrossIncomePrediction & CustomerPrediction;

interface TBarChartProps {
  data: ChartData;
  labelGetter: (labelText: string) => string;
  parseDataForChart: (data: ChartData) => RectData[];
  isCurrencySymbolUsed: boolean;
}

interface InnerProps extends TBarChartProps {
  width: number;
  height: number;
  margin?: typeof DEFAULT_MARGIN;
}
interface Bar {
  key: string;
  bar: {
    data: RectData;
  };
  color: string;
}

const parseBudgetArrayToRecord = (budgetsArray: TStoreBudgets) => {
  const result: { [key: string]: number } = {};
  budgetsArray.forEach((budget) => {
    result[budget.date] = budget.expected_budget;
  });
  return result;
};

const BarChart: React.FC<InnerProps> = ({
  width,
  height,
  margin = DEFAULT_MARGIN,
  data,
  labelGetter,
  parseDataForChart,
  isCurrencySymbolUsed,

  /* eslint-disable-next-line sonarjs/cognitive-complexity */
}) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { dateOptions } = useAppConfig();
  const { t } = useTranslation('whisperme');
  const [isHistoricPredictionHidden, setIsHistoricPredictionHidden] = useState(false);
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const chartRectData = parseDataForChart(data);
  const currPredictionDate = data.prediction_customers_count?.date ?? dateToYMD(getCurrentDate());

  const keys = Object.keys(chartRectData[0]).filter((d) => d !== 'date') as KeyNames[];

  const max = Math.max(...chartRectData.flatMap((d) => [d.history, d.quantile_5, d.quantile_95]));
  const xMax = width;
  const yMax = height - margin.top - 50;

  const dateScale = scaleBand<string>({
    domain: chartRectData.map((d) => d.date),
    padding: 0.2,
  });

  const valueScale = scaleLinear<number>({
    domain: [0, max + 50],
    nice: true,
  });

  dateScale.rangeRound([0, xMax]);

  valueScale.range([yMax, 0]);

  const getDate = (d: RectData) => d.date;

  const barColors = ['#dfb24a', '#bcb4b4', '#46a26c', '#220180'];
  const colorScale = scaleOrdinal<KeyNames, string>({
    domain: keys,
    range: barColors,
  });

  const toggleShowHistoricPredictions = () => setIsHistoricPredictionHidden((curr) => !curr);

  const customBottomAxisTickLabel = (currDate: string) => {
    const date = new Date(currDate);
    return format(date, 'EE', { locale: dateOptions.locale }) + ' ' + format(date, 'MM-dd');
  };

  const getMaxQuantileValue = (tooltipData: RectData, tooltipKey: KeyNames) => {
    return (
      tooltipKey !== 'quantile_95' ? tooltipData[tooltipKey] : tooltipData[tooltipKey] + tooltipData['quantile_5']
    ).toFixed(2);
  };

  const getIsHistoricalPrediction = (bar: Bar): boolean => {
    return bar.key !== 'history' && bar.bar.data.history !== -1;
  };

  const getColorVariant = (color: string, bar: Bar): string => {
    return getIsHistoricalPrediction(bar) ? color : bar.color;
  };

  const getPredictionVariant = (bar: Bar) => {
    return isHistoricPredictionHidden
      ? getColorVariant(`${bar.color}${60}`, bar)
      : getColorVariant(`rgba(170, 170, 170, 0)`, bar);
  };

  const budgetMap = data.store_budgets ? parseBudgetArrayToRecord(data.store_budgets) : {};

  const getEuroSign = isCurrencySymbolUsed ? '€' : '';

  return width < 10 ? null : (
    <div style={{ position: 'relative' }}>
      <svg ref={containerRef} width={width} height={height}>
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={valueScale}
          width={xMax}
          height={yMax}
          stroke="black"
          strokeOpacity={0.1}
          xOffset={dateScale.bandwidth() / 0.001}
          numTicksColumns={0}
        />

        <Group top={margin.top}>
          <BarStack<RectData, KeyNames>
            data={chartRectData}
            keys={keys}
            x={getDate}
            xScale={dateScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  return (
                    <>
                      <rect
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={bar.x + 15}
                        y={bar.y}
                        height={Math.max(bar.height, 0)}
                        width={!getIsHistoricalPrediction(bar) || isHistoricPredictionHidden ? bar.width - 15 : 0}
                        display={bar.key === 'quantile_5' ? 'none' : ''}
                        pointerEvents={bar.key === 'quantile_5' ? 'none' : ''}
                        fill={getPredictionVariant(bar)}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          const eventSvgCoords = localPoint(event);
                          const left = bar.x + bar.width / 2;
                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: left,
                          });
                        }}
                      />
                      {!getIsHistoricalPrediction(bar) && bar.bar.data.budget !== -1 && (
                        <Line
                          key={`line-${barStack.index}-${bar.index}`}
                          from={{ x: bar.x + 15, y: valueScale(budgetMap[bar.bar.data.date]) }}
                          to={{ x: bar.x + bar.width, y: valueScale(budgetMap[bar.bar.data.date]) }}
                          stroke="blue"
                          strokeWidth={2}
                          strokeDasharray="5,5"
                        />
                      )}
                    </>
                  );
                }),
              )
            }
          </BarStack>
          <AxisRight
            scale={valueScale}
            stroke="#00000010"
            hideTicks={true}
            numTicks={5}
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 12,
              textAnchor: 'start',
              dy: '0.33em',
            })}
          />
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          scale={dateScale}
          tickFormat={customBottomAxisTickLabel}
          stroke="#00000010"
          hideTicks={true}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          top: margin.top / 2 - 30,
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          fontSize: '14px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: margin.top / 2 - 30,
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'end',
            fontSize: '14px',
          }}
        >
          <LegendOrdinal scale={colorScale} direction="row" labelMargin="0 15px 0 0">
            {(labels) => {
              const filteredLabels = isCurrencySymbolUsed ? labels : labels.slice(0, -1);
              return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {
                    <div key={`historic-prediction`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                      <Switch
                        size="sm"
                        id="toggleHistoricPredictions"
                        paddingRight="0.4em"
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          ev.preventDefault();
                          toggleShowHistoricPredictions();
                        }}
                      />
                      <>{t('DAILY_TASKS.CUSTOMERS_PREDICTION.CUSTOMERS_LEGEND.HISTORIC_PREDICTIONS')}</>
                    </div>
                  }
                  {filteredLabels.map(
                    (label, i) =>
                      label.datum !== 'quantile_5' && (
                        <div key={`legend-${i}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                          <div
                            style={{
                              width: '12px',
                              height: '12px',
                              marginRight: '5px',
                              backgroundColor: barColors[i],
                              borderRadius: '4px',
                            }}
                          />
                          <span>{labelGetter(label.text)}</span>
                        </div>
                      ),
                  )}
                </div>
              );
            }}
          </LegendOrdinal>
        </div>
      </div>

      {tooltipOpen && tooltipData && tooltipData.key !== 'quantile_5' && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...tooltipStyles,
            display:
              !isHistoricPredictionHidden &&
              tooltipData.bar.data.quantile_95 &&
              tooltipData.bar.data.date !== currPredictionDate
                ? 'none'
                : '',
          }}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{labelGetter(tooltipData.key)}</strong>
          </div>
          <div>
            {tooltipData.key === 'history'
              ? `${getMaxQuantileValue(tooltipData.bar.data, tooltipData.key)}${getEuroSign}`
              : `between ${getMaxQuantileValue(
                  tooltipData.bar.data,
                  'quantile_5',
                )}${getEuroSign} - ${getMaxQuantileValue(tooltipData.bar.data, 'quantile_95')}${getEuroSign}`}
          </div>
          <div>
            <small>budget: {budgetMap[getDate(tooltipData.bar.data)]}</small>
          </div>
          <div>
            <small>date: {getDate(tooltipData.bar.data)}</small>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
};

export const PredictionBarChart = React.memo<TBarChartProps>((props) => (
  <ParentSize>{({ width, height }) => <BarChart {...props} width={width} height={height} />}</ParentSize>
));
