import React from 'react';
import { Option, none } from 'fp-ts/Option';

import { AppUser, TLoginPayload } from 'domain/user';

export interface UserContextType {
  user: Option<AppUser>;
  login: (payload: TLoginPayload) => Promise<void>;
  logout: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextType>({
  user: none,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});
