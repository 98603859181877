import { TProductFamily } from 'domain/product-family';
import { TStoreId } from 'domain/stores';
import { useProductFamiliesMaps } from 'hooks/data';
import { useMemo, useReducer } from 'react';

type StateT = {
  selectedStoreId?: TStoreId;
  selectedFamilyCode?: TProductFamily['name'];
  selectedSectionCode?: TProductFamily['name'];
};

const CHANGE_STORE_ID = 'CHANGE_STORE_ID';

type ChangeStoreId = {
  type: typeof CHANGE_STORE_ID;
  payload: StateT['selectedStoreId'];
};

const CHANGE_FAMILY_CODE = 'CHANGE_FAMILY_CODE';

type ChangeFamilyCode = {
  type: typeof CHANGE_FAMILY_CODE;
  payload: StateT['selectedFamilyCode'];
};

const CHANGE_SECTION_CODE = 'CHANGE_SECTION_CODE';

type ChangeSectionCode = {
  type: typeof CHANGE_SECTION_CODE;
  payload: StateT['selectedSectionCode'];
};

type Actions = ChangeStoreId | ChangeFamilyCode | ChangeSectionCode;

const initialState: StateT = {};

const isInRootFamily = (
  rootFamilies: TProductFamily[],
  productFamilies: TProductFamily[],
  rootFamilyName: TProductFamily['name'],
  productFamilyName: TProductFamily['name'],
): boolean => {
  const rootFamilyChildrens = rootFamilies.find(({ name }) => name === rootFamilyName)?.children_ids;

  if (!rootFamilyChildrens) return false;

  const productFamilyId = productFamilies.find(({ name }) => name === productFamilyName)?.id;

  if (!productFamilyId) return false;

  return rootFamilyChildrens.includes(productFamilyId);
};

/**
 * Returns reducer with avaiable filters.
 */
const useReducerFn = () => {
  const { rootFamilies, productFamilies } = useProductFamiliesMaps();

  const reducer = (state: StateT, action: Actions) => {
    if (action.type === CHANGE_STORE_ID) return { ...state, selectedStoreId: action.payload };

    if (action.type === CHANGE_FAMILY_CODE) return { ...state, selectedFamilyCode: action.payload };

    if (action.type === CHANGE_SECTION_CODE) {
      let selectedFamilyCode = state.selectedFamilyCode;

      if (
        action.payload &&
        state.selectedFamilyCode &&
        !isInRootFamily(rootFamilies, productFamilies, action.payload, state.selectedFamilyCode)
      )
        selectedFamilyCode = undefined;

      return { ...state, selectedSectionCode: action.payload, selectedFamilyCode };
    }

    return state;
  };

  return {
    reducer,
  };
};

export const useProductsListFilters = (optionalState: StateT) => {
  const { reducer } = useReducerFn();
  const [{ selectedStoreId, selectedFamilyCode, selectedSectionCode }, dispatch] = useReducer(reducer, {
    ...initialState,
    ...optionalState,
  });

  return useMemo(
    () => ({
      selectedSectionCode,
      selectedStoreId,
      selectedFamilyCode,
      setSelectedStoreId: (payload: ChangeStoreId['payload']) => dispatch({ type: CHANGE_STORE_ID, payload }),
      setSelectedFamilyCode: (payload: ChangeFamilyCode['payload']) => dispatch({ type: CHANGE_FAMILY_CODE, payload }),
      setSelectedSectionCode: (payload: ChangeSectionCode['payload']) =>
        dispatch({ type: CHANGE_SECTION_CODE, payload }),
    }),
    [selectedSectionCode, selectedStoreId, selectedFamilyCode],
  );
};
