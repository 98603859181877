import { buildChartTheme } from '@visx/xychart';

// NOTE(m.kania): for 'smoother' colors I read that it is advised to create a gradient and read/interpolate values
// from it (depending on amount of colors needed)
export const colors = [
  '#a29ffa',
  '#ff6565',
  '#3686ff',
  '#fdd982',
  '#61e3a1',
  '#58d8fe',
  '#19282d',
  '#96ea4e',
  '#2e0d93',
  '#46a26c',
  '#8b123a',
  '#d58e43',
  '#1a6587',
];

export const backgroundColor = '#fff';
export const gridColor = '#eaf0f4';
export const gridColorDark = '#718096';

type TColors = string[];
export const buildColorTheme = (colors: TColors) =>
  buildChartTheme({
    backgroundColor,
    colors,
    tickLength: 4,
    gridColor,
    gridColorDark,
  });

export const theme = buildColorTheme(colors);

export const insightsColors = ['#76E4F7'];

export const insightsTheme = buildColorTheme(insightsColors);
