import React, { VFC } from 'react';
import { Flex } from '@chakra-ui/react';

export const ChatHeader: VFC = () => {
  return (
    <Flex w="100%">
      <Flex flexDirection="column" mx="5" justify="center"></Flex>
    </Flex>
  );
};
