export const noop = () => {};

// @ts-ignore
export const assertNever = (value: never): never => {
  if (process.env.NODE_ENV === 'development') {
    throw new Error(`Unexpected branch ${value}`);
  }
};

export const returnNull = () => null;
