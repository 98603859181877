import React, { FC, useEffect, useState } from 'react';
import { Box, SliderThumb, SliderTrack, Slider, Text, SliderFilledTrack, SliderMark } from '@chakra-ui/react';
import { useDebounce } from 'react-use';

type FilterProps = {
  id: string;
  value: number;
  maxValue?: number;
  convertToDecimal?: boolean;
  isValueProcentage?: boolean;
  onChange: (value: number) => void;
};

export const NeoEmailCustomersMapFilter: FC<FilterProps> = ({ id, value, onChange, maxValue }) => {
  const [currentValue, setCurrentValue] = useState<number>(value);

  useDebounce(
    () => {
      if (currentValue === value) return;

      onChange(currentValue as number);
    },
    500,
    [currentValue],
  );

  useEffect(() => {
    setCurrentValue(value);
    // NOTE(damlysa): this shouldn't have currentValue because it can be easlly change which can cause Filters to stop working
  }, [value]);

  return (
    <>
      <Text color="grey.500" fontSize="md" fontWeight="bold" mb="20px">
        Select number of customers
      </Text>

      <Box paddingX="2rem">
        <Slider
          id={id}
          value={currentValue}
          min={0}
          max={maxValue ? maxValue : 1000}
          onChange={setCurrentValue}
          onChangeEnd={(newValue) => {
            setCurrentValue(newValue);
          }}
        >
          <SliderMark value={0} mt="1" ml="-1" fontSize="sm">
            0
          </SliderMark>
          <SliderMark value={500} mt="1" ml="-2.5" fontSize="sm">
            500
          </SliderMark>
          <SliderMark value={1000} mt="1" ml="-3.5" fontSize="sm">
            1000
          </SliderMark>
          <SliderMark value={currentValue} bg="blue.200" borderRadius="lg" textAlign="center" mt="-10" ml="-5" w="12">
            {currentValue}
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb borderColor="blue" />
        </Slider>
      </Box>
    </>
  );
};
