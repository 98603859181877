import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link, VStack, Table, Tbody, Td, Thead, Tr, Box, TableContainer, Center, Text, Select } from '@chakra-ui/react';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import * as Ord from 'fp-ts/Ord';
import { identity, pipe } from 'fp-ts/function';
import { AnimatePresence } from 'framer-motion';
import * as PATHS from 'constants/paths';
import { parse, subDays } from 'date-fns';
import { TableHeader as Th } from 'components/TableHeader';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import {
  useAppConfig,
  useCalendarDateRange,
  usePaginate,
  useSortTable,
  useUserContext,
  useUserSections,
  useUserStoreIds,
} from 'hooks';
import { useProductFamiliesMaps, useStoreProductStatistics } from 'hooks/data';
import { DataType as ProductStatistics } from 'hooks/data/useStoreProductStatistics';
import { NullableSingleStoreSelect } from 'components/Select';
import { CardChartSkeleton } from 'components/Skeletons';
import { PageSpinnerOverlay } from 'components/Spinners';
import { getCurrentDate } from 'utils/date';
import { getStoreProductStatisticsExport } from 'domain/statistics';
import { useProductsListFilters } from './hooks';
import { DateRangePicker } from 'components/DateRangePicker';
import { TProductFamily } from 'domain/product-family';
import { Pagination } from 'components/Pagination';
import { ExportLink } from 'components/ExportLink';
import { hasMonitorAccess } from 'domain/user';
import { LocationState } from './types';

const getFamilyName = (familyId: TProductFamily['parent_id'], productFamilies: TProductFamily[]) =>
  pipe(
    familyId,
    O.match<TProductFamily['id'], TProductFamily['name'] | undefined>(
      () => undefined,
      (parentId) => productFamilies.find(({ id }) => id === parentId)?.name,
    ),
  );

const generateOptionList = (
  list: TProductFamily[],
  rootFamiliesList?: TProductFamily[],
  rootFamilyName?: TProductFamily['name'],
) =>
  pipe(
    list,
    A.sort(
      pipe(
        S.Ord,
        Ord.contramap(({ name }: TProductFamily) => name),
      ),
    ),
    rootFamilyName && rootFamiliesList
      ? A.filter((family: TProductFamily) => getFamilyName(family.parent_id, rootFamiliesList) === rootFamilyName)
      : (value) => value,
    A.map(({ id, name }) => (
      <option key={`${id}_${name}`} value={name}>
        {name}
      </option>
    )),
  );

// FIXME(r.leykam): refactor
// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProductAnalyticsListPage: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { user } = useUserContext();
  const { column: column_sort, createColumnOnClick, isSorted } = useSortTable();

  const {
    viewport,
    formatters: { currencyFormatter },
  } = useAppConfig();
  const userStoreIds = useUserStoreIds();
  const userRootFamilies = useUserSections();
  const authSections = userRootFamilies.map((section) => section.name);
  const { productFamilies } = useProductFamiliesMaps();
  const [page, { next, previous, set, reset }] = usePaginate();
  const hasSingleSectionAccess = hasMonitorAccess(user);

  // FIXME: fix params query usage
  const defaultLocationParams: LocationState['params'] = {
    date_from: undefined,
    order: 'DESC',
    root_path: '',
    back_button_title: t('COMPONENTS.BACK_BUTTON.ARIA_LABEL'),
  };
  const params = useParams<LocationState['params']>();
  const {
    date_from: startDateLocation,
    order,
    root_path: rootPath,
    back_button_title: backButtonTitle,
  } = { ...defaultLocationParams, ...params };
  const { dateParams, calendarProps } = useCalendarDateRange({
    initialStartDate: startDateLocation
      ? parse(startDateLocation, 'YYYY-MM-DD', new Date())
      : subDays(getCurrentDate(), 7),
    initialEndDate: getCurrentDate(),
  });

  const {
    selectedStoreId,
    setSelectedStoreId,
    selectedFamilyCode,
    selectedSectionCode,
    setSelectedFamilyCode,
    setSelectedSectionCode,
  } = useProductsListFilters({
    selectedStoreId: userStoreIds.length === 1 ? userStoreIds[0] : undefined,
    selectedSectionCode: authSections.length === 1 ? authSections[0] : undefined,
  });

  const {
    data: { items: data, total } = { items: [] as ProductStatistics['items'], total: 1000 },
    status,
    isFetching,
  } = useStoreProductStatistics(
    {
      storeIds: typeof selectedStoreId !== 'undefined' ? selectedStoreId : userStoreIds,
      column_sort,
      order: order,
      family_filters: selectedFamilyCode ? [selectedFamilyCode] : undefined,
      section_filters: selectedSectionCode ? [selectedSectionCode] : undefined,
      date_to: dateParams.endDate || undefined,
      date_from: dateParams.startDate,
      page,
    },
    {
      keepPreviousData: true,
    },
  );

  const max = useMemo(() => Math.ceil(total / 50), [total]);

  const PaginationComp = useCallback(() => {
    return data.length !== 0 ? (
      <Pagination current={page} set={set} reset={reset} next={next} previous={previous} max={max} />
    ) : null;
  }, [data, page, set, next, reset, previous, max]);

  if (!data && status === 'loading') {
    return <CardChartSkeleton chartMinWidth="100%" chartMinHeight="min(80vh, 700px)" />;
  }

  const Yes = t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_TRUTHY_LABEL');
  const No = t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_FALSY_LABEL');

  const table = (
    <>
      <PaginationComp />
      <Table variant="products">
        <Thead>
          <Tr>
            <Th onClick={createColumnOnClick('description')} isSorted={isSorted('description')}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.PRODUCT')}
            </Th>
            <Th onClick={createColumnOnClick('product_code')} isSorted={isSorted('product_code')}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.PRODUCT_CODE')}
            </Th>
            <Th onClick={createColumnOnClick('section_name')} isSorted={isSorted('section_name')}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.PRODUCT_SECTION')}
            </Th>
            <Th onClick={createColumnOnClick('family_name')} isSorted={isSorted('family_name')}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.PRODUCT_FAMILY')}
            </Th>
            <Th onClick={createColumnOnClick('is_regional')} isSorted={isSorted('is_regional')}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.REGIONAL')}
            </Th>
            <Th onClick={createColumnOnClick('total_discounts_eur')} isSorted={isSorted('total_discounts_eur')}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.DISCOUNT')}
            </Th>
            <Th onClick={createColumnOnClick('quantity')} isSorted={isSorted('quantity')} isNumeric={true}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.UNITS')}
            </Th>
            <Th onClick={createColumnOnClick('sales_eur')} isSorted={isSorted('sales_eur')} isNumeric={true}>
              {t('ANALYTICS.PRODUCTS.LIST_PAGE.TABLE_HEADERS.REVENUE')}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((d, idx) => {
            return (
              <Tr
                key={idx}
                _hover={{
                  bg: 'gray.50',
                }}
                _active={{
                  bg: 'blue.50',
                }}
              >
                <Td
                  sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: '1.5em',
                  }}
                >
                  <Link as={RouterLink} to={PATHS.ANALYTICS.PRODUCTS.DETAILS.toPath(d.product_code)} color="blue.500">
                    {pipe(
                      d.description,
                      O.fold(() => '', identity),
                    )}
                  </Link>
                </Td>
                <Td>{d.product_code}</Td>
                <Td>
                  {pipe(
                    d.section_name,
                    O.fold(() => '', identity),
                  )}
                </Td>
                <Td>
                  {pipe(
                    d.family_name,
                    O.fold(() => '', identity),
                  )}
                </Td>
                <Td>
                  {pipe(
                    d.is_regional,
                    O.getOrElse(() => false),
                    (regional) => (regional ? Yes : No),
                  )}
                </Td>
                <Td>{d.total_discounts_eur < 0 ? Yes : No}</Td>
                <Td isNumeric={true}>{d.quantity}</Td>
                <Td isNumeric={true}>{currencyFormatter.format(d.sales_eur)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <PaginationComp />
    </>
  );

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            <Center p={1}>
              <ExportLink
                href={getStoreProductStatisticsExport({
                  storeIds: typeof selectedStoreId !== 'undefined' ? selectedStoreId : userStoreIds,
                  family_filters: selectedFamilyCode ? [selectedFamilyCode] : undefined,
                  section_filters: selectedSectionCode ? [selectedSectionCode] : undefined,
                  date_to: dateParams.endDate || undefined,
                  date_from: dateParams.startDate,
                })}
                fileName={`products.xlsx`}
              />
            </Center>
            {!hasSingleSectionAccess ? (
              <Select
                size="sm"
                value={selectedSectionCode}
                onChange={(event) => {
                  const selected = userRootFamilies.find((value) => value.name === event.target.value);
                  setSelectedSectionCode(selected?.name);
                }}
              >
                (
                <option value="" key="all">
                  {t('COMPONENTS.OPTION_VALUE_ALL_SECTIONS')}
                </option>
                ){generateOptionList(userRootFamilies)}
              </Select>
            ) : null}
            <Select
              size="sm"
              value={selectedFamilyCode}
              onChange={(event) => {
                const selected = productFamilies.find((value) => value.name === event.target.value);
                setSelectedFamilyCode(selected?.name);
              }}
            >
              <option value="" key="all">
                {t('COMPONENTS.OPTION_VALUE_ALL_FAMILIES')}
              </option>
              {generateOptionList(productFamilies, userRootFamilies, selectedSectionCode)}
            </Select>
            {userStoreIds.length > 1 && (
              <NullableSingleStoreSelect
                selectableStoreIds={userStoreIds}
                onChange={(newStoreId) => {
                  setSelectedStoreId(newStoreId);
                }}
                value={selectedStoreId}
                placeholder={t('COMPONENTS.STORE_SELECT.ALL_STORES_LABEL')}
              />
            )}
            <DateRangePicker {...calendarProps} />
          </CardTitleSectionSettings>
        }
        backButtonLink={rootPath}
      >
        {backButtonTitle}
      </CardTitleSection>
      <VStack flex="1 1 auto">
        <Box position="relative" width="100%" minWidth="200px" minHeight="300px">
          {viewport === 'desktop' ? table : <TableContainer>{table}</TableContainer>}
          {status === 'success' && data.length === 0 && (
            <Center minHeight="10">
              <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
            </Center>
          )}
          <AnimatePresence>{isFetching && <PageSpinnerOverlay />}</AnimatePresence>
        </Box>
      </VStack>
    </Card>
  );
};
