import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { languages, resources, defaultNS, I18nAppLanguage } from './resources';

export const i18nInstance = i18n;
export const defaultLanguage: I18nAppLanguage = 'en-GB';

i18nInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    compatibilityJSON: 'v3',

    resources,

    fallbackLng: defaultLanguage,
    supportedLngs: languages,

    keySeparator: '.',

    interpolation: {
      prefix: '{{',
      suffix: '}}',
      escapeValue: false,
    },
    ns: defaultNS,
    defaultNS,
    react: { useSuspense: true },
  });

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.__i18nInstance = i18nInstance;
}
