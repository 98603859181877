import { pipe } from 'fp-ts/function';
import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, makeCancellableFetch, urlWithQueryParams } from 'utils/request';
import { TStoreId } from '../stores';
import {
  TStatisticType,
  StatisticsPlot,
  ProductStatisticArrayValidator,
  StatisticsPlotsValidator,
  ErrorListValidator,
  ReceiptSourceLinkValidator,
  TErrorId,
} from './types';
import { dateToYMDorUndefined } from 'utils/date';
import { TGetAnyCsvParams, DATA_RESOLUTION } from 'domain/core';
import { SortedT } from 'components/TableHeader';
import { TProductFamily } from 'domain/product-family';
import { ValueOf } from '@visx/scale';

const API_URLS = {
  getStoreStatistics: () => `${API_BASE_URL}/statistics/`,
  getStoreProductStatistics: () => `${API_BASE_URL}/statistics/product`,
  getStoreProductStatisticsExport: () => `${API_BASE_URL}/statistics/product/xlsx`,
  getReceiptErrorList: () => `${API_BASE_URL}/report/errors/list`,
  getReceiptSourceLink: (errorId: TErrorId | undefined) => `${API_BASE_URL}/report/errors/details/${errorId}`,
};

export interface GetReceiptErrorListParams {
  start_time: Date;
  end_time: Date;
  store_ids: TStoreId[];
}

export const getReceiptErrorList = ({ start_time, end_time, store_ids, ...queryParams }: GetReceiptErrorListParams) =>
  pipe(
    API_URLS.getReceiptErrorList(),
    urlWithQueryParams({
      start_time: dateToYMDorUndefined(start_time),
      end_time: dateToYMDorUndefined(end_time),
      store_ids: store_ids,
      ...queryParams,
    }),
    createValidatedCancellableFetch(ErrorListValidator),
  );

export interface GetStoreStatisticsParams {
  storeIds: TStoreId | TStoreId[];
  resolution?: ValueOf<typeof DATA_RESOLUTION>;
  statisticIds?: TStatisticType | TStatisticType[];
  date_from?: Date;
  date_to?: Date;
}

export interface GetReceiptSourceLinkParams {
  error_id: TErrorId | undefined;
}

export const getReceiptSourceLink = ({ error_id }: GetReceiptSourceLinkParams) =>
  pipe(API_URLS.getReceiptSourceLink(error_id), createValidatedCancellableFetch(ReceiptSourceLinkValidator));

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/store_statistics_statistics_get
 */
export const getStoreStatistics = ({
  storeIds,
  statisticIds,
  date_from,
  date_to,
  ...queryParams
}: GetStoreStatisticsParams) =>
  pipe(
    API_URLS.getStoreStatistics(),
    urlWithQueryParams({
      store_id: storeIds,
      stat_id: statisticIds,
      date_from: dateToYMDorUndefined(date_from),
      date_to: dateToYMDorUndefined(date_to),
      ...queryParams,
    }),
    (url) => makeCancellableFetch<StatisticsPlot[]>(url),
  );

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/store_statistics_statistics_get
 */
export const getValidatedStoreStatistics = ({
  storeIds,
  statisticIds,
  date_from,
  date_to,
  ...queryParams
}: GetStoreStatisticsParams) =>
  pipe(
    API_URLS.getStoreStatistics(),
    urlWithQueryParams({
      store_id: storeIds,
      stat_id: statisticIds,
      date_from: dateToYMDorUndefined(date_from),
      date_to: dateToYMDorUndefined(date_to),
      ...queryParams,
    }),
    createValidatedCancellableFetch(StatisticsPlotsValidator),
  );

export interface GetStoreProductStatisticsParams {
  storeIds?: TStoreId | TStoreId[];
  column_sort?: string;
  order?: SortedT;
  family_filters?: TProductFamily['name'][];
  section_filters?: TProductFamily['name'][];
  date_from?: Date;
  date_to?: Date;
  page?: number;
  size?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/product_statistics_statistics_product_get
 */
export const getStoreProductStatistics = ({
  storeIds,
  size,
  page,
  date_from,
  date_to,
  ...queryParams
}: GetStoreProductStatisticsParams) =>
  pipe(
    API_URLS.getStoreProductStatistics(),
    urlWithQueryParams({
      store_id: storeIds,
      size,
      page,
      date_from: dateToYMDorUndefined(date_from),
      date_to: dateToYMDorUndefined(date_to),
      ...queryParams,
    }),
    createValidatedCancellableFetch(ProductStatisticArrayValidator),
  );

export const getStoreProductStatisticsExport = ({
  storeIds,
  size,
  date_from,
  date_to,
  ...queryParams
}: GetStoreProductStatisticsParams & TGetAnyCsvParams) =>
  pipe(
    API_URLS.getStoreProductStatisticsExport(),
    urlWithQueryParams({
      store_id: storeIds,
      size,
      date_from: dateToYMDorUndefined(date_from),
      date_to: dateToYMDorUndefined(date_to),
      ...queryParams,
    }),
  );
