import { useCounter } from 'react-use';
import { CounterActions } from 'react-use/lib/useCounter';

export type PageManagement = {
  /**
   * Go to next page.
   * @param delta Defines how many pages fether (default 1)
   */
  next: (delta?: number) => void;

  /**
   * Go to previous page.
   * @param delta Defines how many pages backwords (default 1)
   */
  previous: (delta?: number) => void;
} & Pick<CounterActions, 'set' | 'reset'>;

/**
 * Pagination hook.
 * @param initial Starting number.
 *
 * @returns Current page and setting funcitons.
 */
export const usePaginate = (initial = 1, last?: number): [number, PageManagement] => {
  const [current, { inc, dec, set, reset }] = useCounter(initial, last, initial);

  return [current, { next: inc, previous: dec, set, reset }];
};
