import {
  Center,
  FormControl,
  FormLabel,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { useUserSections, useUserStoreIds } from 'hooks';
import { useNeoEmailHistory } from 'hooks/data';
import { TABLE_CALENDAR_STYLES } from 'pages/DailyTasksPage/consts';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as O from 'fp-ts/Option';
import { downloadCampaigneHistory, TStatus } from 'domain/neoemail';
import { parse, parseISO } from 'date-fns';
import { ExportLink } from 'components/ExportLink';
import { dateToYMD } from 'utils/date';
import { getOrZero } from '../../MorningTasksPage/StocksPage/utils';

const isEnded = (end_date: string) => parse(end_date, 'yyyy-MM-dd', new Date()) < new Date();

const getStatusValue = (status: O.Option<TStatus>) => {
  return O.getOrElse(() => '')(status);
};

const getStatus = (status: O.Option<TStatus>, end_date: string) => {
  const s = getStatusValue(status);

  if (!s) return '---';

  if (isEnded(end_date)) {
    return 'Ended';
  }

  if (s === 'DONE') {
    return 'Sent';
  }

  return 'Generating';
};

export const History: React.VFC = () => {
  const { t } = useTranslation('whisperme');
  const userStores = useUserStoreIds();
  const userSections = useUserSections();
  const [showHistory, setShowHistory] = React.useState<boolean>(false);
  const { data, isFetching } = useNeoEmailHistory({
    store_id: userStores[0],
    section_id: userSections[0]?.id || undefined,
  });

  const historyToShow = useMemo(() => {
    if (!data) return [];

    return data.filter(({ end_date }) => {
      if (showHistory) return true;

      return !isEnded(end_date);
    });
  }, [data, showHistory]);

  return (
    <Card>
      <CardTitleSection
        settings={
          <CardTitleSectionSettings>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="show-history" mb="0">
                {t('NEOEMAIL.HISTORY.SHOW_HISTORY_LABEL')}
              </FormLabel>
              <Switch id="show-history" checked={showHistory} onChange={() => setShowHistory((prev) => !prev)} />
            </FormControl>
          </CardTitleSectionSettings>
        }
      >
        {t('NEOEMAIL.HISTORY.TITLE')}
      </CardTitleSection>
      <TableContainer>
        {historyToShow.length ? (
          <Table variant="products" size="sm" table-layout="fixed" sx={TABLE_CALENDAR_STYLES}>
            <Thead>
              <Tr>
                <Th>{t('NEOEMAIL.HISTORY.DATE')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.PRODUCT_CODE')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.PRODUCT_NAME')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.PROMOTION')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.DAYS')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.CUSTOMERS')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.STATUS')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.STATISTICS')}</Th>
                <Th>{t('NEOEMAIL.HISTORY.ACTIONS')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {historyToShow.map(
                ({
                  product_code,
                  description,
                  customer_ids,
                  promotion_pct,
                  created,
                  duration,
                  status,
                  end_date,
                  reference_id,
                  emails_sent,
                  visited,
                  purchased,
                }) => (
                  <Tr key={reference_id}>
                    <Td>{dateToYMD(parseISO(created))}</Td>
                    <Td>{product_code}</Td>
                    <Td>{description}</Td>
                    <Td>{promotion_pct}%</Td>
                    <Td>{duration}</Td>
                    <Td
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      maxW="400px"
                    >{`${customer_ids.join(', ')}`}</Td>
                    <Td>{getStatus(status, end_date)}</Td>
                    <Td>
                      {t('NEOEMAIL.HISTORY.STATISTICS_DATA', {
                        visited: getOrZero(visited),
                        sent: emails_sent,
                        purchased: getOrZero(purchased),
                      })}
                    </Td>
                    <Td>
                      {getStatusValue(status) === 'DONE' ? (
                        <ExportLink
                          href={downloadCampaigneHistory({ reference_id })}
                          fileName={`${reference_id}.xlsx`}
                        />
                      ) : (
                        'No emails yet'
                      )}
                    </Td>
                  </Tr>
                ),
              )}
            </Tbody>
          </Table>
        ) : (
          <Center>{!isFetching ? t('NEOEMAIL.HISTORY.NO_DATA') : <Spinner />}</Center>
        )}
      </TableContainer>
    </Card>
  );
};
