export const DIMENSIONS = {
  minWidth: '200px',
  minHeight: '150px',
};

export const TOTAL_CASH_REGISTERS = {
  BS: 15,
  JS: 8,
};

export const DEFAULT_CASHIER_HOUR_RANGES = [
  '08:00 - 10:00',
  '10:00 - 12:00',
  '12:00 - 14:00',
  '14:00 - 16:00',
  '16:00 - 18:00',
  '18:00 - 20:00',
];

export const DEFAULT_TOTAL_CASH_REGS = 10;

export const TABLE_CALENDAR_STYLES = {
  width: '100%',

  'th, td, tr': {
    fontSize: 'xs',
    padding: '2px',
    px: '1',
    py: '0.5',
    flex: '1',
  },

  'tr, td': {
    '&[data-cell-variant="empty"]': {
      bg: 'orange.50',
    },
    '&[data-cell-variant="risky"]': {
      bg: 'orange.100',
    },
    '&[data-cell-variant="safe"]': {
      bg: 'orange.200',
    },
    '&[data-cell-variant="max"]': {
      bg: 'orange.300',
    },
    '&[data-cell-variant="error"]': {
      bg: 'orange.400',
    },
  },
};
