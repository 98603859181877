import { ProductCodeValidator, TFamilyCode, TProductCode } from 'domain/core';
import { StoreIdValidator, TStoreId } from 'domain/stores';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

export type GetHistoryParams = {
  store_id?: TStoreId;
  section_id?: TFamilyCode;
};

export const STATUS_TAGS = {
  RUNNING: 'running',
  DONE: 'done',
};

export const StatusValidator = t.keyof(STATUS_TAGS);

export type TStatus = t.TypeOf<typeof StatusValidator>;

export const NeoEmailCampaigne = t.type({
  reference_id: t.string,
  description: t.string,
  product_code: ProductCodeValidator,
  store_id: StoreIdValidator,
  customer_ids: t.array(t.string),
  promotion_pct: t.number,
  duration: t.number,
  created: t.string,
  end_date: t.string,
  emails_sent: t.number,
  visited: optionFromNullable(t.number),
  purchased: optionFromNullable(t.number),
  status: optionFromNullable(StatusValidator),
});

export type TNeoEmailCampaigne = t.TypeOf<typeof NeoEmailCampaigne>;

export const NeoEmailCampaigneHistoryValidator = t.array(NeoEmailCampaigne);

export type TNeoEmailCampaigneHistory = t.TypeOf<typeof NeoEmailCampaigneHistoryValidator>;

export type TPostCreateCampaigne = {
  product_code: TProductCode;
  store_id: TStoreId;
  customer_ids: string[];
  promotion_pct: number;
  duration: number;
};

export const NeoEmailCustomerSimilarity = t.type({
  customers: t.array(t.string),
  default_scroll_index: t.number,
});

export type TNeoEmailCustomerSimilarity = t.TypeOf<typeof NeoEmailCustomerSimilarity>;

export type DownloadCampaigne = {
  reference_id: string;
};

export type GetCustomersSimilarityParams = {
  store_id: TStoreId;
  product_code: TProductCode;
  limit?: number;
};

export type FindProductsParams = {
  store_id: TStoreId;
  section_id?: TFamilyCode | number;
  product_code_prefix?: string;
  product_description_query?: string;
  limit?: number;
};

export const NeoEmailProduct = t.type({
  product_code: ProductCodeValidator,
  description: t.string,
});

export type TNeoEmailProduct = t.TypeOf<typeof NeoEmailProduct>;

export const NeoEmailProductList = t.array(NeoEmailProduct);

export type TNeoEmailProductList = t.TypeOf<typeof NeoEmailProductList>;
