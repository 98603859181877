type PagesAccessLevelRecord = {
  morningTasks: boolean[];
  duringTheDay: boolean[];
  whenYouHaveTime: boolean[];
  visualisation: {
    [key: string]: boolean[];
  };
  settings: {
    [key: string]: boolean[];
  };
};

export const pagesAccessLevelRecord: PagesAccessLevelRecord = {
  //ROLE-PAGE   //[C-level, AM, SM, Monitor, SecM, Admin]
  morningTasks: [false, false, true, false, true, false], // Morning Tasks
  duringTheDay: [false, false, true, false, true, false], // During the Day
  whenYouHaveTime: [false, false, true, false, true, false], // When You Have Time
  visualisation: {
    products: [true, true, true, true, false, false], // Visualisation > Products
    sales: [true, true, true, true, true, false], // Visualisation > Sales
    customers: [true, true, true, false, true, false], // Visualisation > Customers
    performance: [true, true, true, false, false, false], // Visualisation > Performance
    salesWatchlist: [true, true, true, true, true, false], // Visualisation > Sales Watchlist
    exploration: [true, true, false, false, false, false], // Visualisation > Exploration
    stockShortages: [true, true, true, true, true, false], // Visualisation > Stock Shortages
    orders: [false, false, false, true, true, false], // Visualisation > Orders
    areaShortages: [true, true, false, true, false, false], // Visualisation > Area Shortages
    discounts: [false, false, true, true, false, false], // Visualisation > Discounts
  },
  settings: {
    general: [true, true, true, true, true, true], // Settings > General (Password Management)
    productCatalog: [true, true, true, true, true, true], // Settings > Product Catalog
    processing: [true, true, true, false, false, true], // Settings > Processing
    users: [false, false, false, false, false, true], // Settings > Users
    roles: [false, false, false, false, false, true], // Settings > Roles
  },
};
