import { TFamilyCode } from 'domain/core';
import { TGetSalesAnomaliesParams, TGetTopAnomalousParams } from 'domain/objectives';
import { TGetProductListParams } from 'domain/products';

export const useSectionParams = <T extends TGetProductListParams | TGetSalesAnomaliesParams | TGetTopAnomalousParams>(
  params: T,
  authSections: TFamilyCode[],
  productsSection: TFamilyCode | undefined,
) => {
  const paramsSections = authSections.length === 1 ? authSections[0] : productsSection;
  return { ...params, root_family_id: paramsSections };
};
