import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Text, Center } from '@chakra-ui/react';

import { CSS_VARIABLES } from 'constants/css';

const {
  SIDE_BAR: { WIDTH_COLLAPSED, BACKGROUND_ACTIVE },
} = CSS_VARIABLES;

interface Props extends React.ComponentPropsWithoutRef<typeof RouterLink> {
  active?: boolean;
  icon: React.ReactElement;
}

export const DesktopSidebarItem: React.FC<Props> = ({ active, children, icon, ...rest }) => (
  <Link
    as={RouterLink}
    aria-current={active ? 'page' : undefined}
    sx={{
      display: 'flex',
      height: '48px',
      color: 'white',
      alignItems: 'center',
      fontWeight: 'bold',
      position: 'relative',

      '&[aria-current="page"]': {
        bg: `var(${BACKGROUND_ACTIVE})`,

        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',

          width: '1',
          height: '100%',
          left: 0,
          top: 0,
          bottom: 0,
          bg: `purple.300`,
        },
      },

      '&:hover,:focus': {
        bg: `var(${BACKGROUND_ACTIVE})`,
        textDecoration: 'none',
      },

      '&:focus': {
        zIndex: 1,
      },
    }}
    {...rest}
  >
    <Center
      sx={{
        color: active ? 'purple.300' : 'gray.300',
        minWidth: `var(${WIDTH_COLLAPSED})`,
      }}
    >
      {icon}
    </Center>
    {typeof children === 'string' ? (
      <Text as="span" whiteSpace="nowrap">
        {children}
      </Text>
    ) : (
      children
    )}
  </Link>
);
