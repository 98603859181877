import { createCustomerBeheviorMapKey, createCustomersMapKey } from 'constants/queryCacheKeys';
import { getCustomerBehaviorMapData, getCustomersCategoryMapData, TGetCustomersMapParams } from 'domain/customer';
import { useQuery } from 'react-query';

export const useCustomerMapData = (params: TGetCustomersMapParams) => {
  return useQuery(createCustomersMapKey(params), () => getCustomersCategoryMapData(params));
};

export const useCustomerBehaviorMapData = (params: TGetCustomersMapParams) => {
  return useQuery(createCustomerBeheviorMapKey(params), () => getCustomerBehaviorMapData(params));
};
