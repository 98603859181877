import { Ordering } from 'fp-ts/lib/Ordering';
import { fromCompare } from 'fp-ts/Ord';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

export const StoreType = {
  MARKET: 'Market',
  HYPER: 'Hyper',
} as const;

interface StoreIdBrand {
  readonly storeId: unique symbol;
}

export const StoreIdValidator = t.brand(
  t.string,
  (v: string): v is t.Branded<string, StoreIdBrand> => v.length === 2 || v.length === 3,
  'storeId',
);

export type TStoreId = t.TypeOf<typeof StoreIdValidator>;

export const TStoreIdOrd = fromCompare<TStoreId>((a, b) => a.localeCompare(b) as Ordering);

export const StoreValidator = t.type({
  id: StoreIdValidator,
  name: t.string,
  // ticket_store_id: io.string,
  city: optionFromNullable(t.string),
  department: optionFromNullable(t.string),
  type: optionFromNullable(t.union([t.literal(StoreType.MARKET), t.literal(StoreType.HYPER)])),

  // NOTE(m.kania): optionFromNullable can be removed once BE fixes incomplete store data...
  latitude: optionFromNullable(t.number),
  longitude: optionFromNullable(t.number),
  surface: optionFromNullable(t.number),
});

export type TGetSimilarStoresParams = {
  store_id: TStoreId;
  limit?: number;
};

export const SimilarStoreValidator = t.type({
  name: t.string,
  similarity: t.number,
});

export const SimilarStoresValidator = t.array(SimilarStoreValidator);

export const StoreArrayValidator = t.array(StoreValidator);

export type TStore = t.TypeOf<typeof StoreValidator>;

export type TStoresArray = TStore[];

export const StoreGroupValidator = t.type({
  store_group_id: t.number,
  name: t.string,
  store_ids: optionFromNullable(t.array(StoreIdValidator)),
  is_editable: t.boolean,
});

export type TStoreGroup = t.TypeOf<typeof StoreGroupValidator>;

export const StoreGroupArrayValidator = t.array(StoreGroupValidator);

export type TStoreGroupList = t.TypeOf<typeof StoreGroupArrayValidator>;

export interface TUserStore {
  id: TStoreId;
  name: string;
}

export interface SaveStoreGroupParams {
  store_group_id?: number;
  name: string;
  store_ids: TStoreId[];
}

export enum ColumnsDisplay {
  ALL = 'all',
  GROUPS = 'groups',
  GROUP = 'group/',
  STORE = 'store/',
}

export type SelectableGroupMap = Map<number, string>;

export const SimplifiedStore = t.type({
  id: StoreIdValidator,
  name: t.string,
});

export const StoreGroup = t.type({
  group_id: t.number,
  name: t.string,
  stores: t.array(SimplifiedStore),
});

export const StoresGroups = t.array(StoreGroup);

export interface StatisticsDetails {
  active_products: number;
  store_active_products: Record<string, number>;
  total_products: number;
}

export const StatisticDetailsAttribute = t.union([
  t.literal('active_products'),
  t.literal('store_active_products'),
  t.literal('total_products'),
]);

export type TStatisticDetailsAttribute = t.TypeOf<typeof StatisticDetailsAttribute>;

export type SupermarketDisplayProps = {
  data?: StatisticsDetails;
  store_id?: string;
};
