import React, { useMemo } from 'react';

import { TClusterMap, getClusterMapDimensions } from 'domain/map';

interface ClusterMapGridProps {
  clusterMap: TClusterMap;
  clusterGroupRectProps?: Array<React.SVGProps<SVGRectElement>>;
  dataAttributeClusterId?: string;
}

const StoreClustersMapGrid = React.memo<ClusterMapGridProps>(
  ({ clusterMap, clusterGroupRectProps, dataAttributeClusterId = 'data-cluster-id', ...rest }) => (
    <>
      {clusterMap.map((row, cellY) => {
        const rowRects: JSX.Element[] = [];

        let startIndex = 0;
        const len = row.length;
        const lastCellIndex = len - 1;
        for (let i = 0, len = row.length; i < len; i++) {
          const cell = row[i];
          if (cell !== row[i + 1] || i === lastCellIndex) {
            // add rectangle when color changes with the next cell, or it's the last cell
            const width = i - startIndex + 1;

            rowRects.push(
              <rect
                width={width}
                // NOTE(m.kania): drawing these slightly bigger than 1, so that lines between rows aren't visible
                height={1.4}
                x={startIndex}
                y={cellY}
                key={`${cellY}-${startIndex}-${width}`}
                {...{
                  [dataAttributeClusterId]: cell,
                  ...rest,
                  ...clusterGroupRectProps?.[cell],
                }}
              />,
            );

            startIndex = i + 1;
          }
        }

        return rowRects;
      })}
    </>
  ),
);

export interface StoreClustersMapProps extends ClusterMapGridProps, React.SVGProps<SVGSVGElement> {
  width: number | string;
  height: number | string;
}

const StoreClustersMapComponent = React.forwardRef<SVGSVGElement, StoreClustersMapProps>(
  ({ clusterMap, clusterGroupRectProps, dataAttributeClusterId, width, height, ...rest }, ref) => {
    const dimensions = useMemo(() => getClusterMapDimensions(clusterMap), [clusterMap]);

    return (
      <svg
        ref={ref}
        viewBox={`0 0 ${dimensions.mapWidth} ${dimensions.mapHeight}`}
        width={width}
        height={height}
        preserveAspectRatio="none"
        {...rest}
      >
        <StoreClustersMapGrid
          clusterMap={clusterMap}
          clusterGroupRectProps={clusterGroupRectProps}
          dataAttributeClusterId={dataAttributeClusterId}
        />
      </svg>
    );
  },
);

export const StoreClustersMap = React.memo(StoreClustersMapComponent);
