import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import * as PATHS from 'constants/paths';
import { RolesListPage } from './RolesListPage';
import { RoleDetailPage } from './RoleDetailsPage';
import { RoleEditPage } from './RoleEditPage';
import { RoleNewPage } from './RoleNewPage';

export const RolesPage: React.VFC = () => {
  const listRedirect = <Redirect to={PATHS.SETTINGS.ROLES.ROOT.toPath()} />;

  return (
    <Switch>
      <Route path={PATHS.SETTINGS.ROLES.NEW.PATH}>
        <RoleNewPage />
      </Route>
      <Route path={PATHS.SETTINGS.ROLES.EDIT.PATH}>
        {({ match }) =>
          pipe(
            match?.params,
            PATHS.SETTINGS.ROLES.EDIT.validateParams,
            (matched) => matched.roleId,
            O.match(
              () => listRedirect,
              (roleId) => <RoleEditPage roleId={roleId} />,
            ),
          )
        }
      </Route>
      <Route path={PATHS.SETTINGS.ROLES.DETAILS.PATH}>
        {({ match }) =>
          pipe(
            match?.params,
            PATHS.SETTINGS.ROLES.DETAILS.validateParams,
            (matched) => matched.roleId,
            O.match(
              () => listRedirect,
              (roleId) => <RoleDetailPage roleId={roleId} />,
            ),
          )
        }
      </Route>
      <Route path={PATHS.SETTINGS.ROLES.ROOT.PATH} exact={true}>
        <RolesListPage />
      </Route>
      {listRedirect}
    </Switch>
  );
};
