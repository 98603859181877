import { useQuery, UseQueryOptions } from 'react-query';

import { createMapLayersKey } from 'constants/queryCacheKeys';
import { getMapLayers, TMapLayers } from 'domain/map';
import { ONE_DAY } from 'constants/time';

type DataType = TMapLayers;
type ErrorType = Error;

export const useMapLayers = <Data = DataType>(options?: UseQueryOptions<DataType, ErrorType, Data, string[]>) =>
  useQuery(createMapLayersKey(), getMapLayers, {
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
    ...options,
  });
