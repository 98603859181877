import React, { useContext } from 'react';
import { DataContext, GlyphSeries } from '@visx/xychart';
import { AnyScaleBand } from '@visx/shape/lib/types';

interface Item {
  x: string;
  y: number | null;
}

export const lowerBoundAccessors = {
  xAccessor: (item: Item) => item.x,
  yAccessor: (item: Item) => item.y,
};

interface Props {
  data: Item[];
}

export const ImportStatusWarningSeries: React.VFC<Props> = ({ data }) => {
  const { xScale } = useContext(DataContext);
  const size = Math.min(Math.round(((xScale as AnyScaleBand | undefined)?.bandwidth() ?? 16) * 0.75), 36);

  return (
    <GlyphSeries
      enableEvents={false}
      data={data}
      dataKey="lower-bound-warnings"
      renderGlyph={({ x, y }) => (
        <svg
          color="#F6AD55"
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          height={size}
          width={size}
          xmlns="http://www.w3.org/2000/svg"
          x={x - size / 2}
          y={y - size}
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zM11 16v2h2v-2h-2zm0-7v5h2V9h-2z"></path>
          </g>
        </svg>
      )}
      {...lowerBoundAccessors}
    />
  );
};
