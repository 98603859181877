import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { LineSeries, AnimatedLineSeries } from '@visx/xychart';
import { curveCardinal } from '@visx/curve';
import { AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';

import { ChartProps } from './types';
import { ParentRect } from './ParentRect';
import { nullAccessor, xAccessor as xAccessorDefault, yIndexAccessor } from './utils';
import { BaseXYChart } from './BaseXYChart';
import { ChartDataTooltip, ChartDataTooltipProps } from './ChartDataTooltip';
import { useInteractiveChartMetrics } from './useInteractiveChartMetrics';
import { ChartLegendOrdinal } from './ChartLegendOrdinal';
import { ChartLoadingOverlay } from './ChartLoadingOverlay';
import { useAppConfig } from 'hooks';
import { getDataResolutionFormat } from 'domain/core';
import { theme as defaultTheme } from './chartThemes';
import { ChartLegend } from './ChartLegend';
import { useTranslation } from 'react-i18next';

type SeriesProps = React.ComponentPropsWithoutRef<typeof LineSeries>;
type BaseXYChartProps = React.ComponentPropsWithoutRef<typeof BaseXYChart>;

interface Props
  extends ChartProps,
    Partial<BaseXYChartProps>,
    Pick<ChartDataTooltipProps, 'formatValue' | 'renderTooltip'> {
  curve?: 'cardinal' | 'linear';
  minHeight?: string | number;
  seriesProps?: Partial<Omit<SeriesProps, 'data'>>;
  isLoading?: boolean;
  isUsingSelectAll?: boolean;
  animated?: boolean;
}

export const LineChart: React.FC<Props> = ({
  chartData: { data, chartMetrics, xLabel, yLabel, dataResolution },
  curve = 'linear',
  minHeight = '200px',
  children,
  seriesProps,
  isLoading,
  isUsingSelectAll = false,
  animated = true,
  theme = defaultTheme,
  formatValue,
  renderTooltip,
  ...rest
}) => {
  const { metrics, toggleMetricByIndex, toggleAllMetrics, isMetricVisibleByIndex, legendScale, canAnimate } =
    useInteractiveChartMetrics(chartMetrics, { animated, colors: theme.colors });
  const { t } = useTranslation('whisperme');
  const [isMetricToggleOn, setIsMetricToggleOn] = useState<boolean>(false);
  const toggleLegendMetrics = useCallback(() => {
    toggleAllMetrics(isMetricToggleOn);
    setIsMetricToggleOn((curr) => !curr);
  }, [toggleAllMetrics, isMetricToggleOn]);

  const { dateOptions } = useAppConfig();
  const LineSeriesComponent = canAnimate ? AnimatedLineSeries : LineSeries;

  const xDataType = useMemo(() => (data.some((d) => d.x instanceof Date) ? 'date' : 'string'), [data]);
  const xAxisProps = useMemo(
    (): Props['xAxisProps'] =>
      xDataType === 'date'
        ? {
            tickFormat: (value) => format(value, getDataResolutionFormat(dataResolution), dateOptions),
          }
        : undefined,
    [dateOptions, xDataType, dataResolution],
  );

  return (
    <>
      <Stack width="100%" height="100%" flexGrow={1} position="relative">
        {legendScale && (
          <>
            {isUsingSelectAll && (
              <ChartLegend
                direction="row"
                onLegendItemClick={() => toggleLegendMetrics()}
                isMetricVisible={() => !isMetricToggleOn}
                items={[
                  {
                    name: isMetricToggleOn
                      ? t('COMPONENTS.CHARTS.SALES_IN_SECTIONS_CHART.ALL_SECTIONS_LABEL')
                      : t('COMPONENTS.CHARTS.SALES_IN_SECTIONS_CHART.EMPTY_SECTIONS_LABEL'),
                    color: '#CBD5E0',
                  },
                ]}
              />
            )}
            <ChartLegendOrdinal
              scale={legendScale}
              onLegendItemClick={toggleMetricByIndex}
              isMetricVisible={isMetricVisibleByIndex}
            />
          </>
        )}
        <ParentRect minHeight={minHeight}>
          {({ width, height }) => (
            <>
              <BaseXYChart
                width={width}
                height={height}
                xLabel={xLabel}
                yLabel={yLabel}
                xScale={xDataType === 'date' ? { type: 'time', nice: false } : undefined}
                xAxisProps={xAxisProps}
                theme={theme}
                {...rest}
              >
                {metrics.map(({ dataKey, visible, index }) => {
                  const xAccessor =
                    typeof seriesProps?.xAccessor === 'function' ? seriesProps?.xAccessor : xAccessorDefault;
                  const yAccessor =
                    typeof seriesProps?.yAccessor === 'function' ? seriesProps?.yAccessor : yIndexAccessor(index);

                  return (
                    <LineSeriesComponent
                      key={dataKey}
                      dataKey={dataKey}
                      // NOTE(m.kania): it's easier to draw 'null' series in order to keep legend/series colors 'in sync'
                      data={data}
                      curve={curve === 'cardinal' ? curveCardinal : undefined}
                      {...seriesProps}
                      xAccessor={xAccessor}
                      yAccessor={visible ? yAccessor : nullAccessor}
                    />
                  );
                })}
                <ChartDataTooltip metrics={metrics} formatValue={formatValue} renderTooltip={renderTooltip} />
                {children}
              </BaseXYChart>
              <AnimatePresence>{isLoading && <ChartLoadingOverlay />}</AnimatePresence>
            </>
          )}
        </ParentRect>
      </Stack>
    </>
  );
};
