import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as PATHS from 'constants/paths';
import { Card, CardTitleSection } from 'components/Card';
import { UserForm } from './components';
import { createUserListKey, createUserDetailsKey } from 'constants/queryCacheKeys';
import { createUser } from 'domain/user';
import { useToast } from '@chakra-ui/react';

interface Props {}

export const UserNewPage: React.VFC<Props> = () => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const { mutateAsync } = useMutation(createUser, {
    onSuccess: () => {
      toast({
        title: t('SETTINGS.USERS.USER_FORM.NEW_USER_ADDED_SUCCESS_MESSAGE'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('SETTINGS.USERS.USER_FORM.NEW_USER_ADDED_FAILURE_MESSAGE'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    },
  });
  const queryClient = useQueryClient();
  const { push } = useHistory();

  const goBackLink = PATHS.SETTINGS.USERS.ROOT.toPath();

  return (
    <Card>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {t('SETTINGS.USERS.ADD_NEW_USER_LABEL')}
      </CardTitleSection>
      <UserForm
        variant="new"
        cancelButtonLink={goBackLink}
        onSubmit={(userDetails) => {
          if (typeof userDetails.password === 'string') {
            mutateAsync(
              // NOTE(m.kania): 're-spreading' to convince TS there is a string password
              { ...userDetails, password: userDetails.password },
              {
                onSuccess: ({ id }) => {
                  queryClient.setQueryData(createUserDetailsKey({ userId: id }), userDetails);
                  queryClient.invalidateQueries(createUserListKey());
                  push(PATHS.SETTINGS.USERS.DETAILS.toPath({ userId: id }));
                },
              },
            );
          }
        }}
      />
    </Card>
  );
};
