import { SETTINGS_PATH } from './settings';

const ROOT_PATH = `${SETTINGS_PATH}/product-catalog` as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const NEW_PATH = `${ROOT_PATH}/new` as const;
export const NEW = {
  PATH: NEW_PATH,
  toPath: () => NEW_PATH,
};
