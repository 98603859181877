import { useEffect, useState } from 'react';
import { pipe } from 'fp-ts/function';

import {
  DATA_RESOLUTION,
  DATA_RESOLUTION_ORDER,
  findMinimumDataResolution,
  isDataResolution,
  Plot,
  TDataResolution,
} from 'domain/core';

export const useDataResolutionProps = <PlotType extends Plot>(
  data?: PlotType[],
  initialResolution: TDataResolution = DATA_RESOLUTION.DAY,
) => {
  const [value, setValue] = useState<TDataResolution>(initialResolution);
  const [minimumResolution, setMinimumResolution] = useState<TDataResolution>(initialResolution);

  useEffect(() => {
    if (data && data[0] && isDataResolution(data[0].data_resolution)) {
      setMinimumResolution(data[0].data_resolution);
    }
  }, [data, setMinimumResolution]);

  return {
    setMinimumResolution,
    dataResolution: value,
    dataResolutionSelectProps: {
      value: value,
      isAllowedResolution: (allowedResolution: TDataResolution) => {
        const minResolutionIdx = DATA_RESOLUTION_ORDER.findIndex((r) => r === minimumResolution);

        return DATA_RESOLUTION_ORDER.indexOf(allowedResolution) >= minResolutionIdx;
      },
      onChange: (newResolution: TDataResolution) =>
        pipe(
          { outputResolution: newResolution, plotDataResolution: minimumResolution },
          findMinimumDataResolution,
          setValue,
        ),
    },
  };
};
