import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { GridTableHeader } from 'components/GridTableHeader';

export const StockShortageListHeader: VFC = () => {
  const { t } = useTranslation('whisperme');

  return (
    <GridTableHeader
      rowProps={{
        width: '100%',
        gap: GRID_GAP_DEFAULT_VALUE,
        templateColumns: '0.75fr repeat(9, 1fr) 6rem',
        paddingX: 2,
      }}
      items={[
        t('MORNING_TAKS.STOCKS_TABLE.IFLS_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.EAN7_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.SHELF_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.BLI_DESC_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.AVAILABLE'),
        t('MORNING_TAKS.STOCKS_TABLE.SHORTAGE_HOURS_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.SHORTAGE_DAYS_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.SHORTAGE_WEEK_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.SALES_LABEL'),
        t('MORNING_TAKS.STOCKS_TABLE.LAST_ORDER_DATE_LABEL'),
        '',
      ]}
    />
  );
};
