import { RoleSectionCodeValidator } from 'domain/core';
import { StoreIdValidator } from 'domain/stores';
import type { TUserId } from 'domain/user';
import * as t from 'io-ts';

export const ROLE_CODES = {
  READ: 1,
  ADMIN: 2,
};
export const RoleCodeValidator = t.union([t.literal(ROLE_CODES.READ), t.literal(ROLE_CODES.ADMIN)]);
export type TRoleCode = t.TypeOf<typeof RoleCodeValidator>;

export const RoleCodesValidator = t.array(RoleCodeValidator);
export type TRoleCodes = t.TypeOf<typeof RoleCodesValidator>;

interface RoleIdBrand {
  readonly roleId: unique symbol;
}

export const RoleIdValidator = t.brand(
  t.number,
  (v: number): v is t.Branded<number, RoleIdBrand> => typeof v === 'number' && Number.isInteger(v),
  'roleId',
);
export type TRoleId = t.TypeOf<typeof RoleIdValidator>;

export const RoleStorePrivilegesValidator = t.record(StoreIdValidator, RoleCodesValidator);
export type TRoleStorePrivileges = t.TypeOf<typeof RoleStorePrivilegesValidator>;

export const RoleSectionPrivilegesValidator = t.record(RoleSectionCodeValidator, RoleCodesValidator);
export type TRoleSectionPrivileges = t.TypeOf<typeof RoleSectionPrivilegesValidator>;

export const RoleDetailsValidator = t.type({
  name: t.string,
  store_privileges: RoleStorePrivilegesValidator,
  section_privileges: RoleSectionPrivilegesValidator,
});
export type TRoleDetails = t.TypeOf<typeof RoleDetailsValidator>;

const UserIdListValidator: t.Type<TUserId[]> = t.array(t.number);

export const RoleValidator = t.type({
  id: RoleIdValidator,
  role: RoleDetailsValidator,
  user_ids: UserIdListValidator,
});

export type TRole = t.TypeOf<typeof RoleValidator>;

export const RoleListValidator = t.type({
  items: t.array(RoleValidator),
});
export type TRoleList = t.TypeOf<typeof RoleListValidator>;
