import { useMemo } from 'react';
import { scaleSequential } from 'd3-scale';
import { interpolateRainbow } from 'd3-scale-chromatic';

import { TStore, TStoreId } from 'domain/stores';
import { useStoresData } from './useStores';

export type TStoreColorMap = Map<TStoreId, string>;

export const createStoreColorMap = (stores: TStore[]): TStoreColorMap => {
  const len = stores.length;
  const end = len > 20 ? Math.ceil(len / 2) + 1 : len;
  const colorScale = scaleSequential(interpolateRainbow).domain([0, end]);
  return new Map(stores.map((s, idx) => [s.id, colorScale(idx % end)]));
};

export const useStoreColorMap = () => {
  const stores = useStoresData();

  return useMemo(() => createStoreColorMap(stores), [stores]);
};
