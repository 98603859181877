import { useQuery } from 'react-query';
import { createSimilarStoresKey } from 'constants/queryCacheKeys';
import { getSimilarStores, TGetSimilarStoresParams } from 'domain/stores';
import { TStoreId } from 'domain/stores';

export const useSimilarStores = (storeId: TStoreId) => {
  const params: TGetSimilarStoresParams = {
    store_id: storeId,
  };

  return useQuery(createSimilarStoresKey(params), () => getSimilarStores(params));
};
