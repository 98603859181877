import {
  DashboardTab,
  DashboardTabPanel,
  DashboardTabList,
  DashboardTabPanels,
  DashboardTabs,
} from 'components/Dashboard';
import { PageSpinner } from 'components/Spinners';
import { Suspense, useMemo, useState } from 'react';
import { ErrorBoundary } from 'components/ErrorMessages';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import React, { VFC } from 'react';
import { findNeoEmailNavigationMatch, neoEmailMatchMapping } from './utils';
import { CreateEmail } from './CreateEmail';
import { History } from './History';

export const NeoEmailPage: VFC = () => {
  const { t } = useTranslation('whisperme');
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const navigationMatch = findNeoEmailNavigationMatch(pathname);
  const [tabIndex, setTabIndex] = useState<number>(navigationMatch);

  const handlers = useMemo(() => {
    return {
      handleTabsChange: (index: number) => {
        const [_newMatch, newPath] = neoEmailMatchMapping[index];
        if (typeof newPath === 'string') {
          replace(newPath);
          setTabIndex(index);
        }
      },
    };
  }, [replace]);

  return (
    <DashboardTabs index={tabIndex} onChange={handlers.handleTabsChange}>
      <DashboardTabList>
        <DashboardTab>{t('GENERIC.NAVIGATION.LLM.NEOEMAIL.CREATE')}</DashboardTab>
        <DashboardTab>{t('GENERIC.NAVIGATION.LLM.NEOEMAIL.HISTORY')}</DashboardTab>
      </DashboardTabList>
      <ErrorBoundary>
        <Suspense fallback={<PageSpinner />}>
          <DashboardTabPanels>
            <DashboardTabPanel>
              <CreateEmail />
            </DashboardTabPanel>
            <DashboardTabPanel>
              <History />
            </DashboardTabPanel>
          </DashboardTabPanels>
        </Suspense>
      </ErrorBoundary>
    </DashboardTabs>
  );
};
