const ROOT_PATH = '/morning-tasks' as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

export const ORDERS_PATH = `${ROOT_PATH}/orders` as const;

export const ORDERS = {
  PATH: ORDERS_PATH,
  toPath: () => ORDERS_PATH,
};

export const HISTORY_ORDERS_PATH = `${ROOT_PATH}/history-orders` as const;

export const HISTORY_ORDERS = {
  PATH: HISTORY_ORDERS_PATH,
  toPath: () => HISTORY_ORDERS_PATH,
};
