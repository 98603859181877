import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { Stack, TableContainer, Grid, Table, Th, Thead, Tr, Tbody, Td, Skeleton, Center, Link } from '@chakra-ui/react';
import { useAppConfig, useUserSections } from 'hooks';
import { useStoreProductStatistics } from 'hooks/data';
import { TStoreId } from 'domain/stores';
import { getCurrentDate } from 'utils/date';
import { subDays } from 'date-fns';
import { Card, CardTitleSection } from 'components/Card';
import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import * as PATHS from 'constants/paths';
import { DataType as ProductStatistics } from 'hooks/data/useStoreProductStatistics';
import { t } from 'i18next';

interface Props {
  storeId: TStoreId;
  variant: 'top' | 'low';
}

export const FreeTaskSales: React.FC<Props> = ({ storeId, variant }) => {
  const {
    viewport,
    formatters: { currencyFormatter },
  } = useAppConfig();
  const userRootFamilies = useUserSections();
  const isVariantTop = variant === 'top';
  const isVariantLow = variant === 'low';

  const userAuthSections = pipe(
    userRootFamilies,
    A.map(({ name }) => name),
  );

  const currentDate = getCurrentDate();
  const sub30FromCurrentDate = subDays(getCurrentDate(), 30);

  const {
    data: { items: data } = { items: [] as ProductStatistics['items'], total: 1000 },
    isFetching,
    status,
  } = useStoreProductStatistics(
    {
      storeIds: [storeId],
      order: isVariantLow ? 'ASC' : 'DESC',
      family_filters: undefined,
      section_filters: userAuthSections,
      date_to: currentDate,
      date_from: sub30FromCurrentDate,
    },
    {
      keepPreviousData: true,
    },
  );

  const lowSalesArr = useMemo(() => {
    if (status === 'success' && isVariantLow) {
      return pipe(
        O.fromNullable(data),
        O.fold(
          () => [],
          (data) =>
            pipe(
              data,
              A.findIndex((prod) => prod.sales_eur >= 0),
              O.getOrElse(() => 0),
              (index) => data.slice(index, index + 10),
            ),
        ),
      );
    } else {
      return [];
    }
  }, [data, status, isVariantLow]);

  const dataVariant = isVariantTop ? data.slice(0, 10) : lowSalesArr;

  const table = (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',
        tableLayout: viewport !== 'mobile' ? 'fixed' : undefined,
        '& [data-cell-wrap]': {
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Thead>
        <Tr>
          <Th data-cell-wrap>{t('FREE_TASKS.PRODUCTS_SELLING_TASK.PRODUCTS_TABLE.EAN_LABEL')}</Th>
          <Th data-cell-wrap>{t('FREE_TASKS.PRODUCTS_SELLING_TASK.PRODUCTS_TABLE.DESCRIPTION_LABEL')}</Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.PRODUCTS_SELLING_TASK.PRODUCTS_TABLE.UNITS_LABEL')}
          </Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.PRODUCTS_SELLING_TASK.PRODUCTS_TABLE.REVENUE_LABEL')}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {isFetching && !data.length
          ? Array.from({ length: 10 }).map((_, i) => (
              <Tr key={i}>
                <Td>
                  <Skeleton height="20px" width="auto" />
                </Td>
                <Td>
                  <Skeleton height="20px" width="auto" />
                </Td>
                <Td isNumeric={true}>
                  <Skeleton height="20px" width="auto" />
                </Td>
                <Td isNumeric={true}>
                  <Skeleton height="20px" width="auto" />
                </Td>
              </Tr>
            ))
          : dataVariant.map((p) => (
              <Tr key={p.product_code}>
                <Td>{p.product_code}</Td>
                <Td>
                  {pipe(
                    p.description,
                    O.getOrElse(() => ''),
                  )}
                </Td>
                <Td isNumeric={true}>{p.quantity}</Td>
                <Td isNumeric={true}>{currencyFormatter.format(p.sales_eur)}</Td>
              </Tr>
            ))}
      </Tbody>
    </Table>
  );

  return (
    <Card>
      <Stack spacing="4">
        <Grid
          gap={GRID_GAP_DEFAULT_VALUE}
          alignItems="center"
          gridTemplateColumns={{
            base: '100%',
            [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
          }}
        ></Grid>
        <CardTitleSection>
          {isVariantLow
            ? t('FREE_TASKS.PRODUCTS_SELLING_TASK.LOW_SELLING_PRODUCTS_LABEL')
            : t('FREE_TASKS.PRODUCTS_SELLING_TASK.TOP_SELLING_PRODUCTS_LABEL')}
        </CardTitleSection>
        <Stack position="relative">{viewport === 'mobile' ? <TableContainer>{table}</TableContainer> : table}</Stack>
      </Stack>
      <Center pt="2">
        <Link
          as={RouterLink}
          to={{
            pathname: PATHS.FREE_TASKS.LIST.PATH,
            state: {
              params: {
                order: isVariantLow ? 'ASC' : 'DESC',
                date_from: sub30FromCurrentDate,
                root_path: PATHS.FREE_TASKS.ROOT.PATH,
                back_button_title: t('FREE_TASKS.BACK_BUTTON_LABEL'),
              },
            },
          }}
          color="blue.500"
        >
          {t('GENERIC.SHOW_MORE_DETAILS_LABEL')}
        </Link>
      </Center>
    </Card>
  );
};
