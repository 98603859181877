import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Checkbox, FormControl, Input, FormErrorMessage, Switch, Text } from '@chakra-ui/react';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { TProductsMachineEditValue, TProductsMachineValidationErrors } from './types';
import { TProductFamily } from 'domain/product-family';
import { ProductFamiliesFamilyCodeSelect } from './ProductFamiliesFamilyCodeSelect';
import { itemNameFormatter, parseFamilyCodeValue } from './utils';
import { formatFirstAppearanceDate } from '../utils';
import { NullableSingleSupplierSelect } from 'components/Select';

interface Props extends Omit<BoxProps, 'onChange'> {
  product: TProductsMachineEditValue;
  checked?: boolean;
  onCheckedChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckboxDisabled?: boolean;
  variant: 'single' | 'multiple';
  onChange: (newValues: TProductsMachineEditValue) => void;
  allSubfamilies: TProductFamily[];
  rootFamilies: TProductFamily[];
  productFamilies: TProductFamily[];
  validationErrors?: TProductsMachineValidationErrors;
}

export const ProductFamiliesListEditItem: React.FC<Props> = ({
  product,
  checked,
  onCheckedChange,
  isCheckboxDisabled,
  variant,
  children,
  onChange,
  allSubfamilies,
  productFamilies,
  rootFamilies,
  validationErrors,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');

  const productNameError = validationErrors?.description;
  const productFamilyCodeError = validationErrors?.family_code;
  const minimumStockError = validationErrors?.minimum_stock;

  const filteredSubfamilies = useMemo(
    () =>
      typeof product.rootFamilyCode === 'undefined'
        ? allSubfamilies
        : allSubfamilies.filter((sf) => O.isSome(sf.parent_id) && sf.parent_id.value === product.rootFamilyCode),
    [allSubfamilies, product.rootFamilyCode],
  );

  const onFamilyCodeChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const maybeCode = parseFamilyCodeValue(ev.target.value);
    const rootFamilyCode =
      typeof maybeCode !== 'undefined' ? rootFamilies.find((rf) => rf.children_ids.includes(maybeCode))?.id : undefined;

    onChange({
      ...product,
      family_code: maybeCode,
      rootFamilyCode,
    });
  };

  const isRegional = pipe(
    product.is_regional,
    O.fromNullable,
    O.chain(identity),
    O.fold(() => false, identity),
  );

  const minimumStock = pipe(
    product?.minimum_stock,
    O.fromNullable,
    O.chain(identity),
    O.fold(() => undefined, identity),
  );

  const weekendProduct = pipe(
    product?.weekend_product,
    O.fromNullable,
    O.chain(identity),
    O.fold(() => false, identity),
  );

  const alwaysAvailable = pipe(
    product?.always_available,
    O.fromNullable,
    O.chain(identity),
    O.fold(() => false, identity),
  );

  const supplier = pipe(
    product?.supplier_id,
    O.fromNullable,
    O.chain(identity),
    O.fold(() => undefined, identity),
  );

  return (
    <Box {...rest}>
      <div role="gridcell">
        <Checkbox isChecked={Boolean(checked)} onChange={onCheckedChange} disabled={isCheckboxDisabled} />
      </div>
      <div role="gridcell">{product.product_code}</div>
      <div role="gridcell">
        {variant === 'multiple' ? (
          product.description
        ) : (
          <FormControl isInvalid={Boolean(productNameError)} isRequired={true}>
            <Input
              size="xs"
              placeholder={t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_NAME_INPUT_PLACEHOLDER')}
              onChange={(ev) => {
                onChange({
                  ...product,
                  description: ev.target.value,
                });
              }}
              value={product.description}
            />
            {productNameError && <FormErrorMessage textTransform="none">{productNameError}</FormErrorMessage>}
          </FormControl>
        )}
      </div>
      <div role="gridcell">
        <FormControl isInvalid={Boolean(productFamilyCodeError)} isRequired={true}>
          <ProductFamiliesFamilyCodeSelect
            placeholder={t('SETTINGS.PRODUCT_FAMILIES.FAMILY_CODE_INPUT_PLACEHOLDER')}
            items={allSubfamilies}
            onChange={onFamilyCodeChange}
            value={String(product.family_code ?? '')}
          />
          {productFamilyCodeError && <FormErrorMessage textTransform="none">{productFamilyCodeError}</FormErrorMessage>}
        </FormControl>
      </div>
      <div role="gridcell">
        <ProductFamiliesFamilyCodeSelect
          placeholder={t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_INPUT_PLACEHOLDER')}
          items={rootFamilies}
          disabledPlaceholder={true}
          formatLabel={itemNameFormatter}
          onChange={(ev) => {
            const rootFamilyCode = parseFamilyCodeValue(ev.target.value);
            let newFamilyCode = product.family_code;

            if (
              typeof rootFamilyCode !== 'undefined' &&
              typeof product.rootFamilyCode !== 'undefined' &&
              rootFamilyCode !== product.rootFamilyCode
            ) {
              // NOTE(m.kania): if root family changes, then reset selected family code
              newFamilyCode = undefined;
            }

            onChange({
              ...product,
              rootFamilyCode,
              family_code: newFamilyCode,
            });
          }}
          value={String(product.rootFamilyCode ?? '')}
        />
      </div>
      <div role="gridcell">
        <FormControl isInvalid={Boolean(productFamilyCodeError)} isRequired={true}>
          <ProductFamiliesFamilyCodeSelect
            placeholder={t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_INPUT_PLACEHOLDER')}
            items={filteredSubfamilies}
            formatLabel={itemNameFormatter}
            onChange={onFamilyCodeChange}
            value={String(product.family_code ?? '')}
          />
          {productFamilyCodeError && <FormErrorMessage textTransform="none">{productFamilyCodeError}</FormErrorMessage>}
        </FormControl>
      </div>
      <div role="gridcell">
        <Switch
          isChecked={isRegional}
          onChange={(ev) => {
            onChange({
              ...product,
              is_regional: O.some(ev.target.checked),
            });
          }}
        />
        <Text as="span" ml="1ch">
          {isRegional
            ? t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_TRUE_LABEL')
            : t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_FALSE_LABEL')}
        </Text>
      </div>
      <div role="gridcell">
        <FormControl isInvalid={Boolean(minimumStockError)} isRequired={false}>
          <Input
            size="xs"
            type="number"
            value={minimumStock}
            placeholder={t('SETTINGS.PRODUCT_FAMILIES.MINIMUM_STOCK_PLACEHOLDER')}
            onChange={(ev) => {
              if (ev.target.value === '' || ev.target.value === null || ev.target.value === '0') {
                onChange({
                  ...product,
                  minimum_stock: O.none,
                });
              }

              onChange({
                ...product,
                minimum_stock: O.some(Number(ev.target.value)),
              });
            }}
          />
          {minimumStockError && <FormErrorMessage>{minimumStockError}</FormErrorMessage>}
        </FormControl>
      </div>
      <div role="gridcell">
        <Switch
          isChecked={weekendProduct}
          onChange={(ev) => {
            onChange({
              ...product,
              weekend_product: O.some(ev.target.checked),
            });
          }}
        />
        <Text as="span" ml="1ch">
          {weekendProduct
            ? t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_TRUE_LABEL')
            : t('SETTINGS.PRODUCT_FAMILIES.WEEKEND_PRODUCT_FALSE_LABEL')}
        </Text>
      </div>
      <div role="gridcell">
        <Switch
          isChecked={alwaysAvailable}
          onChange={(ev) => {
            onChange({
              ...product,
              always_available: O.some(ev.target.checked),
            });
          }}
        />
        <Text as="span" ml="1ch">
          {alwaysAvailable
            ? t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_TRUE_LABEL')
            : t('SETTINGS.PRODUCT_FAMILIES.ALWAYS_AVAILABLE_FALSE_LABEL')}
        </Text>
      </div>
      <div role="gridcell">
        <NullableSingleSupplierSelect
          value={supplier}
          onChange={(newSupplierId) => {
            onChange({
              ...product,
              supplier_id: O.fromNullable(newSupplierId),
            });
          }}
        />
      </div>
      <div role="gridcell">{formatFirstAppearanceDate(t, product.first_appearance_date)}</div>
      <Box role="gridcell" textAlign="right">
        {children}
      </Box>
    </Box>
  );
};
