import { Checkbox, HStack, StackProps } from '@chakra-ui/react';
import React, { ChangeEvent, FC, ReactElement, useMemo } from 'react';

export type ItemId = string;

export type Item = {
  label: string;
  value: boolean;
};

export type ItemsMap = Map<ItemId, Item>;

type MultiCheckboxSelectProps = {
  values: ItemsMap;
  onChange: (id: ItemId, checked: boolean) => void;
  direction?: StackProps['direction'];
};

/**
 * Creates ChangeEventHandler which passes properties to upper level handler.
 * @param handler Upper level handler.
 * @param id item id
 */
const handleCheckboxClick =
  (handler: MultiCheckboxSelectProps['onChange'], id: ItemId) => (event: ChangeEvent<HTMLInputElement>) => {
    handler(id, event.target.checked);
  };

export const MultiCheckboxSelect: FC<MultiCheckboxSelectProps> = ({ direction, onChange, values }) => {
  const checkboxes = useMemo(() => {
    const toReturn: ReactElement[] = [];

    values.forEach((item, itemId) => {
      toReturn.push(
        <Checkbox
          size="sm"
          onChange={handleCheckboxClick(onChange, itemId)}
          isChecked={item.value}
          name={itemId}
          key={`store_${itemId}_${item.value}`}
        >
          {item.label}
        </Checkbox>,
      );
    });

    return toReturn;
  }, [values, onChange]);

  return (
    <HStack direction={direction} maxWidth="100%" flexWrap="wrap">
      {checkboxes}
    </HStack>
  );
};
