import React from 'react';
import { Select, SelectProps, Skeleton } from '@chakra-ui/react';
import { pipe } from 'fp-ts/function';
import { map } from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { useUserTypes } from 'hooks/useUserTypes';

interface SelectUserTypesProps extends Omit<SelectProps, 'onChange' | 'value'> {
  value: number;

  onChange: (value: number) => void;
}

export const SelectUserTypes: React.FC<SelectUserTypesProps> = ({ value, onChange, ...props }) => {
  const { data, status } = useUserTypes();

  return (
    <Skeleton isLoaded={status === 'success'}>
      <Select
        size="sm"
        value={value}
        onChange={(event) => {
          event.preventDefault();
          event.stopPropagation();

          onChange(Number(event.target.value));
        }}
        {...props}
        isLoading={status !== 'success'}
      >
        {pipe(
          O.fromNullable(data),
          O.match(
            () => [],
            map(({ user_type_id, name }) => (
              <option key={`${user_type_id}_${name}`} value={user_type_id}>
                {name}
              </option>
            )),
          ),
        )}
      </Select>
    </Skeleton>
  );
};
