import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

import { FamilyCodeValidator } from '../core';

export const ProductFamilyValidator = t.type({
  id: FamilyCodeValidator,
  name: t.string,
  children_ids: t.array(FamilyCodeValidator),
  parent_id: optionFromNullable(FamilyCodeValidator),
  // neo_root_family_name: optionFromNullable(t.string),
  // neo_leaf_family_name: optionFromNullable(t.string),
  // neo_indic22: optionFromNullable(t.string),
  pls: optionFromNullable(t.boolean),
  traditional: optionFromNullable(t.boolean),
  fresh: optionFromNullable(t.boolean),
});

export type TProductFamily = t.TypeOf<typeof ProductFamilyValidator>;

export const ProductFamilyArrayValidator = t.array(ProductFamilyValidator);

export type TProductFamilyArray = t.TypeOf<typeof ProductFamilyArrayValidator>;

export const SectionValidator = t.type({
  id: FamilyCodeValidator,
  name: t.string,
});
