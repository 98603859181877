import React, { VFC } from 'react';
import { ChakraProps, Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/react';

type GridTableHeaderProps = {
  rowProps?: GridProps;
  itemProps?: GridItemProps;
  cellProps?: ChakraProps[];
  items: string[];
};

export const GridTableHeader: VFC<GridTableHeaderProps> = ({
  rowProps = {},
  itemProps = {},
  cellProps = [],
  items,
}) => {
  return (
    <Grid {...rowProps}>
      {items.map((item, index) => (
        <GridItem fontSize="sm" {...itemProps} sx={cellProps[index] ?? {}} key={`${item}_${index}`}>
          {item}
        </GridItem>
      ))}
    </Grid>
  );
};
