import { Grid, GridItem, Select } from '@chakra-ui/react';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { startOfYesterday } from 'date-fns';
import { TStoreId } from 'domain/stores';
import { pipe } from 'fp-ts/function';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DailyTaskShortageList } from './DailyTaskShortageList';
import { StockPointsShortageChart } from 'pages/MorningTasksPage/StocksPage/components';

const LAST_DAYS_OPTIONS = [1, 3, 7, 14];

interface Props {
  storeId: TStoreId;
  lastDaysOptions?: number[];
}

export const ShortageCard: React.FC<Props> = ({ storeId }) => {
  const { t } = useTranslation('whisperme');
  const lastDaysOptions = LAST_DAYS_OPTIONS;
  const [lastDays, setLastDays] = useState(lastDaysOptions[2]);
  const [dataVisibility, setDataVisibility] = useState([true, true]);
  const yesterday = startOfYesterday();

  function handleDataVisibilityChange(index: number) {
    setDataVisibility((s: boolean[]) => {
      const newDataVisibility = [...s];
      newDataVisibility[index] = !newDataVisibility[index];
      return newDataVisibility;
    });
  }

  return (
    <Card>
      <CardTitleSection
        as="h2"
        settings={
          <CardTitleSectionSettings>
            <Grid p="3" justifyContent="end">
              <GridItem width="200px">
                <Select
                  size="sm"
                  value={lastDays}
                  onChange={(ev) => {
                    const maybeValue = pipe(ev.target.value, (v) => Number.parseInt(v, 10));
                    if (lastDaysOptions.includes(maybeValue)) {
                      setLastDays(maybeValue);
                    }
                  }}
                >
                  {lastDaysOptions.map((days) => (
                    <option key={days} value={days}>
                      {t('ANALYTICS.PRODUCTS.STATS_PAGE.TOP_PRODUCTS_TABLE.TIME_SELECTOR.LAST_DAYS_LABEL', {
                        count: days,
                      })}
                    </option>
                  ))}
                </Select>
              </GridItem>
            </Grid>
          </CardTitleSectionSettings>
        }
      >
        {t('DAILY_TASKS.SHORTAGE.SHORTAGES_LABEL')}
      </CardTitleSection>

      <StockPointsShortageChart
        lastDays={lastDays}
        yesterday={yesterday}
        dataVisibility={dataVisibility}
        handleDataVisibilityChange={handleDataVisibilityChange}
      />
      <DailyTaskShortageList
        storeId={storeId}
        lastDays={lastDays}
        yesterday={yesterday}
        dataVisibility={dataVisibility}
      />
    </Card>
  );
};
