import React, { useRef, useCallback } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Link,
  Box,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CSS_VARIABLES } from 'constants/css';
import { AppName } from 'components/AppName';
import { useUserContext } from 'hooks';
import { NavigationItem, useMainNavigationItems } from '../useMainNavigationItems';

const {
  SIDE_BAR: { BACKGROUND_ACTIVE },
} = CSS_VARIABLES;

interface Props {
  children?: never;
}

export const MobileNavigation: React.FC<Props> = () => {
  const { t } = useTranslation('whisperme');
  const { logout } = useUserContext();
  const navItems = useMainNavigationItems();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const generateNav = useCallback(
    (items: NavigationItem[]) => {
      return items.map(({ path, label, active }, idx) => (
        <li key={idx} role="presentation">
          <Link as={RouterLink} aria-current={active ? 'page' : undefined} to={path} onClick={onClose}>
            {label}
          </Link>
        </li>
      ));
    },
    [onClose],
  );

  return (
    <>
      <IconButton
        ref={btnRef}
        icon={<HamburgerIcon />}
        fontSize="xl"
        size="md"
        onClick={onOpen}
        variant="ghost"
        aria-label={
          isOpen
            ? t('GENERIC.NAVIGATION.COLLAPSE_SIDE_BAR_ARIA_LABEL')
            : t('GENERIC.NAVIGATION.EXPAND_SIDE_BAR_ARIA_LABEL')
        }
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <AppName fontSize="2xl" />
          </DrawerHeader>

          <DrawerBody>
            <nav>
              <Box
                as="ul"
                sx={{
                  li: {
                    ':not(:last-of-type)': {
                      borderBottom: '1px',
                      borderColor: 'gray.400',
                    },
                  },

                  a: {
                    display: 'flex',
                    height: '40px',
                    fontWeight: 'bold',
                    width: '100%',
                    position: 'relative',
                    px: 2,
                    py: 2,

                    '&[aria-current="page"]': {
                      bg: `var(${BACKGROUND_ACTIVE})`,

                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',

                        width: '1',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        bg: `purple.300`,
                      },
                    },

                    '&:hover,:focus': {
                      bg: 'gray.100',
                      textDecoration: 'none',
                    },
                  },
                }}
              >
                {generateNav(navItems[0])}
                <li style={{ flexGrow: 1 }} />
                {generateNav(navItems[1])}
                <li role="presentation">
                  <Link as={RouterLink} to="#" onClick={logout}>
                    {t('GENERIC.NAVIGATION.LOGOUT_LABEL')}
                  </Link>
                </li>
              </Box>
            </nav>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
