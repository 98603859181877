import { useQuery, UseQueryOptions } from 'react-query';

import { createStoreStatisticsKey } from 'constants/queryCacheKeys';
import { getStoreStatistics, getValidatedStoreStatistics, GetStoreStatisticsParams } from 'domain/statistics';

type DataType = Unpromisify<ReturnType<typeof getStoreStatistics>>;
type ErrorType = Error;

export const useStoreStatistics = <Data = DataType>(
  params: GetStoreStatisticsParams,
  options?: UseQueryOptions<DataType, ErrorType, Data>,
) => useQuery<DataType, ErrorType, Data>(createStoreStatisticsKey(params), () => getStoreStatistics(params), options);

type ValidatedDataType = Unpromisify<ReturnType<typeof getValidatedStoreStatistics>>;

export const useValidatedStoreStatistics = <DataType = ValidatedDataType>(
  params: GetStoreStatisticsParams,
  options?: UseQueryOptions<ValidatedDataType, ErrorType, DataType>,
) =>
  useQuery<ValidatedDataType, ErrorType, DataType>(
    createStoreStatisticsKey(params),
    () => getValidatedStoreStatistics(params),
    options,
  );
