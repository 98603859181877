import { useQuery, UseQueryOptions } from 'react-query';

import { createUserDetailsKey } from 'constants/queryCacheKeys';
import { TGetUserDetailsParams, getUserDetails, TUser } from 'domain/user';

type DataType = TUser;
type ErrorType = Error;

export const useUserDetails = <Data = DataType>(
  params: TGetUserDetailsParams,
  options?: UseQueryOptions<DataType, ErrorType, Data>,
) => useQuery<DataType, ErrorType, Data>(createUserDetailsKey(params), () => getUserDetails(params), options);
