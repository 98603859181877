import React from 'react';
import { LegendOrdinal } from '@visx/legend';

import { ChartLegend, ChartLegendProps } from './ChartLegend';

type LegendOrdinalProps = React.ComponentPropsWithoutRef<typeof LegendOrdinal>;

interface Props extends Omit<ChartLegendProps, 'items'>, Omit<LegendOrdinalProps, 'direction'> {}

export const ChartLegendOrdinal: React.FC<Props> = ({
  onLegendItemClick,
  isMetricVisible,
  direction = 'row',
  ...rest
}) => (
  <LegendOrdinal {...rest}>
    {(labels) => (
      <ChartLegend
        onLegendItemClick={onLegendItemClick}
        isMetricVisible={isMetricVisible}
        direction={direction}
        items={labels.map((l) => ({ name: l.text, color: l.value }))}
      />
    )}
  </LegendOrdinal>
);
