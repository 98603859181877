import { useCallback } from 'react';
import { differenceInCalendarDays, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { dateToYMD, getCurrentDate } from 'utils/date';
import { PREDEFINED_LAST_DAY_COUNTS } from './utils';

export const useDateRangeFormat = () => {
  const { t } = useTranslation('whisperme');

  return useCallback(
    (startDate: Date, endDate: Date | null) => {
      if (endDate instanceof Date) {
        const dayDiff = differenceInCalendarDays(endDate, startDate);

        if (PREDEFINED_LAST_DAY_COUNTS.some((v) => v === dayDiff) && isSameDay(endDate, getCurrentDate())) {
          return t('COMPONENTS.TIME_FRAME_SELECT.LAST_DAYS_LABEL', { count: dayDiff });
        }

        return [startDate, endDate].map(dateToYMD).join(' - ');
      }

      return dateToYMD(startDate);
    },
    [t],
  );
};
