import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_ROOT_ID } from 'constants/env';

export const useScrollOnNavigation = (elementSelector = `#${APP_ROOT_ID}`) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.querySelector(elementSelector)?.scrollTo(0, 0);
  }, [pathname, elementSelector]);
};
