import { getISOWeek } from 'date-fns';
import { TFunction } from 'i18next';
import { DEFAULT_TOTAL_CASH_REGS } from './consts';

export const getCashierCellVariant = (qty: number, storeTotalCashReg = DEFAULT_TOTAL_CASH_REGS) => {
  if (qty === 0) return 'empty';
  if (qty > 0 && qty <= Math.floor(storeTotalCashReg * 0.33)) return 'risky';
  if (qty > Math.floor(storeTotalCashReg * 0.33) && qty <= Math.floor(storeTotalCashReg * 0.66)) return 'safe';
  if (qty > storeTotalCashReg) return 'max';
  return 'error';
};

export const getWeekRangeLabel = (date: Date, t: TFunction) =>
  t('DAILY_TASKS.CASHIER_ADVICE.SHORT_FOR_WEEK') +
  `${getISOWeek(new Date(date))}` +
  ' - ' +
  t('DAILY_TASKS.CASHIER_ADVICE.SHORT_FOR_WEEK') +
  `${getISOWeek(new Date(date)) + 1}`;
