import * as PATHS from 'constants/paths';
import { dateToYMD, getCurrentDate, getCurrentDayOfWeek } from 'utils/date';
import { createNavigationMatch, Path } from 'utils/router';
import { addWeeks, endOfISOWeek, Locale, startOfISOWeek } from 'date-fns';

export enum MorningTasksNavigationMatch {
  STOCKS,
  ORDERS,
  ADD_STOCK,
  HISTORY_ORDERS,
}

export const morningTasksMatchMapping: Array<[MorningTasksNavigationMatch, Path]> = [
  [MorningTasksNavigationMatch.STOCKS, PATHS.MORNING_TASKS.ROOT.PATH],
  [MorningTasksNavigationMatch.ORDERS, PATHS.MORNING_TASKS.ORDERS.PATH],
  [MorningTasksNavigationMatch.HISTORY_ORDERS, PATHS.MORNING_TASKS.HISTORY_ORDERS.PATH],
];

export const findMorningTasksNavigationMatch = createNavigationMatch({
  matchMapping: morningTasksMatchMapping,
  defaultValue: MorningTasksNavigationMatch.STOCKS,
});

export const generateLabelDates = (date: Date, locale?: Locale) => ({
  day: getCurrentDayOfWeek(date, locale),
  date: dateToYMD(date),
});

/**
 * Returns start and end date of the week.
 * @param week - relative number of week (0 - current week, 1 - next week, -1 - previous week) default: 0
 */
export const getWeekStartEnd = (week = 0) => {
  const date = addWeeks(getCurrentDate(), week);

  return {
    start_date: startOfISOWeek(date),
    end_date: endOfISOWeek(date),
  };
};
