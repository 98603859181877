import { useUserStoreIds } from '../useUserContext';
import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Partial } from 'react-spring';
import { StocksStoreDataParams, useStocksStoreData } from './useStocksStoreData';
import { TStockAPIData } from 'domain/stocks';

export const useStockInsightsObject = (params: Partial<StocksStoreDataParams> = {}) => {
  const userStores = useUserStoreIds();

  const { data, isFetching } = useStocksStoreData({
    store: userStores[0],
    ...params,
  });

  const stockInsights = useMemo(
    () =>
      data && !isFetching
        ? pipe(
            data,
            A.map(
              ({ product_code, ...rest }) =>
                [String(product_code), rest.insights] as [string, TStockAPIData['insights']],
            ),
            (list) => Object.fromEntries(list) as Record<string, TStockAPIData['insights']>,
          )
        : {},
    [data, isFetching],
  );

  return { stockInsights, isFetching };
};
