import { createCashiersPredictionKey } from 'constants/queryCacheKeys';
import { CashierPredictionParams, getCashiersPrediction } from 'domain/predictions';
import { useQuery } from 'react-query';

export const useCashierPredictionData = ({
  storeIds,
  date,
  weeks,
}: {
  storeIds: CashierPredictionParams['store_ids'];
  date?: CashierPredictionParams['date'];
  weeks?: CashierPredictionParams['weeks'];
}) => {
  const params: CashierPredictionParams = {
    store_ids: storeIds,
    date: date,
    weeks: weeks,
  };

  return useQuery(createCashiersPredictionKey(params), () => getCashiersPrediction(params));
};
