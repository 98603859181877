import { pipe } from 'fp-ts/function';
import { API_BASE_URL } from 'constants/env';
import { createValidatedCancellableFetch, makeCancellableFetch } from 'utils/request';
import { TStoreId } from '../stores';
import {
  TAuthenticatedUserData,
  UserListValidator,
  AuthenticatedUserDataValidator,
  TUser,
  TUserId,
  UserValidator,
  UserTypesValidator,
} from './types';

const API_URLS = {
  getAuthenticatedUserDetails: () => `${API_BASE_URL}/auth/login`,
  login: () => `${API_BASE_URL}/auth/login`,
  logout: () => `${API_BASE_URL}/auth/logout`,
  changePassword: () => `${API_BASE_URL}/auth/change_password`,

  getUsers: () => `${API_BASE_URL}/user/`,
  createUser: () => `${API_BASE_URL}/user/`,

  getUserDetails: (userId: TAuthenticatedUserData['user_id']) => `${API_BASE_URL}/user/${userId}`,
  updateUserDetails: (userId: TAuthenticatedUserData['user_id']) => `${API_BASE_URL}/user/${userId}`,
  getUserTypes: () => `${API_BASE_URL}/user_type/`,
  deleteUser: (userId: TUserId) => `${API_BASE_URL}/user/${userId}`,
};

export interface TGetInsightsParams {
  store_id?: TStoreId | Array<TStoreId>;
  date_from?: string;
  limit?: number;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_authentication_auth_login_get
 */
export const getAuthenticatedUserDetails = () =>
  pipe(API_URLS.getAuthenticatedUserDetails(), createValidatedCancellableFetch(AuthenticatedUserDataValidator));
export interface TLoginPayload {
  email: string;
  password: string;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/login_auth_login_post
 */
export const login = (payload: TLoginPayload) =>
  makeCancellableFetch(API_URLS.login(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/logout_auth_logout_post
 */
export const logout = () =>
  makeCancellableFetch(API_URLS.logout(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export interface TChangePasswordPayload {
  password: string;
  old_password: string;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/change_credentials_auth_change_password_post
 */
export const changePassword = (payload: TChangePasswordPayload) =>
  makeCancellableFetch(API_URLS.changePassword(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/list_users_user__get
 */
export const getUsersList = () =>
  pipe(
    API_URLS.getUsers(),
    createValidatedCancellableFetch(UserListValidator, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  );

export interface TCreateUserPayload extends TUser {
  password: string;
}

/**
 *
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/create_user_user__post
 */
export const createUser = (payload: TCreateUserPayload): Promise<{ id: TUserId }> =>
  makeCancellableFetch(API_URLS.createUser(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export interface TGetUserDetailsParams {
  userId: TUserId;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/get_user_user__id__get
 */
export const getUserDetails = ({ userId }: TGetUserDetailsParams) =>
  pipe(API_URLS.getUserDetails(userId), createValidatedCancellableFetch(UserValidator));

export interface TUpdateUserDetailsParams {
  userId: TUserId;
  payload: TUser;
}

/**
 * @link https://api.dev.whisperme.neoinstinct.com/docs#/default/set_user_user__id__put
 */
export const updateUserDetails = ({ userId, payload }: TUpdateUserDetailsParams) =>
  makeCancellableFetch(API_URLS.updateUserDetails(userId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export const getUserTypes = () => pipe(API_URLS.getUserTypes(), createValidatedCancellableFetch(UserTypesValidator));

export interface TDeleteUserParams {
  userId: TUserId;
}

export const deleteUser = ({ userId }: TDeleteUserParams) => {
  const url = API_URLS.deleteUser(userId);
  return makeCancellableFetch(url, {
    method: 'DELETE',
  });
};
