export const formatPriceValue = (value: number, convertToDecimal = true) => {
  if (!convertToDecimal) return value;

  let temp = value.toString();

  temp = temp.length < 3 ? temp.padStart(3, '0') : temp;

  return `${temp.slice(0, temp.length - 2)}.${temp.slice(temp.length - 2)}`;
};

/**
 * Converts a number from decimal to intager in range 0-100 and add to percentage sign converting it to string
 * @param value number to convert
 */
export const formatProcentage = (value: number): string => {
  return `${(value * 100).toFixed(0)}%`;
};
