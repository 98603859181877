import React, { useMemo } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TDataResolution, DATA_RESOLUTION, isDataResolution } from 'domain/core';

const defaultIsAllowedResolution = () => true;
interface Props extends Omit<SelectProps, 'onChange'> {
  value?: TDataResolution;
  isAllowedResolution?: (r: TDataResolution) => boolean;
  onChange?: (newResolution: TDataResolution) => void;
}

export const DataResolutionSelect: React.FC<Props> = ({
  value,
  isAllowedResolution = defaultIsAllowedResolution,
  placeholder,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');

  const options = useMemo(
    () =>
      [
        {
          value: DATA_RESOLUTION.DAY,
          label: t('COMPONENTS.DATA_RESOLUTION_SELECT.DAY_LABEL'),
        },
        {
          value: DATA_RESOLUTION.WEEK,
          label: t('COMPONENTS.DATA_RESOLUTION_SELECT.WEEK_LABEL'),
        },
        {
          value: DATA_RESOLUTION.MONTH,
          label: t('COMPONENTS.DATA_RESOLUTION_SELECT.MONTH_LABEL'),
        },
        {
          value: DATA_RESOLUTION.QUARTER,
          label: t('COMPONENTS.DATA_RESOLUTION_SELECT.QUARTER_LABEL'),
        },
        {
          value: DATA_RESOLUTION.YEAR,
          label: t('COMPONENTS.DATA_RESOLUTION_SELECT.YEAR_LABEL'),
        },
      ].filter((r) => isAllowedResolution(r.value)),
    [t, isAllowedResolution],
  );

  return (
    <Select
      size="sm"
      value={value}
      {...rest}
      onChange={(ev) => {
        const newValue = ev.target.value;
        if (isDataResolution(newValue)) {
          onChange?.(newValue);
        }
      }}
    >
      <option value="" disabled={true}>
        {placeholder ?? t('COMPONENTS.DATA_RESOLUTION_SELECT.PLACEHOLDER_LABEL')}
      </option>
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </Select>
  );
};
