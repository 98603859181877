import { useObservableState } from 'observable-hooks';
import { Observable, of } from 'rxjs';
import { switchScan, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import * as Eq from 'fp-ts/Eq';

import { TGetProductListParams } from 'domain/products';
import { TProductListFilterAction, EProductListFilterActionType } from './types';
import { useProductFamilies } from 'hooks/data';
import { useLiveRef } from 'hooks';

export type TProductListFiltersState = Omit<TGetProductListParams, 'start_date' | 'end_date'> & { use_date?: boolean };

const initialParams: TProductListFiltersState = {
  product_description_query: '',
  product_code_prefix: '',
  family_code: undefined,
  root_family_code: undefined,
  minimum_stock: undefined,
  weekend_product: undefined,
  always_available: undefined,
  supplier_name: undefined,
  is_regional: undefined,
  use_date: false,
};

export const ProductListFiltersStateEq = Eq.fromEquals<Partial<TProductListFiltersState>>(
  (prev, next) =>
    prev.product_code_prefix === next.product_code_prefix &&
    prev.product_description_query === next.product_description_query &&
    prev.family_code === next.family_code &&
    prev.root_family_code === next.root_family_code &&
    prev.is_regional === next.is_regional &&
    prev.minimum_stock === next.minimum_stock &&
    prev.weekend_product === next.weekend_product &&
    prev.always_available === next.always_available &&
    prev.supplier_name === next.supplier_name &&
    prev.use_date === next.use_date,
);

const EMPTY_MAP = new Map<never, never>();

export const useProductListFilters = () => {
  const { data: pfMap = EMPTY_MAP } = useProductFamilies({ select: (d) => new Map(d.map((pf) => [pf.id, pf])) });
  const pfMapRef = useLiveRef(pfMap);

  // TODO(m.kania): sync with url query params
  const [state, dispatch] = useObservableState(
    // FIXME(m.kania): refactor
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (action$: Observable<TProductListFilterAction>, initialState: TProductListFiltersState) =>
      action$.pipe(
        switchScan((state, action) => {
          const pfMap = pfMapRef.current;
          let newState = state;

          switch (action.type) {
            case EProductListFilterActionType.EAN:
              newState = {
                ...state,
                product_code_prefix: action.payload,
              };
              break;
            case EProductListFilterActionType.PRODUCT_NAME:
              newState = {
                ...state,
                product_description_query: action.payload,
              };
              break;
            case EProductListFilterActionType.FAMILY_CODE:
              newState = {
                ...state,
                family_code: action.payload,
              };
              if (typeof action.payload !== 'undefined' && typeof newState.root_family_code !== 'undefined') {
                let rootFamily = undefined;
                if (pfMap instanceof Map) rootFamily = pfMap.get(newState.root_family_code);

                // NOTE(m.kania): if selected family isn't a part of root family, then remove root family
                if (rootFamily && !rootFamily.children_ids.includes(action.payload)) {
                  newState = {
                    ...newState,
                    root_family_code: undefined,
                  };
                }
              }

              break;
            case EProductListFilterActionType.ROOT_FAMILY_CODE:
              newState = {
                ...state,
                root_family_code: action.payload,
              };

              if (typeof action.payload !== 'undefined' && typeof newState.family_code !== 'undefined') {
                let rootFamily = undefined;
                if (pfMap instanceof Map) rootFamily = pfMap.get(action.payload);

                // NOTE(m.kania): if selected root family doesn't contain currently active (sub)family, then remove it
                if (rootFamily && !rootFamily.children_ids.includes(newState.family_code)) {
                  newState = {
                    ...newState,
                    family_code: undefined,
                  };
                }
              }

              break;
            case EProductListFilterActionType.IS_REGIONAL:
              newState = {
                ...state,
                is_regional: action.payload,
              };
              break;
            case EProductListFilterActionType.USE_DATE:
              newState = {
                ...state,
                use_date: action.payload,
              };
              break;
            case EProductListFilterActionType.MINIMUM_STOCK:
              newState = {
                ...state,
                minimum_stock: action.payload,
              };
              break;
            case EProductListFilterActionType.WEEKEND_PRODUCT:
              newState = {
                ...state,
                weekend_product: action.payload,
              };
              break;
            case EProductListFilterActionType.ALWAYS_AVAILABLE:
              newState = {
                ...state,
                always_available: action.payload,
              };
              break;
            case EProductListFilterActionType.SUPPLIER_NAME:
              newState = {
                ...state,
                supplier_name: action.payload,
              };
              break;
          }

          return of(newState);
        }, initialState),
        debounceTime(350),
        distinctUntilChanged(ProductListFiltersStateEq.equals),
      ),
    () => initialParams,
  );

  return [state, dispatch] as const;
};
