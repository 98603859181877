import { Grid, Center } from '@chakra-ui/react';
import { pipe } from 'fp-ts/function';
import { useStocksReducer, updateStoreStock } from 'hooks/useStocksReducer';
import React, { useLayoutEffect, VFC } from 'react';
import { useStocksStoreData } from 'hooks/data';
import { useTranslation } from 'react-i18next';
import * as A from 'fp-ts/Array';
import { PageSpinner } from 'components/Spinners';
import { CompressedStockRow } from './CompressedStockRow';
import { ExpandedStockRow } from './ExpandedStockRow';
import { useUserStoreIds } from 'hooks';
import { ColumnSortDirection, ColumnSorts, TStockAPIData } from 'domain/stocks';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';

type StockRowProps = {
  variant: string;
  sort: ColumnSorts;
  direction: ColumnSortDirection;
  nameOrEan: string;
  deliveryDay: boolean;
  is_always_available: boolean;
};

export const StockRows: VFC<StockRowProps> = ({
  variant,
  sort,
  direction,
  nameOrEan,
  deliveryDay,
  is_always_available,
}) => {
  const { t } = useTranslation('whisperme');
  const userStores = useUserStoreIds();
  const [state, dispatch] = useStocksReducer();

  const { data, isFetching } = useStocksStoreData({
    store: userStores[0],
    column_sort: sort,
    order: direction,
    nameOrEan,
    is_always_available,
    deliveryDay,
  });

  useLayoutEffect(() => {
    if (data?.length && !isFetching) {
      dispatch(updateStoreStock(data));
    }
  }, [data, isFetching]);

  const entries = pipe(
    data || [],
    A.filter(({ product_code }) => state[product_code] !== undefined),
    A.map(({ product_code, ...rest }) => [product_code, rest] as [number, Omit<TStockAPIData, 'product_code'>]),
  );

  if (!isFetching && entries.length === 0)
    return (
      <Center w="100%" h="80vh">
        {t('COMPONENTS.CHARTS.NO_DATA_LABEL')}
      </Center>
    );

  return (
    <Grid
      gap={variant === 'compressed' ? 1 : GRID_GAP_DEFAULT_VALUE}
      gridTemplateColumns={{ base: '100%' }}
      width="100%"
      maxH="71vh"
      overflowY="auto"
    >
      {isFetching ? (
        <PageSpinner />
      ) : (
        pipe(
          entries,
          A.map(([product_code, { store_id }]) =>
            variant === 'compressed' ? (
              <CompressedStockRow productCode={String(product_code)} key={`${product_code}_${store_id}`} />
            ) : (
              <ExpandedStockRow productCode={String(product_code)} key={`${product_code}_${store_id}`} />
            ),
          ),
        )
      )}
    </Grid>
  );
};
