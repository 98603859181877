import React from 'react';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TStoreId } from 'domain/stores';
import { StoreSelectBaseProps } from './types';
import { StoreSelectOptions } from './StoreSelectOptions';

export interface NullableSingleStoreSelectProps extends StoreSelectBaseProps {
  onChange?: (newStoreId?: TStoreId) => void;
  value: TStoreId | undefined;
}

export const NullableSingleStoreSelect: React.FC<NullableSingleStoreSelectProps> = ({
  selectableStoreIds,
  onChange,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');

  return (
    <Select
      size="sm"
      onChange={(ev) => {
        const value = ev.target.value as any;
        const newValue = selectableStoreIds.includes(value) ? value : undefined;

        onChange?.(newValue);
      }}
      {...rest}
    >
      <option value="">{placeholder ?? t('COMPONENTS.STORE_SELECT.ALL_STORES_LABEL')}</option>
      <StoreSelectOptions selectableStoreIds={selectableStoreIds} />
    </Select>
  );
};
