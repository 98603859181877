import { Text } from '@chakra-ui/react';
import React, { VFC } from 'react';

interface TooltipRowProps {
  label: string;
  value: string;
}

export const TooltipRow: VFC<TooltipRowProps> = ({ label, value }) => {
  return (
    <Text as="span">
      <Text as="span" fontWeight="bold">
        {label}:
      </Text>{' '}
      {value}
    </Text>
  );
};
