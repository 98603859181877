import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { Tooltip } from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';

import { dateToYMD } from 'utils/date';
import { ChartDataEntry, InteractiveChartMetric } from './types';

type TooltipProps = React.ComponentPropsWithoutRef<typeof Tooltip>;
export interface ChartDataTooltipProps extends Omit<TooltipProps, 'renderTooltip'> {
  children?: never;
  metrics: InteractiveChartMetric[];
  formatValue?: (v: number) => number | string;
  renderTooltip?: (
    params: RenderTooltipParams<ChartDataEntry>,
    metrics: InteractiveChartMetric[],
  ) => JSX.Element | null;
}

export const formatX = (x: Date | string) => (x instanceof Date ? dateToYMD(x) : x);
// NOTE(m.kania): toFixed rounds up, then parsing to number will remove all insignificant zeros
const defaultFormatValue = (v: number) => Number.parseFloat(v.toFixed(2));

const ChartDataTooltipComponent: React.FC<ChartDataTooltipProps> = ({
  children,
  metrics,
  formatValue = defaultFormatValue,
  renderTooltip: propRenderTooltip,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');

  const renderTooltip = useCallback(
    (params: RenderTooltipParams<ChartDataEntry>) => {
      if (typeof propRenderTooltip === 'function') {
        return propRenderTooltip(params, metrics);
      }

      const { tooltipData, colorScale } = params;

      return tooltipData?.nearestDatum ? (
        <Stack spacing="4">
          <Text>{formatX(tooltipData.nearestDatum.datum.x)}</Text>
          <Stack>
            {tooltipData.nearestDatum.datum.y.map((val, idx) => {
              const { visible, name, dataKey } = metrics[idx] ?? {};

              return visible ? (
                <Text key={idx} as="span">
                  <Text as="span" color={colorScale?.(dataKey)}>
                    {name}:{' '}
                  </Text>
                  {val === null || Number.isNaN(val)
                    ? t('COMPONENTS.CHARTS.NO_VALUE_TOOLTIP_PLACEHOLDER')
                    : formatValue(val)}
                </Text>
              ) : null;
            })}
          </Stack>
        </Stack>
      ) : null;
    },
    [metrics, t, formatValue, propRenderTooltip],
  );

  // NOTE(m.kania): current visx Tooltip implemntation throws findDOMNode errors in console
  return (
    <Tooltip<ChartDataEntry>
      showVerticalCrosshair={true}
      showSeriesGlyphs={true}
      renderTooltip={renderTooltip}
      debounce={10}
      {...rest}
    />
  );
};

export const ChartDataTooltip = React.memo<ChartDataTooltipProps>(ChartDataTooltipComponent);
