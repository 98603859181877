import React, { useMemo } from 'react';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { identity } from 'io-ts';
import { Stack, TableContainer, Grid, Table, Th, Thead, Tr, Tbody, Td, Skeleton, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppConfig, useTopAnomalousQuery, useUserSections } from 'hooks';
import { useSectionParams } from 'hooks/data';
import { useMachine } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { TGetTopAnomalousParams, TTopAnomalousArray } from 'domain/objectives';
import { TStoreId } from 'domain/stores';
import { getCurrentDate } from 'utils/date';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { Card, CardTitleSection } from 'components/Card';
import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { mostAffectedProductsMachine } from 'pages/AnalyticsPage/AreaShortagePage/machine';
import { PROBABILITY_THRESHOLD } from 'pages/AnalyticsPage/AreaShortagePage/utils';
import { getFormattedMonth } from './utils';
import * as PATHS from 'constants/paths';

interface Props {
  storeId: TStoreId;
}

export const FreeTaskShortageComparison: React.FC<Props> = ({ storeId }) => {
  const {
    viewport,
    dateOptions: { locale },
  } = useAppConfig();
  const { t } = useTranslation('whisperme');
  const [state] = useMachine(mostAffectedProductsMachine);
  const userRootFamilies = useUserSections();
  const { productsFamily, productsSection } = state.context;
  const dates = {
    today: getCurrentDate(),
    startOfCurrentMonth: startOfMonth(getCurrentDate()),
    startOfPrevMonth: pipe(getCurrentDate(), (d) => subMonths(d, 1), startOfMonth),
    endOfPrevMonth: pipe(
      pipe(getCurrentDate(), (d) => subMonths(d, 1), startOfMonth),
      (d) => addMonths(d, 0),
      endOfMonth,
    ),
  };
  const userAuthSectionIds = pipe(
    userRootFamilies,
    A.map(({ id }) => id),
  );

  const initialParams: TGetTopAnomalousParams = {
    store_id: storeId,
    probability_threshold: PROBABILITY_THRESHOLD,
    first: undefined,
    end: undefined,
    family_id: productsFamily,
    root_family_id: productsSection,
  };
  const currentMonthParams = { ...initialParams, first: dates.startOfCurrentMonth, end: dates.today };
  const filteredCurrentParams = useSectionParams(currentMonthParams, userAuthSectionIds, productsSection);

  const previousMonthParams = { ...initialParams, first: dates.startOfPrevMonth, end: dates.endOfPrevMonth };
  const filteredPreviousParams = useSectionParams(previousMonthParams, userAuthSectionIds, productsSection);

  const { data, status: currQueryStatus } = useTopAnomalousQuery(filteredCurrentParams);
  const { data: prevData, status: prevQueryStatus } = useTopAnomalousQuery(filteredPreviousParams);

  const currTop5Anomalies = useMemo<TTopAnomalousArray>(() => {
    if (currQueryStatus === 'success' && data) {
      const minListLength = data.length < 10 ? data.length : 10;
      return data.slice(0, minListLength);
    } else {
      return [];
    }
  }, [data, currQueryStatus]);

  const prevTop5Anomalies = useMemo(
    () =>
      pipe(
        O.fromNullable(currTop5Anomalies),
        O.filter(() => prevQueryStatus === 'success'),
        O.map((currTop5) => currTop5.map((product) => product.product_code)),
        O.fold(
          () => new Map(),
          (top5CurrentProdCodeArray) => {
            if (!prevData) {
              return new Map();
            }
            return prevData.reduce((mutatedPrevAnomalies, product) => {
              if (top5CurrentProdCodeArray.includes(product.product_code)) {
                mutatedPrevAnomalies.set(product.product_code, product);
              }
              return mutatedPrevAnomalies;
            }, new Map());
          },
        ),
      ),
    [prevQueryStatus, currTop5Anomalies, prevData],
  );

  const table = (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',
        tableLayout: viewport !== 'mobile' ? 'fixed' : undefined,
        '& [data-cell-wrap]': {
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Thead>
        <Tr>
          <Th data-cell-wrap>{t('FREE_TASKS.TOP_SHORTAGES_TASK.SHORTAGES_TABLE.EAN_LABEL')}</Th>
          <Th data-cell-wrap>{t('FREE_TASKS.TOP_SHORTAGES_TASK.SHORTAGES_TABLE.DESCRIPTION_LABEL')}</Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.TOP_SHORTAGES_TASK.SHORTAGES_TABLE.PREVIOUS_MONTH_LABEL', {
              month: getFormattedMonth(dates.startOfPrevMonth, locale),
            })}
          </Th>
          <Th data-cell-wrap isNumeric={true}>
            {t('FREE_TASKS.TOP_SHORTAGES_TASK.SHORTAGES_TABLE.CURRENT_MONTH_LABEL', {
              month: getFormattedMonth(dates.startOfCurrentMonth, locale),
            })}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {currQueryStatus !== 'success' || prevQueryStatus !== 'success'
          ? Array.from({ length: 10 }).map((_, i) => (
              <Tr key={i}>
                <Td>
                  <Skeleton height="20px" width="auto" />
                </Td>
                <Td>
                  <Skeleton height="20px" width="auto" />
                </Td>
                <Td isNumeric={true}>
                  <Skeleton height="20px" width="auto" />
                </Td>
                <Td isNumeric={true}>
                  <Skeleton height="20px" width="auto" />
                </Td>
              </Tr>
            ))
          : currTop5Anomalies.map((p) => (
              <Tr key={p.product_code}>
                <Td>{p.product_code}</Td>
                <Td>
                  {pipe(
                    p.description,
                    O.fold(() => '', identity),
                  )}
                </Td>
                <Td isNumeric={true}>
                  {prevTop5Anomalies.get(p.product_code) !== undefined
                    ? prevTop5Anomalies.get(p.product_code).incident_count
                    : 0}
                </Td>
                <Td isNumeric={true}>{p.incident_count}</Td>
              </Tr>
            ))}
      </Tbody>
    </Table>
  );

  return (
    <Card>
      <Stack spacing="4">
        <Grid
          gap={GRID_GAP_DEFAULT_VALUE}
          alignItems="center"
          gridTemplateColumns={{
            base: '100%',
            [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
          }}
        ></Grid>
        <CardTitleSection>{t('FREE_TASKS.TOP_SHORTAGES_TASK.TOP_SHORTAGES_TASK_LABEL')}</CardTitleSection>
        <Stack position="relative">{viewport === 'mobile' ? <TableContainer>{table}</TableContainer> : table}</Stack>
        <Link
          as={RouterLink}
          to={PATHS.ANALYTICS.OBJECTIVES.ROOT.toPath()}
          textAlign="center"
          display="block"
          color="blue.500"
          textTransform="capitalize"
        >
          {t('GENERIC.SHOW_MORE_DETAILS_LABEL')}
        </Link>
      </Stack>
    </Card>
  );
};
