import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isNumberCharacteristic, isPercentageCharacteristic, TCharacteristicVariant } from 'domain/map';

interface UseCharacteristicFormatterArgs {
  currencyFormatter: Intl.NumberFormat;
  numberFormatter: Intl.NumberFormat;
  percentageFormatter: Intl.NumberFormat;
}

export const formatCharacteristic =
  ({ currencyFormatter, numberFormatter, percentageFormatter }: UseCharacteristicFormatterArgs) =>
  (characteristic: TCharacteristicVariant) =>
  (value: number) => {
    if (isPercentageCharacteristic(characteristic)) {
      return percentageFormatter.format(value);
    }

    if (isNumberCharacteristic(characteristic)) {
      return numberFormatter.format(value);
    }

    return currencyFormatter.format(value);
  };

export const useCharacteristicFormatter = ({
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
}: UseCharacteristicFormatterArgs) => {
  const { t } = useTranslation('whisperme');

  return useMemo(
    () => (characteristic: TCharacteristicVariant) => {
      const formatValue = formatCharacteristic({ currencyFormatter, numberFormatter, percentageFormatter })(
        characteristic,
      );

      return (value: number) => ({
        label: t(`CHARACTERISTICS.${characteristic}`),
        formattedValue: formatValue(value),
      });
    },
    [currencyFormatter, numberFormatter, percentageFormatter, t],
  );
};
