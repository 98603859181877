import React, { useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/function';

import { deleteProduct, TProduct } from 'domain/products';
import { createProductsListKey } from 'constants/queryCacheKeys';
import { TProductFamily } from 'domain/product-family';

interface Props {
  product: TProduct | null;
  onClose: () => void;
  productFamilies: TProductFamily[];
}

const ProductFamiliesDeleteConfirmationModalComponent: React.VFC<Props> = ({ product, onClose, productFamilies }) => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync, status } = useMutation(deleteProduct, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(createProductsListKey());
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_DELETED_SUCCESS_TEXT'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    },
    onError: () => {
      toast({
        title: t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_DELETED_FAIL_TEXT'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  // NOTE(m.kania): saving last visible product so it doesn't disappear when the modal animates out
  const lastProductRef = useRef<TProduct>();
  if (product) {
    lastProductRef.current = product;
  }

  const family = pipe(
    productFamilies,
    A.findFirst((f) => f.id === lastProductRef.current?.family_code),
  );
  const rootFamily = pipe(
    family,
    O.chain((f) => f.parent_id),
    O.chain((parentId) =>
      pipe(
        productFamilies,
        A.findFirst((f) => f.id === parentId),
      ),
    ),
  );

  const isRegional = pipe(
    lastProductRef.current?.is_regional,
    O.fromNullable,
    O.chain(identity),
    O.fold(() => false, identity),
  );

  return (
    <Modal isOpen={product !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('SETTINGS.PRODUCT_FAMILIES.DELETE_MODAL.TITLE', { value: lastProductRef.current?.description })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="span">{t('SETTINGS.PRODUCT_FAMILIES.DELETE_MODAL.HINT_TEXT')}</Text>

          <Box as="ul" mt="3">
            <HStack as="li">
              <Text as="strong">{t('SETTINGS.PRODUCT_FAMILIES.EAN_LABEL')}:</Text>
              <Text as="span">{lastProductRef.current?.product_code}</Text>
            </HStack>
            <HStack as="li">
              <Text as="strong">{t('SETTINGS.PRODUCT_FAMILIES.PRODUCT_NAME_LABEL')}:</Text>
              <Text as="span">{lastProductRef.current?.description}</Text>
            </HStack>
            <HStack as="li">
              <Text as="strong">{t('SETTINGS.PRODUCT_FAMILIES.SECTION_NAME_LABEL')}:</Text>
              <Text as="span">
                {pipe(
                  rootFamily,
                  O.fold(
                    () => '',
                    (f) => f.name,
                  ),
                )}
              </Text>
            </HStack>
            <HStack as="li">
              <Text as="strong">{t('SETTINGS.PRODUCT_FAMILIES.FAMILY_CODE_LABEL')}:</Text>
              <Text as="span">{lastProductRef.current?.family_code}</Text>
            </HStack>
            <HStack as="li">
              <Text as="strong">{t('SETTINGS.PRODUCT_FAMILIES.FAMILY_NAME_LABEL')}:</Text>
              <Text as="span">
                {pipe(
                  family,
                  O.fold(
                    () => '',
                    (f) => f.name,
                  ),
                )}
              </Text>
            </HStack>
            <HStack as="li">
              <Text as="strong">{t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_LABEL')}:</Text>
              <Text as="span">
                {isRegional
                  ? t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_TRUE_LABEL')
                  : t('SETTINGS.PRODUCT_FAMILIES.REGIONAL_FALSE_LABEL')}
              </Text>
            </HStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button variant="ghost" onClick={onClose}>
              {t('SETTINGS.PRODUCT_FAMILIES.DELETE_MODAL.CANCEL_BUTTON_LABEL')}
            </Button>
            <Button
              colorScheme="red"
              isLoading={status === 'loading'}
              onClick={() => {
                if (lastProductRef.current) {
                  mutateAsync({ productCode: lastProductRef.current.product_code });
                }
              }}
            >
              {t('SETTINGS.PRODUCT_FAMILIES.DELETE_MODAL.DELETE_BUTTON_LABEL')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const ProductFamiliesDeleteConfirmationModal = React.memo(ProductFamiliesDeleteConfirmationModalComponent);
