import { TStoreId } from 'domain/stores';
import { TProductCode } from 'domain/core';
import { createNeoEmailCustomersMapKey } from 'constants/queryCacheKeys';
import { useQuery } from 'react-query';
import { getCustomerSimilarity, TNeoEmailCustomerSimilarity } from 'domain/neoemail';

/**
 * Hook for fetching customers similarity
 */
export const useCustomerSimilarityData = (
  store_id: TStoreId,
  product_code?: TProductCode,
  onSucess?: (data: TNeoEmailCustomerSimilarity) => void,
) => {
  const params = product_code ? { store_id, product_code } : {};
  return useQuery(
    createNeoEmailCustomersMapKey(params),
    () =>
      params.store_id && params.product_code
        ? getCustomerSimilarity({ ...params, limit: 1000 })
        : { customers: [], default_scroll_index: 0 },
    { onSuccess: onSucess },
  );
};
