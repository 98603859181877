import React from 'react';
import { pipe } from 'fp-ts/function';
import { reduceWithIndex } from 'fp-ts/Map';
import { Ord } from 'fp-ts/number';
import { Select, SelectProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useSuppliers } from 'hooks/data';

export interface NullableSingleSupplierSelectProps extends Omit<SelectProps, 'onChange' | 'value'> {
  onChange?: (newSupplierId?: number) => void;
  value?: number;
}

export const NullableSingleSupplierSelect: React.FC<NullableSingleSupplierSelectProps> = ({
  onChange,
  value,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation('whisperme');
  const { data: suppliers, isFetching: isSuppliersFetching } = useSuppliers();

  return (
    <Select
      size="sm"
      defaultValue={value}
      onChange={(ev) => {
        const value = ev.target.value as any;
        onChange?.(value ? Number(value) : undefined);
      }}
      {...rest}
    >
      <option value="">{placeholder ?? t('COMPONENTS.SUPPLIER_SELECT.NONE_LABEL')}</option>
      {!isSuppliersFetching && suppliers
        ? pipe(
            suppliers,
            reduceWithIndex(Ord)([] as unknown as Array<Element | JSX.Element>, (id, returned, name) => [
              ...returned,
              <option value={id} key={id}>
                {name}
              </option>,
            ]),
          )
        : null}
    </Select>
  );
};
