import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { Card } from 'components/Card';
import { TRecipeProduct } from 'domain/neoshopping';
import { ProductsTable } from './ProductsTable';

type MealBuilderRecipeProps = {
  products: TRecipeProduct[];
  recipe: string;
};

const reformatStringToHtml = (text: string) => {
  const cleanArtifacts = text.split(/(?:<.*>|---)/g).join('');

  return { __html: cleanArtifacts.split('\n').join('<br \\>') };
};

export const MealBuilderRecipe: React.VFC<MealBuilderRecipeProps> = ({ products, recipe }) => {
  const { t } = useTranslation('whisperme');
  const recipeRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => recipeRef.current,
  });

  return (
    <Stack>
      <Card>
        <Stack gap={2}>
          <ProductsTable products={products} />
          <Box flexGrow={1} width="100%" height="100%">
            <Text>
              <strong>{t('SHOPPING.MEAL_BUILDER_RECIPE.METHOD_TITLE')}</strong>
            </Text>
            <Box
              sx={{
                ['@media print']: {
                  margin: '3rem',
                },
              }}
              ref={recipeRef}
            >
              <p dangerouslySetInnerHTML={reformatStringToHtml(recipe)} />
            </Box>
            <HStack justifyContent="end" mt={2}>
              <Button
                size="md"
                colorScheme="blue"
                onClick={() => {
                  handlePrint();
                }}
              >
                {t('SHOPPING.MEAL_BUILDER_RECIPE.PRINT_RECIPE')}
              </Button>
            </HStack>
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
};
