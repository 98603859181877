import { ButtonGroup, Grid, GridItem, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { CardErrorBoundary } from 'components/ErrorMessages';
import { sub } from 'date-fns';
import type { TSalesPrediction } from 'domain/predictions';
import { TStockInsights } from 'domain/stocks';
import { TStoreId } from 'domain/stores';
import { pipe } from 'fp-ts/function';
import { useAppConfig } from 'hooks';
import React, { useCallback, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChartBar, FaScribd, FaScroll } from 'react-icons/fa';
import { createStateContext } from 'react-use';
import { dateToYMD, getCurrentDayOfWeek } from 'utils/date';
import { StockCalendar } from './StockCalendar';
import { StockInsights } from './StockInsights';
import { StockPointsChart } from './StockPointsChart';

type StockInfoProps = {
  productCode: string;
  insights: TStockInsights;
  historicalData?: TSalesPrediction;
  storeId: TStoreId;
};

type VisabilityState = {
  isChartVisible: boolean;
  isHistoryVisible: boolean;
  isInsightsVisible: boolean;
};

const DEFAULT_VISABILITY_STATE: VisabilityState = {
  isChartVisible: false,
  isHistoryVisible: false,
  isInsightsVisible: false,
};

export const StockInfoTabs: VFC<StockInfoProps> = ({ productCode, insights, historicalData, storeId }) => {
  const { t } = useTranslation('whisperme');
  const {
    dateOptions: { locale },
  } = useAppConfig();

  const getSoldDateFormat = useCallback(
    (days: number, withYMD = false) => {
      return pipe(
        sub(Date.now(), { days }),
        (date) => getCurrentDayOfWeek(date, locale) + (withYMD ? ` (${dateToYMD(date)}):` : ':'),
      );
    },
    [locale],
  );

  return (
    <Tabs isLazy={true} isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab>{t('MORNING_TAKS.STOCKS_TABLE.INSIGHTS')}</Tab>
        <Tab>Availability</Tab>
        <Tab>{t('MORNING_TAKS.STOCKS_TABLE.HISTORY')}</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <StockInsights insights={insights} />
          {historicalData && (
            <>
              <Grid templateColumns="repeat(4, 1fr)">
                <GridItem>
                  <Grid templateColumns="1fr 30px" maxW="14rem" justifyItems="space-between" w="100%">
                    <GridItem as="span" colSpan={2} fontWeight="bold">
                      {t('MORNING_TAKS.SINGLE_CARD.SOLD.SOLD_LABEL')}
                    </GridItem>
                    <GridItem as="span">{getSoldDateFormat(1, true)}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.yesterday}
                    </GridItem>
                    <GridItem as="span">{getSoldDateFormat(7, true)}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.from_7_days_ago}
                    </GridItem>
                    <GridItem as="span">{getSoldDateFormat(14, true)}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.from_14_days_ago}
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem>
                  <Grid templateColumns="1fr 80px" maxW="12rem" w="100%">
                    <GridItem as="span" colSpan={2} fontWeight="bold">
                      {t('MORNING_TAKS.SINGLE_CARD.SALES_RANGE_LABEL')}
                    </GridItem>
                    <GridItem as="span">{t('MORNING_TAKS.SINGLE_CARD.DATE_RANGE.LAST_30_DAYS')}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.last_30_days.min} - {historicalData.sales_qty.last_30_days.max}
                    </GridItem>
                    <GridItem as="span">{t('MORNING_TAKS.SINGLE_CARD.DATE_RANGE.LAST_90_DAYS')}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.last_90_days.min} - {historicalData.sales_qty.last_90_days.max}
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem>
                  <Grid templateColumns="1fr 30px" maxW="10rem" justifyItems="space-between" w="100%">
                    <GridItem as="span" colSpan={2} fontWeight="bold">
                      {t('MORNING_TAKS.SINGLE_CARD.WASTED.WASTED_LABEL')}
                    </GridItem>
                    <GridItem as="span">{getSoldDateFormat(1, true)}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.yesterday}
                    </GridItem>
                    <GridItem as="span">{t('MORNING_TAKS.SINGLE_CARD.WASTED.LAST_30_DAYS')}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.sales_qty.last_30_days.max}
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem>
                  <Grid templateColumns="1fr 30px" w="100%" maxW="10rem">
                    <GridItem as="span" colSpan={2} fontWeight="bold">
                      {t('MORNING_TAKS.SINGLE_CARD.STOCK_SHORTAGE_LABEL')}
                    </GridItem>
                    <GridItem as="span">{t('MORNING_TAKS.SINGLE_CARD.DATE_RANGE.LAST_30_DAYS')}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.shortages_count.last_30_days}
                    </GridItem>
                    <GridItem as="span">{t('MORNING_TAKS.SINGLE_CARD.DATE_RANGE.LAST_90_DAYS')}</GridItem>
                    <GridItem as="strong" textAlign="right">
                      {' '}
                      {historicalData.shortages_count.last_90_days}
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </>
          )}
        </TabPanel>

        <TabPanel>
          <StockPointsChart productCode={productCode} storeId={storeId} />
        </TabPanel>

        <TabPanel>
          <CardErrorBoundary>
            <StockCalendar productCode={productCode} />
          </CardErrorBoundary>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const [useStockInfoCollapseContext, StockInfoCollapseProvider] =
  createStateContext<VisabilityState>(DEFAULT_VISABILITY_STATE);

export { StockInfoCollapseProvider };

export const useVisability = () => {
  const [state, setState] = useStockInfoCollapseContext();

  const createVisabilitySetter = (key: keyof VisabilityState) => (value: boolean) =>
    setState({ ...DEFAULT_VISABILITY_STATE, [key]: value });

  return [
    state,
    {
      setChartVisability: createVisabilitySetter('isChartVisible'),
      setHistoryVisability: createVisabilitySetter('isHistoryVisible'),
      setInsightsVisability: createVisabilitySetter('isInsightsVisible'),
    },
  ] as const;
};

export const StockInfoButtons: VFC = () => {
  const [
    { isChartVisible, isHistoryVisible, isInsightsVisible },
    { setChartVisability, setInsightsVisability, setHistoryVisability },
  ] = useVisability();

  const { t } = useTranslation('whisperme');

  return (
    <ButtonGroup size="sm" isAttached variant="outline">
      <IconButton
        onClick={() => setChartVisability(!isChartVisible)}
        {...{ 'aria-label': t('COMPONENTS.CHARTS.TOGGLE_CHART') }}
      >
        <FaChartBar />
      </IconButton>
      <IconButton onClick={() => setHistoryVisability(!isHistoryVisible)} aria-label="Toggle history">
        <FaScribd />
      </IconButton>
      <IconButton onClick={() => setInsightsVisability(!isInsightsVisible)} aria-label="Toggle insights">
        <FaScroll />
      </IconButton>
    </ButtonGroup>
  );
};

export const StockInfoCollapse: VFC<StockInfoProps> = ({ productCode, insights, storeId }) => {
  const { isChartVisible, isHistoryVisible, isInsightsVisible } = useVisability()[0];

  return (
    <>
      {isChartVisible && <StockPointsChart productCode={productCode} storeId={storeId} />}
      {isInsightsVisible && <StockInsights insights={insights} />}
      {isHistoryVisible && (
        <CardErrorBoundary>
          <StockCalendar productCode={productCode} />
        </CardErrorBoundary>
      )}
    </>
  );
};
