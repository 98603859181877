import React, { useMemo } from 'react';
import { BarGroup, AnimatedBarGroup, BarStack, AnimatedBarStack, BarSeries, AnimatedBarSeries } from '@visx/xychart';
import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { dateToYMD } from 'utils/date';
import { ChartProps } from './types';
import { ParentRect } from './ParentRect';
import { nullAccessor, xAccessor as xAccessorDefault, yIndexAccessor } from './utils';
import { BaseXYChart } from './BaseXYChart';
import { useInteractiveChartMetrics } from './useInteractiveChartMetrics';
import { ChartDataTooltip, ChartDataTooltipProps } from './ChartDataTooltip';
import { ChartLegendOrdinal } from './ChartLegendOrdinal';
import { ChartLoadingOverlay } from './ChartLoadingOverlay';
import { theme as defaultTheme } from './chartThemes';

type SeriesProps = React.ComponentPropsWithoutRef<typeof BarSeries>;
type BaseXYChartProps = React.ComponentPropsWithoutRef<typeof BaseXYChart>;

export interface BarChartProps
  extends ChartProps,
    Partial<BaseXYChartProps>,
    Pick<ChartDataTooltipProps, 'formatValue' | 'renderTooltip'> {
  minHeight?: string | number;
  seriesProps?: Partial<Omit<SeriesProps, 'data'>>;
  isLoading?: boolean;
  animated?: boolean;
  groupVariant?: 'group' | 'stack';
  barGroupProps?: Partial<React.ComponentPropsWithoutRef<typeof BarGroup>>;
  barStackProps?: Partial<React.ComponentPropsWithoutRef<typeof BarStack>>;
}

export const BarChart: React.FC<BarChartProps> = ({
  chartData: { data: initialData, chartMetrics, xLabel, yLabel },
  minHeight = '200px',
  children,
  seriesProps,
  isLoading,
  animated = true,
  theme = defaultTheme,
  formatValue,
  groupVariant = 'group',
  barGroupProps,
  barStackProps,
  renderTooltip,
  ...rest
}) => {
  const { metrics, toggleMetricByIndex, isMetricVisibleByIndex, legendScale, canAnimate } = useInteractiveChartMetrics(
    chartMetrics,
    { animated, colors: theme.colors },
  );
  const isGrouped = chartMetrics.length > 1;
  const BarSeriesComponent = canAnimate ? AnimatedBarSeries : BarSeries;

  // NOTE(m.kania): time scale doesn't allow for padding between vertical axis and bars, so it doesn't look to good
  // that's why I think it's better to just convert it manually
  const data = useMemo(
    () =>
      initialData.map((d) => ({
        ...d,
        x: d.x instanceof Date ? dateToYMD(d.x) : d.x,
      })),
    [initialData],
  );

  const xAccessor = typeof seriesProps?.xAccessor === 'function' ? seriesProps?.xAccessor : xAccessorDefault;
  const barSeries = metrics.map(({ dataKey, visible, index }) => {
    const yAccessor = typeof seriesProps?.yAccessor === 'function' ? seriesProps?.yAccessor : yIndexAccessor(index);

    return (
      <BarSeriesComponent
        key={dataKey}
        dataKey={dataKey}
        data={data}
        {...seriesProps}
        xAccessor={xAccessor}
        // NOTE(m.kania): it's easier to draw 'null' series in order to keep legend/series colors 'in sync'
        yAccessor={visible ? yAccessor : nullAccessor}
      />
    );
  });

  let series: React.ReactNode = barSeries;
  if (isGrouped) {
    if (groupVariant === 'group') {
      const BarGroupComponent = canAnimate ? AnimatedBarGroup : BarGroup;
      series = <BarGroupComponent {...barGroupProps}>{barSeries}</BarGroupComponent>;
    } else if (groupVariant === 'stack') {
      const BarStackComponent = canAnimate ? AnimatedBarStack : BarStack;
      series = <BarStackComponent {...barStackProps}>{barSeries}</BarStackComponent>;
    }
  }

  return (
    <Flex direction="column" width="100%" height="100%" flexGrow={1} position="relative" gap="4">
      {legendScale && (
        <ChartLegendOrdinal
          scale={legendScale}
          onLegendItemClick={toggleMetricByIndex}
          isMetricVisible={isMetricVisibleByIndex}
        />
      )}
      <ParentRect minHeight={minHeight}>
        {({ width, height }) => (
          <>
            <BaseXYChart width={width} height={height} xLabel={xLabel} yLabel={yLabel} theme={theme} {...rest}>
              {series}
              {/* NOTE(m.kania): glyphs on hover don't make much sense for grouped bar charts (very noisy) */}
              <ChartDataTooltip
                metrics={metrics}
                showSeriesGlyphs={!isGrouped}
                formatValue={formatValue}
                renderTooltip={renderTooltip}
              />
              {children}
            </BaseXYChart>
            <AnimatePresence>{isLoading && <ChartLoadingOverlay />}</AnimatePresence>
          </>
        )}
      </ParentRect>
    </Flex>
  );
};
