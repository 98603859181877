import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import * as PATHS from 'constants/paths';
import { Card, CardTitleSection } from 'components/Card';
import { createRole } from 'domain/roles';
import { StoreRolesForm } from './components';
import { createRoleDetailsKey, createRolesListKey } from 'constants/queryCacheKeys';

interface Props {}

export const RoleNewPage: React.VFC<Props> = () => {
  const { t } = useTranslation('whisperme');
  const { mutateAsync } = useMutation(createRole);
  const queryClient = useQueryClient();
  const { push } = useHistory();

  const goBackLink = PATHS.SETTINGS.ROLES.ROOT.toPath();

  return (
    <Card>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {t('SETTINGS.ROLES.ROLE_FORM.ADD_NEW_ROLE_LABEL')}
      </CardTitleSection>
      <StoreRolesForm
        cancelButtonLink={goBackLink}
        onSubmit={(roleDetails) =>
          mutateAsync(
            { roleDetails },
            {
              onSuccess: ({ id }) => {
                queryClient.setQueryData(createRoleDetailsKey({ roleId: id }), roleDetails);
                queryClient.invalidateQueries(createRolesListKey());
                push(PATHS.SETTINGS.ROLES.DETAILS.toPath({ roleId: id }));
              },
            },
          )
        }
      />
    </Card>
  );
};
