import { useInfiniteQuery } from 'react-query';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

import { TGetProductListParams } from 'domain/products';
import { getProductListWithParams } from './utils';
import { createProductsListParamKey } from 'constants/queryCacheKeys';

const DEFAULT_LIMIT = 100;

const initialParams: TGetProductListParams = {
  limit: DEFAULT_LIMIT,
  paging_forward: true,
  paging_bookmark: 0,
};

export const useInfiniteProductList = (params: TGetProductListParams) =>
  useInfiniteQuery(
    createProductsListParamKey(params),
    (newParams) => getProductListWithParams({ ...initialParams, ...params, ...newParams.pageParam }),
    {
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 5000,
      getNextPageParam: (last): TGetProductListParams | undefined => {
        if (last.data.length < (last.params.limit ?? DEFAULT_LIMIT)) {
          // NOTE(m.kania): no new data
          return undefined;
        }

        return {
          ...last.params,
          paging_bookmark: pipe(
            last.data,
            A.last,
            O.fold(
              () => undefined,
              (product) => product.product_code,
            ),
          ),
        };
      },
    },
  );
