import React from 'react';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { createRoleDeleteKey } from 'constants/queryCacheKeys';
import { deleteRole, TRoleList, TRole } from 'domain/roles';
import { useTranslation } from 'react-i18next';
import { TRoleProps } from './RolesListPage';
import { useRoleDetailsMap } from 'hooks/data';

interface Props {
  role: TRoleProps;
  onClose: () => void;
}

const RoleDeleteConfirmationModalComponent: React.VFC<Props> = ({ role, onClose }) => {
  const toast = useToast();
  const { t } = useTranslation('whisperme');
  const queryClient = useQueryClient();
  const { roleId } = role;
  const roleMap = useRoleDetailsMap();
  const roleName: string = pipe(
    O.fromNullable(roleId),
    O.chain((id) => O.fromNullable(roleMap.get(id)?.name)),
    O.getOrElse(() => ''),
  );

  const { mutateAsync, status } = useMutation(deleteRole, {
    retry: false,
    onMutate: () => {
      const rolesSnapshot = queryClient.getQueryData<TRoleList>(['roles', 'list']);
      if (rolesSnapshot) {
        const filteredRoles = rolesSnapshot.items.filter((item: TRole) => item.id !== roleId);
        queryClient.cancelQueries(createRoleDeleteKey({ roleId: roleId }));
        queryClient.setQueryData(['roles', 'list'], {
          ...rolesSnapshot,
          items: [...filteredRoles],
        });
      }
      onClose();
      return { rolesSnapshot };
    },
    onError: (_err, _var, context) => {
      if (context?.rolesSnapshot) {
        queryClient.setQueryData(['roles', 'list'], context.rolesSnapshot);
      }
      toast({
        title: 'Error: Failed to delete role',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onSuccess: (_err, _var, context) => {
      if (context?.rolesSnapshot) {
        const deletedRole = context.rolesSnapshot.items.filter((item: TRole) => item.id === roleId);
        toast({
          title: `Role ${deletedRole[0].role.name} (role id: ${roleId}) deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Modal isOpen={roleId !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('SETTINGS.ROLES.ROLE_DELETE_MODAL.TITLE', { value: roleName })}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="span">{t('SETTINGS.ROLES.ROLE_DELETE_MODAL.HINT_TEXT')}</Text>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="ghost" onClick={onClose}>
              {t('SETTINGS.ROLES.ROLE_DELETE_MODAL.CANCEL_BUTTON_LABEL')}
            </Button>
            <Button
              colorScheme="red"
              isLoading={status === 'loading'}
              onClick={() => {
                if (roleId !== null) {
                  mutateAsync({ roleId: roleId });
                }
              }}
            >
              {t('SETTINGS.ROLES.ROLE_DELETE_MODAL.DELETE_BUTTON_LABEL')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const RoleDeleteConfirmationModal = React.memo(RoleDeleteConfirmationModalComponent);
