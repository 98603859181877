import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import {
  TClusterMap,
  TClusterMapGroupIdEntry,
  TClusterMapGroup,
  TClusterMapStorePositionCoordinates,
  TClusterId,
  TMapLayer,
  TCharacteristicVariant,
  CHARACTERISTICS_KEYS,
  CHARACTERISTICS,
} from './types';

export const getClusterMapGroupIds = (clusterMap: TClusterMap): TClusterMapGroupIdEntry[] =>
  pipe(
    clusterMap,
    A.reduce(new Set<TClusterMapGroupIdEntry>(), (acc, row) => {
      row.forEach((groupEntry) => {
        if (!acc.has(groupEntry)) {
          acc.add(groupEntry);
        }
      });

      return acc;
    }),
    (entriesSet) => Array.from(entriesSet),
    A.sort(N.Ord),
  );

export interface TClusterMapDimensions {
  mapHeight: number;
  mapWidth: number;
}
export const getClusterMapDimensions = (clusterMap: TClusterMap | TMapLayer): TClusterMapDimensions => ({
  mapHeight: clusterMap.length,
  mapWidth: pipe(
    clusterMap,
    A.head,
    O.fold(
      () => 0,
      (row) => row.length,
    ),
  ),
});

export const getClusterGroupName = (clusterGroup: TClusterMapGroup) =>
  pipe(
    clusterGroup.description,
    O.getOrElse(() => String(clusterGroup.group_id)),
  );

export const clusterIdToCoordinates =
  ({ mapWidth }: TClusterMapDimensions) =>
  (clusterId: TClusterId): TClusterMapStorePositionCoordinates => {
    const x = clusterId % mapWidth;
    const y = (clusterId - x) / mapWidth;

    return { x, y };
  };

export const coordinatesToClusterId =
  ({ mapWidth }: TClusterMapDimensions) =>
  ({ x, y }: TClusterMapStorePositionCoordinates): TClusterId =>
    y * mapWidth + x;

// NOTE(m.kania): my idea was that truthy values mean that given key 'has translation and/or formatting'
export const isSupportedCharacteristic = (v: unknown): v is TCharacteristicVariant =>
  CHARACTERISTICS_KEYS.includes(v as any);

export const BASIC_KPI_KEYS: TCharacteristicVariant[] = [
  CHARACTERISTICS.NUMBER_OF_RECEIPTS,
  CHARACTERISTICS.AVERAGE_CART,
  CHARACTERISTICS.DISCOUNT_RATIO,
  CHARACTERISTICS.REGIONAL_RATIO,
];

export const EXTRA_KPI_KEYS: TCharacteristicVariant[] = CHARACTERISTICS_KEYS.filter((c) => !BASIC_KPI_KEYS.includes(c));

export const isPercentageCharacteristic = (key: TCharacteristicVariant) =>
  [CHARACTERISTICS.DISCOUNT_RATIO, CHARACTERISTICS.REGIONAL_RATIO].includes(key as any);
export const isNumberCharacteristic = (key: TCharacteristicVariant) =>
  [
    CHARACTERISTICS.NUMBER_OF_DISCOUNTS,
    CHARACTERISTICS.NUMBER_OF_ITEMS,
    CHARACTERISTICS.NUMBER_OF_RECEIPTS,
    CHARACTERISTICS.NUMBER_OF_REGIONAL_ITEMS,
  ].includes(key as any);
