import { useMemo } from 'react';
import { useStoresData } from './data';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { useUserStoreIds } from './useUserContext';
import { ItemsMap, Item, ItemId } from 'components/Select';
import { TStoreId, TStore } from 'domain/stores';

/**
 * Parse stores ids and stores data to create MultiSelectCheckbox Items
 * @param values contains default value for shop ids
 * @return items for checkboxes
 */
export const useUserStoresAsItems = (values?: Array<TStoreId>): ItemsMap => {
  const storesId = useUserStoreIds();
  const storesData = useStoresData();

  return useMemo(() => {
    return pipe(
      storesData,
      A.reduce<TStore, (readonly [ItemId, Item])[]>([], (acc, { id, name }) => {
        if (storesId.includes(id)) acc.push([id, { label: name, value: (values && values.includes(id)) ?? true }]);

        return acc;
      }),
      (array) => new Map(array),
    );
  }, [storesId, storesData, values]);
};
