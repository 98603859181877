import * as t from 'io-ts';

import { StoreIdValidator } from '../stores';
import { InsightDetailsValidator } from './insight-tag-types';

export const INSIGHT_TYPE = {
  TOP: 'top',
  LOW: 'low',
  GROW: 'grow',
  DROP: 'drop',
  SHORTAGE: 'shortage',
} as const;
export type TInsightType = TInsight['type'];
export const INSIGHT_TYPE_VALUES = Object.values(INSIGHT_TYPE);

export const InsightValidator = t.type({
  date: t.string,
  store_ids: t.array(StoreIdValidator),
  type: t.union([
    t.literal(INSIGHT_TYPE.TOP),
    t.literal(INSIGHT_TYPE.LOW),
    t.literal(INSIGHT_TYPE.GROW),
    t.literal(INSIGHT_TYPE.DROP),
    t.literal(INSIGHT_TYPE.SHORTAGE),
  ]),
  details: InsightDetailsValidator,
});

export type TInsight = t.TypeOf<typeof InsightValidator>;

export const InsightArrayValidator = t.array(InsightValidator);
export type TInsightArray = t.TypeOf<typeof InsightArrayValidator>;
