import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridProps, HStack, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { getProductsFamiliesStats } from 'domain/products';
import { createProductFamiliesStats } from 'constants/queryCacheKeys';
import { OneLineSkeleton } from 'components/Skeletons';
import { pipe } from 'fp-ts/function';
import { map } from 'fp-ts/Array';

export const DEFAULT_MARGIN = { top: 20, left: 40, right: 25, bottom: 20 };

export interface TProductFamiliesStatsProps {
  children?: React.ReactNode;
}

const Stats: React.FC<{ maxW: GridProps['maxW']; stats: [string, number][] }> = ({ stats, maxW }) => {
  return (
    <Grid gridTemplateColumns="1fr 80px" w="100%" maxW={maxW}>
      {pipe(
        stats,
        map(([label, value]) => (
          <React.Fragment key={label}>
            <Text fontSize="xs" fontWeight="bold" textAlign="justify">
              {label}:
            </Text>
            <Text fontSize="xs" textAlign="right">
              {value}
            </Text>
          </React.Fragment>
        )),
      )}
    </Grid>
  );
};

export const ProductFamiliesStats: React.FC<TProductFamiliesStatsProps> = () => {
  const { t } = useTranslation('whisperme');

  const { data, isFetching } = useQuery(createProductFamiliesStats(), () => getProductsFamiliesStats());

  if (!data || isFetching) {
    return <OneLineSkeleton />;
  }

  return (
    <HStack spacing={1} justifyContent="space-between" alignItems="center" marginBottom={3} paddingX={1} wrap="wrap">
      <Stats
        maxW="25rem"
        stats={[
          [t('SETTINGS.PRODUCT_FAMILIES.ALL_PRODUCTS_LABEL'), data.all_products],
          [t('SETTINGS.PRODUCT_FAMILIES.UNKNOWN_CATEGORY_PRODUCTS_LABEL'), data.products_unknown_category],
          [t('SETTINGS.PRODUCT_FAMILIES.REGIONL_PRODUCTS_LABEL'), data.is_regional],
          [t('SETTINGS.PRODUCT_FAMILIES.BIO_PRODUCTS_LABEL'), data.is_bio],
        ]}
      />
      <Stats
        maxW="30rem"
        stats={[
          [t('SETTINGS.PRODUCT_FAMILIES.NEW_LAST_DAYS_LABEL', { number: 30 }), data.last_30_days],
          [
            t('SETTINGS.PRODUCT_FAMILIES.NEW_UNKNOWN_LAST_DAYS_LABEL', { number: 30 }),
            data.last_30_days_unknown_category,
          ],
        ]}
      />
      <Stats
        maxW="30rem"
        stats={[
          [t('SETTINGS.PRODUCT_FAMILIES.NEW_LAST_DAYS_LABEL', { number: 3 }), data.last_3_days],
          [
            t('SETTINGS.PRODUCT_FAMILIES.NEW_UNKNOWN_LAST_DAYS_LABEL', { number: 3 }),
            data.last_3_days_unknown_category,
          ],
        ]}
      />
    </HStack>
  );
};
