import React from 'react';
import { HStack } from '@chakra-ui/react';
import { DESKTOP_BREAKPOINT } from 'constants/css';

interface Props extends React.ComponentPropsWithoutRef<typeof HStack> {}

export const DashboardTopbarContainer: React.FC<Props> = ({ children, ...rest }) => (
  <HStack
    as="header"
    justifyContent="space-between"
    spacing={{
      base: 2,
      [DESKTOP_BREAKPOINT]: 5,
    }}
    {...rest}
  >
    {children}
  </HStack>
);
