import { createCustomersPredictionKey } from 'constants/queryCacheKeys';
import { SectionPredictionParams, getCustomersPrediction } from 'domain/predictions';
import { useQuery } from 'react-query';

export const useCustomersSectionPredictionData = ({
  date,
  store_id,
  section_id,
}: {
  date?: SectionPredictionParams['date'];
  store_id: SectionPredictionParams['store_id'];
  section_id?: SectionPredictionParams['section_id'];
}) => {
  const params: SectionPredictionParams = {
    date: date,
    store_id: store_id,
    section_id: section_id,
  };

  return useQuery(createCustomersPredictionKey(params), () => getCustomersPrediction(params), { suspense: true });
};
