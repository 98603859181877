export const CELL_DATA_VARIANT = {
  OK: 'ok',
  POSSIBLE: 'possible',
  ERROR: 'error',
  EMPTY: 'empty',
} as const;

export type CellDataVariant = (typeof CELL_DATA_VARIANT)[keyof typeof CELL_DATA_VARIANT];
export interface DataMapEntry {
  morning: CellDataVariant;
  afternoon: CellDataVariant;
  evening: CellDataVariant;
}
