import React, { Suspense, useState } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { DESKTOP_BREAKPOINT, GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import * as PATHS from 'constants/paths';
import { TStoreId } from 'domain/stores';
import { PageSpinner } from 'components/Spinners';
import { useMachine } from '@xstate/react';
import { EObjectiveMode, PROBABILITY_THRESHOLD } from '../components';
import { InventoryShortageChartCard } from './InventoryShortageChartCard';
import { InventoryShortageMostAffectedProductsCard } from './InventoryShortageMostAffectedProductsCard';
import { DateRangeChangePayload, DateRangePickerKind } from 'components/DateRangePicker/DateRangePicker';
import { useCalendarDateRange } from 'hooks';
import { mostAffectedProductsMachine } from './mostAffectedProductsMachine';
import { TFamilyCode, TProductCode } from 'domain/core';
import { InventoryShortageBySectionsCalendar } from './InventoryShortageBySectionsCalendar';
import { ShortagesFilters } from 'components/ShortagesFilters';

interface Props {
  storeId: TStoreId;
}

export type DateRangeContextType = {
  shortageCalendarProps: DateRangePickerKind;
  shortageDateParams: DateRangeChangePayload;
};

export const ObjectivesInventoryShortagePage: React.FC<Props> = ({ storeId }) => {
  const [probablity, setProbablity] = useState<number>(PROBABILITY_THRESHOLD);
  const { calendarProps, dateParams } = useCalendarDateRange();
  const shortageDateRangeContext: DateRangeContextType = {
    shortageCalendarProps: calendarProps,
    shortageDateParams: dateParams,
  };

  const [state, send] = useMachine(mostAffectedProductsMachine);
  const [selectedStoreId, setSelectedStoreId] = useState<TStoreId>(storeId);

  return (
    <Grid
      templateColumns={{
        base: '100%',
        [DESKTOP_BREAKPOINT]: 'repeat(3, minmax(250px, 1fr))',
      }}
      gap={GRID_GAP_DEFAULT_VALUE}
    >
      <GridItem
        colSpan={{
          [DESKTOP_BREAKPOINT]: 3,
        }}
      >
        <ShortagesFilters
          storeId={selectedStoreId}
          probabilityThreshold={probablity}
          setProbabilityThreshold={setProbablity}
          onSelectStore={setSelectedStoreId}
          dateRange={shortageDateRangeContext}
          stockState={state.context}
          setFamily={(family: TFamilyCode) => send({ type: 'SET_FAMILY', payload: family })}
          setSection={(section: TFamilyCode) => send({ type: 'SET_SECTION', payload: section })}
          clearFamily={() => send({ type: 'CLEAR_FAMILY' })}
          clearSection={() => send({ type: 'CLEAR_SECTION' })}
          variant="inventory"
        />
      </GridItem>
      <GridItem
        colSpan={{
          [DESKTOP_BREAKPOINT]: 3,
        }}
      >
        <InventoryShortageChartCard
          storeId={selectedStoreId}
          stockState={state.context}
          dateRange={shortageDateRangeContext}
          probabilityThreshold={probablity}
          backButtonLink={PATHS.OBJECTIVES.ROOT.toPath()}
          variant={EObjectiveMode.SET_OBJECTIVE}
        />
      </GridItem>
      <GridItem
        colSpan={{
          [DESKTOP_BREAKPOINT]: 3,
        }}
      >
        <InventoryShortageBySectionsCalendar
          storeId={selectedStoreId}
          dateRange={shortageDateRangeContext}
          stockState={state.context}
          probabilityThreshold={probablity}
        />
      </GridItem>
      <GridItem
        colSpan={{
          [DESKTOP_BREAKPOINT]: 3,
        }}
      >
        <Suspense fallback={<PageSpinner />}>
          <InventoryShortageMostAffectedProductsCard
            probabilityThreshold={probablity}
            storeId={selectedStoreId}
            stockState={state.context}
            dateRange={shortageDateRangeContext}
            selectProduct={(payload: TProductCode) => send({ type: 'SELECT_PRODUCT', payload })}
            deselectProduct={() => send({ type: 'DESELECT_PRODUCT' })}
          />
        </Suspense>
      </GridItem>
    </Grid>
  );
};
