import React from 'react';
import { Select, SelectProps } from '@chakra-ui/react';

import { TProductFamily } from 'domain/product-family';
import { itemIdFormatter } from './utils';

interface Props extends SelectProps {
  items: TProductFamily[];
  formatLabel?: (item: TProductFamily) => string;
  disabledPlaceholder?: boolean;
  disabled?: boolean;
}

const ProductFamiliesFamilyCodeSelectComponent: React.VFC<Props> = React.forwardRef<HTMLSelectElement, Props>(
  ({ items, formatLabel = itemIdFormatter, placeholder, disabledPlaceholder, disabled, ...rest }, ref) => (
    <Select size="xs" variant="outline" isDisabled={disabled} {...rest} bg="#fff" ref={ref}>
      {placeholder && (
        <option value="" disabled={disabledPlaceholder}>
          {placeholder}
        </option>
      )}
      {items.map((s) => (
        <option key={s.id} value={s.id}>
          {formatLabel(s)}
        </option>
      ))}
    </Select>
  ),
);

export const ProductFamiliesFamilyCodeSelect = React.memo(ProductFamiliesFamilyCodeSelectComponent);
