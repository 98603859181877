import { useTranslation } from 'react-i18next';
import { dateToYMD, getCurrentDate } from 'utils/date';

export const useChartDateRangeLabel = (startDate: Date, endDate: Date | null): string => {
  const { t } = useTranslation('whisperme');

  return t('OBJECTIVES.COMMON.DATE_RANGE_LABEL', {
    startDate: dateToYMD(startDate),
    endDate: dateToYMD(endDate ?? getCurrentDate()),
  });
};
