const ROOT_PATH = '/assortment-management' as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const SUPERMARKET_SELECTED_ASSORTMENT_PATH = `${ROOT_PATH}/supermarket-selected-assortment` as const;

export const SUPERMARKET_SELECTED_ASSORTMENT = {
  PATH: SUPERMARKET_SELECTED_ASSORTMENT_PATH,
  toPath: () => SUPERMARKET_SELECTED_ASSORTMENT_PATH,
};
