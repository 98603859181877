import { Card, CardTitleSection } from 'components/Card';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Text,
  Textarea,
  TextProps,
  useToast,
} from '@chakra-ui/react';
import { ParentSize } from '@visx/responsive';
import { Radio, RadioGroup } from '@chakra-ui/react';
import React, { VFC, useCallback, useState, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { useNeoEmailProductSearch } from 'hooks/data';
import { ProductSelectElement } from './components';
import { useUserSections, useUserStoreIds } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';
import { postCreateCampaigne } from 'domain/neoemail/service';
import { createNeoEmailCampaigneHistoryKey, createNeoEmailMapMainKey } from 'constants/queryCacheKeys';
import { useNeoEmailPointsData } from './hooks/useNeoEmailPointsData';
import { NeoEmailCustomersMapFilter } from 'pages/AnalyticsPage/CustomerMapPage/components/NeoEmailCustomersMapFilter';
import { PageSpinner } from 'components/Spinners';
import styled from '@emotion/styled';
import { TNeoEmailProduct } from 'domain/neoemail';

const Map = React.lazy(() => import('pages/AnalyticsPage/CustomerMapPage/components/Map'));

const StyledBox = styled(Card)`
  border: 0 none;
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - 400px);
  padding: 0;
  overflow: hidden;
  flex-grow: 1;

  .y-axis,
  .x-axis {
    visibility: hidden;
  }
`;

type CustomerSourceType = 'type_in' | 'algorithm';

const Title: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text color="grey.500" fontSize="md" fontWeight="bold" {...rest}>
      {children}
    </Text>
  );
};

type CreateEmailFormData = {
  maybeEan: string;
  promotion: number;
  days: number;
  ids: string;
  maxCustomers: number;
};

export const CreateEmail: VFC = () => {
  const { t } = useTranslation('whisperme');
  const queryClient = useQueryClient();
  const toast = useToast();
  const createFormDefaults = {
    defaultValues: {
      maybeEan: '',
      promotion: 10,
      days: 3,
      ids: '',
      maxCustomers: 1,
    },
  };
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateEmailFormData>(createFormDefaults);

  const userStores = useUserStoreIds();
  const userSections = useUserSections();
  const storeId = userStores[0];

  const [product, setProduct] = useState<TNeoEmailProduct | undefined>(undefined);
  const [customerSourceType, setCustomerSourceType] = React.useState<CustomerSourceType>('type_in');
  const maybeEan = watch('maybeEan');
  const maxCustomers = watch('maxCustomers');
  const { data, isFetching } = useNeoEmailProductSearch(
    maybeEan,
    storeId,
    userSections.length ? userSections[0].id : undefined,
  );
  const [points, selected, pointData, { isFetching: isNeoEmailPointFetching }] = useNeoEmailPointsData(
    storeId,
    maxCustomers,
    (max: number) => setValue('maxCustomers', max),
    product?.product_code,
  );
  const { mutate, isLoading } = useMutation(postCreateCampaigne, {
    onSuccess: () => {
      reset();
      setProduct(undefined);
      toast({
        title: t('NEOEMAIL.CREATE.SUCCESS'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      queryClient.invalidateQueries(
        createNeoEmailCampaigneHistoryKey({ store_id: userStores[0], section_id: userSections[0]?.id }),
      );
    },
    onError: () => {
      toast({
        title: t('NEOEMAIL.CREATE.ERROR'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    },
  });
  const color = 'red';
  const colorScale = useCallback(
    (point: any) => {
      return point.color || color;
    },
    [color],
  );

  const onSubmit = useCallback(
    (values) => {
      const customers =
        customerSourceType === 'type_in'
          ? values.ids.split(/[\t\n\s,]+/).filter((id: string) => id.length > 0)
          : selected.filter((id) => !!id);

      if (!product) {
        toast({
          title: t('NEOEMAIL.CREATE.PRODUCT.MISSING'),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      mutate({
        product_code: product.product_code,
        promotion_pct: values.promotion,
        duration: values.days,
        customer_ids: customers,
        store_id: userStores[0],
      });
    },
    [data, product, userStores, mutate, toast, selected, customerSourceType, t],
  );

  return (
    <Card>
      <CardTitleSection>{t('NEOEMAIL.CREATE.TITLE')}</CardTitleSection>
      <Grid gridTemplateColumns="500px calc(100vw - 650px)" minH="700px" height="calc(100vh - 230px)" gap={3}>
        <GridItem>
          <Stack rowGap={3}>
            <Title>{t('NEOEMAIL.CREATE.PRODUCTS.TITLE')}</Title>
            <FormControl id="product-promo" isInvalid={Boolean(errors.promotion)}>
              <FormLabel>{t('NEOEMAIL.CREATE.PRODUCTS.PROMOTION')}</FormLabel>
              <NumberInput
                step={1}
                defaultValue={10}
                min={1}
                max={100}
                onChange={(_, value) => setValue('promotion', value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {errors.promotion && errors.promotion.message && (
                <FormErrorMessage>{errors.promotion.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="products-days" isInvalid={Boolean(errors.days)}>
              <FormLabel>{t('NEOEMAIL.CREATE.PRODUCTS.DAYS')}</FormLabel>
              <NumberInput step={1} defaultValue={3} min={1} max={366} onChange={(_, value) => setValue('days', value)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {errors.days && errors.days.message && <FormErrorMessage>{errors.days.message}</FormErrorMessage>}
            </FormControl>
            <FormControl id="product-ean">
              <FormLabel>{t('NEOEMAIL.CREATE.PRODUCTS.EAN')}</FormLabel>

              <InputGroup>
                <Input {...register('maybeEan')} />
              </InputGroup>
            </FormControl>
            <Stack alignItems="center" justifyItems="center">
              <Grid gridTemplateColumns="1fr">
                <Text fontWeight="500" mb={2}>
                  {t('NEOEMAIL.CREATE.PRODUCT.SELECTED')}
                </Text>

                {product ? (
                  <ProductSelectElement
                    variant="remove"
                    product={product}
                    onClick={() => {
                      setProduct(() => {
                        setValue('maxCustomers', 0);
                        queryClient.invalidateQueries(createNeoEmailMapMainKey());
                        return undefined;
                      });
                    }}
                    buttonLabel={t('NEOEMAIL.CREATE.PRODUCT.REMOVE')}
                    paddingY={1}
                    color="gray.500"
                    fontWeight="500"
                    textAlign="center"
                    border="1px solid"
                    w="500px"
                    h="auto"
                    borderColor="gray.300"
                    borderRadius="md"
                  />
                ) : (
                  <Center>
                    <Text
                      paddingY={1}
                      color="gray.500"
                      fontWeight="500"
                      textAlign="center"
                      border="1px solid"
                      w="500px"
                      h="auto"
                      borderColor="gray.300"
                      borderRadius="md"
                    >
                      {t('NEOEMAIL.CREATE.PRODUCT.NONE')}
                    </Text>
                  </Center>
                )}
              </Grid>

              <Center minW="520px" height="275px">
                {!isFetching && data ? (
                  <Grid
                    w="500px"
                    gridTemplateColumns="1fr"
                    mt={2}
                    alignSelf="start"
                    rowGap="1rem"
                    maxH="275px"
                    overflow="auto"
                  >
                    {data
                      .filter(({ product_code }) => !product || product_code !== product.product_code)
                      .slice(0, 20)
                      .map((productData) => (
                        <ProductSelectElement
                          key={`possible_${productData.product_code}`}
                          product={productData}
                          onClick={(product) => setProduct(product)}
                          buttonLabel={t('NEOEMAIL.CREATE.PRODUCT.SELECT')}
                        />
                      ))}
                  </Grid>
                ) : (
                  isFetching && <Spinner />
                )}
              </Center>
            </Stack>
          </Stack>
        </GridItem>
        <GridItem height="calc(100vh - 400px)">
          <Stack height="100%" width="100%">
            <Title>{t('NEOEMAIL.CREATE.CUSTOMERS.TITLE')}</Title>
            <RadioGroup
              onChange={(value) => setCustomerSourceType(value as CustomerSourceType)}
              value={customerSourceType}
            >
              <Radio value="type_in" name="customer_source_type" mr={5}>
                {t('NEOEMAIL.CREATE.PRODUCTS.BY_CUSTOMERS_ID')}
              </Radio>
              <Radio value="algorithm" name="customer_source_type">
                {t('NEOEMAIL.CREATE.PRODUCTS.BY_PRODUCTS_INTERESTS')}
              </Radio>
            </RadioGroup>
            {customerSourceType === 'type_in' && (
              <FormControl isInvalid={Boolean(errors.ids)}>
                <FormLabel>{t('NEOEMAIL.CREATE.CUSTOMERS.IDS')}</FormLabel>
                <Textarea w="100%" minH={{ base: '100px', md: '300px' }} {...register('ids')} />
              </FormControl>
            )}
            {customerSourceType === 'algorithm' && (
              <Stack flexGrow={1} gap={2} width="100%">
                <NeoEmailCustomersMapFilter
                  id="customers-map-slider"
                  value={maxCustomers}
                  onChange={(value) => setValue('maxCustomers', value)}
                />

                <StyledBox>
                  <Suspense fallback={<PageSpinner />}>
                    {!isNeoEmailPointFetching ? (
                      <ParentSize>
                        {({ width, height }) => (
                          <Map
                            id="customers-map"
                            key={`${width}-${height}-${maxCustomers}`}
                            width={width + 100}
                            height={height + 100}
                            points={points}
                            pointData={pointData}
                            colorScale={colorScale}
                          />
                        )}
                      </ParentSize>
                    ) : (
                      <PageSpinner />
                    )}
                  </Suspense>
                </StyledBox>
              </Stack>
            )}
          </Stack>
        </GridItem>
      </Grid>
      <HStack>
        <Button
          onClick={() => {
            setProduct(() => undefined);
            reset();
          }}
        >
          {t('NEOEMAIL.CREATE.RESET')}
        </Button>
        <Button
          isDisabled={isFetching || !product}
          isLoading={isSubmitting || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('NEOEMAIL.CREATE.CONFIRM')}
        </Button>
      </HStack>
    </Card>
  );
};
