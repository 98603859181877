import { buildChartTheme } from '@visx/xychart';

// from it (depending on amount of colors needed)
const colors = [
  '#a29ffa',
  '#ff6565',
  '#3686ff',
  '#fdd982',
  '#61e3a1',
  '#58d8fe',
  '#19282d',
  '#96ea4e',
  '#2e0d93',
  '#46a26c',
  '#8b123a',
  '#d58e43',
  '#1a6587',
];

const backgroundColor = '#fff';
const gridColor = '#1A202C';
const gridColorDark = '#718096';

type TColors = string[];
const buildColorTheme = (colors: TColors) =>
  buildChartTheme({
    backgroundColor,
    colors,
    tickLength: 4,
    gridColor,
    gridColorDark,
  });

export const theme = buildColorTheme(colors);
