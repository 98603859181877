import { useMemo } from 'react';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { byName, sortFieldByNumberOrd } from 'utils/sort';
import { useProductFamilies } from './useProductFamilies';

const empty: never[] = [];

const byIdNumeric = sortFieldByNumberOrd('id');

export const useProductFamiliesMaps = () => {
  const { data: productFamilies = empty } = useProductFamilies();
  return useMemo(() => {
    const rootFamilies = pipe(
      productFamilies,
      A.filter((p) => O.isNone(p.parent_id)),
      A.sort(byName),
    );

    const allSubfamilies = pipe(
      productFamilies,
      A.filter((p) => O.isSome(p.parent_id)),
      A.sort(byIdNumeric),
    );

    return {
      pfMap: new Map(productFamilies.map((pf) => [pf.id, pf])),
      rootFamilies,
      allSubfamilies,
      productFamilies,
    };
  }, [productFamilies]);
};
