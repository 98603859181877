import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, IconButton, FlexProps } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { CardTitle } from './CardTitle';

interface Props extends React.ComponentPropsWithoutRef<typeof CardTitle> {
  settings?: React.ReactNode;
  onBackButtonClick?: () => void;
  backButtonLink?: string;
  backButtonAriaLabel?: string;
  alignItems?: FlexProps['alignItems'];
}

const CardTitleSectionBackButton: React.FC<
  Pick<Props, 'onBackButtonClick' | 'backButtonLink' | 'backButtonAriaLabel'>
> = ({ onBackButtonClick, backButtonLink, backButtonAriaLabel }) => {
  const { t } = useTranslation('whisperme');

  if (typeof backButtonLink === 'string' || typeof onBackButtonClick === 'function') {
    const buttonProps = Object.assign(
      {
        'aria-label': backButtonAriaLabel ?? t('COMPONENTS.BACK_BUTTON.ARIA_LABEL'),
        mr: '2',
        size: 'sm',
        variant: 'ghost',
        onClick: onBackButtonClick,
      },
      typeof backButtonLink === 'string'
        ? {
            as: Link,
            to: backButtonLink,
          }
        : undefined,
    );

    return (
      <IconButton {...buttonProps}>
        <ArrowBackIcon />
      </IconButton>
    );
  }

  return null;
};

export const CardTitleSection: React.FC<Props> = ({
  children,
  settings = null,
  onBackButtonClick,
  backButtonLink,
  backButtonAriaLabel,
  alignItems,
  ...rest
}) => {
  return (
    <Flex direction="row" flexWrap="wrap" alignItems={alignItems ?? 'center'} mb="3">
      <CardTitleSectionBackButton
        onBackButtonClick={onBackButtonClick}
        backButtonAriaLabel={backButtonAriaLabel}
        backButtonLink={backButtonLink}
      />
      <CardTitle flexWrap="nowrap" mb="0" {...rest}>
        {children}
      </CardTitle>
      {settings}
    </Flex>
  );
};
