import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { createRoleDetailsKey, createRolesListKey } from 'constants/queryCacheKeys';
import { getRoleById, GetRoleByIdParams, TRoleDetails, TRoleList } from 'domain/roles';

type DataType = TRoleDetails;
type ErrorType = Error;

export const useRoleDetails = (params: GetRoleByIdParams, options?: UseQueryOptions<DataType, ErrorType, any>) => {
  const queryClient = useQueryClient();

  return useQuery<DataType, ErrorType>(createRoleDetailsKey(params), () => getRoleById(params), {
    initialData: () =>
      (queryClient.getQueryData(createRolesListKey()) as TRoleList | undefined)?.items.find(
        (d) => d.id === params.roleId,
      )?.role,
    ...options,
  });
};
