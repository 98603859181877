import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pipe, flow } from 'fp-ts/function';
import { map } from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { Center, Skeleton, Stack, Table, Tbody, Td, Th, Thead, Tr, Text, Link, Box } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { TStoreId } from 'domain/stores';
import { useReceiptErrorList, useStoresDataMap } from 'hooks/data';
import { GetReceiptErrorListParams, TErrorId } from 'domain/statistics';
import { ImportFailureListReceiptModal } from './ImportFailureListReceiptModal';

interface Props {
  storeIds: TStoreId[];
  topProductsCount?: number;
  lastDaysOptions?: number[];
  calendarProps: any;
  children?: never;
}

export const ImportFailureList: React.FC<Props> = ({ storeIds, calendarProps }) => {
  const { t } = useTranslation('whisperme');

  const [currentErrorId, setCurrentErrorId] = useState<TErrorId | undefined>(undefined);
  const allStoresMap = useStoresDataMap();

  const onModalClose = () => setCurrentErrorId(undefined);

  const failedReceiptsParams = useMemo(
    (): GetReceiptErrorListParams => ({
      start_time: calendarProps.startDate,
      end_time: calendarProps.endDate,
      store_ids: storeIds,
    }),
    [calendarProps, storeIds],
  );
  const { data, isLoading } = useReceiptErrorList(failedReceiptsParams);

  const getStoreName = (storeId: TStoreId) => allStoresMap.get(storeId)?.name ?? storeId;

  return (
    <Box>
      <Card>
        <CardTitleSection as="h2">
          {t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.IMPORT_FAILURE_LABEL')}
        </CardTitleSection>
        <Stack spacing="4">
          <Stack position="relative">
            <Table variant="products">
              <Thead>
                <Tr>
                  <Th>{t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.STORE_NAME_LABEL')}</Th>
                  <Th>{t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.FAILURE_MESSAGE_LABEL')}</Th>
                  <Th textAlign="center">{t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.RECEIPT_SOURCE_LABEL')}</Th>
                  <Th isNumeric={true}>{t('SETTINGS.PROCESSING_PAGE.IMPORT_FAILURES.CHECKOUT_DATE_LABEL')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pipe(
                  data,
                  O.fromNullable,
                  O.match(
                    () => [
                      <Tr>
                        {Array.from({ length: 4 }).map((_v, tdIdx) => (
                          <Td key={tdIdx}>
                            <Skeleton minHeight="1em" />
                          </Td>
                        ))}
                      </Tr>,
                    ],
                    flow(
                      map((p) => (
                        <Tr key={p.id}>
                          <Td>{getStoreName(p.store_id)}</Td>
                          <Td>{p.message}</Td>
                          <Td textAlign="right">
                            <Link href="#" color="blue.500" onClick={() => setCurrentErrorId(p.id)}>
                              {p.source.split(':')[0]}
                            </Link>
                          </Td>
                          <Td textAlign="right">{p.checkout_date}</Td>
                        </Tr>
                      )),
                    ),
                  ),
                )}
              </Tbody>
            </Table>
            {!isLoading && data?.length === 0 && (
              <Center minHeight="10">
                <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
              </Center>
            )}
          </Stack>
        </Stack>
      </Card>
      <ImportFailureListReceiptModal onClose={onModalClose} errorId={currentErrorId} />
    </Box>
  );
};
