import { TRoleId } from 'domain/roles';
import { TUser } from 'domain/user';

export interface TUserFormData extends Omit<TUser, 'roles'> {
  password?: string;
  password_confirm?: string;
  roles: number[];
}

export const userDetailsToFormData = (userDetails: TUser): TUserFormData => ({
  display_name: userDetails.display_name,
  email: userDetails.email,
  is_admin: Boolean(userDetails.is_admin),
  user_type_id: userDetails.user_type_id,
  roles: userDetails.roles,
  password: undefined,
});

export const formDataToUserDetails = (formData: TUserFormData): TUser => ({
  display_name: formData.display_name,
  email: formData.email,
  is_admin: Boolean(formData.is_admin),
  user_type_id: formData.user_type_id,
  roles: formData.roles as TRoleId[],
  password:
    typeof formData.password === 'string' && formData.password !== '' && formData.password === formData.password_confirm
      ? formData.password
      : undefined,
});
