import React, { useState, VFC } from 'react';
import { ButtonGroup, Flex, Stack, Text, IconButton, useToast } from '@chakra-ui/react';
import { sendNeoChatResponseFeedback, ChatSource, DisplayFormat } from 'domain/neochat';
import { ThumbDownIcon, ThumbUpIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';

interface ChatMessageProps {
  index: number;
  questionId?: number;
  source: ChatSource;
  message: string;
  displayFormat: DisplayFormat;
  compact?: boolean;
}

export enum FeedbackOptions {
  VALID = 'valid',
  INVALID = 'invalid',
}

export const ChatMessage: VFC<ChatMessageProps> = ({
  index,
  questionId,
  source,
  message,
  displayFormat,
  compact = false,
}) => {
  const { t } = useTranslation('whisperme');
  const toast = useToast();
  const [feedbackIconsDisplay, setFeedbackIconsDisplay] = useState(questionId ? true : false);
  const feedback = function (questionId: number, status: FeedbackOptions) {
    sendNeoChatResponseFeedback({ question_id: questionId, status: status })
      .then(() => {
        setFeedbackIconsDisplay(false);
      })
      .catch(() => {
        toast({
          title: t('COMPONENTS.ERROR_MESSAGES.GENERIC_TEXT'),
          description: t(`NEOCHAT.NOTIFICATIONS.ERROR`),
          status: 'error',
        });
      });
  };

  if (source === ChatSource.QUESTION)
    return (
      <Flex key={index} w="100%" justify="flex-end">
        <Flex bg="gray.200" color="gray.800" minW="100px" maxW="100%" my="1" p={compact ? 1 : 3}>
          <Text>{message}</Text>
        </Flex>
      </Flex>
    );
  if (source === ChatSource.SQL && displayFormat === DisplayFormat.ADVANCED)
    return (
      <Flex key={index} w="100%">
        <Flex bg="green.800" color="green.200" minW="100px" maxW="100%" my="1" p={compact ? 1 : 3} overflowX={'auto'}>
          <Text>{message}</Text>
        </Flex>
      </Flex>
    );
  if (source === ChatSource.DATA && displayFormat === DisplayFormat.ADVANCED)
    return (
      <Flex key={index} w="100%">
        <Flex bg="green.200" color="green.800" minW="100px" maxW="100%" my="1" p={compact ? 1 : 3} overflowX={'auto'}>
          <Text>{message}</Text>
        </Flex>
      </Flex>
    );
  if (source === ChatSource.ANSWER)
    return (
      <Flex key={index} w="100%">
        <Stack direction="column" gap={0}>
          <Flex bg="gray.800" color="gray.200" minW="100px" maxW="100%" mt="1" mb="0" p={compact ? 1 : 3}>
            <Text>{message}</Text>
          </Flex>
          {feedbackIconsDisplay && questionId && (
            <ButtonGroup>
              <IconButton
                icon={<ThumbUpIcon />}
                color="green.500"
                fontSize="sm"
                size="sm"
                onClick={() => {
                  feedback(questionId, FeedbackOptions.VALID);
                }}
                variant="outline"
                aria-label="Good"
              ></IconButton>
              <IconButton
                icon={<ThumbDownIcon />}
                color="red.500"
                fontSize="sm"
                size="sm"
                onClick={() => {
                  feedback(questionId, FeedbackOptions.INVALID);
                }}
                variant="outline"
                aria-label="Bad"
              ></IconButton>
            </ButtonGroup>
          )}
        </Stack>
      </Flex>
    );
  return null;
};
