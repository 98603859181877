import React, { VFC } from 'react';
import { useHistoricalProductStocks } from 'hooks/data';
import { Center, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { PageSpinner } from 'components/Spinners';
import { useTranslation } from 'react-i18next';
import { pipe } from 'fp-ts/function';
import { map } from 'fp-ts/Array';
import * as RA from 'fp-ts/ReadonlyArray';
import { eachDayOfInterval, subDays } from 'date-fns';
import { dateToYMD, getCurrentDate } from 'utils/date';
import type { THistorialProductStock, THistorialProductStockCalendar } from 'domain/stocks';
import { useUserStoreIds } from 'hooks';
import { ParseKeys } from 'i18next';
import { getOrZero } from '../StocksPage/utils';

type StockCalendarProp = {
  productCode: string;
};

const cleanData =
  (date: string, key: keyof THistorialProductStock) =>
  (data: THistorialProductStockCalendar): string => {
    if (!data[date] || (key === 'end_of_day' && date === dateToYMD(getCurrentDate()))) return '';

    return `${getOrZero(data[date][key])}`;
  };

const rows: readonly {
  readonly key: keyof THistorialProductStock;
  readonly title: ParseKeys<'whisperme'>;
}[] = [
  { key: 'start_of_day', title: 'MORNING_TAKS.STOCKS_TABLE.START_OF_DAY' },
  { key: 'delivered', title: 'MORNING_TAKS.STOCKS_TABLE.DELIVERED' },
  { key: 'quantity_regular', title: 'MORNING_TAKS.STOCKS_TABLE.SOLD_REGULAR' },
  { key: 'quantity_discount', title: 'MORNING_TAKS.STOCKS_TABLE.SOLD_DISCOUNT' },
  { key: 'wasted', title: 'MORNING_TAKS.STOCKS_TABLE.WASTED' },
  { key: 'end_of_day', title: 'MORNING_TAKS.STOCKS_TABLE.END_OF_DAY' },
] as const;

const dates = pipe(
  eachDayOfInterval({
    start: subDays(new Date(), 6),
    end: new Date(),
  }),
  map((date) => dateToYMD(date)),
);

export const StockCalendar: VFC<StockCalendarProp> = React.memo(({ productCode }) => {
  const { t } = useTranslation('whisperme');
  const userStores = useUserStoreIds();
  const { data, isFetching } = useHistoricalProductStocks({ store_id: userStores[0], product_code: productCode });

  if (!data || isFetching) return <PageSpinner />;

  if (!Object.keys(data).length)
    return (
      <Center w="100%" h="80vh">
        {t('COMPONENTS.CHARTS.NO_DATA_LABEL')}
      </Center>
    );

  return (
    <Table
      variant="products"
      size="sm"
      sx={{
        width: '100%',

        'th, td': {
          fontSize: 'xs',
          px: '2',
          py: '1',
        },

        td: {
          borderRight: '1px',
          borderLeft: '1px',
          borderColor: 'gray.100',
          backgroundColor: 'gray.100',

          '&[data-cell-variant="start_of_day"], &[data-cell-variant="end_of_day"]': {
            bg: 'blue.200',
          },

          '&[data-cell-variant="delivery"]': {
            bg: 'green.100',
          },

          '&[data-cell-variant="sold"], &[data-cell-variant="returned"]': {
            bg: 'red.50',
          },

          '&[data-cell-variant="wasted"], &[data-cell-variant="stolen"]': {
            bg: 'red.300',
          },
        },
      }}
    >
      <Thead>
        <Tr>
          <Th></Th>
          {pipe(
            dates,
            map((date) => <Th key={date}>{date}</Th>),
          )}
        </Tr>
      </Thead>
      <Tbody>
        {pipe(
          rows,
          RA.map(({ key, title }) => (
            <Tr key={key}>
              <Td textTransform="uppercase" fontWeight="bold">
                {t(title)}
              </Td>
              {pipe(
                dates,
                map((date) => <Td data-cell-variant={key}>{pipe(data, cleanData(date, key))}</Td>),
              )}
            </Tr>
          )),
        )}
      </Tbody>
    </Table>
  );
});
