import React, { Suspense, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  DashboardTab,
  DashboardTabList,
  DashboardTabPanel,
  DashboardTabPanels,
  DashboardTabs,
  TopbarHeading,
} from 'components/Dashboard';
import { RegistersIcon, RolesIcon, SettingsIcon, UsersIcon, ProcessingIcon } from 'components/Icons';

import { findSettingsNavigationMatch, settingsMatchMapping } from './utils';
import { ProductFamiliesPage } from './ProductFamiliesPage';
import { GeneralPage } from './GeneralPage';
import { CardErrorBoundary, ErrorBoundary } from 'components/ErrorMessages';
import { PageSpinner } from 'components/Spinners';
import { useUserContext } from 'hooks';
import { hasAdminAccess, hasMonitorAccess, hasSectionManagerAccess } from 'domain/user';
import { RolesPage } from './RolesPage';
import { UsersPage } from './UsersPage';
import { ProcessingPage } from './ProcessingPage';

export const SettingsPage: React.FC = () => {
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const navigationMatch = findSettingsNavigationMatch(pathname);
  const [tabIndex, setTabIndex] = useState<number>(navigationMatch);
  const { t } = useTranslation('whisperme');
  const { user } = useUserContext();

  const handlers = useMemo(() => {
    return {
      handleTabsChange: (index: number) => {
        const [_newMatch, newPath] = settingsMatchMapping[index];
        if (typeof newPath === 'string') {
          replace(newPath);
          setTabIndex(index);
        }
      },
    };
  }, [replace]);

  const { tabs, panels } = useMemo(() => {
    const isAdmin = hasAdminAccess(user);
    const isSectionManager = hasSectionManagerAccess(user);
    const isMonitor = hasMonitorAccess(user);

    const tabs = [
      <DashboardTab key="general">
        <SettingsIcon /> {t('GENERIC.NAVIGATION.SETTINGS.GENERAL_LABEL')}
      </DashboardTab>,
      <DashboardTab key="product-catalog">
        <RegistersIcon /> {t('GENERIC.NAVIGATION.SETTINGS.PRODUCT_FAMILIES_LABEL')}
      </DashboardTab>,
    ];
    const panels = [
      <DashboardTabPanel key="general">
        <TopbarHeading>
          {[t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL'), t('GENERIC.NAVIGATION.SETTINGS.GENERAL_LABEL')]}
        </TopbarHeading>
        <GeneralPage />
      </DashboardTabPanel>,
      <DashboardTabPanel key="product-catalog">
        <TopbarHeading>
          {[t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL'), t('GENERIC.NAVIGATION.SETTINGS.PRODUCT_FAMILIES_LABEL')]}
        </TopbarHeading>
        <ProductFamiliesPage />
      </DashboardTabPanel>,
    ];

    if (!isSectionManager && !isMonitor) {
      tabs.push(
        <DashboardTab key="processing">
          <ProcessingIcon /> {t('GENERIC.NAVIGATION.PROCESSING_LABEL')}
        </DashboardTab>,
      );

      panels.push(
        <DashboardTabPanel key="processing">
          <TopbarHeading>
            {[t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL'), t('GENERIC.NAVIGATION.PROCESSING_LABEL')]}
          </TopbarHeading>
          <ProcessingPage />
        </DashboardTabPanel>,
      );
    }
    // NOTE(m.kania): users api throws 500 when user without { is_admin: true } sends the request,
    // so restricting it to is_admin users only
    if (isAdmin) {
      tabs.push(
        <DashboardTab key="users">
          <UsersIcon /> {t('GENERIC.NAVIGATION.SETTINGS.USERS_LABEL')}
        </DashboardTab>,
        <DashboardTab key="roles">
          <RolesIcon /> {t('GENERIC.NAVIGATION.SETTINGS.ROLES_LABEL')}
        </DashboardTab>,
      );

      panels.push(
        <DashboardTabPanel key="users">
          <TopbarHeading>
            {[t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL'), t('GENERIC.NAVIGATION.SETTINGS.USERS_LABEL')]}
          </TopbarHeading>
          <CardErrorBoundary>
            <UsersPage />
          </CardErrorBoundary>
        </DashboardTabPanel>,
        <DashboardTabPanel key="roles">
          <TopbarHeading>
            {[t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL'), t('GENERIC.NAVIGATION.SETTINGS.ROLES_LABEL')]}
          </TopbarHeading>
          <CardErrorBoundary>
            <RolesPage />
          </CardErrorBoundary>
        </DashboardTabPanel>,
      );
    }

    return {
      tabs,
      panels,
    };
  }, [t, user]);

  return (
    <DashboardTabs index={tabIndex} onChange={handlers.handleTabsChange}>
      <DashboardTabList>{tabs}</DashboardTabList>

      <ErrorBoundary>
        <Suspense fallback={<PageSpinner />}>
          <DashboardTabPanels>{panels}</DashboardTabPanels>
        </Suspense>
      </ErrorBoundary>
    </DashboardTabs>
  );
};
