import { Card, CardTitleSection } from 'components/Card';
import { Center, Grid, GridItem, HStack, Select, Text } from '@chakra-ui/react';
import { StatisticsDetails, SupermarketDisplayProps, TStoreId } from 'domain/stores';
import { useStoresDataMap } from 'hooks/data';
import { useTranslation } from 'react-i18next';
import { useUserStoreIds } from 'hooks';
import React, { useState } from 'react';
import { getWeek } from 'date-fns';
import { useAssortmentStatistics } from './hooks';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { getOrEmptyString } from 'pages/MorningTasksPage/StocksPage/utils';
import { RowTitle } from './RowTitle';
import { RowData } from './RowData';
import { ShelvesList } from './ShelvesList';

export const SupermarketSelectedAssortment: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const userStoreIds = useUserStoreIds();
  const storesMap = useStoresDataMap();
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentWeek = getWeek(new Date());
  const [selectedWeek, setWeek] = useState(getWeek(today));
  const params = {
    year: currentYear,
    week: selectedWeek,
    store_ids: userStoreIds,
  };
  const { data, isFetching } = useAssortmentStatistics(params);

  const dictData = data?.reduce((dict: Record<string, StatisticsDetails>, item) => {
    const { store_id, total_products, active_products, store_active_products, shelf } = item;

    const shelfId =
      pipe(shelf, getOrEmptyString) || t('SETTINGS.ASSORTMENT_ANALYSIS.SUPERMARKET_SELECTED_ASSORTMENT.UNKNOWN_LABEL');
    if (!dict[store_id]) {
      dict[store_id] = {
        total_products,
        active_products,
        store_active_products: {},
      };
    }
    dict[store_id].store_active_products[shelfId] = store_active_products;

    return dict;
  }, {});

  if (isFetching || !data) {
    return (
      <Center w="100%" minH="2vh">
        {t('COMPONENTS.LOADING_LABEL')}
      </Center>
    );
  }
  const noData = (
    <Center w="100%" minH="2vh">
      {t('COMPONENTS.NO_DATA_LABEL')}
    </Center>
  );

  const dataTable = (
    <>
      <RowTitle label={t('SETTINGS.ASSORTMENT_ANALYSIS.SUPERMARKET_SELECTED_ASSORTMENT.TOTAL_NUMBER_OF_PRODUCTS')} />
      <RowData Display={({ data }: SupermarketDisplayProps) => <>{data?.total_products}</>} data={dictData} />
      <RowTitle
        label={t('SETTINGS.ASSORTMENT_ANALYSIS.SUPERMARKET_SELECTED_ASSORTMENT.NUMBER_OF_MONITOR_SELECTED_PRODUCTS')}
      />
      <RowData Display={({ data }: SupermarketDisplayProps) => <>{data?.active_products}</>} data={dictData} />
      <RowTitle
        label={t(
          'SETTINGS.ASSORTMENT_ANALYSIS.SUPERMARKET_SELECTED_ASSORTMENT.NUMBER_OF_SHELF_MANAGER_SELECTED_PRODUCTS',
        )}
      />
      <RowData Display={ShelvesList} data={dictData} />
    </>
  );

  return (
    <Card>
      <CardTitleSection as="h2">
        {t('SETTINGS.ASSORTMENT_ANALYSIS.SUPERMARKET_SELECTED_ASSORTMENT.PAGE_TITLE')}
      </CardTitleSection>

      <Grid width="100%" templateColumns={`1fr repeat(${R.toArray(dictData ?? {}).length}, 12rem)`} gap="0">
        <GridItem fontWeight="bold" p="2">
          <HStack>
            <Text as="span">{t('SETTINGS.ASSORTMENT_ANALYSIS.FILTERS.WEEK_LABEL')}</Text>
            <Select
              size="sm"
              w="100px"
              value={selectedWeek}
              onChange={(ev) => {
                const newWeek = parseInt(ev.target.value, 10);
                setWeek(newWeek);
              }}
            >
              {[-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <option
                  value={selectedWeek + i}
                  key={`week-${selectedWeek + i}`}
                  selected={selectedWeek === currentWeek}
                >
                  {selectedWeek + i}
                </option>
              ))}
            </Select>
          </HStack>
        </GridItem>

        <RowData
          Display={({ store_id }: SupermarketDisplayProps) => (
            <Text fontWeight="bold">{storesMap?.get((store_id ?? '') as TStoreId)?.name}</Text>
          )}
          data={dictData}
        />

        {!isFetching && !data.length ? noData : dataTable}
      </Grid>
    </Card>
  );
};
