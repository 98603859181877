import { useMemo, useReducer } from 'react';
import { scaleOrdinal } from '@visx/scale';

import { ChartMetric, InteractiveChartMetric } from './types';
import { colors } from './chartThemes';
import { useUpdateEffect } from 'react-use';
import { useAppConfig } from 'hooks';

const prepareChartMetrics = (chartMetrics: ChartMetric[]) => chartMetrics.map((m) => ({ ...m, visible: true }));

type State = InteractiveChartMetric[];

// TODO(m.kania): add something for highlighting/hovering?
type ActionTypes =
  | {
      type: 'toggle-by-dataKey';
      dataKey: string;
    }
  | {
      type: 'toggle-by-index';
      index: number;
    }
  | { type: 'toggle-all'; visibility: boolean }
  | {
      type: 'update-chart-metrics';
      chartMetrics: ChartMetric[];
    };

const reducer = (state: State, action: ActionTypes) => {
  const toggleVisibility = (m: ChartMetric, visibility: boolean) => ({
    ...m,
    visible: visibility,
  });
  switch (action.type) {
    case 'toggle-by-dataKey':
      return state.map((m) => (m.dataKey === action.dataKey ? { ...m, visible: !m.visible } : m));
    case 'toggle-by-index':
      return state.map((m, idx) => (idx === action.index ? { ...m, visible: !m.visible } : m));
    case 'toggle-all':
      return state.map((m) => toggleVisibility(m, action.visibility));
    case 'update-chart-metrics':
      return prepareChartMetrics(action.chartMetrics);
    default:
      return state;
  }
};

interface UseInteractiveChartMetricsOptions {
  colors?: string[];
  animated?: boolean;
}

const DEFAULT_OPTIONS = {
  colors,
  animated: true,
};

export const useInteractiveChartMetrics = (
  chartMetrics: ChartMetric[],
  opts: UseInteractiveChartMetricsOptions = DEFAULT_OPTIONS,
) => {
  const [metrics, dispatch] = useReducer(reducer, [], () => prepareChartMetrics(chartMetrics));
  const { canAnimate } = useAppConfig();

  useUpdateEffect(() => {
    dispatch({ type: 'update-chart-metrics', chartMetrics });
  }, [chartMetrics]);

  const handlers = useMemo(
    () => ({
      toggleMetricByDataKey: (dataKey: string) => dispatch({ type: 'toggle-by-dataKey', dataKey }),
      toggleMetricByIndex: (index: number) => dispatch({ type: 'toggle-by-index', index }),
      toggleAllMetrics: (visibility: boolean) => dispatch({ type: 'toggle-all', visibility }),
    }),
    [],
  );

  const settings = useMemo(() => {
    const showLegend = chartMetrics.length > 1;

    return {
      legendScale: showLegend
        ? scaleOrdinal({
            domain: chartMetrics.map((m) => m.name),
            range: opts?.colors ?? DEFAULT_OPTIONS.colors,
          })
        : undefined,
    };
  }, [chartMetrics, opts]);

  return {
    ...handlers,
    ...settings,
    canAnimate: canAnimate && Boolean(opts?.animated ?? DEFAULT_OPTIONS.animated),
    metrics,
    isMetricVisibleByIndex: (index: number) => Boolean(metrics[index]?.visible),
  };
};
